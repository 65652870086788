import { ChevronLeftIcon, ChevronRightIcon, SearchIcon, UserCircleIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from 'rc-pagination';
import { useState } from 'react';
import { useGetStationsOrSearchQuery } from '../../Features/stationsSlice';
import LoadingSpinner from '../LoadingSpinner';
import swal from 'sweetalert';

function ManageStationsView({}) {
    const navigate = useNavigate();
    const defaultPagination = 19;
    const [search, setSearch] = useState(0)
    const [pager, setPager] = useState("")

    const {
      data: stations,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetStationsOrSearchQuery({paginate: defaultPagination, search: search, page: pager})

    const [input, setInput] = useState({
      search: ''
    })

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value})
    if(input.search != 0){
      setSearch(input.search)
    }else {

      setSearch(0)
    }
  }

  const editStation = (e, id)=>{
    if(window.confirm("Are you sure you want to edit this station?")){
        navigate("/user/edit-station/"+id)
    }
  }

  const handDeleteStation = async(e,id)=>{
    if(window.confirm("Are you sure you want to delete this role?")){
        try {
        // const resp = await deleteSystemRole(id).unwrap();
        // swal("Success",resp.message);
    } catch (err) {
        swal("Error",err.message,'error');
    }
      }
}

    const onChange = (currentPage) => {
      setPager(currentPage)
    }
    
    return (
      <div className="bg-gray-100">
          <div className="md:flex items-center md:justify-between bg-white p-1">
              <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Stations Management</h1>
              <div className='flex items-center space-x-4'>
                <form className="w-full max-w-md">
                  <div className="relative flex items-center text-gray-400 focus-within:text-gray-600">
                    <SearchIcon className="w-4 h-4 absolute ml-3 pointer-events-none" />
                      <input 
                        type="text"
                        name="search"
                        value={input.search}
                        onChange={handleChange}
                        placeholder="Search station"
                        autoComplete="off"
                        aria-label="Search user"
                        className="w-full pr-3 pl-10 px-3 py-1 bg-gray-300 text-sm placeholder-gray-500 text-black rounded-md border-none outline-none"
                      /> 
                  </div>
                </form>
                <Link 
                  to="/user/add-new-station" 
                  className="w-24 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800">
                    Create
                </Link>
              </div>
            </div>
            {(isSuccess)?
                (stations.hasOwnProperty('success') === true && stations.data.data.length > 0)?
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-500 shadow-lg">
                    <tr>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Station
                    </th>
                    <th
                        scope="col"
                        className="flex justify-center px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Description
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                    </th>
                  </tr>
                </thead>  
                <tbody className="divide-y divide-gray-200">
                        { (stations.hasOwnProperty('success') === true && stations.data.data.length > 0)?
                            stations.data.data.map(data => (
                                <tr key={data.id} className="hover:bg-gray-200">
                                    <td className="px-6 py-1 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="flex items-center space-x-4 text-sm font-medium text-gray-900">
                                            <input 
                                                type="checkbox" 
                                                name="roles[]" 
                                                value={data.id}
                                                onChange={handleChange}
                                            />
                                            <label className=''>{data.name}</label>
                                        </div>
                                    </div>
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm ">
                                        <div className=''>{data.description}</div>
                                    </td>
                                    <td onClick={(e)=>editStation(e,data.id)} className="px-1 py-0 whitespace-nowrap">
                                      <div className='flex justify-center md:w-20 w-14 mr-4 mt-1 bg-gray-400 text-sm rounded-md hover:bg-gray-500 cursor-pointer'>
                                          <div className=''>
                                              Edit
                                          </div>
                                      </div>
                                  </td>
                                    <td onClick={(e)=>handDeleteStation(e,data.id)} className="px-1 py-0 whitespace-nowrap">
                                        <div className='flex justify-center md:w-20 w-14 sm:14 mr-4 mt-1 bg-gray-400 text-sm rounded-md hover:bg-gray-500 cursor-pointer'>
                                            <div className=''>
                                                Delete
                                            </div>
                                        </div>
                                    </td>
                                        
                                </tr>
                                ))
                                : ''
                        }
                </tbody>
              </table>
                  :
                    <div>No users record found</div>
              : isLoading?
                    <LoadingSpinner />
                : isError?
                    <div className="md:flex p-4">
                      <p className="text-blue-500 italic border-b border-black flex flex-1">Data not found</p>
                    </div>
                : null
              }
          <div className="flex justify-center p-1 mt-2 mb-2">
            <div>
              {(isSuccess)?
                (stations.hasOwnProperty('success') === true && stations.data.data.length > 0)?
                (stations.data.total > 19)?
                  <Pagination
                    className="flex items-center space-x-3 text-blue-800 cursor-pointer"
                    // activePage={this.state.activePage}

                    defaultPageSize={19}
                    total={stations.data.total}
                    current={stations.data.current_page}
                    onChange={onChange}
                    prevIcon={<ChevronLeftIcon className='h-6 w-10 text-blue-700 cursor-pointer hover:text-blue-500' />}
                    nextIcon={<ChevronRightIcon className='h-6 w-10 text-blue-700 cursor-pointer hover:text-blue-500' />}
                  />
                : null  
                : null
                : null
              }
            </div>
          </div>
      </div>
    )
}

export default ManageStationsView;
