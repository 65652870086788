import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import swal from 'sweetalert'
import LoadingSpinner from '../LoadingSpinner';
import { useCreateTimeMutation } from '../../Features/timeSlice';

function CreateTimeView() {
    const navigate = useNavigate();

    const [input, setInput] = useState({
        time: '',
    })

    const [errors, setErrors] = useState({
        time: '',
    })

    const [createTime, {isLoading}] = useCreateTimeMutation();

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await createTime(input).unwrap()
            swal("Success",resp.message,"success");
            navigate(-1);
        } catch (err) {
            console.log(err)
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors, time: err.data.errors.time})
            }else if (err.data.hasOwnProperty('error') == true){
                setInput({...input, errorMassage: err.data.error})
            }
        }          
    }

    const back = ()=> navigate(-1);

    let constent = isLoading  
                ? <LoadingSpinner />
        :
        (
            <div className="bg-gray-100">
                <div className="flex items-center justify-center bg-white p-1 mb-10">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Time management</h1>
                </div>
                <div class="mt-10 sm:mt-0  md:pt-4">
                    <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                        <div class="mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class=" shadow overflow-hidden sm:rounded-md">
                                        <div class="col-span-6 p-4 shadow-md bg-gray-700 uppercase">
                                            <div className='flex justify-center'>
                                                <h1 className='uppercase font-semibold text-sm  text-white'>Create time</h1>
                                            </div>
                                        </div>
                                <div class="px-4 py-5 bg-gray-200 sm:p-6">
                                    <div class="grid grid-cols-2 gap-4">
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="name" class="block text-sm font-medium text-gray-700">Time</label>
                                            <input 
                                                class="mt-1 p-2 text-sm outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Enter time...'
                                                name="time" 
                                                id="time" 
                                                value={input.time}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.time}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-center space-x-6 items-center px-4 py-4 bg-gray-300 text-right sm:px-6">
                                    <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Cancel</div>
                                    <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Save</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    return constent;
}

export default CreateTimeView;
