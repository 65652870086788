import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import swal from 'sweetalert'
import Home from '../../HeaderLayouts/Home';
import { useTicketVerificationMutation } from '../../Features/ticketsSlice';
import FrontEndLoadingSpinner from '../FrontEndLoadingSpinner';

function VerifyTicketView() {
    const navigate = useNavigate();

    const [search, setSearch] = useState(false)
    const [input, setInput] = useState({
        token: '',
    })

    const [errors, setErrors] = useState({
        token: '',
    })

    const [ticketVerification, {isLoading}] = useTicketVerificationMutation();

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        let data = {
            token: input.token,
        }
        try {
            const resp = await ticketVerification(data).unwrap()
            if(resp.success == true){
                setSearch(resp.data);
                    let customerInfoForm = document.getElementById("info");
                    customerInfoForm.classList.remove("hidden");
            }
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                swal("Search field is empty",err.data.errors.token[0],"error");
            }else if (err.data.hasOwnProperty('error') == true){
                setSearch(false);
                let customerInfoForm = document.getElementById("info");
                customerInfoForm.classList.add("hidden");
                swal("Search not found",err.data.message,"error");
            }
        }          
    }

    const formatDisplayDate = (formatDate)=>{
        let tempDate = new Date(formatDate);
        let month = tempDate.toLocaleString('default',{month: 'short'});
        return month + "-" + tempDate.getDate() + "-" + tempDate.getFullYear();
    }

    const back = ()=> navigate(-1);

    let constent = isLoading  
                ? <FrontEndLoadingSpinner />
        :
        (
            <div className="bg-white">
                <div className="sticky top-0 z-50">
                    <Home />
                </div>
                <div class="mt-12 md:pt-4 mb-10">
                    <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                        <div class="mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class=" shadow overflow-hidden sm:rounded-md">
                                    <div class="col-span-6 p-4 shadow-md bg-orange-400 uppercase">
                                        <div className='flex justify-center'>
                                            <h1 className='uppercase font-semibold text-sm'>Ticket Verification</h1>
                                        </div>
                                    </div>
                                <div class="bg-gray-100 sm:p-6">
                                    <div class="grid grid-cols-0 p-4">
                                        <div class="col-span-1 sm:col-span-1">
                                            <div className='flex justify-center'>
                                                <div className='flex flex-col'>
                                                    <label for="name" class="flex justify-center block text-sm font-medium text-gray-700">Ticket Token</label>
                                                    <input 
                                                        class="mt-1 p-2 text-sm outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder='Enter ticket token...'
                                                        name="token" 
                                                        id="token" 
                                                        value={input.token}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="info" class="hidden grid grid-cols-1 gap-0 mt-4 bg-gray-200">
                                    <div className="flex flex-col rounded-md">
                                        <div className="flex flex-col">
                                            <div className="pr-5 pl-5 md:pr-24 md:pl-24 pt-2 pb-2 space-y-2">
                                                <div className="flex justify-center space-x-4">
                                                    <p className="text-md font-bold text-green-500">Ticket Token:</p>
                                                    <p className="text-md font-semibold">{search.ticket_token}</p>
                                                </div>
                                                <div className="flex justify-between border-b border-gray-400 pl-2 pr-2">
                                                    <p className="text-sm font-semibold">Operator</p>
                                                    <p className="text-md text-gray-700 font-semibold">{search.name} {search.business_type}</p>
                                                </div>
                                                <div className="flex justify-between border-b border-gray-400 pl-2 pr-2">
                                                    <p className="text-sm font-semibold">Customer Name</p>
                                                    <p className="text-md">{search.fname} {search.lname}</p>
                                                </div>
                                                <div className="flex justify-between border-b border-gray-400 pl-2 pr-2">
                                                    <p className="text-sm font-semibold">Phone Number</p>
                                                    <p className="text-md">{search.phone}</p>
                                                </div>
                                                <div className="flex justify-between border-b border-gray-400 pl-2 pr-2">
                                                    <p className="text-sm font-semibold">Route</p>
                                                    <p className="text-md">{search.start_off_station_name} - {search.destination_station_name}</p>
                                                </div>
                                                <div className="flex justify-between border-b border-gray-400 pl-2 pr-2">
                                                    <p className="text-sm font-semibold">Valid Until</p>
                                                    <p className="text-md">{formatDisplayDate(search.date)} @ {search.departure_time} Hrs</p>
                                                </div>
                                                <div className="flex justify-between border-b border-gray-400 pl-2 pr-2">
                                                    <p className="text-sm font-semibold">Seat Number</p>
                                                    <p className="text-md">{search.seat_number}</p>
                                                </div>
                                                <div className="flex justify-between pl-2 pr-2">
                                                    <p className="text-sm font-semibold">Charge</p>
                                                    <p className="text-md text-red-800 font-semibold">K{search.charge}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>        
                                <div class="flex justify-center space-x-6 items-center px-4 py-6 bg-gray-200 text-right sm:px-6">
                                    <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-300 hover:bg-gray-400 cursor-pointer">Back</div>
                                    <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Submit</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    return constent;
}

export default VerifyTicketView;
