

function SideNavOptionHelper({Icon,lable}) {
    return (
      <div className="flex items-center text-sm px-6 py-1 rounded-sm mt-2 mb-2 hover:bg-teal-700 group cursor-pointer">
        <Icon className="h-7 w-7 mr-2 text-orange-300 hover:text-gray-200" />
        <div className="text-gray-200 hover:text-gray-400">{lable}</div>
      </div>
    );
  }
  
  export default SideNavOptionHelper;