import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useGetAllClientManagerUsersQuery, useGetClientByIdQuery } from '../../Features/clientsSlice';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ScriptableContext,
    Filler,
  } from 'chart.js';
  import { Bar, Line, Pie } from 'react-chartjs-2';
import { useGetTeamSalesReportReviewMutation } from '../../Features/dataAnalysisReportingSlice';
import jsPDF from "jspdf";
import { ArrowCircleDownIcon, DocumentIcon } from '@heroicons/react/solid';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import swal from 'sweetalert'
import { useState } from 'react';
import { useGetClientManagerusersWithoutPaginationQuery } from '../../Features/usersSlice';
import ClientSalesHeaderView from './ClientSalesHeaderView';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

function ClientSalesTeamsSalesReportView() {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
    const { admin_user_id } = useParams();

    const [teamData, setTeamData] = useState(false)
    const [teamProfile, setTeamProfile] = useState(false)
  
    const client_id = user.client_id;

    const {
        data: client,
        isSuccess: isSuccessClient,
        isLoading: isLoadingClient,
        isError: isErrorClient,
        error: errorClient
    } = useGetClientByIdQuery(client_id);

    const {
      data: users,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetClientManagerusersWithoutPaginationQuery(client_id)

  const [getTeamSalesReportReview, {isLoading: isLoadingTeamSales, isSuccess: isSuccessTeamSales, isErro: isErrorTeamSales}] = useGetTeamSalesReportReviewMutation()

  const handleFetchTeamReport = async(e,admin_user_id)=>{
    try {
        const resp = await getTeamSalesReportReview(admin_user_id).unwrap();
        if(resp.success){
            resp.data.map(data=>(
              (data.profile)?
                  setTeamProfile(data.profile)
                :null
              ))
            setTeamData(resp)
        }
    } catch (err) {
      return swal("",err.data.message,"error");
    }
}

const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getFullYear();
  }

  const formatDisplayDateForDayMonth = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

  const formatDisplayDateForYarMonth = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

  const numberWithCommas = (x)=>{
    return (x !==null)? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
  }

  const last4WeeksGeneratePDF = ()=>{
    const chart1Image = document.getElementsByTagName('canvas')[1].toDataURL("Image/jpeg",1.0)
    const clientName = isSuccessClient && client.data.name+"_"+client.data.business_type;
    const label = isSuccessClient && client.data.name+" "+client.data.business_type;
    let pdf = new jsPDF("l", "pt", "a2");
        pdf.setFontSize(22);
        pdf.addImage(chart1Image, 'JPEG',140 ,300, 1440, 600);
        pdf.setTextColor('red');
        pdf.text(`SWIFT TICKET ${label.toUpperCase()} TEAM REVENUE REPORT`,620 ,100, 1440, 600);
        pdf.setFontSize(16);
        pdf.setTextColor('black')
        pdf.text(`Copyright 2023 Switft Ticket`,760 ,980, 1440, 1440);
        pdf.save('Swift_Ticket_'+clientName+"_team__last_4_weeks_weekly_revenue_repord.pdf");
  }

  const last12MonthsGeneratePDF = ()=>{
    const chart1Image = document.getElementsByTagName('canvas')[2].toDataURL("Image/jpeg",1.0)
    const clientName = isSuccessClient && client.data.name+"_"+client.data.business_type;
    const label = isSuccessClient && client.data.name+" "+client.data.business_type;
    let pdf = new jsPDF("l", "pt", "a2");
        pdf.setFontSize(22);
        pdf.addImage(chart1Image, 'JPEG',140 ,300, 1440, 600);
        pdf.setTextColor('red');
        pdf.text(`SWIFT TICKET ${label.toUpperCase()} TEAM REVENUE REPORT`,620 ,100, 1440, 600);
        pdf.setFontSize(16);
        pdf.setTextColor('black')
        pdf.text(`Copyright 2023 Switft Ticket`,760 ,980, 1440, 1440);
        pdf.save('Swift_Ticket_'+clientName+"_team_last_12_months_revenue_repord.pdf");
  }

  const annualReportGeneratePDF = ()=>{
    const chart1Image = document.getElementsByTagName('canvas')[3].toDataURL("Image/jpeg",1.0)
    const clientName = isSuccessClient && client.data.name+"_"+client.data.business_type;
    const label = isSuccessClient && client.data.name+" "+client.data.business_type;
    let pdf = new jsPDF("l", "pt", "a2");
        pdf.setFontSize(22);
        pdf.addImage(chart1Image, 'JPEG',140 ,300, 1440, 400);
        pdf.setTextColor('red');
        pdf.text(`SWIFT TICKET ${label.toUpperCase()} TEAM REVENUE REPORT`,620 ,100, 1440, 600);
        pdf.setFontSize(16);
        pdf.setTextColor('black')
        pdf.text(`Copyright 2023 Switft Ticket`,760 ,980, 1440, 1440);
        pdf.save('Swift_Ticket_'+clientName+"_Team_Annual_revenue_repord.pdf");
  }

  const chartBgColor = {
    id: 'chartBgColor',
    beforeDraw: (chart, options)=>{
      const{ ctx, width, height }= chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    }
  }


    return   (
      <div className="bg-gray-100">
      <ClientSalesHeaderView />
       {
          (isSuccess)?
              <div className="flex justify-center items-center bg-gray-50 p-1 mb-10">
                  <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} Stations Sales reports</h1>
              </div>
           : null
       }
        {
          (isSuccess)?
            (users.data.length)?
              <div>
                {
                  (isSuccessClient)?
                      <div class="md:mr-2 md:ml-2  lg:mr-2 lg:ml-2 bg-white rounded-b mb-10">
                        <div className='flex justify-center uppercase text-gray-700 items-center font-bold p-3 bg-gray-100 border border-gray-400 rounded-t'>
                          {`${(teamProfile)? teamProfile.team_name+' station sales reports' : 'Stations sales reports'}`}
                        </div>
                        <div className='flex bg-gray-50'>
                          <div className="w-1/6 flex flex-col justify-start p-2 h-screen border-r border-gray-300">
                            <div className='flex  justify-center items-center w-full font-bold border-b border-gray-200 text-xs p-2 mb-2'>
                              Stations
                            </div>
                            <div className='flex flex-col w-full text-xs'>
                            {
                                users.data.map(data => (
                                  <div className='text-xs'>
                                    <div onClick={(e)=>handleFetchTeamReport(e, data.id)} className='text-xs p-2 hover:bg-gray-200 hover:text-sm cursor-pointer rounded-sm'>
                                      <div className=''>{data.team_name}</div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                          <div className="w-full pb-5 bg-white p-2">
                            {
                              (isSuccessTeamSales)?
                                (teamData && teamData.data.length > 0)?
                                  <div>
                                    <div class="grid grid-cols-1">         
                                        {
                                              teamData.data.map(data => (
                                                  (data.last28Days)?
                                                  <div>
                                                  <div> 
                                                      <Line
                                                          height={200}
                                                          data={{
                                                            labels: (data.last28Days)? data.last28Days.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                            datasets: [
                                                              {
                                                                label: 'Total',
                                                                data: (data.last28Days)? data.last28Days.map(data=>data.total): '',
                                                                fill: "start",
                                                                backgroundColor: (context: ScriptableContext<"line">) => {
                                                                  const ctx = context.chart.ctx;
                                                                  const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                  gradient.addColorStop(1, 'rgba(80, 120, 255, 0.13)');
                                                                  gradient.addColorStop(0, 'rgba(250,174,50,0.6)');
                                                                  return gradient;
                                                                },
                                                                borderColor: 'rgba(80, 120, 255, 0.13)',
                                                                borderWidth: 1
                                                              },
                                                            ]
                                                          }}
                                                          options={{
                                                            bezierCurve: true,
                                                            plugins: {
                                                              color: "red",
                                                              legend: {
                                                                position: 'top',
                                                              },
                                                              title: {
                                                                display: true,
                                                                text: 'Last 28 days daily sales report',
                                                              },
                                                              tooltip: {
                                                                enabled: false
                                                              },
                                                              datalabels: {
                                                                align: 'top',
                                                                labels: {
                                                                  value: {
                                                                    color: 'black',
                                                                    font: {
                                                                      weight: ''
                                                                    }
                                                                  }
                                                                },
                                                                formatter: (value)=>{
                                                                  return numberWithCommas(value);
                                                                }
                                                              }
                                                            },
                                                            elements: {
                                                              line: {
                                                                tension: 0.1
                                                              }
                                                            },
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                              yAxes: [
                                                                {
                                                                  ticks: {
                                                                    beginAtZero: true,
                                                                    stepSize: 10
                                                                  }
                                                                }
                                                              ]
                                                            },
                                                            layout: {
                                                              padding: {
                                                                  right: 50,
                                                              }
                                                            }
                                                          }
                                                        }
                                                        plugins={[chartBgColor]}
                                                        />
                                                    </div>
                                                  </div>
                                                  :null
                                              ))
                                        }
                                    </div>
                                    <div class="grid grid-cols-1">         
                                        {
                                              teamData.data.map(data => (
                                                  (data.last4weeks)?
                                                  <div>
                                                  <div>
                                                    <div className="flex items-center text-xs justify-end">
                                                      <div onClick={last4WeeksGeneratePDF} className='bg-white hover:bg-gray-100 border border-gray-600 cursor-pointer text-xs p-0 mr-8 rounded flex items-center space-x-2 justify-center'>
                                                        <p className="">Generate PDF</p>
                                                        <DocumentIcon className="h-5 round-full text-gray-500" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div> 
                                                      <Bar
                                                          height={200}
                                                          data={{
                                                            labels: (data.last4weeks)? data.last4weeks.map(data=>formatDisplayDateForDayMonth(data.start_date)+" - "+formatDisplayDateForDayMonth(data.end_date)): '',
                                                            datasets: [
                                                              {
                                                                label: 'Total',
                                                                data: (data.last4weeks)? data.last4weeks.map(data=>data.total): '',
                                                                fill: "start",
                                                                backgroundColor: (context: ScriptableContext<"line">) => {
                                                                  const ctx = context.chart.ctx;
                                                                  const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                  gradient.addColorStop(1, 'rgba(80, 120, 255, 0.13)');
                                                                  gradient.addColorStop(0, 'rgba(250,174,50,0.6)');
                                                                  return gradient;
                                                                },
                                                                borderColor: 'rgba(80, 120, 255, 0.13)',
                                                                borderWidth: 0.6,
                                                                borderRadius: 3
                                                              },
                                                            ]
                                                          }}
                                                          options={{
                                                            bezierCurve: true,
                                                            plugins: {
                                                              color: "red",
                                                              legend: {
                                                                position: 'top',
                                                              },
                                                              title: {
                                                                display: true,
                                                                text: 'Last 4 weeks weekly sales report',
                                                              },
                                                              tooltip: {
                                                                enabled: false
                                                              },
                                                              datalabels: {
                                                                align: 'top',
                                                                labels: {
                                                                  value: {
                                                                    color: 'black',
                                                                    font: {
                                                                      weight: ''
                                                                    }
                                                                  }
                                                                },
                                                                formatter: (value)=>{
                                                                  return numberWithCommas(value);
                                                                }
                                                              }
                                                            },
                                                            elements: {
                                                              line: {
                                                                tension: 0.16
                                                              }
                                                            },
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                              yAxes: [
                                                                {
                                                                  ticks: {
                                                                    beginAtZero: true,
                                                                    stepSize: 10
                                                                  }
                                                                }
                                                              ]
                                                            }
                                                          }
                                                        }
                                                        plugins={[chartBgColor]}
                                                        />
                                                    </div>
                                                  </div>
                                                  :null
                                              ))
                                        }
                                    </div>
                                    <div class="ggrid grid-cols-2">         
                                        {
                                          teamData.data.map(data => (
                                              (data.last12Months)?
                                                <div>
                                                  <div>
                                                    <div className="flex items-center text-md justify-end">
                                                      <div onClick={last12MonthsGeneratePDF} className='bg-white hover:bg-gray-100 border border-gray-600 cursor-pointer text-xs p-0 mr-8 rounded flex items-center space-x-2 justify-center'>
                                                        <p className="">Generate PDF</p>
                                                        <DocumentIcon className="h-5 round-full text-gray-500" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div>                                                          
                                                    <Bar
                                                        height={200}
                                                        data={{
                                                        labels: (data.last12Months)? data.last12Months.map(data=>formatDisplayDate(data.monthname)): '',
                                                        datasets: [
                                                                {
                                                                label: 'Total',
                                                                data: (data.last12Months)? data.last12Months.map(data=>data.total): '',
                                                                fill: "start",
                                                                backgroundColor: (context: ScriptableContext<"line">) => {
                                                                  const ctx = context.chart.ctx;
                                                                  const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                  gradient.addColorStop(1, 'rgba(80, 120, 255, 0.13)');
                                                                  gradient.addColorStop(0, 'rgba(250,174,50,0.6)');
                                                                  return gradient;
                                                                },
                                                                borderColor: 'rgba(80, 120, 255, 0.13)',
                                                                borderWidth: 0.6,
                                                                borderRadius: 3
                                                                },
                                                        ]
                                                    }}
                                                    options={{
                                                        bezierCurve : true,
                                                        plugins: {
                                                          legend: {
                                                            position: 'top',
                                                          },
                                                          title: {
                                                            display: true,
                                                            text: 'Last 12 months monthly sales report',
                                                          },
                                                          tooltip: {
                                                            enabled: false
                                                          },
                                                          datalabels: {
                                                            align: 'top',
                                                            color: 'black',
                                                            labels: {
                                                              value: {
                                                                font: {
                                                                  weight: ''
                                                                }
                                                              }
                                                            },
                                                            formatter: (value)=>{
                                                              return numberWithCommas(value);
                                                            }
                                                          }
                                                        },
                                                        elements: {
                                                            line: {
                                                              tension: 0.55
                                                            }
                                                          },
                                                        maintainAspectRatio: false,
                                                        scales: {
                                                          yAxes: [
                                                            {
                                                              ticks: {
                                                                beginAtZero: true,
                                                              }
                                                            }
                                                          ]
                                                        }
                                                    }}
                                                    plugins={[chartBgColor]}
                                                    />
                                                  </div>
                                                </div>
                                              :null
                                          ))
                                        }
                                    </div>
                                    <div class="ggrid grid-cols-2">         
                                              {
                                                teamData.data.map(data => (
                                                  (data.annualSales)?
                                                      <div>
                                                        <div>
                                                          <div className="flex items-center text-md justify-end">
                                                            <div onClick={annualReportGeneratePDF} className='bg-gray-100 hover:bg-gray-200 border border-gray-600 cursor-pointer text-xs p-0 mr-8 rounded flex items-center space-x-2 justify-center'>
                                                              <p className="">Generate PDF</p>
                                                              <DocumentIcon className="h-5 round-full text-gray-500" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div>                                                          
                                                          <Pie
                                                              height={260}
                                                              data={{
                                                                labels: (data.annualSales)? data.annualSales.map(data=>data.year): '',
                                                                datasets: [
                                                                        {
                                                                        label: 'Total',
                                                                        data: (data.annualSales)? data.annualSales.map(data=>data.total): '',
                                                                        backgroundColor: [
                                                                            'rgba(80, 120, 255, 0.1)',
                                                                            'rgba(255, 132, 235, 0.1)',
                                                                            'rgba(54, 162, 235, 0.1)',
                                                                            'rgba(255, 206, 86, 0.1)',
                                                                            'rgba(75, 192, 192, 0.1)',
                                                                        ]
                                                                        },
                                                                ]
                                                                }}
                                                          options={{
                                                              bezierCurve : true,
                                                              plugins: {
                                                                legend: {
                                                                  position: 'top',
                                                                },
                                                                title: {
                                                                  display: true,
                                                                  text: 'Annual revenue report',
                                                                },
                                                                tooltip: {
                                                                  enabled: false
                                                                },
                                                                datalabels: {
                                                                  align: 'center',
                                                                  color: 'black',
                                                                  labels: {
                                                                    value: {
                                                                      font: {
                                                                        weight: ''
                                                                      }
                                                                    }
                                                                  },
                                                                  formatter: (value)=>{
                                                                    return numberWithCommas(value);
                                                                  }
                                                                }
                                                              },
                                                              elements: {
                                                                  line: {
                                                                    tension: 0.55
                                                                  }
                                                                },
                                                              maintainAspectRatio: false,
                                                          }}
                                                          plugins={[chartBgColor]}
                                                          />
                                                        </div>
                                                      </div>
                                                    :null
                                                ))
                                              }
                                          </div>
                                  </div>
                                :
                                  <div class="flex justify-center items-center h-96 ">
                                    <div className='flex justify-center'>
                                      <div className="flex flex-col space-y-3 text-gray-400">
                                        <div className='flex justify-center text-xs uppercase font-bold'>Choose station</div>
                                        <ArrowCircleDownIcon className='h-10 flex justify-center'/>
                                      </div>
                                    </div>
                                  </div> 
                              :isLoadingTeamSales?
                                <div class="flex justify-center items-center h-96 ">
                                    <div className='flex justify-center'>
                                      <div className="flex flex-col space-y-3 text-gray-400">
                                        <div className='flex justify-center text-xs uppercase font-bold'>Loading...</div>
                                      </div>
                                    </div>
                                  </div>
                              :isErrorTeamSales?
                                  <div class="flex justify-center items-center h-96 ">
                                    <div className='flex justify-center'>
                                      <div className="flex flex-col space-y-3 text-gray-400">
                                        <div className='flex justify-center text-xs uppercase font-bold'>Reports not available</div>
                                        <ArrowCircleDownIcon className='h-10 flex justify-center'/>
                                      </div>
                                    </div>
                                  </div>
                              :
                                <div class="flex justify-center items-center h-96 ">
                                <div className='flex justify-center'>
                                  <div className="flex flex-col space-y-3 text-gray-400">
                                    <div className='flex justify-center text-xs uppercase font-bold'>Choose station</div>
                                    <ArrowCircleDownIcon className='h-10 flex justify-center'/>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                  : isLoadingClient?
                      <LoadingSpinner />
                  : isErrorClient?
                      <div className="md:flex flex-col p-4 mb-20">
                          <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                      </div>
                  : null
                  
              }
              </div>
            :
              <div className="md:flex flex-col p-4 mb-20">
                <p className="text-blue-500 italic border-b border-black flex flex-1">Revenue report not available</p>
              </div>
          : isLoading?
            <LoadingSpinner />
          : isError?
            <div className="md:flex flex-col p-4 mb-20">
                <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
            </div>
          :null
        }
  </div>
    )
}

export default ClientSalesTeamsSalesReportView;
