
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { useGetClientRolesQuery } from '../../Features/clientRolesSlice';
import LoadingSpinner from '../LoadingSpinner';

function ManageClientRolesView(props) {
    const navigate = useNavigate();

    const {
        data: roles,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetClientRolesQuery();

    const editRole = (e, id)=>{
        if(window.confirm("Are you sure you want to edit this system role?")){
            navigate("/user/edit-sytems-role/"+id)
        }
    }

    const addRole = ()=>{
        navigate("/user/add-sytems-role");
    }
   
        return (
            <div className="bg-white flex  flex-col">
                <div className="flex items-center justify-center bg-white p-1">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Clients roles management</h1>
                </div>
                {
                    (isSuccess)?
                        <div className="">
                            <div className="md:flex justify-center mt-4 p-1">
                                    <div className="rounded-md bg-gray-100 shadow-sm pr-0 pl-0 sm:pr-0 sm:pl-0">
                                        <div className="md:pr-64 md:pl-64 lg:pr-96 lg:pl-96"></div>
                                        <div className='rounded-sm p-4 shadow-md bg-gray-700 text-sm uppercase font-semibold text-white flex justify-center '>
                                            All clients roles
                                        </div>
                                                <div className="flex flex-col justify-center">
                                                    {
                                                        (roles.hasOwnProperty('success') === true && roles.data.length > 0)?
                                                            <table className="w-full divide-y divide-gray-200">
                                                                <thead className="bg-gray-600 shadow-lg">
                                                                    <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="flex justify-center px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                                    >
                                                                        Description
                                                                    </th>
                                                                    </tr>
                                                                </thead>  
                                                                <tbody className=" divide-y divide-gray-200">
                                                                        { (roles.data)?roles.data.map(data => (
                                                                                <tr key={data.id} className="hover:bg-gray-200">
                                                                                    <td className="px-6 py-1 whitespace-nowrap">
                                                                                    <div className="flex items-center">
                                                                                        <div className="flex items-center space-x-4 text-sm font-medium text-gray-900">
                                                                                            <label className=''>{data.description}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    </td>
                                                                                </tr>
                                                                                ))
                                                                                : ''
                                                                            }
                                                                </tbody>
                                                            </table>
                                                        
                                                        :
                                                            ''
                                                    }
                                                </div>    
                                                <div className="flex justify-center mt-1 bg-gray-400 border-t border-gray-300 p-4">
                                                    <div className="flex flex-col items-center  w-28 rounded-md">                              
                                                    </div>
                                    
                                                </div>
                                    </div>
                            </div>
                        </div>
                :  isLoading
                    ?<LoadingSpinner />
                    : isError
                        ? 
                        <div className="md:flex flex-col p-4">
                            <p className="text-blue-500 italic border-b border-black flex flex-1">{error.data.error}</p>
                            <Link to={`/user/add-client`} className='flex justify-center p-8'>
                                <h1 className='bg-blue-600 hover:bg-yellow-500 p-4 text-sm font-semibold rounded-md text-white cursor-pointer'>Add Client</h1>
                            </Link>
                        </div>
                        : ''
                }
            </div>
        )
}

export default ManageClientRolesView;
