import { apiSlice } from "../app/api/apiSlice";

export const RoutesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllRoutesOrSearch: builder.query({
            query: ({paginate, search, page}) => (page == "")? `/user/routes/${search}/${paginate}` : `user/routes/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Routes']
        }),
        getSystemRoutes: builder.query({
            query: () => `/user/system-routes`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Routes']
        }),
        createRoute: builder.mutation({
            query: (data) => ({
                url: `user/create-route`,
                method: 'POST',
                body: { ...data }
            }),
            invalidatesTags: ['Routes']
        }),
        editRoute: builder.mutation({
            query: (input) => ({
                url: `user/edit-route/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['Routes']
        }),
        getRouteById: builder.query({
            query: (id) => `/user/route/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            // providesTags: ['Stations']
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `user/delete-user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User']
        }),
    })
})

export const {
    useCreateRouteMutation,
    useGetSystemRoutesQuery,
    useGetRouteByIdQuery,
    useDeleteUserMutation,
    useEditRouteMutation,
    useGetAllRoutesOrSearchQuery,
} = RoutesApiSlice

