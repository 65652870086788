import { Link, useNavigate } from "react-router-dom";
import { useGetActiveOperatorsQuery } from "../../Features/clientsSlice";
import Home from "../../HeaderLayouts/Home";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import "../slideStyles.css";


// import Swiper core and required modules
import SwiperCore, {
Autoplay,Pagination,Navigation
} from 'swiper/core';
import { useGetAllImagesForFrontEndQuery } from "../../Features/frontEndImagesSlice";
import FrontEndLoadingSpinner from "../FrontEndLoadingSpinner";

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

function HomeView(props) {  
    const navigate = useNavigate();

    const {
      data: clients,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetActiveOperatorsQuery()

    const {
        data: images,
        isLoading: isLoadingImages,
        isSuccess: isSuccessImages,
        isError: isErrorImages,
        error: errorImage
      } = useGetAllImagesForFrontEndQuery()

    console.log(images)

    return (
        <div className='bg-white'>
            <div className="sticky top-0 z-50">
                <Home />
            </div>
            <div className="flex flex-col pt-1 pb-2 bg-white shadow-xs">
                {
                    (isSuccessImages)?
                        <Swiper 
                            spaceBetween={30} 
                            centeredSlides={true} 
                            autoplay={{
                                "delay": 2500,
                                "disableOnInteraction": false
                                }} 
                            navigation={false} 
                            className="mySwiper">
                                {
                                    (images.hasOwnProperty('success') === true && images.data.length > 0)?
                                        images.data.map(data => (
                                            <SwiperSlide className="object-cover">
                                                <img 
                                                    className=""
                                                    src={`https://ticketing.autozedltd.com/front_end_images/${data.img_url}`} 
                                                    alt="Image" 
                                                />
                                            </SwiperSlide>
                                        ))
                                    :null
                                }
                        </Swiper>
                    : null
                }

            </div>
                <div className="bg-whte flex justify-between flex-wrap pr-2 pl-2 md:pr-20 md:pl-20">
                {(isSuccess)?
                (clients.hasOwnProperty('success') === true && clients.data.length > 0)?
                  clients.data.map(data => (
                      <Link to={`/operator/${data.id}`} key={data.id} className="flex mb-3 bg-gray-50 rounded-t-lg flex-col justify-around shadow-sm hover:shadow-2xl">
                          <div className="">
                            <img 
                                className="bg-gray-100 rounded-t-lg object-fill h-36 w-44 md:h-48 md:w-72"
                                src={`https://ticketing.autozedltd.com/client_logo_image/`+data.logo_image_url} 
                                alt="Image" 
                            />  
                          </div>
                          <Link to={`/operator/${data.id}`} className="flex bg-gray-200 flex-col rounded-b-sm p-1">
                              <div className="p-1 text-gray-700 font-semibold text-xs flex justify-center cursor-pointer">
                                {data.name}
                              </div>
                          </Link>
                      </Link>
                  ))
              :
                <div>No users record found</div>
                : isError?
                  <div className="h-screen p-4">
                    <p className="uppercase italic text-gray-500 flex flex-1">No operator found</p>
                  </div>
                : null   
            }
                </div>
            {
                isLoading?
                <FrontEndLoadingSpinner />
                : null
            }
        </div>
    )
}

export default HomeView;
