
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { useDeleteSystemRoleMutation, useGetSystemRolesQuery } from '../../Features/rolesSlice';
import LoadingSpinner from '../LoadingSpinner';

function ManageSystemRolesView(props) {
    const navigate = useNavigate();

    const {
        data: roles,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSystemRolesQuery();

    const [deleteSystemRole] = useDeleteSystemRoleMutation();

    const handDeleteRole = async(e,id)=>{
        if(window.confirm("Are you sure you want to delete this role?")){
            try {
            const resp = await deleteSystemRole(id).unwrap();
            swal("Success",resp.message);
        } catch (err) {
            swal("Error",err.message,'error');
        }
          }
    }

    const editRole = (e, id)=>{
        if(window.confirm("Are you sure you want to edit this system role?")){
            navigate("/user/edit-sytems-role/"+id)
        }
    }

    const addRole = ()=>{
        navigate("/user/add-sytems-role");
    }
   
        return (
            <div className="bg-gray-100 h-screen flex  flex-col">
                <div className="flex items-center justify-center bg-white p-1">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">System roles management</h1>
                </div>
                {
                    (isSuccess)?
                        <div className="">
                            <div className="md:flex justify-center mt-4 p-1">
                                    <div className="rounded-md bg-gray-200 shadow-sm pr-0 pl-0 sm:pr-0 sm:pl-0">
                                        <div className="md:pr-64 md:pl-64 lg:pr-96 lg:pl-96"></div>
                                        <div className='rounded-sm p-4 shadow-md bg-gray-700 text-sm uppercase font-semibold text-white flex justify-center '>
                                            All system roles
                                        </div>
                                                <div className="flex flex-col justify-center">
                                                    {
                                                        (roles.hasOwnProperty('success') === true && roles.data.length > 0)?
                                                            <table className="w-full divide-y divide-gray-200">
                                                                <thead className="bg-gray-600 shadow-lg">
                                                                    <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                                    >
                                                                        Role
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="flex justify-center px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                                    >
                                                                        Description
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                                    >
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                                    >
                                                                    </th>
                                                                    </tr>
                                                                </thead>  
                                                                <tbody className="bg-gray-300 divide-y divide-gray-200">
                                                                        { (roles.data)?roles.data.map(data => (
                                                                                <tr key={data.id} className="hover:bg-gray-200">
                                                                                    <td className="px-6 py-1 whitespace-nowrap">
                                                                                    <div className="flex items-center">
                                                                                        <div className="flex items-center space-x-4 text-sm font-medium text-gray-900">
                                                                                            <label className=''>{data.name}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    </td>
                                                                                    <td className="px-2 py-4 overflow-y-auto whitespace-nowrap text-sm ">
                                                                                        <div className=''>{data.description}</div>
                                                                                    </td>
                                                                                    <td onClick={(e)=>editRole(e,data.id)} className="px-1 py-0 whitespace-nowrap">
                                                                                        <div className='flex justify-center md:w-20 w-14 mr-4 mt-1 bg-gray-400 text-sm rounded-md hover:bg-gray-500 cursor-pointer'>
                                                                                            <div className=''>
                                                                                                Edit
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td onClick={(e)=>handDeleteRole(e,data.id)} className="px-1 py-0 whitespace-nowrap">
                                                                                        <div className='flex justify-center md:w-20 w-14 sm:14 mr-4 mt-1 bg-gray-400 text-sm rounded-md hover:bg-gray-500 cursor-pointer'>
                                                                                            <div className=''>
                                                                                                Delete
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                ))
                                                                                : ''
                                                                            }
                                                                </tbody>
                                                            </table>
                                                        
                                                        :
                                                            ''
                                                    }
                                                </div>    
                                                <div className="flex justify-center mt-1 bg-gray-400 border-t border-gray-300 p-4">
                                                    <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                        <label onClick={addRole} className="flex justify-center space-x-2 items-center text-sm bg-gray-500 hover:bg-gray-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                            Add Role
                                                        </label>
                                                    </div>
                                    
                                                </div>
                                    </div>
                            </div>
                        </div>
                :  isLoading
                    ?<LoadingSpinner />
                    : isError
                        ? <div>{error.data.error}</div>
                        : ''
                }
            </div>
        )
}

export default ManageSystemRolesView;
