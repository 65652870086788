import { apiSlice } from "../app/api/apiSlice";

export const ticketsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSystemRoles: builder.query({
            query: () => '/user/roles',
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Ticket']
        }),
        getAvailableSeats: builder.query({
            query: ({client_id,client_route_id,time_id,date}) => `user/available-seats/${client_id}/${client_route_id}/${time_id}/${date}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Ticket']
        }),
        getAvailableSeatsForPostponTicket: builder.mutation({
            query: (reqBoby) => ({
                url: `user/get-available-seats-for-postpon-ticket`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Ticket']
        }),
        postponTicket: builder.mutation({
            query: (reqBoby) => ({
                url: `user/postpon-ticket`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Ticket']
        }),
        processTicketInfo: builder.mutation({
            query: (reqBoby) => ({
                url: `user/process-ticket-info`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Ticket']
        }),
        ProcessPhysicalTicketData: builder.mutation({
            query: (reqBoby) => ({
                url: `user/process-physical-ticket-info`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Ticket']
        }),
        reservePhysicalTicketData: builder.mutation({
            query: (reqBoby) => ({
                url: `user/reserve-physical-ticket`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Ticket']
        }),
        processPurchaseTicketSuccessful: builder.mutation({
            query: (reqBoby) => ({
                url: `user/process-purchase-ticket-succesful`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Ticket']
        }),
        ticketVerification: builder.mutation({
            query: (reqBoby) => ({
                url: `/user/ticket-verification`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['Ticket']
        }),
    })
})

export const {
    useProcessTicketInfoMutation,
    useGetAvailableSeatsQuery,
    usePostponTicketMutation,
    useProcessPurchaseTicketSuccessfulMutation,
    useGetAvailableSeatsForPostponTicketMutation,
    useProcessPhysicalTicketDataMutation,
    useReservePhysicalTicketDataMutation,
    useTicketVerificationMutation,
} = ticketsApiSlice

