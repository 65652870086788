
import { Link, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useGetClientByIdQuery, useToggleClientStatusMutation } from '../../Features/clientsSlice';
import { useDeleteSystemRoleMutation } from '../../Features/rolesSlice';
import LoadingSpinner from '../LoadingSpinner';

function ClientDetailsView(props) {
    const navigate = useNavigate();
    const {client_id}= useParams();

    const {
        data: client,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetClientByIdQuery(client_id);

    const [deleteSystemRole] = useDeleteSystemRoleMutation();

    const handDeleteRole = async(e,id)=>{
        if(window.confirm("Are you sure you want to delete this role?")){
            try {
            const resp = await deleteSystemRole(id).unwrap();
            swal("Success",resp.message);
        } catch (err) {
            swal("Error",err.message,'error');
        }
          }
    }
    const [toggleClientStatus] = useToggleClientStatusMutation();

    const handleToggleClientStatus = async(e,id)=>{
        if(window.confirm("Are you sure you want to toggle status?")){
            try {
            const resp = await toggleClientStatus(client.data.id).unwrap();
            swal("Success",resp.message);
        } catch (err) {
            swal("Error",err.message,'error');
        }
          }
    }

    const back = ()=> navigate(-1);
   
        return (
            <div className="bg-gray-100 flex  flex-col">
                <div className="flex items-center justify-center bg-white p-1">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Client Profile</h1>
                </div>
                {
                    (isSuccess)?
                        <div className="">
                            <div className="md:flex justify-center mt-4 p-1">
                                    <div className="rounded-md bg-gray-200 shadow-sm pr-0 pl-0 sm:pr-0 sm:pl-0">
                                        <div className="md:pr-64 md:pl-64 lg:pr-96 lg:pl-96"></div>
                                        <div className='rounded-t-md p-4 shadow-md bg-gray-700 text-sm uppercase font-semibold text-white flex justify-center '>
                                            {client.data.name} profile
                                        </div>
                                        <div className="flex flex-col items-center">
                            <div className="flex justify-center p-1 bg-white w-full">
                                <img 
                                    className="bg-gray-100 object-fill h-40 w-72"
                                    src={`https://ticketing.autozedltd.com/client_logo_image/`+client.data.logo_image_url} 
                                    alt="Image" 
                                />  
                            </div>
                          </div>
                            <div className="flex flex-col justify-center bg-gray-100 rounde">
                                <div className="flex flex-col p-1  border-t border-gray-1 p-1 rounded-t-md">
                                    <div className="flex space-x-4 justify-between border-b border-gray-300 p-1">
                                    <p className="text-sm font-semibold">Business Name</p>
                                    <p className="text-sm ">{client.data.name} {client.data.business_type}</p>
                                    </div>
                                    <div className="flex space-x-4 justify-between border-b border-gray-300 p-1">
                                    <p className="text-sm font-semibold">Contact Number</p>
                                    <p className="text-sm">{client.data.phone}</p>
                                    </div>
                                    <div className="flex space-x-4 justify-between border-b border-gray-300 p-1">
                                    <p className="text-sm font-semibold">Email Address</p>
                                    <p className="text-sm">{client.data.email}</p>
                                    </div>
                                    <div className="flex space-x-4 justify-between border-b border-gray-300 p-1">
                                    <p className="text-sm font-semibold">Physical Address</p>
                                    <p className="text-sm">{client.data.physical_address}</p>
                                    </div>
                                </div>

                                    </div> 
                                    <Link to={`/user/client-routes-management/${client_id}`} className="flex justify-center mt-2 p-2 bg-blue-400 hover:bg-blue-500 cursor-pointer mt-2 border-t border-gray-200 p-1">
                                        <div className="flex flex-col">
                                            <label className="text-sm text-gray-200 uppercase text-white cursor-pointer">Manage Routes</label>
                                        </div>                           
                                    </Link>   
                                    <Link to={`/user/client-bulk-sms/${client_id}`} className="flex justify-center mt-2 p-2 bg-green-400 hover:bg-green-500 cursor-pointer mt-2 border-t border-gray-200 p-1">
                                        <div className="flex flex-col">
                                            <label className="text-sm text-gray-200 uppercase text-white cursor-pointer">Bulk SMS</label>
                                        </div>                           
                                    </Link> 
                                    <div className="flex justify-center mt-1 space-x-4 bg-gray-300 border-t border-gray-400 p-4">
                                            <div onClick={back} className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label  className="flex justify-center space-x-2 items-center text-sm bg-gray-500 hover:bg-gray-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Back
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                        {
                                                            (client.data.status == 0)? 
                                                                <label onClick={handleToggleClientStatus} className="flex justify-center space-x-2 items-center text-sm bg-gray-500 hover:bg-gray-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                                    <div className="flex justify-center">
                                                                        Active
                                                                    </div>
                                                                </label>
                                                            : 
                                                                <label onClick={handleToggleClientStatus} className="flex justify-center space-x-2 items-center text-sm bg-green-400 hover:bg-green-500 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                                    <div className="text-white flex justify-center">
                                                                        Disable
                                                                    </div>
                                                                </label>
                                                        }
                                            </div>
                                            <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <Link to={`/user/client-profile/${client.data.id}`}  className="flex justify-center space-x-2 items-center text-sm bg-blue-500 hover:bg-blue-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Profile
                                                    </div>
                                                </Link>
                                            </div>                           
                                    </div>
                                    <div className="flex justify-center mt-1 space-x-4 bg-gray-200 border-t border-gray-400 p-4">
                                        <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                            <label  className="flex justify-center space-x-2 items-center text-sm bg-red-600 hover:bg-red-700 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                <div className="text-white flex justify-center">
                                                    Delete
                                                </div>
                                            </label>
                                        </div>                          
                                    </div>
                                </div>
                            </div>
                        </div>
                :  isLoading
                    ?<LoadingSpinner />
                    : isError
                        ? <div>{error.data.error}</div>
                        : ''
                }
            </div>
        )
}

export default ClientDetailsView;
