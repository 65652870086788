
import { useEffect, useState} from 'react'
import ReactFileReader from 'react-file-reader';
import { useNavigate, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { useEditClientProfileMutation, useGetClientByIdQuery } from '../../Features/clientsSlice';
import LoadingSpinner from '../LoadingSpinner';
import Select from 'react-select';

function EditClientProfileView() {
    const navigate = useNavigate();
    const {client_id}= useParams();

    const [input, setInput] = useState({});
    const [logo_image, setLogoImage] = useState({
        logo_image: ''
    });

    const {
        data: client,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetClientByIdQuery(client_id);
    
    useEffect(()=>{
        if(isSuccess && client.hasOwnProperty("data")){
            setInput(client.data)
        }
        return () =>{
        }
    },[client]);

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        physical_address: '',
        phone: '',
        phone_2: '',
        business_type: '',
        bank_acc_number: '',
        category: '',
        logo_image: [],
        business_director_1_phone_number:'',
        business_director_1: '',
        business_director_2: '',
        business_director_2_phone_number:'',
        business_tpin_number: '',
        business_registration_id: '',
        bank_name: '',
        branch_name:'',
        branch_code: '',
        bank_account_name: '',
        business_director_1_id: '',
        business_director_1_id_type: '',
        business_director_2_id: '',
        business_director_2_id_type: '',
        contract_id: ''
    })


    const [editClientProfile] = useEditClientProfileMutation();

    const handleSubmit = async(e)=>{
        e.preventDefault();
        let data = {
            name: input.name,
            email: input.email,
            category: input.category,
            physical_address: input.physical_address,
            phone: input.phone,
            phone_2: input.phone_2,
            business_type: input.business_type,
            bank_acc_number: input.bank_acc_number,
            business_director_1_phone_number: input.business_director_1_phone_number,
            business_director_1: input.business_director_1,
            business_director_2: input.business_director_2,
            business_director_2_phone_number: input.business_director_2_phone_number,
            business_tpin_number: input.business_tpin_number,
            business_registration_id: input.business_registration_id,
            business_director_1_id: input.business_director_1_id,
            business_director_1_id_type: input.business_director_1_id_type,
            business_director_2_id: input.business_director_2_id,
            business_director_2_id_type: input.business_director_2_id_type,
            contract_id: input.contract_id,
            bank_name: input.bank_name,
            branch_name: input.branch_name,
            branch_code: input.branch_code,
            bank_account_name: input.bank_account_name,
            id: input.id,
            client_role_id: client.data.client_role_id,
            logo_image: logo_image.logo_image
        }
        try {
            const resp = await editClientProfile(data).unwrap()
            if(resp.success === true){
                swal("Success",resp.message)
                navigate(-1);
            }
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({
                    ...errors, 
                    business_type: err.data.errors.business_type,
                    name: err.data.errors.name,
                    email: err.data.errors.email,
                    phone: err.data.errors.phone,
                    phone_2: err.data.errors.phone_2,
                    category: err.data.errors.category,
                    physical_address: err.data.errors.physical_address,
                    bank_acc_number: err.data.errors.bank_acc_number,
                    logo_image: err.data.errors.logo_image,
                    business_director_1: err.data.errors.business_director_1,
                    business_director_1_phone_number: err.data.errors.business_director_1_phone_number,
                    business_director_2: err.data.errors.business_director_2,
                    business_director_2_phone_number: err.data.errors.business_director_2_phone_number,
                    business_tpin_number: err.data.errors.business_tpin_number,
                    business_registration_id: err.data.errors.business_registration_id,
                    bank_name: err.data.errors.bank_name,
                    branch_name: err.data.errors.branch_name,
                    branch_code: err.data.errors.branch_code,
                    bank_account_name: err.data.errors.bank_account_name,
                    business_director_1_id: err.data.errors.business_director_1_id,
                    business_director_1_id_type: err.data.errors.business_director_1_id_type,
                    business_director_2_id: err.data.errors.business_director_2_id,
                    business_director_2_id_type: err.data.errors.business_director_2_id_type,
                    contract_id: err.data.errors.contract_id,
                })
            }
        }          
    }

    const handleFile = (file) =>{
        setLogoImage({...logo_image, logo_image: file.base64})
    }

    const handleSetCategory = (e)=>{
        setInput({
            ...input, category: e.value
        })
    }

    const handleSetDirect1IdType = (e)=>{
        setInput({
            ...input, business_director_1_id_type: e.value
        })
    }

    const handleSetDirect2IdType = (e)=>{
        setInput({
            ...input, business_director_2_id_type: e.value
        })
    }

    const back = ()=> navigate(-1);

    let constent = (isLoading)
        ?<LoadingSpinner />
        : (
            <div className="bg-gray-100">
                <div className="flex items-center justify-center bg-white p-1 mb-10">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Client Management</h1>
                </div> 
                <div class="sm:mt-0 md:pt-1">
                    <div class="md:grid md:grid-cols-2 md:pr-40 md:pl-40">
                        <div class="bg-gray-200 mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class="sm:rounded-md">
                                <div class="">
                                    <div class="grid grid-cols-6 gap-6">
                                        <div class="col-span-6 p-4 shadow-md bg-gray-700 uppercase">
                                            <div className='flex justify-center'>
                                                <h1 className='uppercase font-semibold text-sm text-white'>edit {input.name} profile</h1>
                                            </div>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="fname" class="block text-sm font-medium text-gray-700">Name</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business name'
                                                name="name" 
                                                id="name" 
                                                value={input.name}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.name}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="fname" class="block text-sm font-medium text-gray-700">Business Type</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='e.g Plc, Lmc'
                                                name="business_type" 
                                                id="business_type" 
                                                value={input.business_type}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_type}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="country" class="block text-sm font-medium text-gray-700">Category</label>
                                            <Select 
                                                    class="block
                                                    w-full
                                                    px-3
                                                    py-1.5
                                                    text-base
                                                    font-normal
                                                    text-gray-700
                                                    bg-white bg-clip-padding
                                                    border border-solid border-gray-300
                                                    rounded
                                                    transition
                                                    ease-in-out
                                                    m-0"
                                                    placeholder="Select category"
                                                    options={[
                                                        {value:0, label:"Big bus"},
                                                        {value:1, label:"Others"}
                                                    ]}
                                                    onChange={handleSetCategory}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.category}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="phone" class="block text-sm font-medium text-gray-700">Contact number</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="number"
                                                placeholder='Business contact number'
                                                name="phone" 
                                                id="phone" 
                                                value={input.phone}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.phone}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="phone_2" class="block text-sm font-medium text-gray-700">Contact number 2</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="number"
                                                placeholder='Business contact number 2'
                                                name="phone_2" 
                                                id="phone_2" 
                                                value={input.phone_2}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.phone_2}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="email"
                                                placeholder='Business email address'
                                                name="email" 
                                                id="email" 
                                                value={input.email}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.email}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="phone" class="block text-sm font-medium text-gray-700">Physical address</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business physical location'
                                                name="physical_address" 
                                                id="physical_address" 
                                                value={input.physical_address}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.physical_address}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="business_director_1" class="block text-sm font-medium text-gray-700">Business Director 1</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business director 1'
                                                name="business_director_1" 
                                                id="business_director_1" 
                                                value={input.business_director_1}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_director_1}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="business_director_1_phone_number" class="block text-sm font-medium text-gray-700">Business Director 1/Contact number</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business director 1 contact number'
                                                name="business_director_1_phone_number" 
                                                id="business_director_1_phone_number" 
                                                value={input.business_director_1_phone_number}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_director_1_phone_number}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="country" class="block text-sm font-medium text-gray-700">Business Director 1 ID Type</label>
                                            <Select 
                                                    class="block
                                                    w-full
                                                    px-3
                                                    py-1.5
                                                    text-base
                                                    font-normal
                                                    text-gray-700
                                                    bg-white bg-clip-padding
                                                    border border-solid border-gray-300
                                                    rounded
                                                    transition
                                                    ease-in-out
                                                    m-0"
                                                    placeholder="Select ID"
                                                    options={[
                                                        {value:"NRC", label:"NRC"},
                                                        {value:"Passport", label:"Passport"},
                                                        {value:"Comapny ID", label:"Company ID"},
                                                    ]}
                                                    onChange={handleSetDirect1IdType}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_director_1_id_type}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="business_director_1_id" class="block text-sm font-medium text-gray-700">Business Director 1 ID</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business director 1 ID number'
                                                name="business_director_1_id" 
                                                id="business_director_1_id" 
                                                value={input.business_director_1_id}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_director_1_id}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="business_director_2" class="block text-sm font-medium text-gray-700">Business Director 2</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business director 2'
                                                name="business_director_2" 
                                                id="business_director_2" 
                                                value={input.business_director_2}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_director_2}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="business_director_2_phone_number" class="block text-sm font-medium text-gray-700">Business Director 2/Contact number</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business director 2 contact number'
                                                name="business_director_2_phone_number" 
                                                id="business_director_2_phone_number" 
                                                value={input.business_director_2_phone_number}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_director_2_phone_number}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="country" class="block text-sm font-medium text-gray-700">Business Director 2 ID Type</label>
                                            <Select 
                                                    class="block
                                                    w-full
                                                    px-3
                                                    py-1.5
                                                    text-base
                                                    font-normal
                                                    text-gray-700
                                                    bg-white bg-clip-padding
                                                    border border-solid border-gray-300
                                                    rounded
                                                    transition
                                                    ease-in-out
                                                    m-0"
                                                    placeholder="Select ID"
                                                    options={[
                                                        {value:"NRC", label:"NRC"},
                                                        {value:"Passport", label:"Passport"},
                                                        {value:"Comapny ID", label:"Company ID"},
                                                    ]}
                                                    onChange={handleSetDirect2IdType}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_director_2_id_type}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="business_director_2_id" class="block text-sm font-medium text-gray-700">Business Director 2 ID</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business director 2 ID number'
                                                name="business_director_2_id" 
                                                id="business_director_2_id" 
                                                value={input.business_director_2_id}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_director_2_id}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="business_registration_id" class="block text-sm font-medium text-gray-700">Business registration ID</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business registration ID'
                                                name="business_registration_id" 
                                                id="business_registration_id" 
                                                value={input.business_registration_id}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_registration_id}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="business_tpin_number" class="block text-sm font-medium text-gray-700">Business TPIN number</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Business TPIN number'
                                                name="business_tpin_number" 
                                                id="business_tpin_number" 
                                                value={input.business_tpin_number}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_tpin_number}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="bank_name" class="block text-sm font-medium text-gray-700">Bank Name</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Bank name'
                                                name="bank_name" 
                                                id="bank_name" 
                                                value={input.bank_name}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.bank_name}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="branch_name" class="block text-sm font-medium text-gray-700">Branch Name</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Branch name'
                                                name="branch_name" 
                                                id="branch_name" 
                                                value={input.branch_name}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.branch_name}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="branch_code" class="block text-sm font-medium text-gray-700">Branch code</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Branch name'
                                                name="branch_code" 
                                                id="branch_code" 
                                                value={input.branch_code}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.branch_code}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="bank_account_name" class="block text-sm font-medium text-gray-700">Bank account name</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Bank account name'
                                                name="bank_account_name" 
                                                id="bank_account_name" 
                                                value={input.bank_account_name}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.bank_account_name}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="phone" class="block text-sm font-medium text-gray-700">Bank/Acc No.</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="number"
                                                placeholder='Business bank account number'
                                                name="bank_acc_number" 
                                                id="bank_acc_number" 
                                                value={input.bank_acc_number}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.bank_acc_number}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="contract_id" class="block text-sm font-medium text-gray-700">Contract ID</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Contract ID'
                                                name="contract_id" 
                                                id="contract_id" 
                                                value={input.contract_id}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.contract_id}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-col bg-gray-200">
                                    <div className='flex flex-col items-center p-2'>
                                        <ReactFileReader 
                                            className='flex justify-center items-center space-x-2 cursor-pointer'
                                            multipleFiles={false}
                                            base64={true}
                                            handleFiles={handleFile}
                                        >
                                            <p className='bg-gray-300 pr-1 pl-1 border border-gray-400'>Upload Logo</p>
                                            {/* <input type="file" name="logo" className='text-sm' /> */}
                                        </ReactFileReader>
                                        <p className="block text-sm font-medium text-red-500 p-0 m-0">{errors.logo_image}</p>
                                    </div>
                                </div>
                                <div class="flex flex-col bg-gray-300">
                                    <div className='flex justify-center space-x-6 items-center px-4 pb-2 bg-gray-300 text-right sm:px-6 mt-2'>
                                        <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Cancel</div>
                                        <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Submit</button>
                                    </div>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    return constent;
}

export default EditClientProfileView;
