import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { Menu} from "@headlessui/react";
import ClientHeaderView from '../App/ClientHeaderView';
import { useGetClientCurrentMonthTransactionsStatementQuery, useGetClientMonthlyTransactionsStatementsDatesQuery, useGetClientSelectedMonthTransactionsStatementMutation } from '../../Features/dataAnalysisReportingSlice';
import { CalendarIcon, DocumentIcon, PrinterIcon } from '@heroicons/react/solid';
import jsPDF from "jspdf";
import swal from 'sweetalert';
import { useState } from 'react';
import { useEffect } from 'react';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';

function ClientSalesTransactionsStatementsView({}) {
    const navigate = useNavigate();   
    const [selectedMonthData, setSelectedMonthData] = useState({
      status: false,
      loading:false,
      data: ''
    });
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState({})

    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const {
      data: client,
      isSuccess: isClientSuccessful,
    } = useGetClientByIdQuery(client_id)
    
    const {
      data: monthlyTransactionStatementsDates,
      isLoading: isLoadingMonthlyTransactionStatementsDates,
      isSuccess: isSuccessMonthlyTransactionStatementsDates,
    } = useGetClientMonthlyTransactionsStatementsDatesQuery(client_id);

    const {
      data: currentMonthTransactionStatement,
      isLoading,
      isSuccess,
      isError
    } = useGetClientCurrentMonthTransactionsStatementQuery(client_id);

    const generatePDF = ()=>{
      const pdf = new jsPDF("l", "pt", "a2");
              pdf.html(document.querySelector("#content"), {
                callback: function(pdf) {
                  pdf.save('pdf')
                },
                format: 'PNG',
                pagesplit: true
              })
    
    }
    useEffect(()=>{
      (selectedMonthData.status)?
        setDate(selectedMonthData.data[0].created_at)
      :(isSuccess)?
        setDate(currentMonthTransactionStatement.data[0].created_at)
      :setDate(false)
      return ()=>{
      }
    },[currentMonthTransactionStatement,selectedMonthData])

  const [getClientSelectedMonthTransactionsStatement] = useGetClientSelectedMonthTransactionsStatementMutation();

  const getSelecteDateDate = async (e, date) =>{
    try {
        setLoading(true)
        const resp = await getClientSelectedMonthTransactionsStatement({client_id: client_id, date: date}).unwrap();
        setSelectedMonthData({
          ...selectedMonthData, 
          status: true,
          data: resp.data,
      })
      setLoading(false)
      } catch (err) {
        setLoading(false)
        if(err.data.hasOwnProperty('error') == true){
            swal('Empty','No record found','error');
          }
      }
  }

  const formatDisplayDate = (formatDate)=>{
    let tempDate = new Date(formatDate);
    let month = tempDate.toLocaleString('default',{month: 'long'});
    return month + "-" + tempDate.getFullYear();
  }


    return (
      <div className="bg-gray-100">
        <ClientHeaderView />
            <div className="mb-1" >
                  {
                    (isClientSuccessful)?
                    <div className="flex items-center justify-center bg-gray-50 p-0">
                      <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} Transactions</h1>
                    </div>
                    : null
                  }
            </div>
            <div className="flex justify-between bg-gray-100 p-2 mb-5">
              <div className='flex justify-center p-2 ml-4'>
                {
                  (selectedMonthData.status || isSuccess)?
                    <div className='text-xs font-bold text-gray-400'>{formatDisplayDate(date)}</div>
                  :null
                }
              </div>
              <div className='flex items-center justify-end space-x-4'>
                {
                  (selectedMonthData.status || isSuccess)?
                    <div onClick={generatePDF} className='bg-gray-100 pr-2 pl-2 pt-1 pb-1 hover:bg-gray-200 border border-gray-600 cursor-pointer text-xs rounded flex items-center space-x-1 justify-center'>
                      <p className="">Generate PDF</p>
                      <PrinterIcon className="h-6 round-full text-gray-500" />
                    </div>
                  :null
                }
                {
                  (isSuccessMonthlyTransactionStatementsDates)?
                    <Menu as="div" className='relative z-10'>
                      <Menu.Button className="bg-gray-100 hover:bg-gray-200 border border-gray-600 pr-2 pl-2 pt-1 pb-1 rounded-md">
                        <div className="flex items-center text-md space-x-1 justify-center text-gray-500">
                          <p className="text-xs text-gray-500">Choose month</p>
                          <CalendarIcon className="h-6 round-full" />
                        </div>
                      </Menu.Button>
                      <Menu.Items className="absolute focus:outline-none right-0 bg-white rounded-md shadow-lg border w-40 mr-6 mt-1">
                        {
                          (monthlyTransactionStatementsDates.success === true && monthlyTransactionStatementsDates.data.length > 0)?
                          monthlyTransactionStatementsDates.data.map(data => (
                              <Menu.Item>
                                <div onClick={(e)=>getSelecteDateDate(e,data.createdAt)} className='flex text-xs justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-gray-700 border-b border-gray-200'>
                                  {data.months}
                                </div>
                              </Menu.Item>
                            ))
                          :''
                        }
                      </Menu.Items>
                    </Menu>
                  :''
                }
              </div>
            </div>
            {
              (selectedMonthData.status)?
                <div className='bg-white mb-10'>
                  {
                    (selectedMonthData.data.length > 0)?
                      <table id='content' className="w-full bg-white divide-y divide-gray-200 mb-10">
                        <thead className="bg-teal-500 text-white">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                            >
                              created_at
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                            >
                              Route
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                            >
                              Charge
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-gray-50 divide-y divide-gray-300">
                          { selectedMonthData.data.map(data => (
                            <tr key={data.id} className="hover:bg-gray-200">
                              <td className="px-6 py-1 whitespace-nowrap flex space-x-1 items-center">
                                <div className="flex items-center space-x-4 text-sm font-medium">
                                  <input
                                    type="checkbox"
                                  />
                                </div>
                                <div className="text-xs text-gray-900">{data.created_at}</div>
                              </td>
                              <td className="px-6 py-1 whitespace-nowrap">
                                <div className="text-xs text-gray-900">{data.start_off_station_name} - {data.destination_station_name}</div>
                              </td>
                              <td className="px-6 py-1 whitespace-nowrap">
                                <div className="text-xs text-gray-900">K {data.charge}</div>
                              </td>
                            </tr>
                          ))
                       }
                        </tbody>
                      </table>
                      :
                        <div>No users record found</div>
                  }
                </div>
              :(loading)?
                <LoadingSpinner />
              :(isSuccess)?
                <div className="">
                  {
                    (currentMonthTransactionStatement.success === true && currentMonthTransactionStatement.data.length > 0)?
                      <table id='content' className="w-full divide-y divide-gray-200 mb-10">
                        <thead className="bg-teal-500">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                            >
                              created_at
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                            >
                              Route
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                            >
                              Charge
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-gray-50 divide-y divide-gray-300">
                          { currentMonthTransactionStatement.data.map(data => (
                            <tr key={data.id} className="hover:bg-gray-200">
                              <td className="px-6 py-1 whitespace-nowrap flex space-x-1 items-center">
                                <div className="flex items-center space-x-4 text-sm font-medium">
                                  <input
                                    type="checkbox"
                                  />
                                </div>
                                <div className="text-xs text-gray-900">{data.created_at}</div>
                              </td>
                              <td className="px-6 py-1 whitespace-nowrap">
                                <div className="text-xs text-gray-900">{data.start_off_station_name} - {data.destination_station_name}</div>
                              </td>
                              <td className="px-6 py-1 whitespace-nowrap">
                                <div className="text-xs text-gray-900">K {data.charge}</div>
                              </td>
                            </tr>
                          ))
                       }
                        </tbody>
                      </table>
                      :
                        <div>Something went wrong try again later</div>
                  }
                </div>
              : isLoading?
                  <LoadingSpinner />
              : isError?
                <div className="flex items-center justify-center ">
                  <p className="text-gray-500 font-bold mt-28 uppercase">No record found for current month.</p>
                </div>
                : null
            }
      </div>
    )
}

export default ClientSalesTransactionsStatementsView;
