import { apiSlice } from "../app/api/apiSlice";

export const clientBulkSmsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createTransaction: builder.mutation({
            query: (input) => ({
                url: `/user/create-bulk-sms-transaction`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Client_bulk_sms']
        }),
        getLastClientTransactionByClientId: builder.query({
            query: (id) => `/user/client-last-bulk-sms-transaction/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Client_bulk_sms']
        }),
        getAllClientTransactionByClientIdForFilter: builder.query({
            query: (id) => `/user/all-client-bulk-sms-transactions/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Client_bulk_sms']
        }),
        previewClientTransactions: builder.query({
            query: ({client_id, transaction_id}) => `/user/client-preview-bulk-sms-transactions/${client_id}/${transaction_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Client_bulk_sms']
        }),
    })
})

export const {
    useCreateTransactionMutation,
    useGetAllClientTransactionByClientIdForFilterQuery,
    usePreviewClientTransactionsQuery,
    useGetLastClientTransactionByClientIdQuery,
} = clientBulkSmsSlice

