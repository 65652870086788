import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOperatorByIdQuery } from "../../Features/clientsSlice";
import Home from "../../HeaderLayouts/Home";
import Select from 'react-select';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import "../slideStyles.css";


// import Swiper core and required modules
import SwiperCore, {
Autoplay,Pagination,Navigation
} from 'swiper/core';
import { useState } from "react";
import swal from "sweetalert";
import { useGetOperatorRouteTimeByStationsAndOperatorIdsMutation } from "../../Features/timeSlice";
import { useProcessPurchaseTicketSuccessfulMutation, useProcessTicketInfoMutation } from "../../Features/ticketsSlice";
import FrontEndLoadingSpinner from "../FrontEndLoadingSpinner";
import { useGetPurposeOfTravelForCustomerQuery } from "../../Features/purposeOfTravelSlice";
import { useSendSMSMessageMutation } from "../../Features/smsSlice";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useGetAllForOperatorsStationsQuery } from "../../Features/stationsSlice";
import { ArrowLeftIcon, ChevronLeftIcon } from "@heroicons/react/solid";



// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

function ProvideTicketPurchaseInfoView(props) {  
    const navigate = useNavigate();
    const {operator_id} = useParams();

    const [seatingSort, setSeatingSort] = useState([]);
    const [selectedSeat, setSeat] = useState(false);
    const [ticketRouteInfo, setTicketRouteInfo] = useState(false);
    const [time, setTime] = useState(false);
    const [from, setFrom] = useState([]);
    const [to, setTo] = useState([]);
    const [purpose, setPurpose] = useState([]);
    const [gatewaySerivceCharge, setGatewaySerivceCharge] = useState([]);
    const [transaction_id, setTransactionId] = useState(null);
    const [network, setNetwork] = useState(null);
    const [phoneNumber, handlePhoneNumberChange] = useState();

    const [input, setInput] = useState({
        fname: '',
        lname: '',
        date: '',
        time: '',
        time_label: ''
    });

    const [errors, setErrors] = useState({
        fname: '',
        lname: '',
        time_id: '',
        route_id: '',
        date: '',
        purpose_id: ''
    })

    const {
      data: client,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetOperatorByIdQuery(operator_id)
    
    const {
        data: clientRoute,
        isSuccess: isSuccessClientRoute,
        isLoading: isLoadingClientRoute,
        isError: isErrorClientRoute,
    } = useGetAllForOperatorsStationsQuery();

    const {
        data: purposes,
        isSuccess: isSuccessPurposes,
        isLoading: isLoadingPurposes,
        isError: isErrorPurposes,
    } = useGetPurposeOfTravelForCustomerQuery();

    const [getOperatorRouteTimeByStationsAndOperatorIds] = useGetOperatorRouteTimeByStationsAndOperatorIdsMutation()

    const handleStationToChange = async(e)=>{
             try {
                const dataResp = await getOperatorRouteTimeByStationsAndOperatorIds({from: from.value, to: e.value, client_id: operator_id}).unwrap()
                if(dataResp.success){
                    dataResp.data.map(data=>(
                      (data.route_info)?
                          setTicketRouteInfo(data.route_info)
                        :null
                      ))
                }
                if(dataResp.success){
                    dataResp.data.map(data=>(
                    (data.route_time)?
                        setTime(data.route_time)
                      :null
                      ))
                    let customerInfoForm = document.getElementById("allFieldss");
                    return customerInfoForm.classList.remove("hidden");
                }
                } catch (err) {
                    swal("Error",err.data.message,"error");
                } 
    }

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const [sendSMSMessage] = useSendSMSMessageMutation();

    const msgRef = (length) => {
        var result = '';
        var characters =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      };

    const handleDeliverTicket = async(customerData)=>{
        const data = new URLSearchParams();

        let message = `${customerData.fname} ${customerData.lname} ${customerData.route} ticket No ${customerData.ticket_token} on ${formatDisplayDate(customerData.date)}@${customerData.time} seat ${customerData.seat_number}. ${customerData.extention}`
        // return console.log(message)
        data.append("username", "INCEL");
        data.append("password", "1Nc3L01");
        data.append("source", "Swift ticket delivery");
        data.append("senderid", "SwiftTKT");
        data.append("msg_ref", msgRef(32));
        data.append("message", message);
        data.append("recipient[]", customerData.phone);
        try{
            const response = await sendSMSMessage(data);
                 return response

        }catch(error){
            return error
        }
    }

    const [processPurchaseTicketSuccessful,{isLoading: isLoadingPurchaseTicketSuccessful}] = useProcessPurchaseTicketSuccessfulMutation()

    const handlePayment = async(e)=>{
        e.preventDefault();
        if(selectedSeat == false){
            return swal("Error","Select seat number","error");
        }
        const data = {
            fname: input.fname,
            lname: input.lname,
            phone: phoneNumber.replace('+', ''),
            gateway_service_charge: gatewaySerivceCharge,
            transaction_id: transaction_id,
            network: network,
            client_route_id: ticketRouteInfo.client_route_id,
            time: input.time_label,
            time_id: input.time,
            client_id: operator_id,
            date: input.date,
            seat_id: selectedSeat,
            purpose_id: purpose,
            agent_user_id: '81f16130-cdc2-4c70-a102-3f30ef0f0b7b',
        }
        try {
            const resp = await processPurchaseTicketSuccessful(data).unwrap();
            //Ticket data
            const name = resp.data.fname+" "+resp.data.lname;
            const date = resp.data.date;
            const time = resp.data.time;

            handleDeliverTicket(resp.data) 
            swal("Success","Check out successful","success");
            navigate(`/customer-ticket-information/${name}/${resp.data.phone}/${resp.data.charge}/${resp.data.route}/${date}/${time}/${resp.data.seat_number}/${client.data.name}/${resp.data.ticket_token}/${client.data.logo_image_url}`);
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({
                    ...errors, 
                    fname: err.data.errors.fname, 
                    lname: err.data.errors.lname, 
                    route_id: err.data.errors.route_id, 
                    time_id: err.data.errors.time_id,
                    date: err.data.errors.date,
                    phone: err.data.errors.phone,
                    purpose_id: err.data.errors.purpose_id,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }          
    }

     const [processTicketInfo,{isLoading: isLoadingProcessTicker}] = useProcessTicketInfoMutation();

    const processTicket = async(e)=>{
            e.preventDefault();
            setCalculatedServiceCharge()
            const data = {
                fname: input.fname,
                lname: input.lname,
                phone: phoneNumber.replace('+', ''),
                route_id: ticketRouteInfo.client_route_id,
                time_id: input.time,
                client_id: operator_id,
                date: input.date,
                purpose_id: purpose,
            }
            try {
                const resp = await processTicketInfo(data).unwrap()
                if(resp.hasOwnProperty("success") == true){
                    let customerInfoForm = document.getElementById("customer-info");
                    let setInfoForm = document.getElementById("customer-seat-info");
                    customerInfoForm.classList.add("hidden");
                    setInfoForm.classList.remove("hidden");
                    setSeatingSort(resp)
                }
            } catch (err) {
                if(err.data.hasOwnProperty('errors') == true){
                    setErrors({
                        ...errors, 
                        fname: err.data.errors.fname, 
                        lname: err.data.errors.lname, 
                        route_id: err.data.errors.route_id, 
                        time_id: err.data.errors.time_id,
                        date: err.data.errors.date,
                        phone: err.data.errors.phone,
                        purpose_id: err.data.errors.purpose_id,
                    })
                }else if (err.data.hasOwnProperty('error') == true){
                    const dataResp = await getOperatorRouteTimeByStationsAndOperatorIds({from: from.value, to: to.value, client_id: operator_id}).unwrap()
                    if(dataResp.success){
                        dataResp.data.map(data=>(
                        (data.route_time)?
                            setTime(data.route_time)
                          :null
                          ))
                    }
                    swal("Error",err.data.error,"error");
                }
            }           
    }

    const formatDisplayDate = (date)=>{
        let tempDate = new Date(date);
        let month = tempDate.toLocaleString('default',{month: 'short'});
        return month + "-" + tempDate.getDate() + "-" + tempDate.getFullYear();
    }

    const previous = async(e)=>{
        try {
            const dataResp = await getOperatorRouteTimeByStationsAndOperatorIds({from: from.value, to: to.value, client_id: operator_id}).unwrap()
            if(dataResp.success){
                dataResp.data.map(data=>(
                (data.route_time)?
                    setTime(data.route_time)
                  :null
                  ))
                let customerInfoForm = document.getElementById("customer-info");
                let setInfoForm = document.getElementById("customer-seat-info");
                customerInfoForm.classList.remove("hidden");
                setInfoForm.classList.add("hidden");
            }

        } catch (err) {
            if(err.data.hasOwnProperty('message') == true){
                swal("Error",err.data.message,"error");
            }
        }
    }

    const handleSeatChange = (e)=>{
        const { value, checked } = e.target;
        setSeat(value)
    }

    const handleSetTime = (e)=>{
        setInput({
            ...input, time: e.value, time_label: e.label
        })
    }

    const handleSetPurpose = (e)=>{
        setPurpose(e.value);
    }

    const calculateServiceCharge = ()=>{
        const gatewayServiceChargeFee = ((3/100) * ticketRouteInfo.client_charge)
        return ticketRouteInfo.commission_charge + Math.round(gatewayServiceChargeFee); 
      }
      
      const setCalculatedServiceCharge = ()=>{       
        const gatewayServiceChargeFee = ((3/100) * ticketRouteInfo.client_charge) 
        return setGatewaySerivceCharge(gatewayServiceChargeFee); 
      }

      const calculateTotalCharge = ()=>{
        return ticketRouteInfo.client_charge + calculateServiceCharge(); 
      }

    return (
                    <div className='bg-white'>
                        <div className="sticky top-0 z-50">
                            <Home />
                        </div>
                        {
                            (isSuccess)?
                                (isSuccessClientRoute)?
                                    (isSuccessPurposes)?
                                        <div className="bg-white">
                                            <div className="flex flex-col bg-white">
                                                <Swiper 
                                                    centeredSlides={true} 
                                                    className="mySwiper">
                                                        <SwiperSlide className="object-cover">
                                                        <img 
                                                            className=""
                                                            src={`http://localhost:8000/client_logo_image/${client.data.logo_image_url}`} 
                                                            alt="Image" 
                                                        />
                                                        </SwiperSlide>
                                                </Swiper>
                                            </div>
                                        <div class="mt-10 sm:mt-0 md:pt-4">
                                            <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                                                <div class="mt-5 md:mt-0 md:col-span-2">
                                                    <div id="customer-info" className="bg-white">                                   
                                                        <form onSubmit={processTicket} autoComplete="off">
                                                            <div className="flex flex-col bg-gray-200 mb-4 p-4 rounded-t text-sm">
                                                                <div className="flex justify-center">
                                                                    <h1 className="text-xs uppercase text-gray-700">
                                                                        Provide your ticket information below.
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                            <div class="grid grid-cols-2 gap-4">
                                                                <div class="form-group mb-4">
                                                                    <input type="text" class="form-control
                                                                    block
                                                                    w-full
                                                                    px-3
                                                                    py-1.5
                                                                    text-base
                                                                    font-normal
                                                                    text-gray-700
                                                                    bg-white bg-clip-padding
                                                                    border border-solid border-gray-300
                                                                    rounded
                                                                    transition
                                                                    ease-in-out
                                                                    m-0
                                                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                    aria-describedby="emailHelp123" 
                                                                    placeholder="First name"
                                                                    name="fname" 
                                                                    id="fname" 
                                                                    value={input.fname}
                                                                    onChange={handleChange}
                                                                    />
                                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.fname}</p>
                                                                </div>
                                                                <div class="form-group mb-4">
                                                                    <input type="text" class="form-control
                                                                    block
                                                                    w-full
                                                                    px-3
                                                                    py-1.5
                                                                    text-base
                                                                    font-normal
                                                                    text-gray-700
                                                                    bg-white bg-clip-padding
                                                                    border border-solid border-gray-300
                                                                    rounded
                                                                    transition
                                                                    ease-in-out
                                                                    m-0
                                                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                                    aria-describedby="emailHelp124" 
                                                                    placeholder="Last name"
                                                                    name="lname" 
                                                                    id="lname" 
                                                                    value={input.lname}
                                                                    onChange={handleChange}
                                                                    />
                                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.lname}</p>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-4">
                                                            <PhoneInput type="tel" 
                                                                class="
                                                                    w-full
                                                                    px-3
                                                                    focus:border-blue-600 focus:outline-none
                                                                    "
                                                                    placeholder="Phone No"
                                                                    defaultCountry="ZM"
                                                                    style={{
                                                                        borderRadius: '3px',
                                                                        border: '1px solid gray',
                                                                        padding: '8px',
                                                                    }}
                                                                    value={input.phone}
                                                                    onChange={handlePhoneNumberChange}
                                                                    required
                                                            />
                                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.phone}</p>
                                                            </div>
                                                            <div class="form-group mb-4">
                                                                <Select 
                                                                        class=""
                                                                        placeholder="From"
                                                                        options={clientRoute}
                                                                        isSearchable
                                                                        onChange={setFrom}
                                                                    />
                                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.route_id}</p>
                                                            </div>
                                                            <div class="form-group mb-4">
                                                                <Select 
                                                                        class=""
                                                                        placeholder="To"
                                                                        options={clientRoute}
                                                                        isSearchable
                                                                        onChange={(e)=>{
                                                                            setTo(e);
                                                                            handleStationToChange(e);
                                                                        }}
                                                                    />
                                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.route_id}</p>
                                                            </div>
                                                            <div id='allFieldss' className="hidden">
                                                                <div class="form-group mb-6">
                                                                    <input type="date" class="form-control block
                                                                        w-full
                                                                        px-3
                                                                        py-1.5
                                                                        text-base
                                                                        font-normal
                                                                        text-gray-700
                                                                        bg-white bg-clip-padding
                                                                        border border-solid border-gray-300
                                                                        rounded
                                                                        transition
                                                                        ease-in-out
                                                                        m-0
                                                                        focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                                                                        placeholder="Select travel date"
                                                                        name="date" 
                                                                        id="date" 
                                                                        value={input.date}
                                                                        onChange={handleChange}
                                                                        />
                                                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.date}</p>
                                                                </div>
                                                                <div class="form-group mb-4">
                                                                    <Select 
                                                                            class=""
                                                                            placeholder="Select departure time"
                                                                            name="time" 
                                                                            id="time" 
                                                                            options={time}
                                                                            isSearchable
                                                                            onChange={handleSetTime}
                                                                        />
                                                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.time_id}</p>
                                                                </div>
                                                                <div class="form-group mb-4">
                                                                    <Select 
                                                                            class=""
                                                                            placeholder="Share with us the purpose of travel"
                                                                            options={purposes.data}
                                                                            isSearchable
                                                                            onChange={handleSetPurpose}
                                                                        />
                                                                        <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.purpose_id}</p>
                                                                </div>
                                                                <button type="submit" class="
                                                                    w-full
                                                                    px-6
                                                                    py-2.5
                                                                    bg-gray-200
                                                                    text-gray-700
                                                                    font-bold
                                                                    text-xs
                                                                    leading-tight
                                                                    rounded
                                                                    shadow-md
                                                                    hover:bg-gray-300 hover:shadow-lg
                                                                    ease-in-out"
                                                                >
                                                                    {
                                                                        (isLoadingProcessTicker)?
                                                                            <div className="">Please wait...</div>
                                                                        :
                                                                        <div className="uppercase font-bold">Next</div>    
                                                                    }
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div id="customer-seat-info" className="hidden bg-white">
                                                    <form onSubmit={handlePayment} autoComplete="off">
                                                                <div className="flex flex-col bg-gray-200 mb-4 p-4 rounded-t text-sm">
                                                                    <div className="flex justify-between items-center">
                                                                        <div onClick={previous} className="hover:text-gray-400 text-gray-700 cursor-pointer">
                                                                            <ArrowLeftIcon className="h-5 " />
                                                                        </div>
                                                                        <h1 className="text-xs uppercase text-gray-700">
                                                                            Ticket information
                                                                        </h1>
                                                                        <div className="">
                                                                            <ArrowLeftIcon className="h-5 text-gray-200" />
                                                                        </div>
                                                                    </div>
                                                                </div>                                        
                                                            <div class="form-group mb-1">
                                                                <div className="flex flex-col ">
                                                                    <div className="flex flex-col">
                                                                        <div className="mt-1 mb-2 space-y-2">
                                                                            <div className="flex justify-between items-center">
                                                                                <p className="font-bold text-xs pl-4">Customer Name</p>
                                                                                <p className="pr-4 text-xs">{input.fname} {input.lname}</p>
                                                                            </div>
                                                                            <div className="flex items-center justify-between">
                                                                                <p className="font-bold text-xs pl-4">Phone No</p>
                                                                                <p className="pr-4 text-xs">{phoneNumber}</p>
                                                                            </div>
                                                                            <div className="flex items-center justify-between">
                                                                                <p className="font-bold text-xs pl-4">Duration</p>
                                                                                <p className="pr-4 text-xs">{ticketRouteInfo.route_duration} {(ticketRouteInfo.route_duration > 1)? "Hrs" : "Hr"}</p>
                                                                            </div>
                                                                            <div className="flex items-center justify-between">
                                                                                <p className="font-bold text-xs pl-4">Charge</p>
                                                                                <p className="pr-4 text-xs">K {(ticketRouteInfo.client_charge)}</p>
                                                                            </div>
                                                                            <div className="flex items-center justify-between">
                                                                                <p className="font-bold text-xs pl-4">Service Charge</p>
                                                                                <p className="pr-4 text-xs">K {calculateServiceCharge()}</p>
                                                                            </div>
                                                                            <div className="flex items-center justify-between">
                                                                                <p className="font-bold text-xs pl-4">Grand Total</p>
                                                                                <p className="pr-4 text-xs">K {calculateTotalCharge()}</p>
                                                                            </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-4">
                                                                <div className="flex flex-col ">
                                                                    <div className="flex flex-col ">
                                                                        </div>
                                                                            <h1 className="flex space-x-4 bg-gray-200 p-2 justify-center text-sm">
                                                                                <div className="flex">
                                                                                    <div className="flex-row justify-center">
                                                                                        <div style={{color: '#003356'}} className="font-bold text-xs flex justify-center">Route</div>
                                                                                        <div style={{color: '#003356'}} className="text-xs pl-2 flex justify-center">
                                                                                            {ticketRouteInfo.start_off_station_name} - {ticketRouteInfo.destination_station_name}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex">
                                                                                    <div className="flex-row justify-center">
                                                                                        <div style={{color: '#003356'}} className="font-bold text-xs flex justify-center">Departure</div>
                                                                                        <div style={{color: '#003356'}} className="text-xs pl-2 flex justify-center">
                                                                                        {formatDisplayDate(input.date)} @ {(input.time_label != null)? input.time_label: '--'} Hrs
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </h1> 
                                                                        </div>
                                                                        {
                                                                            (seatingSort.hasOwnProperty("success") == true)?
                                                                                <div className="mb-3 flex flex-wrap justify-between p-2 border-2  border-gray-200 mt-0 rounded-b-md">
                                                                                    { (seatingSort.data.length > 0)?seatingSort.data.map(data => (
                                                                                            <div htmlFor="seat_number" key={data.id} className={`${(selectedSeat == data.id)? "border-gray-300" : "bg-gray-100 border border-gray-500"}  border pt-1 pb-1 pl-2 pr-2 rounded-lg mt-2 mr-1`}>
                                                                                                <p style={{color: '#003356'}} className="text-md">{data.number}</p>
                                                                                                <input 
                                                                                                    className="bg-green-500"
                                                                                                    type="radio" 
                                                                                                    name="seat_number"
                                                                                                    id="seat_number"
                                                                                                    value={data.id}
                                                                                                    onChange={handleSeatChange}
                                                                                                />
                                                                                            </div>
                                                                                            
                                                                                        ))
                                                                                            : ''
                                                                                    }
                                                                                </div>
                                                                            :
                                                                                <div className="flex justify-center pl-2 pr-2">
                                                                                    { 
                                                                                        (seatingSort.success == false)?
                                                                                            <h1 className="text-sm text-red-500">{seatingSort.error}</h1>
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="submit" 
                                                            style={{backgroundColor: '#FFD881'}}
                                                            class="
                                                                    w-full
                                                                    px-6
                                                                    py-2.5
                                                                    text-gray-700
                                                                    text-xs
                                                                    leading-tight
                                                                    rounded
                                                                    shadow-md
                                                                    hover:bg-gray-300 hover:shadow-lg
                                                                    ease-in-out"
                                                                >
                                                                    {
                                                                        (isLoadingPurchaseTicketSuccessful)?
                                                                            <div className="">Processing...</div>
                                                                        :
                                                                        <div className="uppercase font-bold">Checkout</div>    
                                                                    }
                                                                </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    :isLoadingPurposes?
                                        <FrontEndLoadingSpinner />
                                    :isErrorPurposes?
                                        <div className="">
                                            <div className="flex flex-col p-4">
                                                <p className="text-red-500 italic border-b border-gray-300 flex flex-1">Something went wrong</p>
                                                <Link to={`/`} className='flex justify-center p-8  mb-44'>
                                                    <h1 className='bg-gray-300 hover:bg-gray-400 p-6 text-sm font-semibold rounded-md text-white cursor-pointer uppercase'>Browse more operators</h1>
                                                </Link>
                                            </div>
                                        </div>
                                    :null
                                :isLoadingClientRoute?
                                    <FrontEndLoadingSpinner />
                                : isErrorClientRoute?
                                    <div className="">
                                        <div className="flex flex-col p-4">
                                            <p className="text-red-500 italic border-b border-gray-300 flex flex-1">{client.data.name} opening shortly for sales</p>
                                            <Link to={`/`} className='flex justify-center p-8  mb-44'>
                                                <h1 className='bg-gray-300 hover:bg-gray-400 p-6 text-sm font-semibold rounded-md text-white cursor-pointer uppercase'>Browse more operators</h1>
                                            </Link>
                                        </div>
                                    </div>
                                :null
                            :isLoading?
                                <FrontEndLoadingSpinner />
                            : isError?
                                <div className="">Not open for sales</div>
                            : <div></div>

                        }
                        </div>
    )
}

export default ProvideTicketPurchaseInfoView;
