import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from 'rc-pagination';
import { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import swal from 'sweetalert';
import { useDeletePurposeMutation, useGetSytemPurposesOfTravelQuery } from '../../Features/purposeOfTravelSlice';

function ManagePurposeOfTravelView({}) {
    const navigate = useNavigate();

    const {
      data: data,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetSytemPurposesOfTravelQuery()

    const [input, setInput] = useState({
      search: ''
    })

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value})
  }

  const editPurpose = (e, id)=>{
    if(window.confirm("Are you sure you want to edit this purpose?")){
        navigate("/user/edit-purpose/"+id)
    }
  }

  const [deletePurpose] = useDeletePurposeMutation();

  const handDelete = async(e,id)=>{
    if(window.confirm("Are you sure you want to delete this purpose?")){
        try {
        const resp = await deletePurpose(id).unwrap();
        swal("Success",resp.message);
    } catch (err) {
        swal("Error",err.message,'error');
    }
      }
}
    
    return (
      <div className="bg-gray-100">
          <div className="md:flex items-center md:justify-between bg-white p-1">
              <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Purpose of Travel Management</h1>
              <div className='flex items-center space-x-4'>
                <Link 
                  to="/user/create-new-purpose" 
                  className="w-24 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800">
                    New
                </Link>
              </div>
            </div>
            {(isSuccess)?
                (data.hasOwnProperty('success') === true && data.data.data.length > 0)?
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-700 shadow-lg">
                    <tr>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Purpose
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                    </th>
                  </tr>
                </thead>  
                <tbody className="divide-y divide-gray-200">
                        { (data.hasOwnProperty('success') === true && data.data.data.length > 0)?
                            data.data.data.map(data => (
                                <tr key={data.id} className="hover:bg-gray-200">
                                    <td className="px-6 py-1 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="flex items-center space-x-4 text-sm font-medium text-gray-900">
                                            <input 
                                                type="checkbox" 
                                                name="roles[]" 
                                                value={data.id}
                                                onChange={handleChange}
                                            />
                                            <label className=''>{data.purpose}</label>
                                        </div>
                                    </div>
                                    </td>
                                    <td onClick={(e)=>editPurpose(e,data.id)} className="px-1 py-0 whitespace-nowrap">
                                      <div className='flex justify-center md:w-20 w-14 mr-4 mt-1 bg-gray-400 text-sm rounded-md hover:bg-gray-500 cursor-pointer'>
                                          <div className=''>
                                              Edit
                                          </div>
                                      </div>
                                  </td>
                                    <td onClick={(e)=>handDelete(e,data.id)} className="px-1 py-0 whitespace-nowrap">
                                        <div className='flex justify-center md:w-20 w-14 sm:14 mr-4 mt-1 bg-gray-400 text-sm rounded-md hover:bg-gray-500 cursor-pointer'>
                                            <div className=''>
                                                Delete
                                            </div>
                                        </div>
                                    </td>
                                        
                                </tr>
                                ))
                                : ''
                        }
                </tbody>
              </table>
                  :
                    <div>No users record found</div>
              : isLoading?
                    <LoadingSpinner />
                : isError?
                    <div className="md:flex p-4">
                      <p className="text-blue-500 italic border-b border-black flex flex-1">Data not found</p>
                    </div>
                : null
              }
      </div>
    )
}

export default ManagePurposeOfTravelView;
