
import { Link, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import LoadingSpinner from '../LoadingSpinner';

function ClientProfileView() {
    const navigate = useNavigate();
    const {client_id}= useParams();

    const {
        data: client,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetClientByIdQuery(client_id);


    const back = ()=> navigate(-1);
   
        return (
            <div className="bg-gray-100 flex flex-col">
                <div >
            {
              (isSuccess)?
              <div className="flex items-center justify-center bg-white p-1">
                <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">{client.data.name} {client.data.business_type} Profile</h1>
              </div>
              : null
            }
            </div>
                {
                    (isSuccess)?
                        <div className="">
                            <div className="md:flex justify-center mt-6 mb-10 p-1">
                            <div className="rounded-md bg-white shadow-sm pr-0 pl-0 sm:pr-0 sm:pl-0">
                                <div className="md:pr-64 md:pl-64 lg:pr-96 lg:pl-96"></div>
                            <div className="flex flex-col items-center mb-5">
                                <div className="flex justify-center p-1 bg-white w-full">
                                    <img 
                                        className="bg-white object-contain h-72 w-72 rounded-full"
                                        src={`https://ticketing.autozedltd.com/client_logo_image/`+client.data.logo_image_url} 
                                        alt="Image" 
                                    />  
                                </div>
                            </div>
                            <div className="flex justify-center bg-gray-700 p-4">
                                <div className="flex justify-center font-semibold text-gray-300">Business Information</div>
                            </div>
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Name</div>
                                    <div className="text-sm">
                                        {client.data.name}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Business Type</div>
                                    <div className="text-sm">
                                        {client.data.business_type}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Category</div>
                                    <div className="text-sm">
                                    {(client.data.category == 0)? 'Big bus': 'Others'}
                                    </div>
                                </div>
                            </h1>
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Physical Address</div>
                                    <div className="text-sm">
                                        {client.data.physical_address}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Contact Number 1</div>
                                    <div className="text-sm">
                                        {client.data.phone}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Contact Number 2</div>
                                    <div className="text-sm">
                                        {client.data.phone_2}
                                    </div>
                                </div>
                            </h1>
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">TPIN number</div>
                                    <div className="text-sm">
                                        {client.data.business_tpin_number}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Registration Number</div>
                                    <div className="text-sm">
                                        {client.data.business_registration_id}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500 uppercase">Contract ID</div>
                                    <div className="text-sm">
                                        {client.data.contract_id}
                                    </div>
                                </div>
                            </h1>
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm ">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500 uppercase">Email Address</div>
                                    <div className="text-sm">
                                        {client.data.email}
                                    </div>
                                </div>
                            </h1> 
                            <div className="flex justify-center bg-gray-700 p-4">
                                <div className="flex justify-center font-semibold text-gray-300">Business Directors</div>
                            </div> 
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Name</div>
                                    <div className="text-sm">
                                        {client.data.business_director_1}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Contact Number</div>
                                    <div className="text-sm">
                                        {client.data.business_director_1_phone_number}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">ID No.</div>
                                    <div className="text-sm">
                                        {client.data.business_director_1_id}
                                    </div>
                                </div>
                            </h1>
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase border-b border-gray-300">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">ID Type</div>
                                    <div className="text-sm">
                                        {client.data.business_director_1_id_type}
                                    </div>
                                </div>
                            </h1> 
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Name</div>
                                    <div className="text-sm">
                                        {client.data.business_director_2}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Contact Number</div>
                                    <div className="text-sm">
                                        {client.data.business_director_2_phone_number}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">ID No.</div>
                                    <div className="text-sm">
                                        {client.data.business_director_2_id}
                                    </div>
                                </div>
                            </h1>
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">ID Type</div>
                                    <div className="text-sm">
                                        {client.data.business_director_2_id_type}
                                    </div>
                                </div>
                            </h1> 
                            <div className="flex justify-center bg-gray-700 p-4">
                                <div className="flex justify-center font-semibold text-gray-300">Business Bank Details</div>
                            </div> 
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Bank Name</div>
                                    <div className="text-sm">
                                        {client.data.bank_name}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Account Name</div>
                                    <div className="text-sm">
                                        {client.data.bank_account_name}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Branch Name</div>
                                    <div className="text-sm">
                                        {client.data.branch_name}
                                    </div>
                                </div>
                            </h1>
                            <h1 className="flex bg-gray-100 p-2 justify-around text-sm uppercase">
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Branch Code</div>
                                    <div className="text-sm">
                                        {client.data.branch_code}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1 items-center justify-center">
                                    <div className="flex justify-center font-bold text-gray-500">Account Number</div>
                                    <div className="text-sm">
                                        {client.data.bank_acc_number}
                                    </div>
                                </div>
                            </h1>
                                    <div className="flex justify-center mt-1 space-x-4 bg-gray-300 border-t border-gray-400 p-4">
                                            <div onClick={back} className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label  className="flex justify-center space-x-2 items-center text-sm bg-gray-400 hover:bg-gray-500 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text- flex justify-center">
                                                        Back
                                                    </div>
                                                </label>
                                            </div>    
                                            <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <Link to={`/user/edit-client-profile/${client.data.id}`}  className="flex justify-center space-x-2 items-center text-sm bg-blue-500 hover:bg-blue-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Edit 
                                                    </div>
                                                </Link>
                                            </div>                        
                                    </div>
                                </div>
                            </div>
                        </div>
                :  isLoading
                    ?<LoadingSpinner />
                    : isError
                        ? <div>{error.data.error}</div>
                        : ''
                }
            </div>
        )
}

export default ClientProfileView;
