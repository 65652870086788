import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import swal from 'sweetalert'
import LoadingSpinner from '../LoadingSpinner';
import { useGetCommissionRateQuery, useSetCommissionRateMutation } from '../../Features/commissionSlice';
import { useEffect } from 'react';

function EditCommissionRateView() {
    const navigate = useNavigate();

    const [input, setInput] = useState({
        rate: '',
        id: false
    })

    const [errors, setErrors] = useState({
        rate: '',
    })

    const {
        data: rate,
        isLoadingCommissionRate,
        isSuccess,
      } = useGetCommissionRateQuery()

    const [setCommissionRate, {isLoading}] = useSetCommissionRateMutation();

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    useEffect(()=>{
        if(isSuccess){
            if(rate.data.rate){
                setInput({...input, 
                    rate: rate.data.rate,
                    id: rate.data.id
                });
            }
        }
        return () =>{
        }
    },[rate]);

    const handleSubmit = async(e)=>{
        e.preventDefault();
        let data = {
            rate: input.rate,
            id: input.id,
        }
        try {
            const resp = await setCommissionRate(data).unwrap()
            swal("Success",resp.message,"success");
            navigate(-1);
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors, rate: err.data.errors.rate})
            }else if (err.data.hasOwnProperty('error') == true){
                swal("",err.data.error,"error")
            }
        }          
    }

    const back = ()=> navigate(-1);

    let constent = isLoadingCommissionRate  
                ? <LoadingSpinner />
        :
        (
            <div className="bg-gray-100">
                <div className="flex items-center justify-center bg-whitep-1 mb-10">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Commission Management</h1>
                </div>
                <div class="md:flex justify-center mt-5 sm:mt-0 mb-10 md:pt-4">
                    <div class="bg-gray-100 md:w-1/2">
                        <div class="w-full sm:rounded-md">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class=" shadow overflow-hidden sm:rounded-md">
                                    <div class="col-span-6 p-4 shadow-md bg-blue-400 uppercase">
                                        <div className='flex justify-center'>
                                            <h1 className='uppercase font-semibold text-sm text-white'>Edit Commission Rate</h1>
                                        </div>
                                    </div>
                                <div class="px-4 py-5 bg-gray-100 sm:p-6">
                                    <div class="grid grid-cols-2 gap-4">
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="name" class="block text-sm font-medium text-gray-700">Rate</label>
                                            <input 
                                                class="mt-1 p-2 text-sm outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="number"
                                                placeholder='Enter rate...'
                                                name="rate" 
                                                id="rate" 
                                                value={input.rate}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.rate}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-center space-x-6 items-center px-4 py-4 bg-gray-200 text-right sm:px-6">
                                    <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-300 hover:bg-gray-400 cursor-pointer">Cancel</div>
                                    <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Submit</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    return constent;
}

export default EditCommissionRateView;
