import { Link, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {ChevronDownIcon, UserCircleIcon } from '@heroicons/react/solid';
import { Menu} from "@headlessui/react";
import Logo from "../Views/Logo";
import { useLogoutMutation } from "../Features/usersSlice";
import { logOut } from "../Features/auth/authSlice";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import { selectCurrentClientId } from "../Features/menuSlice";

function ClientAccountingUserLayout() {

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* large screen */}
      {/* Header and Feed */}
      <div className="flex-1">
        {/* Feed Header */}
        {/* Feed */}
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default ClientAccountingUserLayout;
