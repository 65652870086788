import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOperatorByIdQuery } from "../../Features/clientsSlice";
import Home from "../../HeaderLayouts/Home";
import Select from 'react-select';
import LoadingSpinner from "../LoadingSpinner";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import "../slideStyles.css";


// import Swiper core and required modules
import SwiperCore, {
Autoplay,Pagination,Navigation
} from 'swiper/core';
import { useState } from "react";
import { useGetOperatorRoutesForReserveSeatQuery } from "../../Features/clientsRoutesSlice";
import swal from "sweetalert";
import { useProcessPhysicalTicketDataMutation } from "../../Features/ticketsSlice";

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

function CheckAvailableSeatsForRouteView(props) {  
    const navigate = useNavigate();
    const {operator_id} = useParams();

    const [input, setInput] = useState({
        date: '',
        time: '',
        time_label: '',
    });
    const [route, setRoute] = useState([]);
    const [time, setTime] = useState([]);

    const handleChange = (e)=>{
        setInput({
            ...input, [e.target.name]: e.target.value
        })
    }

    const handleSetTime = (e)=>{
        setInput({
            ...input, time: e.value, time_label: e.label
        })
    }

    const {
      data: client,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetOperatorByIdQuery(operator_id)
    
    const {
        data: clientRoute,
        isSuccess: isSuccessClientRoute,
        isLoading: isLoadingClientRoute,
        isError: isErrorClientRoute,
        error: errorClientRoute
    } = useGetOperatorRoutesForReserveSeatQuery(operator_id)

    const [ProcessPhysicalTicketData] = useProcessPhysicalTicketDataMutation();

    const search = async(e)=>{
            e.preventDefault();
            if(route.value == "" || operator_id == "" || input.time == "" || input.date == ""){
                return swal("Error","Error: Invalid provided data","error");
            }
            const data = {
                route_id: route.value,
                client_id: operator_id,
                time_id: input.time,
                date: input.date,
            }
            try {
                const resp = await ProcessPhysicalTicketData(data).unwrap()
                navigate(`/available-seats/${operator_id}/${route.value}/${input.time}/${input.date}/${input.time_label}`)
            } catch (err) {
                return swal("Error",err.data.error,"error");
            }           
    }

    const getRouteTime = async(e)=>{
        try {
            // const timeResp = await getOperatorRouteTimeByclientRouteId(e.value).unwrap()
            // if(timeResp.success){
            //     setTime(timeResp.data);
            // }
        } catch (err) {
            
        }           
    }

    return (
                    <div className='bg-gray-100'>
                        <div className="sticky top-0 z-50">
                            <Home />
                        </div>
                        {
                            (isSuccess)?
                                (isSuccessClientRoute)?
                                        <div className="bg-white">
                                        <div class="mt-10 sm:mt-0 md:pt-4">
                                            <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                                                <div class="mt-5 md:mt-0 md:col-span-2 bg-gray-200">
                                                    <div id="customer-seat-info" className="">
                                                        <form autoComplete="off" onSubmit={search}>
                                                            <div className="flex flex-col bg-gray-300 p-4 rounded text-sm text-yellow-800">
                                                                <div className="flex justify-center">
                                                                    <h1 className="text-sm font-semibold uppercase text-gray-900">
                                                                        {
                                                                            (isSuccess)?
                                                                                client.data.name+" routes seating status"
                                                                            :null
                                                                        }
                                                                    </h1>
                                                                </div>
                                                            </div> 
                                                            <div className="flex flex-col bg-white">
                                                                <Swiper 
                                                                    centeredSlides={true} 
                                                                    className="mySwiper">
                                                                        <SwiperSlide className="object-cover">
                                                                        <img 
                                                                            className=""
                                                                            src={`http://localhost:8000/client_logo_image/${client.data.logo_image_url}`} 
                                                                            alt="Image" 
                                                                        />
                                                                        </SwiperSlide>
                                                                </Swiper>
                                                            </div>
                                                        <div>
                                                            <div class="form-group mt-3 mb-2 pr-2 pl-2">
                                                                <label for="name" class="flex justify-center block text-sm font-medium text-gray-700">Select route</label>
                                                                    <Select 
                                                                        class="block
                                                                        w-full
                                                                        px-3
                                                                        py-1.5
                                                                        text-base
                                                                        font-normal
                                                                        text-gray-700
                                                                        bg-white bg-clip-padding
                                                                        border border-solid border-gray-300
                                                                        rounded
                                                                        transition
                                                                        ease-in-out
                                                                        m-0"
                                                                        placeholder="Select route"
                                                                        options={clientRoute.data}
                                                                        isSearchable
                                                                        onChange={(e)=>{
                                                                            setRoute(e);
                                                                            getRouteTime(e);
                                                                        }}
                                                                    />
                                                            </div>
                                                        </div> 
                                                        <div class="grid grid-cols-2 gap-2">
                                                            <div class="form-group mb-6">
                                                            <label for="name" class="flex justify-center block text-sm font-medium text-gray-700">Select Date</label>
                                                                <input type="date" class="form-control block
                                                                    w-full
                                                                    px-3
                                                                    py-1.5
                                                                    text-base
                                                                    font-normal
                                                                    text-gray-700
                                                                    bg-white bg-clip-padding
                                                                    border border-solid border-gray-300
                                                                    rounded
                                                                    transition
                                                                    ease-in-out
                                                                    m-0
                                                                    focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                                                                    placeholder="Select travel date"
                                                                    name="date" 
                                                                    id="date" 
                                                                    value={input.date}
                                                                    onChange={handleChange}
                                                                    />
                                                            </div>
                                                            <div class="form-group m-1">
                                                                <label for="name" class="flex justify-center block text-sm font-medium text-gray-700">Select time</label>
                                                                <Select 
                                                                        class="block
                                                                        w-full
                                                                        px-3
                                                                        py-1.5
                                                                        text-base
                                                                        font-normal
                                                                        text-gray-700
                                                                        bg-white bg-clip-padding
                                                                        border border-solid border-gray-300
                                                                        rounded
                                                                        transition
                                                                        ease-in-out
                                                                        m-0"
                                                                        placeholder="Time"
                                                                        options={time}
                                                                        isSearchable
                                                                        onChange={handleSetTime}
                                                                />
                                                            </div>                                                                
                                                            </div>                                      
                                                                <div className="flex justify-center p-3 space-x-4">
                                                                    <button  class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm text-white font-medium rounded-md bg-gray-500 hover:bg-gray-600">Search</button>
                                                                </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                :isLoadingClientRoute?
                                    <LoadingSpinner />
                                : isErrorClientRoute?
                                    <div className="md:flex flex-col p-4">
                                        <p className="text-blue-500 italic border-b border-black flex flex-1">Routes not active for operator</p>
                                        <div className='flex justify-center p-8'>
                                            <h1 className='bg-yellow-400 hover:bg-yellow-500 p-6 text-sm font-semibold rounded-md text-white cursor-pointer uppercase'>{client.data.name} {client.data.business_type} not yet open for sales. Opening soon...</h1>
                                        </div>
                                        <Link to={`/`} className='flex justify-center p-8'>
                                            <h1 className='bg-green-500 hover:bg-green-600 p-6 text-sm font-semibold rounded-md text-white cursor-pointer uppercase'>Browse more operators</h1>
                                        </Link>
                                    </div>
                                :null
                            :isLoading?
                                <LoadingSpinner />
                            : isError?
                                <div className="">No open for sales</div>
                            : <div>lkjd</div>

                        }
                        </div>
    )
}

export default CheckAvailableSeatsForRouteView;
