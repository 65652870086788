import { Link, useNavigate } from 'react-router-dom';
import { useGetAllImagesQuery } from '../../Features/frontEndImagesSlice';
import LoadingSpinner from '../LoadingSpinner';

function ManageFrontEndImagesView({}) {
    const navigate = useNavigate();

    const {
      data: images,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetAllImagesQuery()

    return (
      <div className="bg-gray-800">
          <div className="flex items-center justify-between bg-white p-1">
              <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Front End Images Management</h1>
              <Link 
                to="/user/upload-front-end-image" 
                className="w-24 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800">
                  Upload
              </Link>
            </div>
            <div className="flex p-4 mt-2 flex justify-around flex-wrap">
            {(isSuccess)?
                (images.hasOwnProperty('success') === true && images.data.length > 0)?
                  images.data.map(data => (
                      <div key={data.id} className="flex m-2 bg-gray-100 rounded-md flex-col justify-around">
                          <div className="flex">
                            <img 
                                className="bg-gray-100 rounded-t-lg object-fill h-48 w-72"
                                src={`http://localhost:8000/front_end_images/`+data.img_url} 
                                alt="Image" 
                            />  
                          </div>
                          <Link to={`/user/manage-front-end-image/${data.id}`} className="flex flex-col shadow-lg">
                              <div className="bg-blue-400 hover:bg-blue-500 flex justify-center cursor-pointer p-2 rounded-b-md text-sm text-gray-100">
                                View More
                              </div>
                          </Link>
                      </div>
                  ))
              :
                <div>No users record found</div>
              : isLoading?
                    <LoadingSpinner />
                : isError?
                    <div className="md:flex flex-col p-4">
                        <Link to={`/user/upload-front-end-image`} className='flex justify-center p-8'>
                            <h1 className='bg-green-500 hover:bg-green-600 p-6 text-sm font-semibold rounded-md text-white cursor-pointer uppercase'>Empty click here to upload image</h1>
                        </Link>
                    </div>
                : null   
            }
                </div>
      </div>
    )
}

export default ManageFrontEndImagesView;
