import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react'
import swal from 'sweetalert'
import LoadingSpinner from '../LoadingSpinner';
import Select from 'react-select';
import { useCreatClientSeatingProfileMutation, useGetSystemSeatsForClientRouteTimeQuery } from '../../Features/seatsSlice';
import ClientHeaderView from '../App/ClientHeaderView';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';

function CreateClientSeatingProfileView() {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;
        
    const [input, setInput] = useState({
        name: '',
    });

    const [seatsArray, setSeats] = useState([]);

    const [errors, setErrors] = useState({
        name: '',
        seats: '',
    })

    const {
        data: client,
        isSuccess: isClientSuccessful,
      } = useGetClientByIdQuery(client_id)

    const {
        data: seats,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetSystemSeatsForClientRouteTimeQuery()

      const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const [creatClientSeatingProfile, {isLoading: isLoadingCreateSeatingProfile}] = useCreatClientSeatingProfileMutation()

    const handleSubmit = async(e)=>{
        e.preventDefault();
        const data = {
            name: input.name,
            client_id: client_id,
            seats: seatsArray
        }

        try {
            const resp = await creatClientSeatingProfile(data).unwrap()
            swal("Success",resp.message,"success");
            navigate(-1);
        } catch (err) {
            if(err.data){
                setErrors({
                    ...errors, 
                    name: err.data.errors.name, 
                    seats: err.data.errors.seats
                })
            }else if (err.data.error == true){
                swal("Error",err.data.error,"error");
            }
        } 
    }

    const back = ()=> navigate(-1);
    
   return (
            <div className="bg-gray-100">
                <ClientHeaderView />
                <div className="mb-10" >
                  {
                    (isClientSuccessful)?
                    <div className="flex items-center justify-center bg-gray-50 p-0">
                      <h1 className="text-sm text-gray-700 uppercase font-semibold p-2">{client.data.name} Edit Seating Profile</h1>
                    </div>
                    : null
                  }
                </div>
                <div class="md:flex justify-center mt-5 sm:mt-0 mb-10 md:pt-4">
                    <div class="bg-gray-100 md:w-1/2">
                        <div class="w-full sm:rounded-md">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class=" shadow sm:rounded-md">
                                        <div class="col-span-6 p-4 shadow-md bg-teal-500 uppercase">
                                            <div className='flex justify-center'>
                                                <h1 className='uppercase font-semibold text-sm text-white'>Edit Seating Profile</h1>
                                            </div>
                                        </div>
                                <div class="px-4 py-5 bg-gray-100 sm:p-6">
                                    <div class="grid grid-cols-2 gap-4">
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="limit" class="block text-sm font-medium text-gray-700">Profile Name</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="name"
                                                placeholder='Profile name'
                                                name="name" 
                                                id="name" 
                                                value={input.name}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                                        </div>
                                        <div className='col-span-6 sm:col-span-3 border-b border-gray-300 mt-4'>
                                        </div>
                                            <div class="col-span-6 sm:col-span-3">
                                                <label for="" class="block text-sm font-medium text-gray-700">
                                                    Select Seats
                                                </label>
                                                <Select 
                                                    class=""
                                                    options={seats}
                                                    isMulti
                                                    isSearchable
                                                    onChange={setSeats}
                                                />
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.seats}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-center space-x-6 items-center px-4 py-4 bg-gray-200 text-right sm:px-6">
                                    <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-300 hover:bg-gray-400 cursor-pointer">Cancel</div>
                                    <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500">
                                        {
                                            (isLoadingCreateSeatingProfile)?
                                            <div>Wait...</div>
                                            :
                                                <div>Save</div>
                                        }
                                    </button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

}

export default CreateClientSeatingProfileView;
