import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'
import swal from 'sweetalert'
import LoadingSpinner from '../LoadingSpinner';
import Select from 'react-select';
import { useGetSystemtimesForRoutesQuery } from '../../Features/timeSlice';
import { useAddTimeAndChargeToClientRouteMutation, useDeleteClientRouteMutation, useEditTimeAndChargeForClientRouteMutation, useGetClientRouteByClientIdAndRouteIdQuery, useGetClientRouteDataForEditQuery } from '../../Features/clientsRoutesSlice';

function EditClientRouteView() {
    const navigate = useNavigate();
    const { client_id, route_id, client_route_id } = useParams();
        
    const [input, setInput] = useState({
        route_duration: '',
        handler: '',
    });
    const [routeLogs, setRouteLogs] = useState({
        updated_at: false
    });

    const [timeArray, setTime] = useState([]);
    const [clientTime, setClientTime] = useState(false);
    const [clientRouteRespObj, setClientRouteRespObj] = useState([]);
    const [isEdit, setIsEdit] = useState(false);


    const [clientRouteCharge, setClientRouteCharge] = useState({
        success: false,
        client_charge: '',
        commission_charge: '',
        cash_charge: '',
        id:''
    });

    const [errors, setErrors] = useState({
        client_charge: '',
        commission_charge: '',
        cash_charge: '',
        route_duration: '',
        handler: '',
        time: '',
    })

    const {
        data: clientRoute,
        isLoading: clientRouteIsLoading,
        isSuccess: isSuccessClientRoute,
        isError: isErrorClientRoute
      } = useGetClientRouteDataForEditQuery({client_id: client_id, route_id: route_id})

    const {
        data: time,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetSystemtimesForRoutesQuery()

      useEffect(()=>{
        if(isSuccessClientRoute){
            clientRoute.data.map(data=>(
                (data.client_route_time)?
                    setIsEdit(true)
                :null
            ))
            clientRoute.data.map(data=>(
                (data.client_route)?
                    setClientRouteRespObj(data.client_route)
                :null
            ))
            clientRoute.data.map(data=>(
                (data.client_route)?
                    setInput({
                        ...input, 
                        handler: data.client_route.handler, 
                        route_duration: data.client_route.route_duration
                    })
                :null
            ))
            clientRoute.data.map(data=>(
                (data.client_route_charge && data.client_route_charge != false)?
                setClientRouteCharge({
                        ...clientRouteCharge, 
                        success: true,
                        client_charge: data.client_route_charge.client_charge, 
                        commission_charge: data.client_route_charge.commission_charge, 
                        cash_charge: data.client_route_charge.cash_charge, 
                        id: data.client_route_charge.id, 
                    })
                :null
            ))
            clientRoute.data.map(data=>(
                (data.client_route_time && data.client_route_time != false)?
                    setClientTime(data.client_route_time)
                :
                setClientTime(false)
            ))
            clientRoute.data.map(data=>(
                (data.client_route_logs && data.client_route_logs != false)?
                setRouteLogs({
                    ...routeLogs, 
                    updated_at: data.client_route_logs.created_at,
                })
                :
                null
            ))
            
        }
        return () =>{
        }
    },[clientRoute]);

      const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const [addTimeAndChargeToClientRoute] = useAddTimeAndChargeToClientRouteMutation()
    const [editTimeAndChargeForClientRoute] = useEditTimeAndChargeForClientRouteMutation()

    const handleSubmit = async(e)=>{
        e.preventDefault();
        if(isEdit){
            if(isSuccessClientRoute){
                const data = {
                    client_charge: clientRouteCharge.client_charge,
                    commission_charge: clientRouteCharge.commission_charge,
                    cash_charge: clientRouteCharge.cash_charge,
                    route_duration: input.route_duration,
                    handler: input.handler,
                    charge_id: clientRouteCharge.id,
                    time: (timeArray.length > 0)? timeArray : clientTime,
                    client_id: client_id,
                    route_id: clientRouteRespObj.client_route_id,
                }
                try {
                    const resp = await editTimeAndChargeForClientRoute(data).unwrap()
                    swal("Success",resp.message,"success");
                    navigate(-1);
                } catch (err) {
                    if(err.data.hasOwnProperty('errors') == true){
                        setErrors({
                            ...errors, 
                            client_charge: err.data.errors.client_charge, 
                            commission_charge: err.data.errors.commission_charge, 
                            cash_charge: err.data.errors.cash_charge, 
                            route_duration: err.data.errors.route_duration, 
                            handler: err.data.errors.handler, 
                            time: err.data.errors.time,
                        })
                    }else if (err.data.hasOwnProperty('error') == true){
                        swal("Error",err.data.error,"error");
                    }
                } 
            }
        }else{
            const data = {
                client_charge: clientRouteCharge.client_charge,
                commission_charge: clientRouteCharge.commission_charge,
                cash_charge: clientRouteCharge.cash_charge,
                route_duration: input.route_duration,
                handler: input.handler,
                time: timeArray,
                client_id: client_id,
                route_id: clientRouteRespObj.client_route_id,
            }
            try {
                const resp = await addTimeAndChargeToClientRoute(data).unwrap()
                swal("Success",resp.message,"success");
                navigate(-1);
            } catch (err) {
                if(err.data.hasOwnProperty('errors') == true){
                    setErrors({
                        ...errors, 
                        client_charge: err.data.errors.client_charge, 
                        commission_charge: err.data.errors.commission_charge, 
                        cash_charge: err.data.errors.cash_charge, 
                        route_duration: err.data.errors.route_duration, 
                        handler: err.data.errors.handler, 
                        time: err.data.errors.time,
                    })
                }else if (err.data.hasOwnProperty('error') == true){
                    swal("Error",err.data.error,"error");
                }
            }           
        }

    }

    const [deleteClientRoute] = useDeleteClientRouteMutation()

    const handDeleteUser = async(e,client_route_id)=>{
        if(window.confirm("Are you sure you want to delete this route? This action cannot be reversed")){
            try {
            const resp = await deleteClientRoute({client_route_id: client_route_id,client_id: client_id}).unwrap();
            swal("Success",resp.message);
            navigate(-1);
        } catch (err) {
            swal("Error",err.message,'error');
        }
          }
    }

    const editTime = ()=>{
        setClientTime(false)
    }

    const setClientCharge = (e)=>{
        setClientRouteCharge({
            ...clientRouteCharge, 
            client_charge: e.target.value 
        })
    }

    const setCommissionCharge = (e)=>{
        setClientRouteCharge({
            ...clientRouteCharge, 
            commission_charge: e.target.value 
        })
    }

    const setCashCharge = (e)=>{
        setClientRouteCharge({
            ...clientRouteCharge, 
            cash_charge: e.target.value 
        })
    }

    const back = ()=> navigate(-1);
    
   let constent = isLoading  
                ? <LoadingSpinner />
        :
        (
            <div className="bg-gray-100">
                <div className="flex items-center justify-center bg-white p-1 mb-5">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Route management</h1>
                </div>
                {
                    (isSuccessClientRoute)?
                        <div class="md:flex justify-center mt-5 sm:mt-0 mb-10 md:pt-4">
                            <div class=" bg-gray-700 md:w-2/3">
                                <div class="w-full shadow sm:rounded-md">
                                    <form onSubmit={handleSubmit} autoComplete="off" >
                                                <div class="col-span-6 p-4 shadow-md bg-gray-700 uppercase">
                                                    {
                                                        <div className='flex justify-center'>
                                                            <h1 className='uppercase font-semibold text-sm text-white'>edit {clientRouteRespObj.start_off_station_name} - {clientRouteRespObj.destination_station_name} Route</h1>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    (routeLogs.updated_at != false)?
                                                        <div class="col-span-6 p-4 shadow-md bg-gray-200 italic">
                                                            {
                                                                <div className='flex justify-center'>
                                                                    <h1 className='text-sm text-gray-700'>updated_at: {routeLogs.updated_at}</h1>
                                                                </div>
                                                            }
                                                        </div>
                                                    :null
                                                }
                                        <div class="px-4 py-5 bg-gray-100 sm:p-6">
                                            <div class="grid grid-cols-2 gap-4">
                                                <div class="col-span-6 sm:col-span-3">
                                                    <label for="client_charge" class="block text-sm font-medium text-gray-700">Charge</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder='Charge in ZWM'
                                                        name="client_charge" 
                                                        id="client_charge" 
                                                        value={clientRouteCharge.client_charge}
                                                        onChange={setClientCharge}
                                                    />
                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.client_charge}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3">
                                                    <label for="cash_charge" class="block text-sm font-medium text-gray-700">Cash Charge</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder='Cash charge in ZWM'
                                                        name="cash_charge" 
                                                        id="cash_charge" 
                                                        value={clientRouteCharge.cash_charge}
                                                        onChange={setCashCharge}
                                                    />
                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.cash_charge}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3">
                                                    <label for="commission_charge" class="block text-sm font-medium text-gray-700">Commission Charge</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder='Commission charge in ZWM'
                                                        name="commission_charge" 
                                                        id="commission_charge" 
                                                        value={clientRouteCharge.commission_charge}
                                                        onChange={setCommissionCharge}
                                                    />
                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.commission_charge}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3">
                                                    <label for="handler" class="block text-sm font-medium text-gray-700">Handler Contact</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder='Enter route handler phone (Optional)'
                                                        name="handler" 
                                                        id="handler" 
                                                        value={input.handler}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.handler}</p>
                                                </div>
                                                {
                                                    (!clientTime)?
                                                        <div class="col-span-6 sm:col-span-3">
                                                            <label for="name" class="block text-sm font-medium text-gray-700">
                                                                Select times
                                                            </label>
                                                            <Select 
                                                                class=""
                                                                placeholder={'Select time'}
                                                                options={time}
                                                                isMulti
                                                                isSearchable
                                                                onChange={setTime}
                                                            />
                                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.time}</p>
                                                     </div>
                                                    :
                                                        <div class="col-span-6 sm:col-span-3 rouded-md'">
                                                            <div className="flex justify-center bg-gray-300 p-2">
                                                                <div className='space-y-3'>
                                                                    <div className='flex justify-center'>
                                                                        <label for="name" class="block text-sm font-medium text-gray-700">Route Time</label>
                                                                    </div>
                                                                    <div className='flex items-center flex-wrap space-x-4'>
                                                                        {
                                                                            clientTime.map(data=>(
                                                                                    <p className='pr-1 pl-1 bg-gray-200 text-gray-600 border rounded-lg border-gray-400'>{data.label}</p>
                                                                                ))
                                                                        }
                                                                    </div>
                                                                    <div onClick={editTime} className='flex justify-center text-blue-500 cursor-pointer'>
                                                                        Edit time
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                }
                                                <div class="col-span-6 sm:col-span-3">
                                                    <label for="route_duration" class="block text-sm font-medium text-gray-700">Route Duration</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder=''
                                                        name="route_duration" 
                                                        id="route_duration" 
                                                        value={input.route_duration}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.route_duration}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-center space-x-6 items-center px-4 py-2 bg-gray-300 text-right sm:px-6">
                                            <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Cancel</div>
                                            <div onClick={(e)=>handDeleteUser(e, client_route_id)} class="w-20 inline-flex text-white justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-red-700 hover:bg-red-800 cursor-pointer">Delete</div>
                                            <Link to={`/user/client-route-logs/${client_route_id}`} class="w-20 inline-flex text-white justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-blue-400 hover:bg-blue-500 cursor-pointer">Logs</Link>
                                        </div>
                                        <div class="flex justify-center space-x-6 items-center px-4 py-2 bg-gray-400 text-right sm:px-6">
                                            <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    : clientRouteIsLoading?
                    <LoadingSpinner />
                    :isErrorClientRoute?
                            <div className="md:flex flex-col p-4">
                                <p className="text-blue-500 italic border-b border-black flex flex-1">You do not have any seating profile available</p>
                            </div>
                    :null

                }
            </div>
        )

    return constent;
}

export default EditClientRouteView;
