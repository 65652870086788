import {  UserCircleIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import ClientHeaderView from '../App/ClientHeaderView';

function UserProfileView(props) {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;
    const editUserPassword = ()=>{

    }

    const back = ()=> navigate(-1);

        return (
            <div className="bg-gray-100 flex  flex-col">
                <ClientHeaderView />
                <div className="mb-10" >
                    <div className="flex items-center justify-center bg-gray-50 p-0">
                        <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">User Profile</h1>
                    </div>
                </div>
                <div className="">
                   <div className="md:flex justify-center mt- p-8">
                        <div className="bg-gray-200 shadow-sm pr-0 pl-0 pt-2 pb-0 rounded-md">
                            <div className="flex justify-center md:pr-60 md:pl-60">
                                <UserCircleIcon className="h-20 text-gray-500" />
                            </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Name</p>
                                    <p className="mr-20 p-2 text-xs uppercase">{user.fname} {user.lname}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Email</p>
                                    <p className="mr-20 p-2 text-xs">{user.email}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Phone No.</p>
                                    <p className="mr-20 p-2 text-xs">{user.phone}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-semibold text-sm">ID</p>
                                    <p className="mr-20 p-2 text-sm">{user.user_id}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Business/Company</p>
                                    <p className="mr-20 p-2 text-xs">{user.name} {user.business_type}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-bold text-xs">Gender</p>
                                    <p className="mr-20 p-2 text-xs">{user.gender}</p>
                                </div>
                                <div className="flex justify-center mt-4 bg-blue-400 border-t border-gray-300 p-4">
                                    <div className="flex flex-col">
                                        <label onClick={editUserPassword} className="text-xs text-gray-100 hover:text-black cursor-pointer">Change password</label>
                                    </div>                           
                                 </div>
                        </div>
                   </div>
                </div>
            </div>
        )
}

export default UserProfileView;
