
import { useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { useCreateTransactionMutation } from '../../Features/clientBulkSmsSlice';
import LoadingSpinner from '../LoadingSpinner';

function CreateClientBulkSMSTransactionView() {
    const navigate = useNavigate();
    const { client_id } = useParams(); 

    const [input, setInput] = useState({
        charge: '',
        bundle: '',
        transaction_date: '',
        rate: '',
        description: '',
        client_id: client_id,
    });

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const [errors, setErrors] = useState({
        charge: '',
        bundle: '',
        transaction_date: '',
        rate: '',
        description: '',
    })


    const [createTransaction, {isLoading}] = useCreateTransactionMutation();

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await createTransaction(input).unwrap()
            if(resp.success === true){
                swal("Success",resp.message)
                navigate(-1);
            }
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({
                    ...errors, 
                    charge: err.data.errors.charge,
                    bundle: err.data.errors.bundle,
                    transaction_date: err.data.errors.transaction_date,
                    rate: err.data.errors.rate,
                    description: err.data.errors.description,
                })
            }
        }          
    }

    let constent = (isLoading)
        ?<LoadingSpinner />
        : (
            <div className="bg-gray-100">
                <div className="flex items-center justify-center bg-white p-1 mb-10">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Bulk SMS Management</h1>
                </div> 
                <div class="sm:mt-0 md:pt-1">
                    <div class="md:grid md:grid-cols-2 md:pr-40 md:pl-40">
                        <div class="bg-gray-200 mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class="sm:rounded-md">
                                <div class="">
                                    <div class="grid grid-cols-6 gap-6">
                                        <div class="col-span-6 p-4 shadow-md bg-gray-700 uppercase">
                                            <div className='flex justify-center'>
                                                <h1 className='uppercase font-semibold text-sm text-white text-white'>Create bulk sms transaction</h1>
                                            </div>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="charge" class="block text-xs font-medium text-gray-700">Charge</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Charge'
                                                name="charge" 
                                                id="charge" 
                                                value={input.charge}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.charge}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="bundle" class="block text-xs font-medium text-gray-700">Bundle</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Bundle'
                                                name="bundle" 
                                                id="bundle" 
                                                value={input.bundle}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.bundle}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="rate" class="block text-xs font-medium text-gray-700">Rate</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Rate'
                                                name="rate" 
                                                id="rate" 
                                                value={input.rate}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.rate}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3 p-2">
                                            <label for="transaction_date" class="block text-xs font-medium text-gray-700">Transaction Date</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Transaction date'
                                                name="transaction_date" 
                                                id="transaction_date" 
                                                value={input.transaction_date}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.transaction_date}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-6 p-2">
                                            <label for="description" class="block text-xs font-medium text-gray-700">Description</label>
                                            <textarea 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='description'
                                                name="description" 
                                                id="description" 
                                                value={input.description}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.description}</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="flex flex-col bg-gray-300">
                                    <div className='flex justify-center space-x-6 items-center px-4 pb-2 bg-gray-300 text-right sm:px-6 mt-2'>
                                        <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    return constent;
}

export default CreateClientBulkSMSTransactionView;
