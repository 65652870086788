import { apiSlice } from "../app/api/apiSlice";

export const SeatsSliceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPaginatedSytemsSeats: builder.query({
            query: ({paginate, page}) => (page == "")? `/user/system-seats/${paginate}` : `user/system-seats/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Seats']
        }),
        getSystemSeatsForClientRouteTime: builder.query({
            query: () => `/user/seats`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Seats']
        }),
        createSeat: builder.mutation({
            query: (input) => ({
                url: `user/create-seat`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Seats']
        }),
        editSeat: builder.mutation({
            query: (input) => ({
                url: `user/edit-seat/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['Seats']
        }),
        getSeatById: builder.query({
            query: (id) => `/user/seat/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Seats']
        }),
        creatClientSeatingProfile: builder.mutation({
            query: (input) => ({
                url: `/user/create-seating-profile`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Seats']
        }),
        getClientSeatingProfileByclientId: builder.query({
            query: (id) => `/user/client-seating-profile/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Seats']
        }),
        getClientSeatingProfileForTimeByclientId: builder.query({
            query: (id) => `/user/client-seating-profile-for-time/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Seats']
        }),
        getSeatingProfileSeatsByClientAndSeatingProfileIds: builder.query({
            query: ({client_id}) => `/user/seating-profile-seats/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Seats']
        }),
        getRouteSeatingInfo: builder.query({
            query: ({client_id, client_route_id}) => `/user/route-seating-info/${client_id}/${client_route_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Seats']
        }),
    })
})

export const {
    useCreateSeatMutation,
    useGetPaginatedSytemsSeatsQuery,
    useGetSystemSeatsForClientRouteTimeQuery,
    useGetSeatingProfileSeatsByClientAndSeatingProfileIdsQuery,
    useGetSeatByIdQuery,
    useCreatClientSeatingProfileMutation,
    useEditSeatMutation,
    useGetClientSeatingProfileByclientIdQuery,
    useGetClientSeatingProfileForTimeByclientIdQuery,
} = SeatsSliceApiSlice

