import { apiSlice } from "../app/api/apiSlice";

export const purposeOfTravelSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSytemPurposesOfTravel: builder.query({
            query: () => `/user/system-purposees`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['PurposeOfTravel']
        }),
        getPurposeOfTravelForCustomer: builder.query({
            query: () => `/user/get-purpose-of-travel`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['PurposeOfTravel']
        }),
        createPurpose: builder.mutation({
            query: (input) => ({
                url: `user/create-purpose`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['PurposeOfTravel']
        }),
        editPurpose: builder.mutation({
            query: (input) => ({
                url: `user/edit-purpose/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['PurposeOfTravel']
        }),
        getPurposeById: builder.query({
            query: (id) => `/user/purpose/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['PurposeOfTravel']
        }),
        getPurposeOfTravelForOptimization: builder.query({
            query: ({client_id, from, to}) => `/user/purposes-for-optimization/${client_id}/${from}/${to}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['PurposeOfTravel']
        }),
        deletePurpose: builder.mutation({
            query: (id) => ({
                url: `user/delete-purpose/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PurposeOfTravel']
        }),
    })
})

export const {
    useGetSytemPurposesOfTravelQuery,
    useCreatePurposeMutation,
    useEditPurposeMutation,
    useGetPurposeByIdQuery,
    useGetPurposeOfTravelForOptimizationQuery,
    useDeletePurposeMutation,
    useGetPurposeOfTravelForCustomerQuery,
} = purposeOfTravelSlice

