import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ScriptableContext,
    Filler,
  } from 'chart.js';
  import { Bar, Line, Pie } from 'react-chartjs-2';
import { useGetClientManagerTeamRevenueReportReviewQuery } from '../../Features/dataAnalysisReportingSlice';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetUserProfileByIdQuery } from '../../Features/usersSlice';
import ClientDataAnalysisHeaderView from './ClientDataAnalysisHeaderView';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

function ClientDataAnalystClientManagerTeamRevenueReportView() {
    const  user  = useSelector(selectCurrentUser);
    const { admin_user_id } = useParams();
  
    const client_id = user.client_id;

    const {
        data: client,
        isSuccess: isSuccessClient,
        isLoading: isLoadingClient,
        isError: isErrorClient,
    } = useGetClientByIdQuery(client_id);

    const {
      data: managerUser,
      isSuccess,
  } = useGetUserProfileByIdQuery(admin_user_id)

    const {
      data: teamRevenueReport,
      isSuccess: isSuccessTeamRevenueReport,
      isLoading: isLoadingTeamRevenueReport,
      isError: isErrorTeamRevenueReport,
  } = useGetClientManagerTeamRevenueReportReviewQuery(admin_user_id);

const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getFullYear();
  }

  const formatDisplayDateForDayMonth = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

  const numberWithCommas = (x)=>{
    if(x !==null){
      const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
      return formatedNumber;
    }
    return null;
  }

  const chartBgColor = {
    id: 'chartBgColor',
    beforeDraw: (chart, options)=>{
      const{ ctx, width, height }= chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    }
  }


    return   (
      <div className="bg-gray-100">
      <ClientDataAnalysisHeaderView />
       {
          (isSuccess)?
              <div className="flex justify-center items-center bg-gray-50 p-1">
                  <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{`${managerUser.data.team_name}`} station revenue reports {`(${managerUser.data.lname} ${managerUser.data.fname})`}</h1>
              </div>
           : null
       }
        {
          (isSuccessTeamRevenueReport)?
            (teamRevenueReport.data.length)?
              <div>
                {
                  (isSuccessClient)?
                      <div class="mt-4 sm:mt-0  md:pt-4 space-y-4 md:mr-28 md:ml-28  lg:mr-28 lg:ml-28 mb-12">
                          <div class="grid grid-cols-1">         
                              {
                                    teamRevenueReport.data.map(data => (
                                        (data.last28Days)?
                                        <div>
                                          <div>
                                            <Line
                                                height={260}
                                                data={{
                                                  labels: (data.last28Days)? data.last28Days.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                  datasets: [
                                                    {
                                                      label: 'Total',
                                                      data: (data.last28Days)? data.last28Days.map(data=>data.cashTotal): '',
                                                      fill: "start",
                                                      backgroundColor: (context: ScriptableContext<"line">) => {
                                                        const ctx = context.chart.ctx;
                                                        const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                        gradient.addColorStop(1, 'rgba(80, 120, 255, 0.13)');
                                                        gradient.addColorStop(0, 'rgba(250,174,50,0.2)');
                                                        return gradient;
                                                      },
                                                      borderColor: 'rgba(80, 120, 255, 0.13)',
                                                      borderWidth: 0.8
                                                    },
                                                  ]
                                                }}
                                                options={{
                                                  bezierCurve: true,
                                                  plugins: {
                                                    color: "red",
                                                    legend: {
                                                      position: 'top',
                                                    },
                                                    title: {
                                                      display: true,
                                                      text: 'Last 28 days daily revenue report',
                                                    },
                                                    tooltip: {
                                                      enabled: false
                                                    },
                                                    datalabels: {
                                                      align: 'top',
                                                      labels: {
                                                        value: {
                                                          color: 'black',
                                                          font: {
                                                            weight: ''
                                                          }
                                                        }
                                                      },
                                                      formatter: (value)=>{
                                                        return numberWithCommas(value);
                                                      }
                                                    }
                                                  },
                                                  elements: {
                                                    line: {
                                                      tension: 0.16
                                                    }
                                                  },
                                                  maintainAspectRatio: false,
                                                  scales: {
                                                    yAxes: [
                                                      {
                                                        ticks: {
                                                          beginAtZero: true,
                                                          stepSize: 10
                                                        }
                                                      }
                                                    ]
                                                  },
                                                  layout: {
                                                    padding: {
                                                        right: 50,
                                                    }
                                                  }
                                                }
                                              }
                                              plugins={[chartBgColor]}
                                              />
                                          </div>
                                        </div>
                                        :null
                                    ))
                              }
                          </div>
                          <div class="grid grid-cols-1">         
                              {
                                    teamRevenueReport.data.map(data => (
                                        (data.last4Weeks)?
                                        <div> 
                                            <Bar
                                                height={200}
                                                data={{
                                                  labels: (data.last4Weeks)? data.last4Weeks.map(data=>formatDisplayDateForDayMonth(data.start_date)+" - "+formatDisplayDateForDayMonth(data.end_date)): '',
                                                  datasets: [
                                                    {
                                                      label: 'Total',
                                                      data: (data.last4Weeks)? data.last4Weeks.map(data=>data.cashTotal): '',
                                                      fill: "start",
                                                      backgroundColor: (context: ScriptableContext<"line">) => {
                                                        const ctx = context.chart.ctx;
                                                        const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                        gradient.addColorStop(1, 'rgba(80, 120, 255, 0.13)');
                                                        gradient.addColorStop(0, 'rgba(250,174,50,0.2)');
                                                        return gradient;
                                                      },
                                                      borderColor: 'rgba(80, 120, 255, 0.13)',
                                                      borderWidth: 0.6,
                                                      borderRadius: 3
                                                    },
                                                  ]
                                                }}
                                                options={{
                                                  bezierCurve: true,
                                                  plugins: {
                                                    color: "red",
                                                    legend: {
                                                      position: 'top',
                                                    },
                                                    title: {
                                                      display: true,
                                                      text: 'Last 4 weeks weekly revenue report',
                                                    },
                                                    tooltip: {
                                                      enabled: false
                                                    },
                                                    datalabels: {
                                                      align: 'top',
                                                      labels: {
                                                        value: {
                                                          color: 'black',
                                                          font: {
                                                            weight: ''
                                                          }
                                                        }
                                                      },
                                                      formatter: (value)=>{
                                                        return numberWithCommas(value);
                                                      }
                                                    }
                                                  },
                                                  elements: {
                                                    line: {
                                                      tension: 0.16
                                                    }
                                                  },
                                                  maintainAspectRatio: false,
                                                  scales: {
                                                    yAxes: [
                                                      {
                                                        ticks: {
                                                          beginAtZero: true,
                                                          stepSize: 10
                                                        }
                                                      }
                                                    ]
                                                  }
                                                }
                                              }
                                              plugins={[chartBgColor]}
                                              />
                                          </div>
                                        :null
                                    ))
                              }
                          </div>
                          <div class="ggrid grid-cols-2">         
                              {
                                teamRevenueReport.data.map(data => (
                                    (data.last12Months)?
                                      <div>
                                        <div>                                                          
                                          <Bar
                                              height={260}
                                              data={{
                                              labels: (data.last12Months)? data.last12Months.map(data=>formatDisplayDate(data.month)): '',
                                              datasets: [
                                                      {
                                                      label: 'Total',
                                                      data: (data.last12Months)? data.last12Months.map(data=>data.cashTotal): '',
                                                      fill: "start",
                                                      backgroundColor: (context: ScriptableContext<"line">) => {
                                                        const ctx = context.chart.ctx;
                                                        const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                        gradient.addColorStop(1, 'rgba(80, 120, 255, 0.13)');
                                                        gradient.addColorStop(0, 'rgba(250,174,50,0.2)');
                                                        return gradient;
                                                      },
                                                      borderColor: 'rgba(80, 120, 255, 0.13)',
                                                      borderWidth: 0.6,
                                                      borderRadius: 3
                                                      },
                                              ]
                                          }}
                                          options={{
                                              bezierCurve : true,
                                              plugins: {
                                                legend: {
                                                  position: 'top',
                                                },
                                                title: {
                                                  display: true,
                                                  text: 'Last 12 months monthly revenue report',
                                                },
                                                tooltip: {
                                                  enabled: false
                                                },
                                                datalabels: {
                                                  align: 'top',
                                                  color: 'black',
                                                  labels: {
                                                    value: {
                                                      font: {
                                                        weight: ''
                                                      }
                                                    }
                                                  },
                                                  formatter: (value)=>{
                                                    return numberWithCommas(value);
                                                  }
                                                }
                                              },
                                              elements: {
                                                  line: {
                                                    tension: 0.55
                                                  }
                                                },
                                              maintainAspectRatio: false,
                                              scales: {
                                                yAxes: [
                                                  {
                                                    ticks: {
                                                      beginAtZero: true,
                                                    }
                                                  }
                                                ]
                                              }
                                          }}
                                          plugins={[chartBgColor]}
                                          />
                                        </div>
                                      </div>
                                    :null
                                ))
                              }
                          </div>
                          <div class="ggrid grid-cols-2">         
                            {
                              teamRevenueReport.data.map(data => (
                                (data.annualRevenue)?
                                    <div>
                                      <div>                                                          
                                        <Pie
                                            height={260}
                                            data={{
                                              labels: (data.annualRevenue)? data.annualRevenue.map(data=>data.year): '',
                                              datasets: [
                                                      {
                                                        label: 'Total',
                                                        data: (data.annualRevenue)? data.annualRevenue.map(data=>data.cashTotal): '',
                                                        backgroundColor: [
                                                            'rgba(80, 120, 255, 0.1)',
                                                            'rgba(255, 132, 235, 0.1)',
                                                            'rgba(54, 162, 235, 0.1)',
                                                            'rgba(255, 206, 86, 0.1)',
                                                            'rgba(75, 192, 192, 0.1)',
                                                        ]
                                                        },
                                              ]
                                              }}
                                        options={{
                                            bezierCurve : true,
                                            plugins: {
                                              legend: {
                                                position: 'top',
                                              },
                                              title: {
                                                display: true,
                                                text: 'Annual revenue report',
                                              },
                                              tooltip: {
                                                enabled: false
                                              },
                                              datalabels: {
                                                align: 'center',
                                                color: 'black',
                                                labels: {
                                                  value: {
                                                    font: {
                                                      weight: ''
                                                    }
                                                  }
                                                },
                                                formatter: (value)=>{
                                                  return numberWithCommas(value);
                                                }
                                              }
                                            },
                                            elements: {
                                                line: {
                                                  tension: 0.55
                                                }
                                              },
                                            maintainAspectRatio: false,
                                        }}
                                        plugins={[chartBgColor]}
                                        />
                                      </div>
                                    </div>
                                  :null
                              ))
                            }
                        </div>
                      </div>
                  : isLoadingClient?
                      <LoadingSpinner />
                  : isErrorClient?
                      <div className="md:flex flex-col p-4 mb-20">
                          <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                      </div>
                  : null
                  
              }
              </div>
            :
              <div className="md:flex flex-col p-4 mb-20">
                <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
              </div>
          : isLoadingTeamRevenueReport?
            <LoadingSpinner />
          : isErrorTeamRevenueReport?
            <div className="md:flex flex-col p-4 mb-20">
                <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
            </div>
          :null
        }
  </div>
    )
}

export default ClientDataAnalystClientManagerTeamRevenueReportView;
