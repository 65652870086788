
import {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import Select from 'react-select';
import LoadingSpinner from '../LoadingSpinner';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import ClientHeaderView from '../App/ClientHeaderView';
import { useClientAccountingUserRegisterMutation } from '../../Features/usersSlice';

function AccountingUserRegisterView(props) {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const [clientAccountingUserRegister, {isLoading}] = useClientAccountingUserRegisterMutation()

    const [input, setInput] = useState({
        username: '',
        password: '',
        password_comfirm: '',
        email: '',
        phone: '',
        fname: '',
        lname: '',
        gender: '',
        user_id: '',
        client_id: client_id,
        selected_gender: '',
    })

    const [errors, setErrors] = useState({
        username: '',
        password: '',
        password_comfirm: '',
        email: '',
        phone: '',
        fname: '',
        lname: '',
        gender: '',
        user_id: '',
        client_id: ''
    })

      const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleSetGender = (e)=>{
        setInput({
            ...input, gender: e.value,
            selected_gender: {value: e.value, label: e.label}
        })
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        if(input.password !== input.password_comfirm){
            return swal("","Password and comfirm password don't match","error");
        }
        try {
            const resp = await clientAccountingUserRegister(input).unwrap()
            if(resp.success === true){
                swal("Success",resp.message)
                navigate(-1);
            }
        } catch (err) {
            if(err.data){
                setErrors({
                    ...errors, 
                    password: err.data.errors.password, 
                    username: err.data.errors.username,
                    fname: err.data.errors.fname,
                    lname: err.data.errors.lname,
                    email: err.data.errors.email,
                    phone: err.data.errors.phone,
                    gender: err.data.errors.gender,
                    user_id: err.data.errors.user_id,
                    client_id: err.data.errors.client_id,
                })
            }else if(err.data.error){
                return swal("Registration Failed",err.data.error,"error");
            }
        }          
    }

    const back = ()=> navigate(-1);

    let constent = (isLoading)
        ?<LoadingSpinner />
        : (
            <div className="bg-gray-100">
                <ClientHeaderView />
                <div className='flex items-center justify-center bg-gray-50 p-0 mb-10'>
                      <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">Register accounting user</h1>
                  </div>
                        <div>
                            <div class="sm:mt-0 md:pt-1 mb-16">
                                <div class="md:grid md:grid-cols-1 md:pr-60 md:pl-60">
                                    <div class="bg-gray-100 mt-5 md:mt-0 md:col-span-2">
                                        <form onSubmit={handleSubmit} autoComplete="off" >
                                                <div class="p-3 mb-6 bg-teal-500 rounded-t ">
                                                    <div className='flex justify-center'>
                                                        <h1 className='uppercase font-semibold text-xs text-gray-100'>user register</h1>
                                                    </div>
                                                </div>
                                                <div class="p-4 grid grid-cols-6 gap-6">
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="fname" class="block text-xs font-medium text-gray-700">First name</label>
                                                        <input 
                                                            class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            type="text"
                                                            name="fname" 
                                                            id="fname" 
                                                            value={input.fname}
                                                            onChange={handleChange}
                                                        />
                                                        <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.fname}</p>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="lname" class="block text-xs font-medium text-gray-700">Last name</label>
                                                        <input 
                                                            class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            type="text"
                                                            name="lname" 
                                                            id="lname" 
                                                            value={input.lname}
                                                            onChange={handleChange}
                                                        />
                                                        <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.lname}</p>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="email" class="block text-xs font-medium text-gray-700">Email</label>
                                                        <input 
                                                            class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            type="email"
                                                            name="email" 
                                                            id="email" 
                                                            value={input.email}
                                                            onChange={handleChange}
                                                        />
                                                        <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.email}</p>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="phone" class="block text-xs font-medium text-gray-700">Phone number</label>
                                                        <input 
                                                            class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            type="text"
                                                            name="phone" 
                                                            id="phone" 
                                                            value={input.phone}
                                                            onChange={handleChange}
                                                        />
                                                        <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.phone}</p>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="country" class="block text-xs font-medium text-gray-700">Gender</label>
                                                        <Select 
                                                                class="block
                                                                w-full
                                                                px-3
                                                                py-1.5
                                                                text-base
                                                                font-normal
                                                                text-gray-700
                                                                bg-white bg-clip-padding
                                                                border border-solid border-gray-300
                                                                rounded
                                                                transition
                                                                ease-in-out
                                                                m-0"
                                                                placeholder="Select gender"
                                                                defaultValue={input.selected_gender}
                                                                options={[
                                                                    {value:1, label:"Male"},
                                                                    {value:2, label:"Female"}
                                                                ]}
                                                                isSearchable
                                                                onChange={handleSetGender}
                                                        />
                                                        <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.gender}</p>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="user_id" class="block text-xs font-medium text-gray-700">Valid NRC/Passport number</label>
                                                        <input 
                                                            class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            type="text"
                                                            placeholder='Enter NRC/Passport ID'
                                                            name="user_id" 
                                                            id="user_id" 
                                                            value={input.user_id}
                                                            onChange={handleChange}
                                                        />
                                                        <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.user_id}</p>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="username" class="block text-xs font-medium text-gray-700">Username</label>
                                                        <input 
                                                            class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            type="text"
                                                            name="username" 
                                                            id="username" 
                                                            value={input.username}
                                                            onChange={handleChange}
                                                        />
                                                        <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.username}</p>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="password" class="block text-xs font-medium text-gray-700">Password</label>
                                                        <input 
                                                            class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            type="password"
                                                            name="password" 
                                                            id="password" 
                                                            value={input.password}
                                                            onChange={handleChange}
                                                        />
                                                        <p className="block text-xs font-medium text-red-800 p-0 m-0">{errors.password}</p>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-3">
                                                        <label for="password_comfirm" class="block text-xs font-medium text-gray-700">Confirm password</label>
                                                        <input 
                                                            class="mt-1 p-2 outline-none bg-white block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            type="password"
                                                            name="password_comfirm" 
                                                            id="password_comfirm" 
                                                            value={input.password_comfirm}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="flex justify-center space-x-6 items-center px-4 py-4 bg-gray-200 text-right sm:px-6 mt-2">
                                            <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md bg-gray-300 hover:bg-gray-400 cursor-pointer">Cancel</div>
                                            <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Submit</button>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
        )

    return constent;
}

export default AccountingUserRegisterView;
