import { apiSlice } from "../app/api/apiSlice";

export const RgionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPaginatedSytemsRegions: builder.query({
            query: ({paginate, page}) => (page == "")? `/user/system-regions/${paginate}` : `user/system-regions/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Regions']
        }),
        getSystemRegionsForRoutes: builder.query({
            query: () => `/user/regions`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Regions']
        }),
        createRegion: builder.mutation({
            query: (input) => ({
                url: `user/create-region`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Regions']
        }),
        editRegion: builder.mutation({
            query: (input) => ({
                url: `user/edit-region/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['Regions']
        }),
        getRegionById: builder.query({
            query: (id) => `/user/region/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Regions']
        }),
        getRegionsForOptimization: builder.query({
            query: ({client_id, from, to}) => `/user/regions-optimization/${client_id}/${from}/${to}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Regions']
        }),
        deleteRegion: builder.mutation({
            query: (id) => ({
                url: `user/delete-region/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Regions']
        }),
    })
})

export const {
    useCreateRegionMutation,
    useEditRegionMutation,
    useGetPaginatedSytemsRegionsQuery,
    useGetRegionsForOptimizationQuery,
    useGetRegionByIdQuery,
    useDeleteRegionMutation,
    useGetSystemRegionsForRoutesQuery,
} = RgionApiSlice

