import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectCurrentRoles, selectCurrentUser, selectCurrentToken, selectCurrentClientRole, selectIsManagerState, selectIsClientState, selectIsClientPromotorState, selectIsClientAccountantState, selectIsClientDataAnalystManagerState} from "../Features/auth/authSlice";


const PublicAuth = ()=>{
    const  token  = useSelector(selectCurrentToken);
    const  user  = useSelector(selectCurrentUser);
    const  roles  = useSelector(selectCurrentRoles);
    const  isClientUser  = useSelector(selectCurrentClientRole);
    const  is_manager  = useSelector(selectIsManagerState);
    const  is_client_accountant  = useSelector(selectIsClientAccountantState);
    const  is_client_promotor  = useSelector(selectIsClientPromotorState);
    const  is_client_data_analyst_manager  = useSelector(selectIsClientDataAnalystManagerState);
    const  is_client  = useSelector(selectIsClientState);
    
    return (
        (!roles && !token && !user)?
            <Outlet />
        :(is_manager)?
            < Navigate to={'/manager'} />
        :(is_client_data_analyst_manager)?
            < Navigate to={'/data-analysis'} />
        :(is_client_promotor)?
            < Navigate to={'/sales'} />
        :(is_client_accountant)?
            < Navigate to={'/accounting'} />
        :(is_client)?
            < Navigate to={'/client'} />
         :(roles && token && user && !isClientUser)?
            < Navigate to={"/user/users"}  />
         :<Outlet />
    )

}

export default PublicAuth;