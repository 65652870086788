import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import swal from 'sweetalert';
import { useGetSystemRoutesQuery } from '../../Features/RoutesSlice';
import { useCheckClientIsSubscribedToRouteMutation, useSubscribeToRoutesMutation } from '../../Features/clientsRoutesSlice';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import ClientHeaderView from '../App/ClientHeaderView';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';

function ManageSystemRoutesForClientSubscriptionView({}) {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const {
        data: client,
        isSuccess: isClientSuccessful,
      } = useGetClientByIdQuery(client_id)

    const {
      data: routes,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetSystemRoutesQuery()

    const [input, setInput] = useState({
      "client_routes": []
    })

  const [checkClientIsSubscribedToRoute,{isLoading: isLoadingRouteClientStatus}] = useCheckClientIsSubscribedToRouteMutation();


  const handleChange = async(e)=>{
      const { value, checked } = e.target;
      const {client_routes} = input;

      if(checked){
          try {
                const resp = await checkClientIsSubscribedToRoute({route_id: value, client_id: client_id}).unwrap()
                setInput({client_routes: [...client_routes, value]})
          } catch (err) {
                      swal("Action failed",err.data.message,'error')
          } 
      }else{
          setInput({
            client_routes: client_routes.filter((e)=> e !== value)
          });
      }
  }

  const [subscribeToRoutes,{isLoading: isLoadingSubscribe}] = useSubscribeToRoutesMutation();

  const handleSubmit = async(e)=>{
      e.preventDefault();
      try {

          const reqBoby = {
              client_id: client_id,
              routes: input.client_routes
          }

          const resp = await subscribeToRoutes(reqBoby).unwrap()
          swal("Success",resp.message)
          navigate(-1);
      } catch (err) {
          if(err){
              if(err.data.success == false){
                  swal("Action failed",err.data.message,'error')
              }
          }
      }          
  }

  const back = ()=> navigate(-1);

    return   (
            <div className="bg-gray-100">
                <ClientHeaderView />
                <div className="" >
                  {
                    (isClientSuccessful)?
                    <div className="flex items-center justify-center bg-gray-50 p-0">
                      <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} Route Subscription</h1>
                    </div>
                    : null
                  }
                </div>
                <div className="flex justify-start p-0 bg-white">
                    <h1 className="text-xs text-gray-500 ml-2 p-1">Please check all routes you wish to subscribe to and submit</h1>
                </div>
                <form onSubmit={handleSubmit} >
                    {(isSuccess)?
                        (routes.success === true && routes.data.length > 0)?
                        <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-teal-500 shadow-lg">
                                <tr>
                                <th
                                    scope="col"
                                    className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                >
                                    Departure
                                </th>
                                <th
                                    scope="col"
                                    className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                >
                                    Destination
                                </th>
                            </tr>
                            </thead>  
                            <tbody className="bg-white divide-y divide-gray-200">
                                    { (routes.success === true && routes.data.length > 0)?
                                        routes.data.map(data => (
                                            <tr key={data.id} className="hover:bg-gray-200">
                                                <td className="px-6 py-1 whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <div className="flex items-center space-x-4 text-xs font-medium text-gray-900">
                                                        <input 
                                                            type="checkbox" 
                                                            name="roles[]" 
                                                            value={data.id}
                                                            onChange={handleChange}
                                                        />
                                                        <label className=''>{data.start_off_station_name}</label>
                                                    </div>
                                                </div>
                                                </td>
                                                <td className="px-2 py-1 whitespace-nowrap text-xs font-medium text-gray-900">
                                                    <div className=''>{data.destination_station_name}</div>
                                                </td>
                                            </tr>
                                            ))
                                            : ''
                                    }
                            </tbody>
                        </table>
                        :
                            <div>No system route found</div>
                    : isLoading?
                            <LoadingSpinner />
                        : isError?
                            <div className="md:flex p-4">
                            <p className="text-blue-500 italic border-b border-black flex flex-1">Data not found</p>
                            </div>
                        : null
                    }
                        <div className="flex justify-center mt-1 space-x-4 bg-gray-200 p-4 mb-4">
                                <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                    <div onClick={back}  className="flex justify-center space-x-2 items-center text-xs bg-gray-300 text-gray-700 hover:bg-gray-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                        <div className="flex justify-center">
                                            Back
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                    <button type='submit' className="flex justify-center space-x-2 items-center text-xs bg-blue-400 hover:bg-blue-400 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                        <div className="text-white flex justify-center">
                                            {
                                                (isLoadingSubscribe)?
                                                <div>Wait...</div>
                                                :
                                                    <div>Submit</div>
                                            }
                                        </div>
                                    </button>
                                </div>                           
                        </div>
                    </form>

            </div>
    )
    
}

export default ManageSystemRoutesForClientSubscriptionView;
