import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import { Menu } from '@headlessui/react';
import { useState } from 'react';
import Logo from '../Logo';
import { useGetAllClientTransactionByClientIdForFilterQuery, useGetLastClientTransactionByClientIdQuery, usePreviewClientTransactionsQuery } from '../../Features/clientBulkSmsSlice';
import { useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/outline';

function ManageClientBulkSMSView(props) {
  const navigate = useNavigate();
  const { client_id } = useParams(); 
  
  const [transactionId, setTransactionId] = useState(0);

    const {
      data: client,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetClientByIdQuery(client_id)

  const {
    data: lastTransaction,
    isLoading: isLoadingLastTransaction,
    isSuccess: isSuccessLastTransaction,
    isError: isErrorLastTransaction,
    error: errorLastTransaction
  } = useGetLastClientTransactionByClientIdQuery(client_id)

  const {
    data: previewClientTransactions,
    isSuccess: isSuccessPreviewClientTransactions,
    isLoading: isLoadingPreviewClientTransactions,
    isError: isErrorPreviewClientTransactions,
    error: errorPreviewClientTransactions,
    refetch
} = usePreviewClientTransactionsQuery({client_id: client_id, transaction_id: transactionId});

const {
  data: allClientBulkSmsTransaction,
  isLoading: isLoadingAllClientBulkSmsTransaction,
  isSuccess: isSuccessAllClientBulkSmsTransaction,
  isError: isErrorAllClientBulkSmsTransaction,
  error: errorAllClientBulkSmsTransaction
} = useGetAllClientTransactionByClientIdForFilterQuery(client_id)

  useEffect(()=>{
    refetch()
    return () =>{
    }
  },[transactionId]);

  const getSelectedTransactionData = async(e, id)=>{
    setTransactionId(id)
  }

  const numberWithCommas = (x)=>{
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

    return (
      <div className="bg-gray-100 mb-10">
          {
            (isSuccess) ?
              (isSuccessLastTransaction)?
                (isSuccessPreviewClientTransactions)?
                  <div>
                  <div className="mb-5" >
                    {
                      (isSuccess)?
                      <div className="flex items-center justify-between bg-gray-50 p-1">
                        <h1 className="text-xs text-gray-700 uppercase font-bold p-2">{client.data.name} Bulk sms management</h1>
                        <Link to={`/user/create-bulk-transaction/${client_id}`}  className="text-xs bg-gray-700 text-gray-200 cursor-pointer rounded uppercase flex justify-center font-bold p-2 w-20">
                          <div>New</div>
                        </Link>
                      </div>
                      : null
                    }
                    </div>
                  <div class="mt-4 sm:mt-0  md:pt-4 md:mr-32 md:ml-32  lg:mr-32 lg:ml-32">
                  <div class="grid grid-cols-1 bg-white rounded-sm">
                    <div className='flex justify-center items-center'>
                      <Logo />
                    </div>
                    <div className='flex justify-end items-center'>
                      {
                        (isSuccessAllClientBulkSmsTransaction)?
                          <Menu as="div" className='relative z-10 mr-8'>
                            <Menu.Button className="bg-gray-100 hover:bg-gray-200 border w-52 border-gray-500 pr-2 pl-2 pt-0 pb-0 rounded-md">
                              <div className="flex items-center text-gray-700 text-md space-x-1 justify-between">
                                <p className="">Filter</p>
                                <ChevronDownIcon className="h-4 round-full" />
                              </div>
                            </Menu.Button>
                            <Menu.Items className="absolute focus:outline-none right-0 bg-white rounded-md shadow-lg border w-52 mr-0 mt-1">
                              {
                                (allClientBulkSmsTransaction.hasOwnProperty('success') === true && allClientBulkSmsTransaction.data.length > 0)?
                                  allClientBulkSmsTransaction.data.map(data => (
                                    <Menu.Item>
                                      <div onClick={(e)=>getSelectedTransactionData(e,data.id)} className='flex justify-center hover:bg-gray-100 cursor-pointer px-4 py-1 text-xs text-gray-700 border-b border-gray-200'>
                                        {data.transaction_date} for {data.bundle}
                                      </div>
                                    </Menu.Item>
                                  ))
                                :''
                              }
                            </Menu.Items>
                          </Menu>
                        :null
                      }
                    </div>
                    <div className='flex justify-end p-4 mt-6'>
                      <div className='flex flex-col'>
                        <div className='flex flex-col'>
                          <div className='text-xs font-bold pt-6 pb-3 flex justify-start text-gray-500'>Customer Details</div>
                          <div className="flex justify-between space-x-8 p-1">
                              <p className="text-xs font-semibold">Customer Name:</p>
                              <p className="text-xs">{client.data.name} {client.data.business_type}</p>
                          </div>
                          <div className="flex justify-between space-x-8 p-1">
                              <p className="text-xs font-semibold">Email address:</p>
                              <p className="text-xs">{client.data.email}</p>
                          </div>
                          <div className="flex justify-between space-x-8 p-1">
                              <p className="text-xs font-semibold">TPIN ID:</p>
                              <p className="text-xs">{client.data.business_tpin_number}</p>
                          </div>
                          <div className="flex justify-between space-x-8 p-1">
                              <p className="text-xs font-semibold">Sender ID:</p>
                              <p className="text-xs">{'JULDAN'}</p>
                          </div>
                          <div className="flex justify-between space-x-8 p-1">
                              <p className="text-xs font-semibold">Total available bundle:</p>
                              <p className="text-xs">{(client.data.bulk_sms_bundle !== null)? numberWithCommas(client.data.bulk_sms_bundle) : '0'}</p>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div className='text-xs font-bold pt-6 pb-3 flex justify-start text-gray-500'>Payment Details</div>
                          <div className="flex justify-between space-x-8 p-1">
                              <p className="text-xs font-semibold">Date:</p>
                              <p className="text-xs">{previewClientTransactions.data.transaction_date}</p>
                          </div>
                          <div className="flex justify-between space-x-8 p-1">
                              <p className="text-xs font-semibold">Amount:</p>
                              <p className="text-xs">ZMW {numberWithCommas(previewClientTransactions.data.charge)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col p-1">
                      <div className="flex flex-1 items-center border-b border-gray-700">
                        <div className="flex-1 w-1/2">
                          <div className="flex justify-center text-xs font-bold ">Description</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs font-bold">Transaction Date</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs font-bold">Rate</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs font-bold">VAT</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs font-bold">Subtotal</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs font-bold">Bundle</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs font-bold">Total</div>
                        </div>
                      </div>
                      <div className="flex flex-1 items-center p-2">
                        <div className="flex-1 w-1/2">
                          <div className="flex justify-center text-xs">{previewClientTransactions.data.description}</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs">{previewClientTransactions.data.transaction_date}</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs">ZMW {previewClientTransactions.data.rate}</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs">ZMW {numberWithCommas(previewClientTransactions.data.vat)}</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs">ZMW {numberWithCommas(previewClientTransactions.data.charge)}</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs">{numberWithCommas(previewClientTransactions.data.bundle)}</div>
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-center text-xs">ZMW {numberWithCommas(previewClientTransactions.data.charge)}</div>
                        </div>
                      </div>
                    </div>
                    <div className='flex justify-end p-4 mt-2'>
                      <div className='flex flex-col'>
                        <div className='flex flex-col'>
                          <div className='text-xs font-bold pt-6 pb-3 flex justify-start text-gray-500'>Last Transaction</div>
                          <div className="flex justify-between space-x-72 p-2 border-b border-gray-300">
                              <p className="text-xs font-semibold">Subtotal</p>
                              <p className="text-xs">ZMW {numberWithCommas(lastTransaction.data.charge)}</p>
                          </div>
                          <div className="flex justify-between space-x-72 p-2 border-b border-gray-300">
                              <p className="text-xs font-semibold">Tax(16%)</p>
                              <p className="text-xs">ZMW {numberWithCommas(lastTransaction.data.vat)}</p>
                          </div>
                          <div className="flex justify-between space-x-72 p-2">
                              <p className="text-xs font-semibold">Total</p>
                              <p className="text-xs">ZMW {numberWithCommas(lastTransaction.data.charge)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                </div>
                :(isLoadingPreviewClientTransactions)?
                  <LoadingSpinner />
                :(isErrorPreviewClientTransactions)?
                  <div className="mb-5" >
                    {
                      (isSuccess)?
                      <div className="flex items-center justify-between bg-gray-50 p-1">
                        <h1 className="text-xs text-gray-700 uppercase font-bold p-2">{client.data.name} Bulk sms management</h1>
                        <Link to={`/user/create-bulk-transaction/${client_id}`}  className="text-xs bg-gray-700 text-gray-200 cursor-pointer rounded uppercase flex justify-center font-bold p-2 w-20">
                          <div>New</div>
                        </Link>
                      </div>
                      : null
                    }
                    </div>
                :null
              :isLoadingLastTransaction?
                <LoadingSpinner />
              :isErrorLastTransaction?
              <div className="mb-5" >
                {
                  (isSuccess)?
                  <div className="flex items-center justify-between bg-gray-50 p-1">
                    <h1 className="text-xs text-gray-700 uppercase font-bold p-2">{client.data.name} Bulk sms management</h1>
                    <Link to={`/user/create-bulk-transaction/${client_id}`}  className="text-xs bg-gray-700 text-gray-200 cursor-pointer rounded uppercase flex justify-center font-bold p-2 w-20">
                      <div>New</div>
                    </Link>
                  </div>
                  : null
                }
                </div>
                :null
              : isLoading ?
              <LoadingSpinner />
              : isError ?
                  <div>
                  
                  </div>
                : null
          }
      </div>
    )
}

export default ManageClientBulkSMSView;
