import { apiSlice } from "../app/api/apiSlice";

export const TimeApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPaginatedSytemsTimes: builder.query({
            query: ({paginate, page}) => (page == "")? `/user/system-times/${paginate}` : `user/system-times/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Time']
        }),
        getSystemtimesForRoutes: builder.query({
            query: () => `/user/time`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Time']
        }),
        createTime: builder.mutation({
            query: (input) => ({
                url: `user/create-time`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Time']
        }),
        editTime: builder.mutation({
            query: (input) => ({
                url: `user/edit-time/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['Time']
        }),
        getTimeById: builder.query({
            query: (id) => `/user/time/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Time']
        }),
        getClientRouteTimeByClientIdRouteIdAndTimeId: builder.query({
            query: ({client_route_time_id}) => `user/single-client-route-time/${client_route_time_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Time'],
            keepUnusedDataFor: 0
        }),
        getOperatorRouteTimeByStationsAndOperatorIds: builder.mutation({
            query: ({from, to, client_id}) => `user/operator-route-time/${from}/${to}/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Time'],
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `user/delete-user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Time']
        }),
    })
})

export const {
    useCreateTimeMutation,
    useGetPaginatedSytemsTimesQuery,
    useGetClientRouteTimeByClientIdRouteIdAndTimeIdQuery,
    useGetOperatorRouteTimeByStationsAndOperatorIdsMutation,
    useGetTimeByIdQuery,
    useDeleteUserMutation,
    useEditTimeMutation,
    useGetSystemtimesForRoutesQuery,
} = TimeApiSlice

