import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import ClientHeaderView from '../App/ClientHeaderView';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useDeleteClientTicketExtentionMutation, useGetClientTicketExtentionsQuery } from '../../Features/clientTicketExtentionSlice';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import swal from 'sweetalert';

function ManageClientTicketExtentiontionView() {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const {
        data: client,
        isSuccess: isSuccessClient,
    } = useGetClientByIdQuery(client_id);

    const {
        data: data,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetClientTicketExtentionsQuery(client_id)

      const [deleteClientTicketExtention] = useDeleteClientTicketExtentionMutation();

      const handDelete = async(e,id)=>{
        if(window.confirm("Are you sure you want to delete this extention?")){
              try {
              const resp = await deleteClientTicketExtention(id).unwrap();
                return swal("Success",resp.message);
              } catch (err) {
                  swal("Error",err.message,'error');
              }
          }
    }

    const back = ()=> navigate(-1);

    return   (
                <div className="bg-gray-100">
                    <ClientHeaderView />
                    {
                        (isSuccessClient)?
                            <div className="flex justify-between mb-12 items-center bg-gray-50">
                            <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} Ticket Extention Management</h1>
                            <div className='flex items-center justify-center'>
                                <Link 
                                to="/client/client-create-ticket-extention" 
                                className="bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-900 text-xs cursor-pointer flex justify-center rounded-md mr-3 p-1 w-20">
                                New
                                </Link>
                            </div>
                            </div>

                         : null
                     }
                    {
                        (isSuccess)?
                            <div class="md:flex justify-center mt-5 sm:mt-0 mb-6 md:pt-4">
                                <div class="g-gray-100 md:w-2/3">
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <div class=" shadow overflow-hidden rounded-t-md">
                                                <div class="col-span-6 p-4  bg-teal-500 uppercase">
                                                    <div className='flex justify-center'>
                                                        <h1 className='uppercase font-semibold text-xs text-white'>Ticket Extentions</h1>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="md:col-span-2 bg-gray-100">
                                    <table className="w-full divide-y divide-gray-200">
                                        <thead className="bg-teal-500 shadow-lg">
                                            <tr>
                                            <th
                                                scope="col"
                                                className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                            >
                                                Motive
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                            >
                                                Extention
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                            >
                                                Actions
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody className='bg-gray-100 divide-y divide-gray-100'>
                                            { 
                                            (data.success === true && data.data.length > 0)?
                                                data.data.map(data => (
                                                <tr>
                                                    <td className="px-2 py-6 whitespace-nowrap text-xs font-bold">
                                                        <div className=''>{data.purpose}</div>
                                                    </td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-xs font-medium">
                                                        <div className=''>{data.body}</div>
                                                    </td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-xs font-medium">
                                                        <div className='flex justify-start space-x-1 items-center'>
                                                            <Link to={`/client/client-edit-ticket-extention/${data.id}`} className='cursor-pointer hover:bg-gray-200 text-gray-700 rounded-md pl-1.5 pr-1.5 flex justify-center w-16 space-x-1 items-center'>
                                                                <PencilAltIcon className="h-5"/>
                                                                <div className='text-xs'>Edit</div>
                                                            </Link>
                                                            <div onClick={(e)=>handDelete(e, data.id)} className='cursor-pointer hover:bg-gray-200 rounded-md text-gray-700 pl-1.5 pr-1.5 flex justify-center w-16 space-x-1 items-center'>
                                                                <TrashIcon className="h-5"/>
                                                                <div className='text-xs'>delete</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                ))
                                            :null
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        : isLoading?
                            <LoadingSpinner />
                        : isError?
                            <div className="md:flex p-4">
                                <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                            </div>
                        : null
                        
                    }
                </div>
    )
}

export default ManageClientTicketExtentiontionView;
