import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOperatorByIdQuery } from "../../Features/clientsSlice";
import Home from "../../HeaderLayouts/Home";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import "../slideStyles.css";


// import Swiper core and required modules
import SwiperCore, {
Autoplay,Pagination,Navigation
} from 'swiper/core';
import { useState } from "react";
import swal from "sweetalert";
import { useGetAvailableSeatsQuery, useReservePhysicalTicketDataMutation } from "../../Features/ticketsSlice";
import { useGetClientRouteByIdQuery } from "../../Features/clientsRoutesSlice";
import FrontEndLoadingSpinner from "../FrontEndLoadingSpinner";

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

function ReserveSeatView(props) {  
    const navigate = useNavigate();
    const {client_id, client_route_id, time_id, date, time_label} = useParams();

    const [input, setInput] = useState({
        access_code: ''
    });
    const [selectedSeat, setSeat] = useState(false);

    const handleChange = (e)=>{
        setInput({
            ...input, [e.target.name]: e.target.value
        })
    }

    const handleSeatChange = (e)=>{
        const { value, checked } = e.target;
        setSeat(value)
    }

    const {
      data: client,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetOperatorByIdQuery(client_id)

    const {
        data: clientRoute,
        isLoading: isLoadingClientRoute,
        isSuccess: isSuccessClientRoute,
        // isError: isErrorClientRoute,
        // error: isErrorClientRoute
      } = useGetClientRouteByIdQuery({client_route_id})

    const {
        data: availableSeats,
        isLoading: isLoadingAvailableSeats,
        isSuccess: isSuccessAvailableSeats,
        isError: isErrorAvailableSeats,
        error: errorAvailableSeats
      } = useGetAvailableSeatsQuery({client_id:client_id, client_route_id:client_route_id, time_id:time_id, date: date})

    const [reservePhysicalTicketData] = useReservePhysicalTicketDataMutation();

    const handleReserveSeaet = async(e)=>{
        e.preventDefault();
        if(selectedSeat == false){
            return swal("Error","Select seat number","error");
        } else if(input.access_code == ""){
            return swal("Error","Access code is required","error");
        }
        const data = {
            client_route_id: client_route_id,
            time_id: time_id,
            date: date,
            client_id: client_id,
            seat_id: selectedSeat,
            access_code: input.access_code
        }
        try {
            const resp = await reservePhysicalTicketData(data).unwrap()
            swal("Success","Succesful","success");
            navigate(-1)
        } catch (err) {
            return swal("Error",err.data.error,"error");
        }           
    }

    const formatDisplayDate = (formatDate)=>{
        let tempDate = new Date(formatDate);
        let month = tempDate.toLocaleString('default',{month: 'short'});
        return month + "-" + tempDate.getDate() + "-" + tempDate.getFullYear();
    }

    return (
                    <div className='bg-white'>
                        <div className="sticky top-0 z-50">
                            <Home />
                        </div>
                        {
                            (isSuccess)?
                                (isSuccessAvailableSeats)?
                                    (isSuccessClientRoute)?
                                        <div className="bg-white">
                                        <div class="mt-10 sm:mt-0 md:pt-4">
                                            <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                                                <div class="mt-5 md:mt-0 md:col-span-2 bg-gray-200">
                                                <div className="flex flex-col bg-gray-300 p-4 rounded text-sm text-yellow-800">
                                                    <div className="flex justify-center">
                                                        <h1 className="text-sm font-semibold uppercase text-gray-900">
                                                            {
                                                                (isSuccess)?
                                                                    client.data.name+" routes seating status"
                                                                :null
                                                            }
                                                        </h1>
                                                    </div>
                                                </div>
                                                    <div className="flex flex-col bg-white">
                                                        <Swiper 
                                                            centeredSlides={true} 
                                                            className="mySwiper">
                                                                <SwiperSlide className="object-cover">
                                                                <img 
                                                                    className=""
                                                                    src={`http://localhost:8000/client_logo_image/${client.data.logo_image_url}`} 
                                                                    alt="Image" 
                                                                />
                                                                </SwiperSlide>
                                                        </Swiper>
                                                    </div>
                                                    <div className="" id="reserve-button">
                                                        <form autoComplete="off" onSubmit={handleReserveSeaet}> 
                                                            <div class="form-group mb-4">
                                                                <div id="seats-info" className="flex flex-col ">
                                                                    <div className="flex flex-col p-1 bg-gray-300">
                                                                        </div>
                                                                            <h1 className="flex space-x-4 bg-gray-300 p-2 justify-center text-sm uppercase text-gray-500">
                                                                                <div className="flex flex-col text-gray-500">
                                                                                    <div className="flex justify-center font-semibold">Route</div>
                                                                                    <div className="text-sm">
                                                                                        {clientRoute.data.start_off_station_name} - {clientRoute.data.destination_station_name}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col text-gray-500">
                                                                                    <div className="flex justify-center font-semibold">Date</div>
                                                                                    <div className="text-sm">
                                                                                        {formatDisplayDate(date)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col text-gray-500">
                                                                                    <div className="flex justify-center font-semibold">Time</div>
                                                                                    <div className="text-sm">
                                                                                        {time_label}
                                                                                    </div>
                                                                                </div>
                                                                            </h1> 
                                                                        </div>
                                                                {
                                                                    (availableSeats.hasOwnProperty("success") == true)?
                                                                        <div className="flex justify-around flex-wrap pl-2 pr-2">
                                                                            { (availableSeats.data.length > 0)?availableSeats.data.map(data => (
                                                                                    <div htmlFor="seat_number" key={data.id} className='flex justify-center bg-gray-100 w-14 mt-2 rounded-md border border-gray-500'>
                                                                                        <input 
                                                                                            className="text-green-400"
                                                                                            type="radio" 
                                                                                            name="seat_number"
                                                                                            id="seat_number"
                                                                                            value={data.id}
                                                                                            onChange={handleSeatChange}
                                                                                    />
                                                                                        <p className='text-gray-900'>{data.number}</p>
                                                                                    </div>
                                                                                    
                                                                                ))
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    :
                                                                        <div className="flex justify-center pl-2 pr-2">
                                                                            {/* { 
                                                                                (availableSeats.success == false)?
                                                                                    <h1 className="text-sm text-red-500">{seatingSort.error}</h1>
                                                                                : null
                                                                            } */}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div class="form-group m-1">
                                                                    <div className="md:pr-52 md:pl-52">
                                                                        <label for="access_code" class="flex justify-center block text-sm font-medium text-gray-700">Access Code</label>
                                                                        <input 
                                                                            class="block
                                                                            w-full
                                                                            px-3
                                                                            py-1.5
                                                                            text-base
                                                                            font-normal
                                                                            text-gray-700
                                                                            bg-white bg-clip-padding
                                                                            border border-solid border-gray-300
                                                                            rounded
                                                                            transition
                                                                            ease-in-out
                                                                            m-0"
                                                                            type="password"
                                                                            name="access_code"
                                                                            id="access_code"
                                                                            placeholder="Enter Access Code"
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div> 
                                                                <div className="flex justify-center p-3 space-x-4">
                                                                    <button class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm text-white font-medium rounded-md bg-blue-400 hover:bg-blue-500">Submit</button>
                                                                </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    // :isLoadingClientRoute
                                    //     <LoadingSpinner />
                                    // :isErrorClientRoute
                                    //     <div>Client Route Error</div>
                                    :null
                                :isLoadingAvailableSeats?
                                    <FrontEndLoadingSpinner />
                                : isErrorAvailableSeats?
                                    <div className="md:flex flex-col p-4">
                                        <p className="text-blue-500 italic border-b border-black flex flex-1">Routes not active for operator</p>
                                        <div className='flex justify-center p-8'>
                                            <h1 className='bg-yellow-400 hover:bg-yellow-500 p-6 text-sm font-semibold rounded-md text-white cursor-pointer uppercase'>{client.data.name} {client.data.business_type} not yet open for sales. Opening soon...</h1>
                                        </div>
                                        <Link to={`/`} className='flex justify-center p-8'>
                                            <h1 className='bg-green-500 hover:bg-green-600 p-6 text-sm font-semibold rounded-md text-white cursor-pointer uppercase'>Browse more operators</h1>
                                        </Link>
                                    </div>
                                :null
                            :isLoading?
                                <FrontEndLoadingSpinner />
                            : isError?
                                <div className="">No open for sales</div>
                            : <div>lkjd</div>

                        }
                        </div>
    )
}

export default ReserveSeatView;
