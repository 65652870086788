import { apiSlice } from "../app/api/apiSlice";

export const clientTicketExtentionSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClientTicketExtentions: builder.query({
            query: (id) => `/user/client-ticket-extentions/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientTicketExtentions']
        }),
        createClientTicketExtention: builder.mutation({
            query: (input) => ({
                url: `user/create-ticket-extention`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['ClientTicketExtentions']
        }),
        editClientTicketExtention: builder.mutation({
            query: (input) => ({
                url: `user/edit-client-ticket-extention/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['ClientTicketExtentions']
        }),
        getClientTicketExtentionById: builder.query({
            query: (id) => `/user/ticket-extention/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['ClientTicketExtentions']
        }),
        deleteClientTicketExtention: builder.mutation({
            query: (id) => ({
                url: `user/delete-extention/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ClientTicketExtentions']
        }),
    })
})

export const {
    useGetClientTicketExtentionByIdQuery,
    useGetClientTicketExtentionsQuery,
    useEditClientTicketExtentionMutation,
    useDeleteClientTicketExtentionMutation,
    useCreateClientTicketExtentionMutation,
} = clientTicketExtentionSlice

