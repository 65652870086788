import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import Select from 'react-select';
import { useGetClientCustomerMotiveDataByPurposeIdsMutation, useGetClientCustomerMotiveDataPhoneNumbersByPurposesIdsMutation, useGetClientCustomerTravelRelashionshipMotivesMutation, useGetClientCustomerTravelRelashionshipRegionsQuery, useGetClientCustomerTravelRelashionshipStatisticsReportQuery, useGetClientCustomerTravelRelationshipDataByRegionAndPurposeIdsMutation, useGetClientCustomerTravelRelationshipDataPhoneNumbersByRegionPurposesIdsMutation } from '../../Features/dataAnalysisReportingSlice';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Pie } from 'react-chartjs-2';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useGetRegionsForOptimizationQuery } from '../../Features/regionSlice';
import { PaperAirplaneIcon } from '@heroicons/react/outline';
import { useSendSMSMessageMutation } from '../../Features/smsSlice';
import ClientSalesHeaderView from './ClientSalesHeaderView';

Chart.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function ClientSalesClientCustomerTravelRelashionshipStatisticsView(props) {
  const navigate = useNavigate();
  const  user  = useSelector(selectCurrentUser);
  
  const client_id = user.client_id;

  const [totalMarket, setTotalMarket] = useState(0);
  const [Region, setRegion] = useState([]);
  const [motiveData, setMotivedata] = useState(false);
  const [statisticsData, setStatisticsData] = useState(false);
  const [proccessedData, setProccessedData] = useState(false);
  const [proccessedDataTotal, setProccessedDataTotal] = useState(0);
  const [motivesArray, setMotives] = useState([]);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const [input, setInput] = useState({
    from: 0,
    to: 0,
    limit: '',
    message: '',
});

const [errors, setErrors] = useState({
    from: '',
    to: '',
    limit: '',
    message: '',
})

    const {
      data: client,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetClientByIdQuery(client_id)

    const {
      data: salesStatistics,
      isSuccess: isSuccessSalesStatistics,
      isLoading: isLoadingSalesStatistics,
      isError: isErrorSalesStatistics,
      error: errorSalesStatistics,
      refetch
  } = useGetClientCustomerTravelRelashionshipStatisticsReportQuery({client_id: client_id, from: startDate, to: endDate});

  const {
    data: optimizationRegions,
    isLoading: isLoadingOptimizationRegions,
    isSuccess: isSuccessOptimizationRegions,
    isError: isErrorOptimizationRegions,
    error: errorOptimizationRegions,
    refetch: refetchRegions
  } = useGetClientCustomerTravelRelashionshipRegionsQuery({client_id: client_id, from: startDate, to: endDate})

  useEffect(()=>{
    if(isSuccessSalesStatistics){
      if(salesStatistics.data.length > 0){
        let total = 0
          salesStatistics.data.map(data => (
          total = total += data.total
        ))
        setTotalMarket(total)
        setMotives([])
        setProccessedData(false)
        setProccessedDataTotal(0)
        setMotivedata(false)

        setErrors({...errors, 
          limit: '',
          message: '',
        }) 
      }
    }
    return () =>{
    }
  },[salesStatistics]);

  const [getClientCustomerTravelRelationshipDataByRegionAndPurposeIds, {isLoading: isLoadingMotiveStatisticsByPurposeIds, isSuccess: isSuccessMotiveStatisticsByPurposeIds}] = useGetClientCustomerTravelRelationshipDataByRegionAndPurposeIdsMutation()

  const handleProcess = async(e)=>{
    e.preventDefault();
    const data = {
      data_array: motivesArray,
      region_id: Region.value,
      from: input.from,
      to: input.to,
    }
    try {
        const resp = await getClientCustomerTravelRelationshipDataByRegionAndPurposeIds(data).unwrap()
        if(resp.data.length > 0){
          setProccessedData(resp.data);
          let total = 0
          resp.data.map(data => (
            total = total += data.total
          ))
          setProccessedDataTotal(total)
        }

        setErrors({...errors, 
          data_array: '',
        })
    } catch (err) {
      setErrors({...errors, 
        data_array: err.data.errors.data_array,
      })
    }          
}

  const [getClientCustomerTravelRelationshipDataPhoneNumbersByRegionPurposesIds, {isLoading: isLoadingPhoneNumbers, isSuccess: isSuccessPhoneNumber}] = useGetClientCustomerTravelRelationshipDataPhoneNumbersByRegionPurposesIdsMutation()

  const handleSend = async(e)=>{
    e.preventDefault();
    const data = {
      from: input.from,
      to: input.to,
      data_array: motivesArray,
      region_id: Region.value,
      limit: input.limit,
      message: input.message
    }
    try {
        const resp = await getClientCustomerTravelRelationshipDataPhoneNumbersByRegionPurposesIds(data).unwrap()
        handleDeliverTicket(resp.data)
        setMotivedata(false)
        swal("Success",resp.message,"success");
        setProccessedData(false);
        setInput({...input, 
          limit: '',
          message: '',
        }) 
        setErrors({...errors, 
          limit: '',
          message: '',
        }) 
      } catch (err) {
      setErrors({...errors, 
        limit: err.data.errors.limit,
        message: err.data.errors.message,
      })
    }          
  }

const [sendSMSMessage] = useSendSMSMessageMutation();

const msgRef = (length) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const handleDeliverTicket = async(phoneNumberObj)=>{
  const data = new URLSearchParams();

  // return console.log(data)
  data.append("username", "INCEL");
  data.append("password", "1Nc3L01");
  data.append("source", "Swift ticket delivery");
  data.append("senderid", "SwiftTKT");
  data.append("msg_ref", msgRef(32));
  data.append("message", input.message);
  phoneNumberObj.map(singData =>
    data.append("recipient[]", singData.phone)
  )
  try{
      const response = await sendSMSMessage(data);
           return response

  }catch(error){
      return error
  }
}

const [getClientCustomerTravelRelashionshipMotives,{isLoading: isLoadingFetchMotive, isSuccess:isSuccessFetchMotive}] = useGetClientCustomerTravelRelashionshipMotivesMutation()

const handleRegionChange = async(e)=>{
  try {
         const resp = await getClientCustomerTravelRelashionshipMotives({client_id: client_id, region_id: e.value, from: startDate, to: endDate}).unwrap()
         if(resp.success){
           resp.data.map(data=>(
              (data.motives)?
                setMotivedata(data.motives)
              :null
          ))
          resp.data.map(data=>(
              (data.statistics)?
                setStatisticsData(data.statistics)
              :null
          ))

          let total = 0
          resp.data.map(data=>(
            (data.statistics)?
              data.statistics.map(data => (
                total += data.total
              ))
              :null
            ))
            setTotalMarket(total)
      }
     } catch (err) {
         if(err.data.message == true){
             swal("Error",err.data.message,"error");
         }
     } 
}

const handleChange = (e)=>{
  setInput({...input, [e.target.name]: e.target.value})
}

const handleChangeTimeFrame = async(e)=>{
  e.preventDefault();
  if(input.to !== '' || input.from !== ''){
    if(input.to !== 0 && input.from !== 0){
      setStartDate(input.from)
      setEndDate(input.to)
      refetch()
      return refetchRegions()
    }
  }
  swal("",'Specify timeframe',"error");
}

const handleResetTimeFrame = async(e)=>{
  e.preventDefault();
  const from = input.from = 0;
  const to = input.to = 0;
  setStartDate(from)
  setEndDate(to)
  refetch()
  return refetchRegions()
}

    return (
      <div className="bg-gray-100 mb-10">
          <ClientSalesHeaderView />
          {
            (isSuccessSalesStatistics) ?
              (isSuccessOptimizationRegions)?
              <div>
              <div className="mb-2" >
                {
                  (isSuccess)?
                  <div className="flex items-center justify-center bg-gray-50 p-0">
                    <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} customer travel relationship Statistics</h1>
                  </div>
                  : null
                }
                </div>
              <div className='flex items-center justify-end mr-3 md:pr-10'>
              <div>
                <div class="col-span-6 sm:col-span-3 mb-1">
                <div class="grid grid-cols-2 gap-4 border-t border-r border-l border-gray-300">
                      <div className='col-span-6 sm:col-span-3 mb-2'>
                        <div className='flex justify-center text-xs'>
                          Create Time Frame
                        </div>
                      </div>
                    </div>
                    <div class="grid grid-cols-2 gap-2">
                      <div class="form-group">
                          <label for="from" class="block text-xs font-medium text-gray-700">From</label>
                          <input type="date" class="form-control block
                            w-full
                            px-3
                            py-0
                            text-xs
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-green-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                            placeholder="Select travel date"
                            name="from" 
                            id="from" 
                            value={input.from}
                            onChange={handleChange}
                            />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.from}</p>
                      </div>
                      <div class="form-group">
                          <label for="to" class="block text-xs font-medium text-gray-700">To</label>
                          <input type="date" class="form-control block
                            w-full
                            px-3
                            py-0
                            text-xs
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-green-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                            placeholder="Select travel date"
                            name="to" 
                            id="to" 
                            value={input.to}
                            onChange={handleChange}
                            />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.to}</p>
                      </div>
                    </div>
                </div>
                {
                    (input.to !=0 && input.from != 0)?
                      <div className="flex justify-between items-center text-right space space-x-1">
                        <button onClick={handleResetTimeFrame} class="w-1/3 inline-flex justify-center p-1 border border-transparent shadow-xs text-xs font-medium rounded-sm text-gray-700 bg-gray-300 hover:bg-gray-300">Reset</button>
                        <button onClick={handleChangeTimeFrame} class="w-3/4 inline-flex justify-center p-1 border border-transparent shadow-xs text-xs font-medium rounded-sm text-gray-50 bg-teal-400 hover:bg-teal-500">Generate</button>
                      </div>
                    :
                      <div class="flex justify-center items-center text-right">
                        <button onClick={handleChangeTimeFrame} class="w-full inline-flex justify-center p-1 border border-transparent shadow-xs text-xs font-medium rounded-sm text-gray-50 bg-teal-400 hover:bg-teal-500">Generate</button>
                      </div>
                  }
              </div>
            </div>
              <div class="flex flex-between rounded-sm bg-gray-50 justify-center items-center flex-wrap mt-1 sm:mt-0  md:pt-1 md:mr-2 md:ml-2  lg:mr-10 lg:ml-10">
                {
                    (salesStatistics.data.length > 0) ?
                      salesStatistics.data.map(data=>(
              <div class="grid grid-cols-1 bg-gray-50 border border-gray-200 rounded-md">
                        <Pie
                          height={250}
                          data={{
                            labels: data.purposes.map(data=>data.purpose+': '+data.total),
                            datasets: [
                              {
                                label: 'Total revenue',
                                data: data.purposes.map(data=>data.total),
                                fill: "start",
                                backgroundColor: [
                                  'rgba(53, 162, 235, 0.5)',
                                  'rgba(255, 206, 86, 0.5)',
                                  'rgba(75, 192, 192, 0.5)',
                                  'rgba(255, 159, 64, 0.5)',
                                  'rgba(255, 132, 235, 0.5)',
                                  'rgba(153, 102, 255, 0.5)',
                                  'rgba(255, 99, 132, 0.5)',
                                  'rgba(54, 162, 235, 0.5)',
                                  'rgba(255, 206, 86, 0.5)',
                                  'rgba(75, 192, 192, 0.5)',
                                ],
                                borderColor: [
                                   'rgba(53, 162, 235, 0.6)',
                                  'rgba(255, 206, 86, 0.6)',
                                  'rgba(75, 192, 192, 0.6)',
                                  'rgba(255, 159, 64, 0.6)',
                                  'rgba(255, 132, 235, 0.6)',
                                  'rgba(153, 102, 255, 0.6)',
                                  'rgba(255, 99, 132, 0.6)',
                                  'rgba(54, 162, 235, 0.6)',
                                  'rgba(255, 206, 86, 0.6)',
                                  'rgba(75, 192, 192, 0.60)',
                                ],
                                borderWidth: 0,
                              },
                            ]
                          }}
                          options={{
                            bezierCurve: true,
                            plugins: {
                              legend: {
                                position: 'right',
                              },
                              title: {
                                display: true,
                                text: data.region,
                              },
                              tooltip: {
                                enabled: false
                              },
                              datalabels: {
                                align: 'center',
                                color: 'black',
                                labels: {
                                  value: {
                                    font: {
                                      weight: ''
                                    }
                                  }
                                },
                                formatter: (value, context)=>{
                                  const datapoint = context.chart.data.datasets[0].data;
                                  function totalSum(total, datapoint){
                                    return total + datapoint
                                  }
                                  const totalValue = datapoint.reduce(totalSum, 0);
                                  const percentageValue = (value / totalValue * 100).toFixed(1);
                                  const display = [`${percentageValue}%`]
                                  return display;
                                }
                              }
                            },
                            elements: {
                              line: {
                                tension: 0.3
                              }
                            },
                            maintainAspectRatio: false,
                          }}
                          plugins={[ChartDataLabels]}
                        />
              </div>
                      ))
                      : null
                }
            </div>
            <div class="mt-6 sm:mt-0 md:pt-4">
                <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                  <div class="mt-5 md:mt-0 md:col-span-2">
                    <div className='flex justify-center p-2 font-semibold text-gray-700 mb-6'>
                      <h1 className='text-md'>Optimization Tools</h1>
                    </div>
                  <form onSubmit={handleProcess} autoComplete="off">
                    <div class="grid grid-cols-2 gap-4 border-t border-r border-l border-gray-300">
                      <div className='col-span-6 sm:col-span-3 mb-4'>
                        <div className='flex justify-center '>
                            Time Frame
                        </div>
                      </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                      <div class="form-group mb-2">
                          <label for="from" class="block text-sm font-medium text-gray-700">From</label>
                          <input type="date" class="form-control block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-300
                            rounded-b
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                            placeholder="Select travel date"
                            name="from" 
                            id="from" 
                            value={input.from}
                            disabled={true}
                            />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.from}</p>
                      </div>
                      <div class="form-group mb-2">
                          <label for="to" class="block text-sm font-medium text-gray-700">To</label>
                          <input type="date" class="form-control block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-300
                            rounded-b
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                            placeholder="Select travel date"
                            name="to" 
                            id="to" 
                            value={input.to}
                            disabled={true}
                            />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.to}</p>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                        <div class="form-group mb-4">
                            <label for="region" class="block text-sm font-medium text-gray-700">Region</label>
                            <Select 
                                class="p-1"
                                placeholder='Search region...'
                                options={optimizationRegions.data}
                                isSearchable
                                onChange={(e)=>{
                                  setRegion(e);
                                  handleRegionChange(e);
                              }}
                            />
                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.regions_array}</p>
                        </div>
                      </div>
                    </div>
                  </form>
                  {
                    (isSuccessFetchMotive)?
                      (motiveData)?
                          <div class="mt-6 sm:mt-0 md:pt-4">
                              <div class="mt-5 md:mt-0 md:col-span-2">
                                <div className='flex justify-center items-center space-x-4 p-2 bg-blue-200 border-b border-gray-400 rounded-t'>
                                  <div className='flex flex-col space-y-2 text-gray-900'>
                                    <div className='text-xs font-bold'>Total Market</div>
                                    <div className='text-xs flex justify-center'>{totalMarket}</div>
                                  </div>
                                </div>
                                <div className='flex justify-center items-center space-x-4 p-2 bg-blue-100 mb-3'>
                                  {
                                    (statisticsData.length > 0) ?
                                    statisticsData.map(data => (
                                        <div key={data.name} className='flex flex-col space-y-2 border-b border-gray-300'>
                                          <div className='text-xs font-bold'>{data.purpose}</div>
                                          <div className='text-xs flex justify-center'>{data.total}</div>
                                        </div>
                                      ))
                                    :null
                                  }
                                </div>
                              <form autoComplete="off">
                                <div class="col-span-6 sm:col-span-3">
                                  <div class="form-group mb-4">
                                      <label for="motives" class="block text-sm font-medium text-gray-700">Motives</label>
                                      <Select 
                                          class="p-1"
                                          placeholder='Search motive...'
                                          options={motiveData}
                                          isSearchable
                                          isMulti
                                          onChange={setMotives}
                                      />
                                      <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.data_array}</p>
                                  </div>
                                </div>
                                <div class="flex justify-center items-center text-right mb-4">
                                  {
                                    (isLoadingMotiveStatisticsByPurposeIds)?
                                      <div class="w-full inline-flex cursor-pointer justify-center items-center space-x-1 p-2 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-gray-200 hover:bg-gray-300">
                                        <div className='text-xs text-gray-700'>Please wait...</div>
                                      </div>
                                    :
                                      <div onClick={handleProcess} class="w-full inline-flex cursor-pointer justify-center items-center space-x-1 p-2 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-gray-200 hover:bg-gray-300">
                                        <div className='text-xs text-gray-700'>Generate</div>
                                      </div>
                                  }
                                </div>
                                  {
                                    (isSuccessMotiveStatisticsByPurposeIds)?
                                      (proccessedData)?
                                        <div>  
                                          <div className='flex justify-center items-center space-x-4 p-2 bg-green-200 border-b border-gray-400 rounded-t'>
                                              <div className='flex flex-col space-y-2 text-gray-900'>
                                                <div className='text-xs font-bold'>Total Audience</div>
                                                <div className='text-xs flex justify-center'>{proccessedDataTotal}</div>
                                              </div>
                                            </div>
                                            <div className='flex justify-center items-center space-x-4 p-2 bg-green-100 mb-3'>
                                              {
                                                (proccessedData.length > 0) ?
                                                proccessedData.map(data => (
                                                    <div key={data.name} className='flex flex-col space-y-2 border-b border-gray-300'>
                                                      <div className='text-xs font-bold'>{data.purpose}</div>
                                                      <div className='text-xs flex justify-center'>{data.total}</div>
                                                    </div>
                                                  ))
                                                :null
                                              }
                                          </div>                           
                                          <div class="grid grid-cols-2 gap-4">
                                            <div class="col-span-6 sm:col-span-3 mb-2">
                                                <label for="limit" class="block text-sm font-medium text-gray-700">Limit</label>
                                                <input 
                                                    class="mt-1 p-2 text-sm outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    type="text"
                                                    placeholder='Limit reach...'
                                                    name="limit" 
                                                    id="limit" 
                                                    value={input.limit}
                                                    onChange={handleChange}
                                                />
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.limit}</p>
                                            </div>
                                            <div class="col-span-6 sm:col-span-3 mb-2">
                                                <label for="message" class="block text-sm font-medium text-gray-700">Message</label>
                                                <textarea 
                                                    class="mt-1 p-2 h-20 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    type="text"
                                                    placeholder='Message in 160 characters...'
                                                    name="message" 
                                                    id="message" 
                                                    value={input.message}
                                                    maxLength="160"
                                                    onChange={handleChange}
                                                />
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.message}</p>
                                            </div>
                                          </div>
                                          <div class="flex justify-center items-center text-right">
                                              {
                                                (isLoadingPhoneNumbers)?
                                                  <div class="w-full cursor-pointer inline-flex justify-center items-center space-x-1 p-2 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-green-400 hover:bg-green-500">
                                                    <div className=''>Please wait...</div>
                                                  </div>
                                                :
                                                  <div onClick={handleSend} class="w-full cursor-pointer inline-flex justify-center items-center space-x-1 p-2 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-green-400 hover:bg-green-500">
                                                    <div className=''>Send</div>
                                                    <PaperAirplaneIcon className="w-4 h-4 ml-3 pointer-events-none" />
                                                  </div>
                                              }
                                            </div>
                                        </div>
                                      :null
                                    :isLoadingMotiveStatisticsByPurposeIds?
                                      null
                                    :null
                                  }
                              </form>
                              </div>
                        </div>
                      :null
                    :(isLoadingFetchMotive)?
                      <div className='flex items-center justify-center'>
                        Please wait...
                      </div>
                    :null
                  }
                  </div>
              </div>
            </div>

            </div>
              :isLoadingOptimizationRegions?
                <LoadingSpinner />
              :null
              
              : isLoadingSalesStatistics ?
              <LoadingSpinner />
              : isErrorSalesStatistics ?
              <div>
                  <div className="mb-10" >
                    {
                      (isSuccess) ?
                        <div className="flex items-center justify-center bg-gray-50 p-0">
                          <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} regional market share Statistics</h1>
                        </div>
                        : null
                    }
                  </div>
                  <div className='flex items-center justify-end mr-3 md:pr-32'>
                    <div>
                      <div class="col-span-6 sm:col-span-3 mb-1">
                        <div class="grid grid-cols-2 gap-4 border-t border-r border-l border-gray-300">
                          <div className='col-span-6 sm:col-span-3 mb-2'>
                            <div className='flex justify-center text-xs'>
                              Create Timeframe
                            </div>
                          </div>
                        </div>
                        <div class="grid grid-cols-2 gap-2">
                          <div class="form-group">
                            <label for="from" class="block text-xs font-medium text-gray-700">From</label>
                            <input type="date" class="form-control block
                            w-full
                            px-3
                            py-0
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-green-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                              placeholder="Select travel date"
                              name="from"
                              id="from"
                              value={input.from}
                              onChange={handleChange}
                            />
                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.from}</p>
                          </div>
                          <div class="form-group">
                            <label for="to" class="block text-xs font-medium text-gray-700">To</label>
                            <input type="date" class="form-control block
                            w-full
                            px-3
                            py-0
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-green-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                              placeholder="Select travel date"
                              name="to"
                              id="to"
                              value={input.to}
                              onChange={handleChange}
                            />
                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.to}</p>
                          </div>
                        </div>
                      </div>
                      {
                        (input.to != 0 && input.from != 0) ?
                          <div className="flex justify-between items-center text-right space space-x-1">
                            <button onClick={handleResetTimeFrame} class="w-1/3 inline-flex justify-center p-1 border border-transparent shadow-xs text-xs font-medium rounded-sm text-gray-700 bg-gray-300 hover:bg-gray-300">Reset</button>
                            <button onClick={handleChangeTimeFrame} class="w-3/4 inline-flex justify-center p-1 border border-transparent shadow-xs text-xs font-medium rounded-sm text-gray-50 bg-teal-400 hover:bg-teal-500">Generate</button>
                          </div>
                          :
                          <div class="flex justify-center items-center text-right">
                            <button onClick={handleChangeTimeFrame} class="w-full inline-flex justify-center p-1 border border-transparent shadow-xs text-xs font-medium rounded-sm text-gray-50 bg-teal-400 hover:bg-teal-500">Generate</button>
                          </div>
                      }
                    </div>
                  </div>
                  <div class="mt-1 sm:mt-0  md:pt-1 md:mr-32 md:ml-32  lg:mr-32 lg:ml-32">
                    <div class="grid grid-cols-2 bg-white">
                      {
                        <Pie
                          height={375}
                          data={{
                            labels: [],
                            datasets: [
                              {
                                label: 'Total revenue',
                                data: [],
                                fill: "start",
                                backgroundColor: [
                                  'rgba(53, 162, 235, 0.6)',
                                  'rgba(255, 206, 86, 0.6)',
                                  'rgba(75, 192, 192, 0.6)',
                                  'rgba(255, 159, 64, 0.6)',
                                  'rgba(255, 132, 235, 0.6)',
                                  'rgba(153, 102, 255, 0.6)',
                                  'rgba(255, 99, 132, 0.6)',
                                  'rgba(54, 162, 235, 0.6)',
                                  'rgba(255, 206, 86, 0.9)',
                                  'rgba(75, 192, 192, 0.8)',
                                ],
                                borderColor: [
                                  'rgba(53, 162, 235, 0.2)',
                                  'rgba(255, 206, 86, 0.2)',
                                  'rgba(75, 192, 192, 0.2)',
                                  'rgba(255, 159, 64, 0.2)',
                                  'rgba(255, 132, 235, 0.2)',
                                  'rgba(153, 102, 255, 0.2)',
                                  'rgba(255, 99, 132, 0.2)',
                                  'rgba(54, 162, 235, 0.2)',
                                  'rgba(255, 206, 86, 0.2)',
                                  'rgba(75, 192, 192, 0.2)',
                                ],
                                borderWidth: 3,
                              },
                            ]
                          }}
                          options={{
                            bezierCurve: true,
                            plugins: {
                              legend: {
                                position: 'top',
                              },
                              title: {
                                display: true,
                                text: 'Data not available',
                              },
                              tooltip: {
                                enabled: false
                              },
                              datalabels: {
                                align: 'center',
                                color: 'black',
                                labels: {
                                  value: {
                                    font: {
                                      weight: 'bold'
                                    }
                                  }
                                },
                              }
                            },
                            elements: {
                              line: {
                                tension: 0.3
                              }
                            },
                            maintainAspectRatio: false,
                          }}
                          plugins={[ChartDataLabels]}
                        />
                      }
                    </div>
                  </div>
                </div>
                : null
          }
      </div>
    )
}

export default ClientSalesClientCustomerTravelRelashionshipStatisticsView;
