import { useState} from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import HomeHeader from '../HeaderLayouts/HomeHeader'
import LoadingSpinner from './LoadingSpinner';
import { useLoginMutation } from '../Features/auth/authApiSlice'
import { setCredentials } from '../Features/auth/authSlice';
import { LockOpenIcon, LoginIcon } from '@heroicons/react/outline';

function UserRegisterView(props) {  
    const navigate = useNavigate(); 
    const dispatch = useDispatch()

    const [input, setInput] = useState({
        username: '',
        password: '',
        errorMassage: '',
        errors:[{username:'',password:''}]
    })
    const [errors, setErrors] = useState({
        username: '',
        password: ''
    })

    const [login, {isLoading}] = useLoginMutation();

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try {
            const userData = await login(input).unwrap()
            const token = userData.token
            const user = userData.user
            const roles = userData.roles
            const client_role = userData.user_client_role
            if(user.is_manager == 1){
                dispatch(setCredentials({user, token, roles, client_role}))
                return navigate("/manager");
            }
            if(user.is_client_data_analyst_manager == 1){
                dispatch(setCredentials({user, token, roles, client_role}))
                return navigate("/data-analysis");
            }
            if(user.is_client_accountant == 1){
                dispatch(setCredentials({user, token, roles, client_role}))
                return navigate("/accounting");
            }
            if(user.is_client_promotor == 1){
                dispatch(setCredentials({user, token, roles, client_role}))
                return navigate("/sales");
            }
            if(client_role == 1 && user.is_client == 1){
                dispatch(setCredentials({user, token, roles, client_role}))
                return navigate("/client");
            }else{
                dispatch(setCredentials({user, token, roles, client_role: null}))
                    return navigate("/user/users");
            }
        } catch (err) {
            if(err){
                if(err.data.hasOwnProperty('errors') == true){
                    setErrors({...errors, password: err.data.errors.password, username: err.data.errors.username})
                }else if (err.data.error === true){
                    setInput({...input, errorMassage: err.data.error})
                }
            }
        }          
    }

    return (
            <div className="bg-gray-100 "> 
                <HomeHeader />
                <div class="flex h-screen justify-center items-center">
                    <div class="md:w-1/3 rounded-md">
                        <div class="shadow sm:rounded-md">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class="sm:rounded-md">
                                <div class="px-2 py-6 bg-gray-50 rounded-md sm:p-6">
                                    <div class="grid grid-cols-2 gap-4">
                                        <div class="col-span-6">
                                            <div className='flex justify-center'>
                                                <h1 className='uppercase text-teal-800 font-semibold text-sm'>Login into your account</h1>
                                            </div>
                                        </div>
                                        <div className='col-span-4'>
                                            <div className='flex justify-center'>
                                                <LockOpenIcon className='h-16 text-teal-700 rounded-full' />
                                            </div>
                                        </div>
                                        <div class="col-span-6">
                                        {
                                            (input.errorMassage != '')
                                            ?<div className='flex justify-center'>
                                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{input.errorMassage}</p>
                                            </div>
                                                    : ''
                                        }
                                        </div>
                                        <div class="col-span-6 sm:col-span-6">
                                            <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
                                            <input 
                                                class="mt-1 p-2 outline-none bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                name="username" 
                                                id="username" 
                                                value={input.username}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.username}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-6">
                                            <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                                            <input 
                                                class="mt-1 p-2 bg-gray-100 outline-none focus:ring-gray-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="password"
                                                name="password" 
                                                id="password" 
                                                value={input.password}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.password}</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (isLoading)?
                                        <div class=" flex justify-center items-center px-4 py-1 text-right sm:px-6 bg-teal-700 hover:bg-teal-800 cursor-pointer">
                                            <div type="submit" class="inline-flex space-x-1 w-full justify-center py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white">
                                                <div>Please wait...</div>
                                            </div>
                                        </div>
                                    :
                                        <div class=" flex justify-center items-center px-4 py-1 text-right sm:px-6 bg-teal-700 hover:bg-teal-800 cursor-pointer">
                                            <button type="submit" class="inline-flex space-x-1 w-full justify-center py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white">
                                                <div>Login</div>
                                                <LoginIcon className='h-6 text-white'/>
                                            </button>
                                        </div>
                                }
                                <div class="flex justify-center px-4 py-2 bg-gray-200 text-right sm:px-2">
                                    <Link to="/user/register">
                                        <div className='cursor-pointer text-sm text-blue-500 hover:text-blue-600'>Don't have an account? Registe here</div>
                                    </Link>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
            </div>
        )
}

export default UserRegisterView;
