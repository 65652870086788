import { apiSlice } from "../app/api/apiSlice";

export const StationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStationsOrSearch: builder.query({
            query: ({paginate, search, page}) => (page == "")? `/user/stations/${search}/${paginate}` : `user/stations/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            // providesTags: ['Stations']
        }),
        getAllForOperatorsStations: builder.query({
            query: () => `/user/operators-stations`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Stations']
        }),
        getAllStations: builder.query({
            query: () => `/user/stations`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Stations']
        }),
        createStation: builder.mutation({
            query: (input) => ({
                url: `user/create-station`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Stations']
        }),
        editStation: builder.mutation({
            query: (input) => ({
                url: `user/edit-station/${input.id}`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['Stations']
        }),
        getStationById: builder.query({
            query: (id) => `/user/station/${id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Stations']
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `user/delete-user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User']
        }),
    })
})

export const {
    useCreateStationMutation,
    useGetStationsOrSearchQuery,
    useGetStationByIdQuery,
    useGetAllForOperatorsStationsQuery,
    useDeleteUserMutation,
    useEditStationMutation,
    useGetAllStationsQuery,
} = StationsApiSlice

