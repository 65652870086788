import { useNavigate, useParams } from "react-router-dom";
import Home from "../../HeaderLayouts/Home";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import "../slideStyles.css";


// import Swiper core and required modules
import SwiperCore, {
Autoplay,Pagination,Navigation
} from 'swiper/core';
import 'react-phone-number-input/style.css'

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

function CheckoutTicketInfoView(props) {  
    const navigate = useNavigate();
    const {logo_url,name,phone,charge,route,date,time,seat_number,operator,ticket_number} = useParams();

    const formatDisplayDate = (date)=>{
        let tempDate = new Date(date);
        let month = tempDate.toLocaleString('default',{month: 'short'});
        return month + "-" + tempDate.getDate() + "-" + tempDate.getFullYear();
    }

    const home = ()=>navigate("/")

    return (
            <div className='bg-white'>
                <div className="sticky top-0 z-50">
                    <Home />
                </div>
                    <div className="bg-white">
                        <div className="flex flex-col bg-white">
                            <Swiper 
                                centeredSlides={true} 
                                className="mySwiper">
                                    <SwiperSlide className="object-cover">
                                    <img 
                                        className=""
                                        src={`http://localhost:8000/client_logo_image/${logo_url}`} 
                                        alt="Image" 
                                    />
                                    </SwiperSlide>
                            </Swiper>
                        </div>
                    <div class="mt-10 sm:mt-0 md:pt-4">
                        <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                            <div class="mt-5 md:mt-0 md:col-span-2">
                                <div>
                                    <div className="flex flex-col bg-green-300 mb-4 p-4 rounded-t text-sm">
                                        <div className="flex justify-center items-center">
                                            <h1 className="text-xs uppercase text-gray-700">
                                                Checkout successful ticket details
                                            </h1>
                                        </div>
                                    </div>                                        
                                <div class="form-group mb-1">
                                    <div className="flex flex-col ">
                                        <div className="flex flex-col">
                                            <div className="mt-1 mb-2 space-y-2">
                                            <div className="flex justify-between items-center">
                                                    <p className="font-bold text-xs pl-4">Operator</p>
                                                    <p className="pr-4 text-xs">{operator}</p>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <p className="font-bold text-xs pl-4">Customer Name</p>
                                                    <p className="pr-4 text-xs">{name}</p>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <p className="font-bold text-xs pl-4">Ticket Number</p>
                                                    <p className="pr-4 text-xs">{ticket_number}</p>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <p className="font-bold text-xs pl-4">Phone Number</p>
                                                    <p className="pr-4 text-xs">{phone}</p>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <p className="font-bold text-xs pl-4">Seat Number</p>
                                                    <p className="pr-4 text-xs">{seat_number}</p>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <p className="font-bold text-xs pl-4">Charge</p>
                                                    <p className="pr-4 text-xs">K {(charge)}</p>
                                                </div>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <div className="flex flex-col ">
                                        <div className="flex flex-col ">
                                            </div>
                                                <h1 className="flex space-x-4 bg-gray-200 p-2 justify-center text-sm">
                                                    <div className="flex">
                                                        <div className="flex-row justify-center">
                                                            <div style={{color: '#003356'}} className="font-bold text-xs flex justify-center">Route</div>
                                                            <div style={{color: '#003356'}} className="text-xs pl-2 flex justify-center">
                                                                {route}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="flex-row justify-center">
                                                            <div style={{color: '#003356'}} className="font-bold text-xs flex justify-center">Departure</div>
                                                            <div style={{color: '#003356'}} className="text-xs pl-2 flex justify-center">
                                                            {formatDisplayDate(date)} @ {time} Hrs
                                                            </div>
                                                        </div>
                                                    </div>
                                                </h1> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={home} 
                                class="
                                        w-full
                                        px-6
                                        py-2.5
                                        text-gray-700
                                        bg-gray-200
                                        text-xs
                                        leading-tight
                                        rounded
                                        shadow-md
                                        hover:bg-gray-300 hover:shadow-lg
                                        ease-in-out"
                                    >
                                        <div className="uppercase font-bold">Ok</div>    
                                    </button>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
    )
}

export default CheckoutTicketInfoView;
