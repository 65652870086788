import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'
import swal from 'sweetalert'
import LoadingSpinner from '../LoadingSpinner';
import { useGetAllStationsQuery } from '../../Features/stationsSlice';
import Select from 'react-select';
import { useCreateRouteMutation, useEditRouteMutation, useGetRouteByIdQuery } from '../../Features/RoutesSlice';
import { useGetSystemRegionsForRoutesQuery } from '../../Features/regionSlice';

function EditRouteView() {
    const navigate = useNavigate();
    const { route_id } = useParams();

    const [start_off, setStart_off] = useState(false);
    const [destination, setDestination] = useState(false);
    const [region, setRegion] = useState({});

    const [errors, setErrors] = useState({
        start_off: '',
        destination: '',
        region: '',
    })

    const {
        data: routes,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetAllStationsQuery()

      const {
        data: regions,
        isLoading: isLoadingRegions,
        isSuccess: isSuccessRegions,
        isError: isErrorRegions,
        error: errorRegions
      } = useGetSystemRegionsForRoutesQuery()

      const {
        data: route,
        isLoading: isLoadingRoute,
        isSuccess: isSuccessRoute,
        isError: isErrorRoute,
        error: ErrorRoute
      } = useGetRouteByIdQuery(route_id)

      const [editRoute] = useEditRouteMutation();

    const handleSubmit = async(e)=>{
        e.preventDefault();
        let data = '';
        if(!start_off || !destination || !region){
            data = {
                id: route.data.id,
                start_off: route.data.start_off_station_id,
                destination: route.data.destination_station_id,
                region: route.data.region_id
            }
        } else{
            data = {
                id: route.data.id,
                start_off: start_off.value,
                destination: destination.value,
                region: region.value,
            }
        }
        try {
            const resp = await editRoute(data).unwrap()
            swal("Success",resp.message,"success");
            navigate(-1);
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors, 
                    start_off: err.data.errors.start_off, 
                    destination: err.data.errors.destination,
                    region: err.data.errors.region
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const back = ()=> navigate(-1);
        let constent = isLoading? 
            <LoadingSpinner />
            : (isLoadingRegions)?
                <LoadingSpinner />
            : (isError)?
                <div className='flex h-screen bg-white justify-center items-center'>
                    <div className="text-md">Please add stations and regions</div>
                </div>
            : (isErrorRegions)?
                <div className='flex h-screen bg-white justify-center items-center'>
                    <div className="text-md">Please add stations and regions</div>
                </div>
            :
        (
            <div className="bg-gray-100">
                <div className="flex items-center justify-center bg-white p-1 mb-10">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Routes management</h1>
                </div>
                <div class="mt-10 sm:mt-0  md:pt-4">
                    <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                        <div class="mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class="sm:rounded-md">
                                        <div class="col-span-6 p-4 shadow-md bg-gray-700 uppercase">
                                            <div className='flex justify-center'>
                                                { (isLoadingRoute)
                                                    ? <div>Loading...</div>
                                                    : (isSuccessRoute)
                                                        ? <h1 className='uppercase font-semibold text-sm text-white'>edit {route.data.start_off_station_name} - {route.data.destination_station_name} route</h1>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                <div class="px-4 py-5 bg-gray-200 sm:p-6">
                                    <div class="grid grid-cols-2 gap-4">
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="name" class="block text-sm font-medium text-gray-700">Start off</label>
                                            <Select 
                                                class=""
                                                placeholder='Search station...'
                                                options={routes}
                                                isSearchable
                                                onChange={setStart_off}
                                            />
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.start_off}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="name" class="block text-sm font-medium text-gray-700">Destination</label>
                                            <Select 
                                                class=""
                                                placeholder='Search station...'
                                                options={routes}
                                                isSearchable
                                                onChange={setDestination}
                                            />
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.destination}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="region" class="block text-sm font-medium text-gray-700">Region</label>
                                            <Select 
                                                class=""
                                                placeholder='Search region...'
                                                options={regions.data}
                                                isSearchable
                                                onChange={setRegion}
                                            />
                                            <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.region}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-center space-x-6 items-center px-4 py-4 bg-gray-300 text-right sm:px-6">
                                    <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Cancel</div>
                                    <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Save</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    return constent;
}

export default EditRouteView;
