import { ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon, SearchIcon } from '@heroicons/react/outline';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from 'rc-pagination';
import { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import swal from 'sweetalert';
import { Menu} from "@headlessui/react";
import { useGetClientRoutesOrSearchForAdminQuery, useToggleClientRouteStatusMutation } from '../../Features/clientsRoutesSlice';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';

function ManageClientRoutesView({}) {
    const navigate = useNavigate();
    const defaultPagination = 100;
    const [search, setSearch] = useState(0)
    const [pager, setPager] = useState("")
    const { client_id } = useParams();    

    const {
      data: routes,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetClientRoutesOrSearchForAdminQuery({paginate: defaultPagination, search: search, page: pager, id: client_id})

    const {
      data: client,
      isSuccess: isSuccessClient,
  } = useGetClientByIdQuery(client_id);

    const [input, setInput] = useState({
      search: ''
    })

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value})
    if(input.search != 0){
      setSearch(input.search)
    }else {

      setSearch(0)
    }
  }

  const onChange = (currentPage) => {
    setPager(currentPage)
  }

  const incremenentCharge = () =>{
    navigate("/user/increment-routes-charge/"+client_id)
  }

  const decremenentCharge = () =>{
    navigate("/user/decrement-routes-charge/"+client_id)
  }

  const [toggleClientRouteStatus] = useToggleClientRouteStatusMutation();

  const toggleClientRouteStatusHandler = async (e, routeId, clientId) =>{
    if(window.confirm("Are you sure you want to toggle route status?")){
      try {
        const resp = await toggleClientRouteStatus({route_id: routeId, client_id: clientId}).unwrap()
        swal("Success",resp.message,"success");
      } catch (err) {
        if(err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    }
  }
    
    return (
      <div className="bg-gray-100">
          <div className="flex justify-between items-center bg-white">
              <div className='flex items-center'>
                {
                  (isSuccessClient)?
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">{client.data.name} {client.data.business_type} Routes Management</h1>
                  : null
                }
              </div>
                <Menu as="div" className='relative z-10 mr-2 p-1'>
                  <Menu.Button className="flex justify-center bg-gray-700 hover:bg-gray-800 curosor-pointer w-20 rounded-md">
                    <DotsHorizontalIcon className='h-8 w-10 text-white' />
                  </Menu.Button> 
                  <Menu.Items className="absolute focus:outline-none right-0 bg-gray-100 rounded-md shadow-lg border mt-1 w-52">
                    <Menu.Item>
                        {
                          (isSuccessClient)?
                            <Link to={`/user/manage-routes-for-client-subscription/${client.data.id}`} className='flex justify-center hover:bg-gray-200 cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-gray-200'>
                                  <div>
                                    Add route
                                  </div>
                            </Link>
                          :null
                        }
                    </Menu.Item>
                    <Menu.Item>
                        {
                          (isSuccessClient)?
                            <Link to={`/user/client-seating-profile-management/${client.data.id}`} className='flex justify-center hover:bg-gray-200 cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-gray-200'>
                                  <div>
                                    Seats
                                  </div>
                            </Link>
                          :null
                        }
                    </Menu.Item>
                    <Menu.Item>
                      <div onClick={(e)=>incremenentCharge()} className='flex justify-center hover:bg-gray-200 cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-gray-200'>
                        Increase fare
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div onClick={(e)=>decremenentCharge()} className='flex justify-center hover:bg-gray-200 cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-gray-200'>
                        Decrease fare
                      </div>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
            </div>
            <div className="flex justify-end p-1 bg-gray-100 mr-2">
                <form className="">
                  <div className="relative flex items-center text-gray-400 focus-within:text-gray-600">
                    <SearchIcon className="w-4 h-4 absolute ml-3 pointer-events-none" />
                    <input
                      type="text"
                      name="search"
                      value={input.search}
                      onChange={handleChange}
                      placeholder="Search route"
                      autoComplete="off"
                      aria-label="Search user"
                      className="pr-3 pl-10 px-3 py-1 bg-gray-300 text-sm placeholder-gray-500 text-black rounded-sm border-none outline-none"
                    />
                  </div>
                </form>
            </div>
            {(isSuccess)?
                (routes.hasOwnProperty('success') === true && routes.data.data.length > 0)?
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-800 shadow-lg">
                    <tr>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Departure
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Destination
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Duration
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Charge
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Interest
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Total_charge
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Handler
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Code
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                        Status
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                    >
                    </th>
                  </tr>
                </thead>  
                <tbody className="bg-white divide-y divide-gray-200">
                        { (routes.hasOwnProperty('success') === true && routes.data.data.length > 0)?
                            routes.data.data.map(data => (
                                <tr key={data.id} className={`${(data.deleted == 1)? 'bg-gray-200 text-gray-500 hover:bg-gray-300': (data.route_duration !== '' && data.client_charge == null)? 'bg-yellow-200 hover:bg-yellow-300 text-gray-900': (data.status == 1)? 'bg-green-200 hover:bg-green-300 text-gray-900': (data.client_charge != null && data.status == 0)? 'bg-blue-200 hover:bg-blue-300 text-gray-900': 'hover:bg-gray-200 text-gray-900'}`}>
                                    <td className="px-6 py-1 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="flex items-center space-x-4 text-sm text-gray-900">
                                            <input 
                                                type="checkbox" 
                                                name="roles[]" 
                                                value={data.id}
                                                onChange={handleChange}
                                            />
                                            <label className='font-md'>{data.start_off_station_name}</label>
                                        </div>
                                    </div>
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">
                                        <div className='font-md'>{data.destination_station_name}</div>
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm">
                                      {
                                          (data.route_duration !== "")?
                                            <div className=' text-sm'>
                                                <div>
                                                    {data.route_duration} Hrs
                                                </div>
                                            </div>
                                          :
                                            <div className='text-sm'>
                                                <div className='text-sm'>
                                                    --
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm">
                                      {
                                          (data.client_charge !== null)?
                                            <div className=' text-sm'>
                                                <div className=''>
                                                    K {data.client_charge}
                                                </div>
                                            </div>
                                          :
                                            <div className='text-sm'>
                                                <div className='text-sm'>
                                                    --
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm">
                                      {
                                          (data.commission_charge !== null)?
                                            <div className=' text-sm'>
                                                <div className=''>
                                                    K {data.commission_charge}
                                                </div>
                                            </div>
                                          :
                                            <div className='text-sm'>
                                                <div className='text-sm'>
                                                    --
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm">
                                      {
                                          (data.commission_charge !== null && data.client_charge !== null)?
                                            <div className=' text-sm'>
                                                <div className=''>
                                                    K {(data.commission_charge + data.client_charge)}
                                                </div>
                                            </div>
                                          :
                                            <div className='text-sm'>
                                                <div className='text-sm'>
                                                    --
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td className="px-1 py-0 whitespace-nowrap">
                                      {
                                          (data.handler !== null)?
                                            <div className=' text-sm'>
                                                <div className=''>
                                                    {data.handler}
                                                </div>
                                            </div>
                                          :
                                            <div className='text-sm'>
                                                <div className='text-sm'>
                                                    --
                                                </div>
                                            </div>
                                        }
                                  </td>
                                  <td className="px-1 py-0 whitespace-nowrap">
                                      {
                                          (data.access_code !== null)?
                                            <div className=' text-sm'>
                                                <div className=''>
                                                    {data.access_code}
                                                </div>
                                            </div>
                                          :
                                            <div className='text-sm'>
                                                <div className='text-sm'>
                                                    --
                                                </div>
                                            </div>
                                        }
                                  </td>
                                    <td className="px-1 py-0 whitespace-nowrap">
                                      {
                                        (data.status === 1)?
                                          <div onClick={(e)=>toggleClientRouteStatusHandler(e,data.client_route_id,data.client_id)} className='flex justify-center md:w-20 w-14 sm:14 mr-4 mt-1 bg-gray-100 text-sm rounded-md hover:bg-gray-300  cursor-pointer text-green-500 hover:text-green-600'>
                                              <div className=''>
                                                  Active
                                              </div>
                                          </div>
                                        :
                                          <div onClick={(e)=>toggleClientRouteStatusHandler(e,data.client_route_id,data.client_id)} className='flex justify-center md:w-20 w-14 sm:14 mr-4 mt-1 bg-gray-100 text-sm rounded-md hover:bg-gray-300 cursor-pointer text-red-600 hover:text-red-700'>
                                              <div className=''>
                                                  Disabled
                                              </div>
                                          </div>
                                      }
                                  </td>
                                  <td className="px-1 py-0 whitespace-nowrap">
                                      <Link to={`/user/edit-client-route/${data.client_id}/${data.route_id}/${data.client_route_id}`} className='flex justify-center md:w-20 w-14 mr-4 mt-1 bg-blue-500 text-gray-200 text-sm rounded-md hover:bg-blue-600 hover:text-white cursor-pointer'>
                                          <div className=''>
                                              Edit
                                          </div>
                                      </Link>
                                  </td> 
                                </tr>
                                ))
                                : ''
                        }
                </tbody>
              </table>
                  :
                    <div>No users record found</div>
              : isLoading?
                    <LoadingSpinner />
                : isError?
                  <div className="md:flex flex-col p-4">
                    <p className="text-blue-500 italic border-b border-black flex flex-1">You are not subscribed to any route</p>
                    <div className='flex justify-center p-8'>
                      <h1 className='flex p-1 text-sm rounded-md text-gray-600 space-x-2'>
                        <p className=''>Note: Make sure to add a seating profile before subscribing to routes</p>
                        <Link to={`/client/client-seating-profile-management/${client_id}`} className='text-blue-400'>
                          click here to add a seating profile
                        </Link>
                      </h1>
                    </div>
                    <Link to={`/user/manage-routes-for-client-subscription/${client_id}`} className='flex justify-center p-8'>
                      <h1 className='bg-yellow-600 hover:bg-yellow-500 p-4 text-white text-sm font-semibold rounded-md cursor-pointer uppercase'>Subscribe to routes</h1>
                    </Link>
                  </div>
                : null
              }
          <div className="flex justify-center p-1 mt-2 mb-2">
            <div>
              {(isSuccess)?
                (routes.hasOwnProperty('success') === true && routes.data.data.length > 0)?
                (routes.data.total > defaultPagination)?
                  <Pagination
                    className="flex items-center space-x-3 text-blue-800 cursor-pointer"
                    defaultPageSize={19}
                    total={routes.data.total}
                    current={routes.data.current_page}
                    onChange={onChange}
                    prevIcon={<ChevronLeftIcon className='h-6 w-10 text-blue-700 cursor-pointer hover:text-blue-500' />}
                    nextIcon={<ChevronRightIcon className='h-6 w-10 text-blue-700 cursor-pointer hover:text-blue-500' />}
                  />
                : null
                : null
                : null
              }
            </div>
          </div>
      </div>
    )
}

export default ManageClientRoutesView;
