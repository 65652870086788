import { Menu } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import Logo from "../Views/Logo";
import { useNavigate } from 'react-router-dom';


function Home() {
  const navigate = useNavigate();

  const home = ()=>{
    navigate('/');
  }

    return (
      <main  className="bg-teal-600 shadow-lg">
          <div className="flex items-center justify-between p-1">
            <div onClick={home} className="cursor-pointer">
              <Logo />
            </div>
            <div className="flex space-x-4">
              <Menu as="div" className='relative'>
                <Menu.Button className="rounded-full text-gray-200">
                  <MenuIcon className="h-8 w-10 round-full"/>
                </Menu.Button> 
                <Menu.Items className="absolute focus:outline-none right-0 bg-white rounded-md shadow-lg border w-32">
                  <Menu.Item>
                    <div className='flex justify-center block hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                      <Link to="/ticket-verication">
                        Verify Ticket
                      </Link>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div className='flex justify-center block hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                      <Link to="/postpon-customer-ticket">
                        Postpon Ticket
                      </Link>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div className='flex justify-center block hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                      <Link to="/select-operator">
                        Route Seats
                      </Link>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/login" className='flex justify-center block hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                      <div >
                        Login
                      </div>
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
          </div>
          <div className="flex flex-col bg-gray-200 p-2 shadow-2xl">
                <div className="flex justify-center">
                    <p style={{color: '#003356', fontSize: 10}} className='uppercase'>
                    Buy time save cash
                    </p>
                </div>
            </div>
      </main>
  )
  }
  
  export default Home;

