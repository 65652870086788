import { Link } from "react-router-dom";
import {AdjustmentsIcon, ChartBarIcon, ChartPieIcon, ChatAlt2Icon, ChevronDownIcon, ColorSwatchIcon, DocumentIcon, GlobeIcon, HomeIcon, IdentificationIcon, LightBulbIcon, LogoutIcon, MapIcon, MicrophoneIcon, PuzzleIcon, TableIcon, TruckIcon, UserCircleIcon, UsersIcon } from '@heroicons/react/solid';
import { Menu} from "@headlessui/react";
import swal from 'sweetalert';
import Logo from "../Logo";
import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../../Features/usersSlice";
import { useNavigate } from 'react-router-dom';
import { logOut } from "../../Features/auth/authSlice";
import { UserGroupIcon } from "@heroicons/react/outline";


function ClientDataAnalysisHeaderView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
    
  const [logout, {isLoading}] = useLogoutMutation();

  const logUserOut = async () =>{
    try {
      const resp = await logout().unwrap()
      dispatch(logOut())
  } catch (err) {
      if(err){
          swal("Error", err.message,"error")
          dispatch(logOut())
      }    
    }
  }

  const home = ()=>{
    navigate('/data-analysis');
  }
   

    return   (
        <div className="sticky top-0 z-50 flex justify-between shadow-2xl items-center p-1 bg-teal-600 border-b border-rose-500">
          <div onClick={home} className="cursor-pointer">
            <Logo />
          </div>
          <div className="flex md:space-x-4 items-center">
            <div className="hidden md:flex md:space-x-1 items-center">
            <Link to={`/data-analysis`} className="p-2 w-28 rounded-md">
                <div className="flex items-center justify-center text-gray-100 hover:text-gray-300 text-sm space-x-1">
                  <HomeIcon className="h-5 round-full"/>
                  <p className="">Dashboard</p>
                </div>
            </Link>
            <Menu as="div" className='relative z-50'>
              <Menu.Button className="flex items-center justify-center p-2 w-28 rounded-md">
                <div className="flex items-center text-gray-100 hover:text-gray-300 text-sm space-x-0">
                  <ChartPieIcon className="h-5 mr-1"/>
                  <p className="">Reports</p>
                  <ChevronDownIcon className="h-5 round-full"/>
                </div>
              </Menu.Button> 
              <Menu.Items className="absolute z-40 focus:outline-none bg-white rounded-md shadow-lg border w-40">
                <Menu.Item>
                  <Link to={`/data-analysis/sales-agents-summary-reports`} className='flex justify-start z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700  border-b border-gray-200'>
                  <div className="flex items-center justify-center text-gray-900 text-xs space-x-1">
                    <UserGroupIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                    <p className="">Sales Agents</p>
                  </div>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`/data-analysis/stations-summary-reports`} className='flex justify-start z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700  border-b border-gray-200'>
                  <div className="flex items-center justify-center text-gray-900 text-xs space-x-1">
                    <UserGroupIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                    <p className="">Stations</p>
                  </div>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`/data-analysis/total-sales-summary-reports`} className='flex justify-start z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700  border-b border-gray-200'>
                  <div className="flex items-center justify-center text-gray-900 text-xs space-x-1">
                    <ChartBarIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                    <p className="">Total Sales</p>
                  </div>
                  </Link>
                </Menu.Item>
              </Menu.Items>
            </Menu>
              <Menu as="div" className='relative z-50'>
                <Menu.Button className="flex items-center justify-center p-2 w-28 rounded-md">
                  <div className="flex items-center text-gray-100 hover:text-gray-300 text-sm space-x-0">
                    <UserGroupIcon className="h-5 mr-1"/>
                    <p className="">Managers</p>
                    <ChevronDownIcon className="h-5 round-full"/>
                  </div>
                </Menu.Button> 
                <Menu.Items className="absolute z-40 focus:outline-none bg-white rounded-md shadow-lg border w-40">
                  <Menu.Item>
                    <Link to={`/data-analysis/station-mananger-users`} className='flex justify-start z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-gray-200'>
                    <div className="flex items-center justify-center text-gray-900 text-xs space-x-1">
                      <UserGroupIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                      <p className="">Stations</p>
                    </div>
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
            <Menu as="div" className='relative z-50 md:hidden'>
              <Menu.Button className="bg-gray-800 hover:bg-gray-900 pr-3 pl-3 pt-1 pb-1 mr-4 rounded-md">
                <div className="flex items-center text-gray-100 text-xs space-x-0">
                  <p className="">Menu</p>
                  <ChevronDownIcon className="h-6 round-full"/>
                </div>
              </Menu.Button> 
              <Menu.Items className="absolute z-40 focus:outline-none right-0 bg-white rounded-md shadow-lg border w-60">
                <Menu.Item>
                  <div className='flex justify-center hover:bg-gray-100 cursor-pointer px-4 py-2 text-xs text-gray-700 border-b border-gray-200'>
                    <Link to={`/client/routes-management`}>
                      Routes
                    </Link>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div className='flex justify-center hover:bg-gray-100 cursor-pointer px-4 py-2 text-xs text-gray-700 border-b border-gray-200'>
                    <Link to={`/client/client-seating-profile-management`}>
                      Seats
                    </Link>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div className='flex justify-center hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-gray-200'>
                    <Link to={`/client/business-performance-reporting`}>
                      Revenue
                    </Link>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div className='flex justify-center z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700  border-b border-gray-200'>
                    <Link to={`/client/client-sales-transactions-statement`}>
                     Transactions
                    </Link>
                  </div>
                </Menu.Item>
              <Menu.Item>
                <div className='flex justify-center z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                  <Link to={`/client/client-profile`}>
                    Profile
                  </Link>
                </div>
                </Menu.Item>
              </Menu.Items>
            </Menu>
            <Menu as="div" className='relative z-50 md:mr-2'>
              <Menu.Button className="focus:outline-none focus:ring-2 bg-teal-500 text-gray-300 focus:ring-offset-2 focus:ring-blue-500 mr-4 rounded-full">
                <UserCircleIcon className="h-10 w-10 round-full "/>
              </Menu.Button> 
              <Menu.Items className="absolute focus:outline-none right-0 bg-white rounded-md shadow-lg border w-32 mr-2">
                <Menu.Item>
                  <Link to={`/client/user-profile`} className='flex justify-center z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700  border-b border-gray-200'>
                  <div className="flex items-center justify-center text-gray-900 text-xs space-x-1">
                    <UsersIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                    <p className="">My profile</p>
                  </div>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <div onClick={logUserOut} className="flex items-center justify-center text-xs space-x-1 z-40 hover:bg-gray-100 cursor-pointer px-4 py-2 text-gray-700">
                    <LogoutIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                    <p className="">Logout</p>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div> 
        )

}

export default ClientDataAnalysisHeaderView;
