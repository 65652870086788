import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Link,  useParams } from 'react-router-dom';
import Pagination from 'rc-pagination';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import swal from 'sweetalert';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetClientAgentUserDailySalesDatesQuery, useGetClientAgentUserDailySalesQuery } from '../../Features/dataAnalysisReportingSlice';
import { useGetAgentUserProfileByIdQuery } from '../../Features/usersSlice';
import { Menu } from '@headlessui/react';
import ClientAccountingHeaderView from './ClientAccountingHeaderView';

function ClientAccoutingSalesUserDiarySalesView() {
    const defaultPagination = 100;
    const [search, setSearch] = useState(0)
    const [pager, setPager] = useState("")
    const [date, setDate] = useState(0);
    const [totalCharge, setTotalCharge] = useState(0);
    const [totalSales, setTotalSales] = useState(0);

    const { agent_user_id } = useParams();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;    

    const {
      data: agentUser,
      isSuccess: isSuccessAgentUser,
  } = useGetAgentUserProfileByIdQuery(agent_user_id);

  const {
    data: dates,
    isSuccess: isSuccessDates,
    isError: isErrorDates,
  } = useGetClientAgentUserDailySalesDatesQuery({client_id: client_id, agent_user_id: agent_user_id});

  const {
    data: routes,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetClientAgentUserDailySalesQuery({client_id: client_id, agent_user_id: agent_user_id, date: date})

    const [input, setInput] = useState({
      search: ''
    })

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value})
    if(input.search != 0){
      setSearch(input.search)
    }else {

      setSearch(0)
    }
  }

  useEffect(()=>{
    if(isSuccess){
      if(routes.data.length > 0){
        let total_charge = 0
        let total_sales = 0

        routes.data.map(data => (
          total_charge += data.charge
        ))
        routes.data.map(data => (
          total_sales += data.totalSales
        ))
        setTotalCharge(total_charge)
        setTotalSales(total_sales)
      }
    }
    // refetch()
    return () =>{
    }
  },[routes]);

  const onChange = (currentPage) => {
    setPager(currentPage)
  }

  const getSelecteDateData = async (e, date) =>{
    setDate(date)
  }

  const formatTotal = (x)=>{
    return (x !==null)? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
  }

  const numberWithCommas = (x)=>{
    if(x !==null){
      const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
      return formatedNumber;
    }
    return null;
  }

  const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate() + "-" + tempDate.getFullYear();
}
    
    return (
      <div className=" bg-gray-100">
        <ClientAccountingHeaderView />
          <div className="flex justify-between items-center bg-gray-50 p-0">
              {
                (isSuccessAgentUser)?
                  <div className='flex  items-center'>
                      <h1 className="flex text-xs text-gray-700 uppercase font-semibold p-2">
                        <div>
                        {agentUser.data.fname} {agentUser.data.lname}'s Sales 
                        </div> 
                        </h1>
                  </div>
                : null
              }
            </div>
            {(isSuccess)?
                <div>
                  {
                  (isSuccessDates)?
                  <div className='flex items-center space-x-3 justify-end p-2 mr-2'>
                    {
                          (isSuccess)?
                            <div className='text-gray-500 text-xs'> {(date == 0)? "Today" : formatDisplayDate(date)}</div>
                          :null
                        }
                    <Menu as="div" className='relative z-10'>
                      <Menu.Button className="bg-gray-200 hover:bg-gray-300 border w-32 border-gray-500 pr-2 pl-2 pt-1 pb-1 rounded-md">
                        <div className="flex items-center text-gray-700 text-xs space-x-1 justify-center">
                          <p className="">Select day</p>
                          <CalendarIcon className="h-4 round-full" />
                        </div>
                      </Menu.Button>
                      <Menu.Items className="absolute focus:outline-none right-0 bg-white rounded-md shadow-lg border w-40 mr-6 mt-1">
                        {
                          (dates.hasOwnProperty('success') === true && dates.data.length > 0)?
                          dates.data.map(data => (
                              <Menu.Item>
                                <div onClick={(e)=>getSelecteDateData(e,data.createdAt)} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-1 text-xs text-gray-700 border-b border-gray-200'>
                                  {data.day}
                                </div>
                              </Menu.Item>
                            ))
                          :''
                        }
                      </Menu.Items>
                    </Menu>
                  </div>
                  :''
                  }
                  {
                  (routes.success === true && routes.data.length > 0)?
                  <div className='pr-1 pl-1'>
                    <table className="w-full divide-y divide-gray-200">
                      <thead className="bg-teal-500 shadow-lg">
                          <tr>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Departure
                          </th>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Destination
                          </th>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Sales
                          </th>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Revenue
                          </th>
                        </tr>
                      </thead>  
                      <tbody className="bg-white divide-y divide-gray-200">
                              { (routes.success === true && routes.data.length > 0)?
                                  routes.data.map(data => (
                                      <tr key={data.id} className={`hover:bg-gray-200 text-gray-900`}>
                                          <td className="px-6 py-1 whitespace-nowrap">
                                          <div className="flex items-center">
                                              <div className="flex items-center space-x-4 text-xs font-medium">
                                                  <input 
                                                      type="checkbox" 
                                                      name="roles[]" 
                                                      value={data.id}
                                                      onChange={handleChange}
                                                  />
                                                  <label className=''>{data.start_off_station_name}</label>
                                              </div>
                                          </div>
                                          </td>
                                          <td className="px-2 py-1 whitespace-nowrap text-xs font-medium">
                                              <div className=''>{data.destination_station_name}</div>
                                          </td>
                                          <td className="px-2 py-1 whitespace-nowrap text-xs">
                                            <div className=' text-xs'>
                                                <div className=''>
                                                    {formatTotal(data.totalSales)} 
                                                </div>
                                            </div>
                                          </td>
                                        <td className="px-1 py-0 whitespace-nowrap">
                                          <div className=' text-xs'>
                                              <div className='font-md'>
                                                  K {numberWithCommas(data.charge)}
                                              </div>
                                          </div>
                                        </td>
                                      </tr>
                                      ))
                                      : ''
                              }
                              <tr>
                                <td className='px-6 py-2 whitespace-nowrap uppercase text-xs font-bold'>Total</td>
                                <td></td>
                                <td className="px-1 py-0 whitespace-nowrap">
                                  <div className=' text-sm'>
                                      <div className='font-bold'>
                                          {formatTotal(totalSales)}
                                      </div>
                                  </div>
                                </td>
                                <td className="px-1 py-0 whitespace-nowrap">
                                  <div className=' text-sm'>
                                      <div className='font-bold'>
                                          K {numberWithCommas(totalCharge)}
                                      </div>
                                  </div>
                                </td>
                              </tr>
                        </tbody>
                      </table>
                    </div>
                      :
                        <div>No users record found</div>
                    }
                </div>
              : isLoading?
                    <LoadingSpinner />
                : isError?
                  <div className="md:flex flex-col p-4">
                    <p className="text-blue-500 italic text-xs border-b border-black flex flex-1">No sales recorded today</p>
                    {
                  (isSuccessDates)?
                  <div className='flex items-center space-x-3 justify-end p-2 mr-2'>
                    <div className='text-gray-500 text-xs'>Today</div>
                    <Menu as="div" className='relative z-10'>
                      <Menu.Button className="bg-gray-200 hover:bg-gray-300 border w-32 border-gray-500 pr-2 pl-2 pt-1 pb-1 rounded-md">
                        <div className="flex items-center text-gray-700 text-xs space-x-1 justify-center">
                          <p className="">Select day</p>
                          <CalendarIcon className="h-4 round-full" />
                        </div>
                      </Menu.Button>
                      <Menu.Items className="absolute focus:outline-none right-0 bg-white rounded-md shadow-lg border w-40 mr-6 mt-1">
                        {
                          (dates.success === true && dates.data.length > 0)?
                          dates.data.map(data => (
                              <Menu.Item>
                                <div onClick={(e)=>getSelecteDateData(e,data.createdAt)} className='flex text-xs justify-center hover:bg-gray-100 cursor-pointer px-4 py-2 text-gray-700 border-b border-gray-200'>
                                  {data.day}
                                </div>
                              </Menu.Item>
                            ))
                          :''
                        }
                      </Menu.Items>
                    </Menu>
                  </div>
                  :''
                  }
                    <div className='flex justify-center p-10'>
                      <h1 className='p-4 text-gray-500 text-xs font-semibold rounded-md uppercase'>No sales recorded today</h1>
                    </div>
                  </div>
                : null
              }
          <div className="flex justify-center p-1 mt-2 mb-2">
            <div>
              {(isSuccess)?
                (routes.success === true && routes.data.length > 0)?
                (routes.data.total > 100)?
                  <Pagination
                    className="flex items-center space-x-3 text-blue-800 cursor-pointer"
                    defaultPageSize={100}
                    total={routes.data.total}
                    current={routes.data.current_page}
                    onChange={onChange}
                    prevIcon={<ChevronLeftIcon className='h-6 w-10 text-blue-700 cursor-pointer hover:text-blue-500' />}
                    nextIcon={<ChevronRightIcon className='h-6 w-10 text-blue-700 cursor-pointer hover:text-blue-500' />}
                  />
                : null
                : null
                : null
              }
            </div>
          </div>
      </div>
    )
}

export default ClientAccoutingSalesUserDiarySalesView;
