import Select from 'react-select';
import {useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { useEditAgentUserProfileMutation, useGetAgentUserProfileByIdQuery } from '../../Features/usersSlice';
import LoadingSpinner from '../LoadingSpinner';
import { useGetRegisteredPartinersQuery } from '../../Features/clientsSlice';

function AgentUserProfileEditView() {
    const navigate = useNavigate();
    const { user_id } = useParams();

    const [input, setInput] = useState({});

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const [errors, setErrors] = useState({
        email: '',
        phone: '',
        fname: '',
        lname: '',
        client_id: '',
        user_id: '',
    })

    const {
        data: partiners,
        isLoading: isLoadingPartiners,
        isSuccess: isSuccessPartiners,
        isError,
        error
      } = useGetRegisteredPartinersQuery()

    const {
        data: user,
        isLoading,
        isSuccess,
    } = useGetAgentUserProfileByIdQuery(user_id)

    useEffect(()=>{
        if(isSuccess && user.hasOwnProperty("data")){
            setInput(user.data)
        }
        return () =>{
        }
    },[user]);

    const [editAgentUserProfile] = useEditAgentUserProfileMutation()

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await editAgentUserProfile(input).unwrap()
            if(resp.success === true){
                swal("Success",resp.message)
                navigate(-1);
            }
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({
                    ...errors, 
                    fname: err.data.errors.fname,
                    lname: err.data.errors.lname,
                    email: err.data.errors.email,
                    phone: err.data.errors.phone,
                    client_id: err.data.errors.client_id,
                    user_id: err.data.errors.user_id,
                })
            }else if(err.data.hasOwnProperty('error')){
                return swal("Registration Failed",err.data.error,"error");
            }
        }          
    }

    const handleSetClientId = (e)=>{
        setInput({
            ...input, 
            client_id: e.value,
        })
    }

    const back = ()=> navigate(-1);

    let constent = (isLoading)
        ?<LoadingSpinner />
        : (
            <div className="bg-gray-100">
                <div className="flex items-center justify-center bg-white p-1 mb-10">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">edit agent user profile</h1>
                </div> 
                {
                    (isSuccessPartiners)?
                        <div class="sm:mt-0 md:pt-2">
                            <div class="md:grid md:grid-cols-2 md:pr-40 md:pl-40">
                                <div class="bg-gray-200 mt-5 md:mt-0 md:col-span-2">
                                    <form onSubmit={handleSubmit} autoComplete="off" >
                                        <div class="p-6sm:rounded-md">
                                        <div class="">
                                            <div class="grid grid-cols-6 gap-6">
                                                <div class="col-span-6 p-4 shadow-md bg-gray-700 uppercase">
                                                    <div className='flex justify-center'>
                                                        <h1 className='uppercase font-semibold text-sm text-white'>Edit {user.data.fname} {user.data.lname} profile</h1>
                                                    </div>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="fname" class="block text-sm font-medium text-gray-700">First name</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        name="fname" 
                                                        id="fname" 
                                                        value={input.fname}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.fname}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="lname" class="block text-sm font-medium text-gray-700">Last name</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        name="lname" 
                                                        id="lname" 
                                                        value={input.lname}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.lname}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="email"
                                                        name="email" 
                                                        id="email" 
                                                        value={input.email}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.email}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="phone" class="block text-sm font-medium text-gray-700">Phone number</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        name="phone" 
                                                        id="phone" 
                                                        value={input.phone}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.phone}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3">
                                                    <label for="business" class="block text-sm font-medium text-gray-700">Business/Company</label>
                                                    <Select 
                                                            class="block
                                                            w-full
                                                            px-3
                                                            py-1.5
                                                            text-base
                                                            font-normal
                                                            text-gray-700
                                                            bg-white bg-clip-padding
                                                            border border-solid border-gray-300
                                                            rounded
                                                            transition
                                                            ease-in-out
                                                            m-0"
                                                            placeholder="Select business/company"
                                                            defaultValue={input.client_id}
                                                                options={
                                                                    partiners.data.map(data=>(
                                                                        {value:data.id, label:data.name}
                                                                    ))
                                                                }
                                                            isSearchable
                                                            onChange={handleSetClientId}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.client_id}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3">
                                                    <label for="user_id" class="block text-sm font-medium text-gray-700">Valid NRC/Passport number</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Enter NRC/Passport ID'
                                                        name="user_id" 
                                                        id="user_id" 
                                                        value={input.user_id}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.user_id}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-center space-x-6 items-center px-4 py-4 bg-gray-300 text-right sm:px-6 mt-2">
                                            <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Cancel</div>
                                            <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Save</button>
                                        </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    :isLoadingPartiners?
                        <LoadingSpinner />
                    :isError?
                        <div className="md:flex flex-col p-4">
                            <p className="text-blue-500 italic border-b border-black flex flex-1">You do not have any seating profile available</p>
                        </div>
                    :null
                }
            </div>
        )

    return constent;
}

export default AgentUserProfileEditView;
