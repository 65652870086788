
import { Link, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useDeleteImageMutation, useGetImageByIdQuery, useToggleImageStatusMutation } from '../../Features/frontEndImagesSlice';
import LoadingSpinner from '../LoadingSpinner';

function ManageFrontEndImageView(props) {
    const navigate = useNavigate();
    const {image_id}= useParams();

    const {
        data: image,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetImageByIdQuery(image_id);

    const [deleteImage] = useDeleteImageMutation();

    const handleDelete = async(id)=>{
        if(window.confirm("Are you sure you want to delete this image?")){
            try {
            const resp = await deleteImage(id).unwrap();
            swal("Success",resp.message);
            navigate(-1)
        } catch (err) {
            swal("Error",err.message,'error');
        }
          }
    }

    const [toggleImageStatus] = useToggleImageStatusMutation();

    const handleToggleStatus = async(id)=>{
        try {
            const resp = await toggleImageStatus(id).unwrap();
            swal("Success",resp.message);
        } catch (err) {
            swal("Error",err.message,'error');
        }
    }

    const back = ()=> navigate(-1);
   
        return (
            <div className="bg-gray-100 flex  flex-col">
                <div className="flex items-center justify-center bg-white p-1">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Front End Images management</h1>
                </div>
                {
                    (isSuccess)?
                        <div className="">
                            <div className="md:flex justify-center mt-4 p-1">
                                    <div className="rounded-md bg-gray-200 shadow-sm pr-0 pl-0 sm:pr-0 sm:pl-0">
                                        <div className="md:pr-64 md:pl-64 lg:pr-96 lg:pl-96"></div>
                                        <div className='flex justify-between rounded-sm p-4 shadow-md bg-gray-700 text-sm  text-white flex justify-center '>
                                            <div></div>
                                            <div className='uppercase font-semibold'>Front end image </div>
                                            {
                                                (image.data.status != 1)?
                                                    <label onClick={(e)=>handleToggleStatus(image.data.id)} for="small-toggle" class="inline-flex relative items-center cursor-pointer">
                                                        <div class="flex justify-center text-white w-14 h-5 bg-gray-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                                                            <span class="text-sm mr-2">Off</span>
                                                        </div>
                                                    </label>
                                                :
                                                    <label onClick={(e)=>handleToggleStatus(image.data.id)} for="small-toggle" class="inline-flex relative items-center cursor-pointer">
                                                        <div class="flex justify-center text-white w-14 h-5 bg-green-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                                                            <span class="text-sm mr-2">Active</span>
                                                        </div>
                                                    </label>
                                            }
                                        </div>
                                        <div className="flex flex-col items-center">
                            <div className="flex justify-center p-1 bg-white w-full">
                                <img 
                                    className="bg-gray-100 object-fill h-40 w-72"
                                    src={`http://localhost:8000/front_end_images/`+image.data.img_url} 
                                    alt="Image" 
                                />  
                            </div>
                            <div className="flex flex-col bg-gray-100 w-full">
                                <div className="ml-2 p-2 text-xs font-bold">Description</div>
                                <div className="ml-2 p-2">
                                <div className="p-1 text-sm">{image.data.description}</div>
                                </div>
                            </div>
                          </div>
                            <div className="flex flex-col justify-center">
                                    </div>    
                                    <div className="flex justify-center mt-1 space-x-4 bg-gray-400 border-t border-gray-400 p-4">
                                            <div onClick={back} className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label  className="flex justify-center space-x-2 items-center text-sm bg-gray-500 hover:bg-gray-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Back
                                                    </div>
                                                </label>
                                            </div>
                                            <div onClick={(e)=>handleDelete(image.data.id)} className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label  className="flex justify-center space-x-2 items-center text-sm bg-red-600 hover:bg-red-700 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Delete
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <Link to={`/user/edit-front-end-image/${image.data.id}`}  className="flex justify-center space-x-2 items-center text-sm bg-blue-500 hover:bg-blue-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Edit
                                                    </div>
                                                </Link>
                                            </div>                           
                                    </div>
                                </div>
                            </div>
                        </div>
                :  isLoading
                    ?<LoadingSpinner />
                    : isError
                        ? <div>{error.data.error}</div>
                        : ''
                }
            </div>
        )
}

export default ManageFrontEndImageView;
