import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react'
import swal from 'sweetalert'
import LoadingSpinner from '../LoadingSpinner';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import { useCreateRefundPolicyRateMutation, useEditRefundPolicyRateMutation, useGetClintRefundPolicyByClientIdQuery } from '../../Features/clientsRoutesSlice';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import ClientHeaderView from '../App/ClientHeaderView';
import { useEffect } from 'react';

function EditClientRefundPolicyView() {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const [isEdit, setIsEdit] = useState(false)
    const [input, setInput] = useState({
        rate: '',
    })

    const [errors, setErrors] = useState({
        rate: '',
    })

    const {
        data: client,
        isSuccess: isSuccessClient,
    } = useGetClientByIdQuery(client_id);

    const {
        data: clientRefundPolicy,
        isSuccess: isSuccessClientRefundPolicy,
    } = useGetClintRefundPolicyByClientIdQuery(client_id);

    useEffect(()=>{
        if(isSuccessClientRefundPolicy && clientRefundPolicy.data){
            setInput(clientRefundPolicy.data)
            setIsEdit(true)
        }
        return () =>{
        }
    },[clientRefundPolicy]);

    const [CreateRefundPolicyRate, {isLoading}] = useCreateRefundPolicyRateMutation();
    const [EditRefundPolicyRate, {isLoading: isLoadingEditPolicy}] = useEditRefundPolicyRateMutation();

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        let data = '';
        if(isEdit){
            data = {
                rate: input.rate,
                policy_id: input.id,
                client_id: client_id,
            }
        }else{
            data = {
                rate: input.rate,
                client_id: client_id,
            }
        }
        try {
            let resp = '';
            if(isEdit){
                resp = await EditRefundPolicyRate(data).unwrap()
            }else{
                resp = await CreateRefundPolicyRate(data).unwrap()
            }
            swal("Success",resp.message,"success");
            navigate(-1);
        } catch (err) {
            if(err.data.errors == true){
                setErrors({...errors, 
                    rate: err.data.errors.rate,
                    client_id: err.data.errors.client_id,
                })
            }else if (err.data.error == true){
                swal("",err.data.error,"error")
            }
        }          
    }

    const back = ()=> navigate(-1);

    let constent = isLoading? 
                <LoadingSpinner />
            :isLoadingEditPolicy? 
            <LoadingSpinner />
        :
        (
            <div className="bg-gray-100">
                <ClientHeaderView />
                <div className="flex items-center justify-center bg-gray-50 p-1 mb-10">
                {
                  (isSuccessClient)?
                    <h1 className="text-xs text-gray-800 uppercase font-semibold p-2">edit {client.data.name} Refund pernalty fee</h1>
                  : null
                }
                </div>
                <div class="md:flex justify-center mt-5 sm:mt-0 mb-10 md:pt-4">
                    <div class="bg-gray-100 md:w-1/2">
                        <div class="w-full sm:rounded-md">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class=" shadow overflow-hidden sm:rounded-md">
                                    {
                                        (isSuccessClient)?
                                            <div class="col-span-6 p-4 shadow-md bg-teal-500 uppercase">
                                                <div className='flex justify-center'>
                                                    <h1 className='uppercase font-semibold text-sm text-gray-100'>Edit refund pernalty fee</h1>
                                                </div>
                                            </div>
                                        : null
                                    }
                                <div class="px-4 py-5 bg-gray-100 sm:p-6">
                                <div class="grid grid-cols-2 gap-4">
                                        <div class="col-span-6 sm:col-span-3 mb-1">
                                            <p className="block text-sm font-sm text-gray-400 p-0 m-0">
                                                {`Note: Enter fixed penalty fee for cancelled sales(e.g 20)`}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4">
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="rate" class="block text-xs font-medium text-gray-700">Pernalty Fee</label>
                                            <input 
                                                class="mt-1 p-2 text-sm outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="number"
                                                placeholder='Enter pernalty fee...'
                                                name="rate" 
                                                id="rate" 
                                                value={input.rate}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-xs font-medium text-red-600 p-0 m-0">{errors.rate}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-center space-x-6 items-center px-4 py-4 bg-gray-200 text-right sm:px-6">
                                    <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md bg-gray-300 hover:bg-gray-400 cursor-pointer">Cancel</div>
                                    <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500">Submit</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    return constent;
}

export default EditClientRefundPolicyView;
