import { apiSlice } from "../app/api/apiSlice";

export const clientsRoutesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClientRoutesOrSearch: builder.query({
            query: ({paginate, search, page, id}) => (page == "")? `/user/subscribed/${id}/${search}/${paginate}` : `user/subscribed/${id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes'],
        }),
        getClientRoutesOrSearchForAdmin: builder.query({
            query: ({paginate, search, page, id}) => (page == "")? `/user/client-subscribed-routes-for-admin/${id}/${search}/${paginate}` : `user/subscribed/${id}/${search}/${paginate}?page=${page}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes'],
        }),
        getOperatorRoutesWithChargeByClientId: builder.query({
            query: (operator_id) => `/user/operator-routes/${operator_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes'],
        }),
        getOperatorRoutesForReserveSeat: builder.query({
            query: (operator_id) => `/user/operator-routes-for-reserve-seat/${operator_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes'],
        }),
        getClientRouteByClientIdAndRouteId: builder.query({
            query: ({client_id, route_id}) => `user/client-route/${client_id}/${route_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes'],
            keepUnusedDataFor: 0
        }),
        getClientRouteDataForEdit: builder.query({
            query: ({client_id, route_id}) => `user/client-route-data/${client_id}/${route_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes'],
            keepUnusedDataFor: 0
        }),
        getClientRouteById: builder.query({
            query: ({client_route_id}) => `user/client-route-details/${client_route_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes'],
            keepUnusedDataFor: 0
        }),
        getClientRouteEditLogs: builder.query({
            query: (client_route_id) => `user/client-route-logs/${client_route_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes_logs'],
            keepUnusedDataFor: 60
        }),
        getOperatorRouteTicketSummaryInfoByClientRouteId: builder.mutation({
            query: (client_route_id) => `user/operator-route-info/${client_route_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['Clients_Routes'],
        }),
        subscribeToRoutes: builder.mutation({
            query: (reqBody) => ({
                url: `user/client-subscribe-to-route/${reqBody.client_id}`,
                method: 'POST',
                body: reqBody
            }),
            invalidatesTags: ['Clients_Routes']
        }),
        checkClientIsSubscribedToRoute: builder.mutation({
            query: ({route_id, client_id}) => `user/check-route-client-status/${route_id}/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
        }),
        addTimeAndChargeToClientRoute: builder.mutation({
            query: (input) => ({
                url: `user/add-time-charge-to-route`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Clients_Routes','Clients_Routes_logs']
        }),
        editTimeAndChargeForClientRoute: builder.mutation({
            query: (input) => ({
                url: `user/edit-time-charge-for-route`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['Clients_Routes','Clients_Routes_logs']
        }),

        toggleClientRouteStatus: builder.mutation({
            query: ({route_id, client_id}) => `user/toggle-client-route-status/${route_id}/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            invalidatesTags: ['Clients_Routes']
        }),
        increamentClientRoutesCharge: builder.mutation({
            query: (input) => ({
                url: `user/increament-client-routes-charge`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Clients_Routes']
        }),
        increamentClientRoutesCashCharge: builder.mutation({
            query: (input) => ({
                url: `user/increament-client-routes-cash-charge`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Clients_Routes']
        }),
        decreamentClientRoutesCharge: builder.mutation({
            query: (input) => ({
                url: `user/decreament-client-routes-charge`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Clients_Routes']
        }),
        decreamentClientRoutesCashCharge: builder.mutation({
            query: (input) => ({
                url: `user/decreament-client-routes-cash-charge`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Clients_Routes']
        }),
        CreateRefundPolicyRate: builder.mutation({
            query: (input) => ({
                url: `user/create-policy-rate`,
                method: 'POST',
                body: { ...input }
            }),
            invalidatesTags: ['Refund_Policy']
        }),
        EditRefundPolicyRate: builder.mutation({
            query: (input) => ({
                url: `user/client-policy-rate-edit`,
                method: 'PUT',
                body: { ...input }
            }),
            invalidatesTags: ['Refund_Policy']
        }),
        GetClintRefundPolicyByClientId: builder.query({
            query: (client_id) => `user/find-client-policy-rate/${client_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            keepUnusedDataFor: 60,
            providesTags: ['Refund_Policy'],
        }),
        deleteClientRoute: builder.mutation({
            query: ({client_route_id, client_id}) => ({
                url: `user/delete-client_route/${client_route_id}/${client_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Clients_Routes']
        }),
    })
})

export const {
    useGetClientRoutesOrSearchQuery,
    useSubscribeToRoutesMutation,
    useGetClientRouteByIdQuery,
    useGetClientRouteByClientIdAndRouteIdQuery,
    useGetOperatorRoutesWithChargeByClientIdQuery,
    useAddTimeAndChargeToClientRouteMutation,
    useEditTimeAndChargeForClientRouteMutation,
    useGetClientRoutesOrSearchForAdminQuery,
    useCreateRefundPolicyRateMutation,
    useIncreamentClientRoutesCashChargeMutation,
    useEditRefundPolicyRateMutation,
    useGetClintRefundPolicyByClientIdQuery,
    useCheckClientIsSubscribedToRouteMutation,
    useGetOperatorRoutesForReserveSeatQuery,
    useDecreamentClientRoutesCashChargeMutation,
    useIncreamentClientRoutesChargeMutation,
    useDecreamentClientRoutesChargeMutation,
    useToggleClientRouteStatusMutation,
    useGetClientRouteDataForEditQuery,
    useDeleteClientRouteMutation,
    useGetClientRouteEditLogsQuery,
} = clientsRoutesApiSlice

