import { PencilAltIcon, TrashIcon, UserCircleIcon } from '@heroicons/react/outline'
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useGetUserCommissionBalanceQuery } from '../../Features/commissionSlice';
import { useDeleteAgentUserMutation, useGetAgentUserProfileByIdQuery } from '../../Features/usersSlice';
import LoadingSpinner from '../LoadingSpinner';


function ManageAgentUserProfileView() {

    const navigate = useNavigate();
    let { user_id } = useParams();

    const {
        data: user,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAgentUserProfileByIdQuery(user_id)

    const {
        data: commission,
        isLoading: isLoadingCommissionBalance,
        isSuccess: isSuccessCommissionBalance,
        isError: isErrorCommissionBalance,
        error: ErrorCommissionBalance
    } = useGetUserCommissionBalanceQuery(user_id)

    const [deleteAgentUser] = useDeleteAgentUserMutation();

    const editUser = (e,id)=>{
        navigate("/user/agent-user-profile-edit/"+id)
    }

    const handDeleteUser = async(e,id)=>{
        if(window.confirm("Are you sure you want to delete this user?")){
            try {
            const resp = await deleteAgentUser(user_id).unwrap();
            swal("Success",resp.message);
            navigate(-1);
        } catch (err) {
            swal("Error",err.message,'error');
        }
          }
    }

        return (
            <div className="bg-gray-100 flex  flex-col">
                <div className="flex items-center justify-center bg-white p-1">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">manage user profile</h1>
                </div>
                {
                (isSuccess)?
                    (user.data)?
                        <div className="">
                           <div className="md:flex justify-center mt- p-8">
                                <div className="bg-gray-200 shadow-sm pr-0 pl-0 pt-2 pb-2 rounded-md">
                                    <div className="flex justify-center md:pr-60 md:pl-60">
                                        <UserCircleIcon className="h-20 text-gray-500" />
                                    </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Name</p>
                                            <p className="mr-20 p-2 text-sm uppercase">{user.data.fname} {user.data.lname}</p>
                                        </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Email</p>
                                            <p className="mr-20 p-2 text-sm">{user.data.email}</p>
                                        </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Phone No.</p>
                                            <p className="mr-20 p-2 text-sm">{user.data.phone}</p>
                                        </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Gender</p>
                                            <p className="mr-20 p-2 text-sm">{user.data.gender}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="flex justify-center font-semibold text-sm text-gray-700 uppercase bg-gray-400 p-2">
                                                Manage commission
                                            </p>
                                            {
                                                (isSuccessCommissionBalance)? 
                                                <p className="flex justify-center p-2 font-semibold text-lg">
                                                    Balance K{(commission.data.balance > 0)? commission.data.balance : '0.00'}
                                                </p>
                                                : isLoadingCommissionBalance?
                                                <p className="flex justify-center p-2 font-semibold text-sm">
                                                    Loading...
                                                </p>
                                                :isErrorCommissionBalance?
                                                <p className="flex justify-center p-2 font-semibold text-sm">
                                                    Balance K 0.00
                                                </p>
                                                : null
                                            }
                                            <p className="flex justify-center font-semibold text-sm text-blue-500 cursor-pointer p-2">
                                                Review transactions
                                            </p>
                                        </div>
                                        <div className="flex justify-center mt-1 space-x-4 border-t border-gray-300 p-4">
                                            <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label onClick={(e)=>handDeleteUser(e, user.data.id)} className="flex justify-center space-x-2 items-center text-sm bg-red-600 hover:bg-red-700 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Delete
                                                    </div>
                                                    <div className="text-white flex justify-center">
                                                        <TrashIcon className="h-4 " />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label onClick={(e)=>editUser(e, user.data.id)} className="flex justify-center space-x-2 items-center text-sm bg-blue-400 hover:bg-blue-500 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Edit user
                                                    </div>
                                                    <div className="text-white flex justify-center">
                                                        <PencilAltIcon className="h-4 " />
                                                    </div>
                                                </label>
                                            </div>                           
                                         </div>
                                </div>
                           </div>
                        </div>
                    :
                        <div>{user.error}</div>
                    : isLoading?
                    <LoadingSpinner />
                    : isError
                        ? error.data.error
                        : ''
              }
            </div>
        )
}

export default ManageAgentUserProfileView;
