
import { useState} from 'react'
import ReactFileReader from 'react-file-reader';
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import { useRegisterClientMutation, useValidateClientBusinessBankingInformationMutation, useValidateClientBusinessInformationMutation } from '../../Features/clientsSlice';
import Select from 'react-select';

function AddClientView() {
    const navigate = useNavigate();

    const [input, setInput] = useState({
        name: '',
        trading_name: '',
        email: '',
        physical_address: '',
        phone: '',
        sender_id: '',
        business_type: '',
        bank_acc_number: '',
        category: '',
        logo_image: [],
        business_tpin_number: '',
        bank_name: '',
        branch_name:'',
        branch_code: '',
        bank_account_name: '',
        contract_id: '',
        //Primary user details
        username: '',
        password: '',
        password_comfirm: '',
        user_email: '',
        user_phone: '',
        fname: '',
        lname: '',
        gender: '',
        user_id: '',
        selected_gender: '',
    });

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const [errors, setErrors] = useState({
        name: '',
        trading_name: '',
        email: '',
        physical_address: '',
        phone: '',
        sender_id: '',
        business_type: '',
        category: '',
        logo_image: [],
        business_tpin_number: '',
        bank_name: '',
        branch_name:'',
        branch_code: '',
        bank_account_name: '',
        bank_acc_number: '',
        contract_id: '',
        //Primary user details
        username: '',
        password: '',
        password_comfirm: '',
        user_email: '',
        user_phone: '',
        fname: '',
        lname: '',
        gender: '',
        user_id: '',
        selected_gender: '',
    })


    const [registerClient, {isLoading: isLoadingSubmit}] = useRegisterClientMutation();

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await registerClient(input).unwrap()
            if(resp.success === true){
                swal("Success",resp.message)
                navigate(-1);
            }
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({
                    ...errors, 
                    name: err.data.errors.name,
                    trading_name: err.data.errors.trading_name,
                    email: err.data.errors.email,
                    physical_address: err.data.errors.physical_address,
                    phone: err.data.errors.phone,
                    sender_id: err.data.errors.sender_id,
                    business_type: err.data.errors.business_type,
                    category: err.data.errors.category,
                    logo_image: err.data.errors.logo_image,
                    business_tpin_number: err.data.errors.business_tpin_number,
                    bank_name: err.data.errors.bank_name,
                    branch_name: err.data.errors.branch_name,
                    branch_code: err.data.errors.branch_code,
                    bank_account_name: err.data.errors.bank_account_name,
                    bank_acc_number: err.data.errors.bank_acc_number,
                    contract_id: err.data.errors.contract_id,
                    //primary user
                    username: err.data.errors.username,
                    password: err.data.errors.password,
                    user_email: err.data.errors.user_email,
                    user_phone: err.data.errors.user_phone,
                    fname: err.data.errors.fname,
                    lname: err.data.errors.lname,
                    gender: err.data.errors.gender,
                    user_id: err.data.errors.user_id,
                })
            }
        }          
    }

    const handleSetGender = (e)=>{
        setInput({
            ...input, gender: e.value,
            selected_gender: {value: e.value, label: e.label}
        })
    }

    const handleFile = (file) =>{
        setInput({...input, logo_image: file.base64})
    }

    const handleSetCategory = (e)=>{
        setInput({
            ...input, category: e.value
        })
    }

    const [validateClientBusinessInformation, {isLoading: isLoadingBusinessInformationValidation}] = useValidateClientBusinessInformationMutation()

    const validateBusinessInformation = async(e)=>{
        try {
            const dataResp = await validateClientBusinessInformation({
                name: input.name,
                trading_name: input.trading_name,
                email: input.email,
                physical_address: input.physical_address,
                phone: input.phone,
                sender_id: input.sender_id,
                business_type: input.business_type,
                category: input.category,
                logo_image: input.logo_image,
                business_tpin_number: input.business_tpin_number,
                contract_id: input.contract_id,
            }).unwrap()
            if(dataResp.success){
                let business_information = document.getElementById("business_information");
                let business_banking_information = document.getElementById("business_banking_information");
                business_information.classList.add("hidden");
                business_banking_information.classList.remove("hidden");
            }
        } catch (err) {
            if(err.data.errors){
                setErrors({
                    ...errors, 
                    business_type: err.data.errors.business_type,
                    name: err.data.errors.name,
                    trading_name: err.data.errors.trading_name,
                    email: err.data.errors.email,
                    phone: err.data.errors.phone,
                    sender_id: err.data.errors.sender_id,
                    category: err.data.errors.category,
                    physical_address: err.data.errors.physical_address,
                    logo_image: err.data.errors.logo_image,
                    business_tpin_number: err.data.errors.business_tpin_number,
                    contract_id: err.data.errors.contract_id,
                })
            }
        }
    }

    const [validateClientBusinessBankingInformation, {isLoading: isLoadingBusinessBankingInformationValidation}] = useValidateClientBusinessBankingInformationMutation()

    const validateBusinessBankingInformation = async(e)=>{
        try {
            const dataResp = await validateClientBusinessBankingInformation({
                bank_name: input.bank_name,
                branch_name: input.branch_name,
                branch_code: input.branch_code,
                bank_account_name: input.bank_account_name,
                bank_acc_number: input.bank_acc_number,
            }).unwrap()
            if(dataResp.success){
                let business_banking_information = document.getElementById("business_banking_information");
                let business_primary_user_information = document.getElementById("business_primary_user_information");
                    business_banking_information.classList.add("hidden");
                    business_primary_user_information.classList.remove("hidden");
            }
        } catch (err) {
            if(err.data.errors){
                setErrors({
                    ...errors, 
                    bank_name: err.data.errors.bank_name,
                    branch_name: err.data.errors.branch_name,
                    branch_code: err.data.errors.branch_code,
                    bank_account_name: err.data.errors.bank_account_name,
                    bank_acc_number: err.data.errors.bank_acc_number,
                })
            }
        }
    }

    const backToBusinessInformation = ()=>{
        let business_information = document.getElementById("business_information");
        let business_banking_information = document.getElementById("business_banking_information");
            business_information.classList.remove("hidden");
            business_banking_information.classList.add("hidden");
    }

    const backToBusinessBankingInformation = ()=>{
        let business_banking_information = document.getElementById("business_banking_information");
        let business_primary_user_information = document.getElementById("business_primary_user_information");
            business_banking_information.classList.remove("hidden");
            business_primary_user_information.classList.add("hidden");
    }

    const back = ()=> navigate(-1);

    return (
            <div className="bg-gray-100">
                <div className="flex items-center justify-center bg-white p-1 mb-10">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Create Client</h1>
                </div> 
                <div class="sm:mt-0 md:pt-1">
                    <div class="md:grid md:grid-cols-2 md:pr-40 md:pl-40">
                        <div class="bg-gray-200 mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class="sm:rounded-md">
                                <div class="">
                                    <div class="">
                                        <div class="col-span-6 p-4 shadow-md bg-teal-500 uppercase">
                                            <div className='flex justify-center'>
                                                <h1 className='uppercase font-semibold text-sm text-white'>Add client</h1>
                                            </div>
                                        </div>
                                        <div id='business_information' className=''>
                                            <div className='grid grid-cols-6 gap-6'>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="name" class="block text-sm font-medium text-gray-700">Business Name</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Business name'
                                                        name="name" 
                                                        id="name" 
                                                        value={input.name}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.name}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="trading_name" class="block text-sm font-medium text-gray-700">Trading Name</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Trading Name'
                                                        name="trading_name" 
                                                        id="trading_name" 
                                                        value={input.trading_name}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.trading_name}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="business_type" class="block text-sm font-medium text-gray-700">Business Type</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='e.g Plc, Lmc'
                                                        name="business_type" 
                                                        id="business_type" 
                                                        value={input.business_type}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_type}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3">
                                                    <label for="country" class="block text-sm font-medium text-gray-700">Category</label>
                                                    <Select 
                                                            class="block
                                                            w-full
                                                            px-3
                                                            py-1.5
                                                            text-base
                                                            font-normal
                                                            text-gray-700
                                                            bg-white bg-clip-padding
                                                            border border-solid border-gray-300
                                                            rounded
                                                            transition
                                                            ease-in-out
                                                            m-0"
                                                            placeholder="Select category"
                                                            options={[
                                                                {value:0, label:"Big bus"},
                                                                {value:1, label:"Others"}
                                                            ]}
                                                            onChange={handleSetCategory}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.category}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="phone" class="block text-sm font-medium text-gray-700">Contact Number</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder='Contact number'
                                                        name="phone" 
                                                        id="phone" 
                                                        value={input.phone}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.phone}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="phone_2" class="block text-sm font-medium text-gray-700">Sender ID</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Sender ID'
                                                        name="sender_id" 
                                                        id="sender_id" 
                                                        value={input.sender_id}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.sender_id}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="email"
                                                        placeholder='Email address'
                                                        name="email" 
                                                        id="email" 
                                                        value={input.email}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.email}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="phone" class="block text-sm font-medium text-gray-700">Physical address</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Physical location'
                                                        name="physical_address" 
                                                        id="physical_address" 
                                                        value={input.physical_address}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.physical_address}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="business_tpin_number" class="block text-sm font-medium text-gray-700">TPIN Number</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='TPIN Number'
                                                        name="business_tpin_number" 
                                                        id="business_tpin_number" 
                                                        value={input.business_tpin_number}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.business_tpin_number}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="contract_id" class="block text-sm font-medium text-gray-700">Contract ID</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder='Contract ID'
                                                        name="contract_id" 
                                                        id="contract_id" 
                                                        value={input.contract_id}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.contract_id}</p>
                                                </div>
                                            </div>
                                            <div class="flex flex-col bg-gray-200">
                                                <div className='flex flex-col items-center p-2'>
                                                    <ReactFileReader 
                                                        className='flex justify-center items-center space-x-2 cursor-pointer'
                                                        multipleFiles={false}
                                                        base64={true}
                                                        handleFiles={handleFile}
                                                    >
                                                        <p className='bg-gray-300 pr-1 pl-1 border border-gray-400'>Upload Logo</p>
                                                    </ReactFileReader>
                                                    <p className="block text-sm font-medium text-red-500 p-0 m-0">{errors.logo_image}</p>
                                                </div>
                                            </div>
                                            <div class="flex flex-col bg-gray-300">
                                                <div className='flex justify-center space-x-6 items-center px-4 pb-2 bg-gray-300 text-right sm:px-6 mt-2'>
                                                    <div onClick={back} class="w-28 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Cancel</div>
                                                    <div onClick={validateBusinessInformation} class="w-28 inline-flex justify-center cursor-pointer py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600">
                                                        {
                                                            (isLoadingBusinessInformationValidation)?
                                                            <div>Wait...</div>
                                                            :
                                                            <div>Continue</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='business_banking_information' className='hidden'>
                                            <div className='grid grid-cols-6 gap-6'>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="bank_name" class="block text-sm font-medium text-gray-700">Bank Name</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Bank name'
                                                        name="bank_name" 
                                                        id="bank_name" 
                                                        value={input.bank_name}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.bank_name}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="branch_name" class="block text-sm font-medium text-gray-700">Branch Name</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Branch name'
                                                        name="branch_name" 
                                                        id="branch_name" 
                                                        value={input.branch_name}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.branch_name}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="branch_code" class="block text-sm font-medium text-gray-700">Branch code</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Branch name'
                                                        name="branch_code" 
                                                        id="branch_code" 
                                                        value={input.branch_code}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.branch_code}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="bank_account_name" class="block text-sm font-medium text-gray-700">Bank account name</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="text"
                                                        placeholder='Bank account name'
                                                        name="bank_account_name" 
                                                        id="bank_account_name" 
                                                        value={input.bank_account_name}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.bank_account_name}</p>
                                                </div>
                                                <div class="col-span-6 sm:col-span-3 p-2">
                                                    <label for="phone" class="block text-sm font-medium text-gray-700">Bank/Acc No.</label>
                                                    <input 
                                                        class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        type="number"
                                                        placeholder='Business bank account number'
                                                        name="bank_acc_number" 
                                                        id="bank_acc_number" 
                                                        value={input.bank_acc_number}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.bank_acc_number}</p>
                                                </div>                                             
                                            </div>
                                            <div class="flex flex-col bg-gray-300">
                                                <div className='flex justify-center space-x-6 items-center px-4 pb-2 bg-gray-300 text-right sm:px-6 mt-2'>
                                                    <div onClick={backToBusinessInformation} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Back</div>
                                                    <div onClick={validateBusinessBankingInformation} class="w-20 inline-flex justify-center cursor-pointer py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">
                                                        {
                                                            (isLoadingBusinessBankingInformationValidation)?
                                                            <div>Wait...</div>
                                                            :
                                                            <div>Continue</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='business_primary_user_information' className='hidden'>
                                        <div className='grid grid-cols-6 gap-6'>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="fname" class="block text-sm font-medium text-gray-700">First name</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                name="fname" 
                                                id="fname" 
                                                value={input.fname}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.fname}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="lname" class="block text-sm font-medium text-gray-700">Last name</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                name="lname" 
                                                id="lname" 
                                                value={input.lname}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.lname}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="user_email" class="block text-sm font-medium text-gray-700">Email</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="user_email"
                                                name="user_email" 
                                                id="user_email" 
                                                value={input.user_email}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.user_email}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="user_phone" class="block text-sm font-medium text-gray-700">Phone number</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                name="user_phone" 
                                                id="user_phone" 
                                                value={input.user_phone}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.user_phone}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="country" class="block text-sm font-medium text-gray-700">Gender</label>
                                            <Select 
                                                    class="block
                                                    w-full
                                                    px-3
                                                    py-1.5
                                                    text-base
                                                    font-normal
                                                    text-gray-700
                                                    bg-white bg-clip-padding
                                                    border border-solid border-gray-300
                                                    rounded
                                                    transition
                                                    ease-in-out
                                                    m-0"
                                                    placeholder="Select gender"
                                                    defaultValue={input.selected_gender}
                                                    options={[
                                                        {value:1, label:"Male"},
                                                        {value:2, label:"Female"}
                                                    ]}
                                                    isSearchable
                                                    onChange={handleSetGender}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.gender}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="user_id" class="block text-sm font-medium text-gray-700">Valid NRC/Passport number</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Enter NRC/Passport ID'
                                                name="user_id" 
                                                id="user_id" 
                                                value={input.user_id}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.user_id}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                name="username" 
                                                id="username" 
                                                value={input.username}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.username}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                                            <input 
                                                class="mt-1 p-2 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="password"
                                                name="password" 
                                                id="password" 
                                                value={input.password}
                                                onChange={handleChange}
                                            />
                                            <p className="block text-sm font-medium text-red-800 p-0 m-0">{errors.password}</p>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="password_comfirm" class="block text-sm font-medium text-gray-700">Confirm password</label>
                                            <input 
                                                class="mt-1 p-2 outline-none bg-white block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="password"
                                                name="password_comfirm" 
                                                id="password_comfirm" 
                                                value={input.password_comfirm}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        </div>
                                        <div class="flex flex-col bg-gray-300">
                                            <div className='flex justify-center space-x-6 items-center px-4 pb-2 bg-gray-300 text-right sm:px-6 mt-2'>
                                                <div onClick={backToBusinessBankingInformation} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Back</div>
                                                <div onClick={handleSubmit} class="w-20 inline-flex justify-center cursor-pointer py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">
                                                    {
                                                        (isLoadingSubmit)?
                                                        <div>Wait...</div>
                                                        :
                                                        <div>Finish</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

}

export default AddClientView;
