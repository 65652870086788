import { Link, useNavigate } from "react-router-dom";
import { useGetActiveOperatorsQuery } from "../../Features/clientsSlice";
import Home from "../../HeaderLayouts/Home";
import { useGetAllImagesForFrontEndQuery } from "../../Features/frontEndImagesSlice";
import FrontEndLoadingSpinner from "../FrontEndLoadingSpinner";

function OperatorSeatingStatusView(props) {  
    const navigate = useNavigate();

    const {
      data: clients,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetActiveOperatorsQuery()

    const {
        data: images,
        isLoading: isLoadingImages,
        isSuccess: isSuccessImages,
        isError: isErrorImages,
        error: errorImage
      } = useGetAllImagesForFrontEndQuery()

    console.log(images)

    return (
        <div className='bg-white'>
            <div className="sticky top-0 z-50">
                <Home />
            </div>
            <div className="flex justify-center p-2 mb-4 bg-blue-400 uppercase text-sm font-semibold text-gray-100">
              Reserve Physical ticket
            </div>
            <div className='flex flex-col md:pl-32 md:pr-32 bg-white'>
                <div className="flex justify-between flex-wrap pb-2 md:pl-16 md:pr-16 ">
                {(isSuccess)?
                (clients.hasOwnProperty('success') === true && clients.data.length > 0)?
                  clients.data.map(data => (
                      <Link to={`/operator-routes-seating-status/${data.id}`} key={data.id} className="flex m-2 bg-gray-100 rounded-md flex-col justify-around shadow-xl hover:shadow-2xl">
                          <div className="flex shadow-md hover:shadow-2xl border border-gray-200 rounded-md">
                            <img 
                                className="bg-gray-100 rounded-t-lg object-fill h-32 w-32 md:h-48 md:w-72"
                                src={`http://localhost:8000/client_logo_image/`+data.logo_image_url} 
                                alt="Image" 
                            />  
                          </div>
                          <Link to={`/operator-routes-seating-status/${data.id}`} className="flex flex-col shadow-lg">
                              <div className="bg-blue-500 hover:bg-blue-600 flex justify-center cursor-pointer p-2 rounded-b-md text-sm text-gray-100">
                                {data.name}
                              </div>
                          </Link>
                      </Link>
                  ))
              :
                <div>No users record found</div>
                : isError?
                  <div className="md:flex flex-col p-4">
                    <p className="text-blue-500 italic border-b border-black flex flex-1">Empty</p>
                    <Link to={`/user/add-client`} className='flex justify-center p-8'>
                      <h1 className='bg-blue-600 hover:bg-yellow-500 p-4 text-sm font-semibold rounded-md text-white cursor-pointer'>Add Client</h1>
                    </Link>
                  </div>
                : null   
            }
                </div>
            </div>
            {
                isLoading?
                <FrontEndLoadingSpinner />
                : null
            }
        </div>
    )
}

export default OperatorSeatingStatusView;
