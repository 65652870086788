import { UserCircleIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { selectCurrentClientObject } from '../../Features/clientObjectSlice';
import { useGetCurrentUserRolesQuery } from '../../Features/rolesSlice';
import LoadingSpinner from '../LoadingSpinner';


function CurrentUserProfileView(props) {
    const navigate = useNavigate();

    const  user  = useSelector(selectCurrentUser);
    const  client  = useSelector(selectCurrentClientObject);

    const {
        data: userRoles,
        isLoading: userRolesIsLoading,
        isSuccess: userRolesIsSuccess,
        isError: userRolesIsError,
        error: userRolesError
    } = useGetCurrentUserRolesQuery()

    const editUserPassword = ()=>{

    }
        return (
            <div className="bg-gray-100 flex  flex-col">
                <div className="flex items-center justify-center bg-white p-1">
                  <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">System User profile</h1>
                  </div>
                <div className="">
                   <div className="md:flex justify-center mt- p-8">
                        <div className="bg-gray-200 shadow-sm pr-0 pl-0 pt-2 pb-0 rounded-sm">
                            <div className="flex justify-center md:pr-60 md:pl-60">
                                <UserCircleIcon className="h-20 text-gray-500" />
                            </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-semibold text-sm">Name</p>
                                    <p className="mr-20 p-2 text-sm uppercase">{user.fname} {user.lname}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-semibold text-sm">Email</p>
                                    <p className="mr-20 p-2 text-sm">{user.email}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-semibold text-sm">Phone No.</p>
                                    <p className="mr-20 p-2 text-sm">{user.phone}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-semibold text-sm">ID</p>
                                    <p className="mr-20 p-2 text-sm">{user.user_id}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-semibold text-sm">Business/Company</p>
                                    <p className="mr-20 p-2 text-sm">{user.name} {user.business_type}</p>
                                </div>
                                <div className="flex justify-between border-b border-gray-300">
                                    <p className="ml-20 p-2 font-semibold text-sm">Gender</p>
                                    <p className="mr-20 p-2 text-sm">{user.gender}</p>
                                </div>
                                <div className="flex flex-col justify-center mt-4">
                                    <p className="p-2 font-semibold text-sm text-gray-500 uppercase flex justify-center">User Roles</p>
                                    {
                                        (userRolesIsSuccess)?
                                            (userRoles.hasOwnProperty('success') === true && userRoles.data.length > 0)?
                                                <table className="w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-500 shadow-lg">
                                                        <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                        >
                                                            Role
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="flex justify-center px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                        >
                                                            Description
                                                        </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-gray-300 divide-y divide-gray-300">
                                                    { (userRoles)?userRoles.data.map(data => (
                                                            <tr key={data.id} className="hover:bg-gray-400">
                                                                <td className="px-6 py-1 whitespace-nowrap">
                                                                <div className="flex items-center">
                                                                    <div className="text-sm font-medium text-gray-900">{data.name}</div>
                                                                </div>
                                                                </td>
                                                                <td className="px-2 py-1 whitespace-nowrap text-sm ">
                                                                    <div className=''>{data.description}</div>
                                                                </td>
                                                            </tr>
                                                            ))
                                                            : ''
                                                        }
                                                    </tbody>
                                                </table>
                                            : <div>{userRoles.message}</div>
                                        : userRolesIsLoading
                                            ? <div>Loading...</div>
                                            : userRolesIsError
                                                ? <div>{userRolesError.data.error}</div>
                                                : ''
                                    }
                                </div>
                                <div className="flex justify-center mt-4 bg-blue-400 border-t border-gray-300 p-4">
                                    <div className="flex flex-col">
                                        <label onClick={editUserPassword} className="text-sm text-gray-700 hover:text-black cursor-pointer">Change password</label>
                                    </div>                           
                                 </div>
                        </div>
                   </div>
                </div>
            </div>
        )
}

export default CurrentUserProfileView;
