import { Link, Outlet } from "react-router-dom";
import { HomeIcon, MenuIcon, QrcodeIcon, ShieldExclamationIcon, UserCircleIcon, UsersIcon } from '@heroicons/react/solid';
import { Dialog, Menu} from "@headlessui/react";
import SideNavOptionHelper from "./SideNavOptionHelper";
import { useState } from "react";
import Logo from "../Views/Logo";
import { useLogoutMutation } from "../Features/usersSlice";
import { logOut } from "../Features/auth/authSlice";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import { ClockIcon, TicketIcon, TruckIcon } from "@heroicons/react/outline";

function UserLayout() {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(true)

  const [logout, {isLoading}] = useLogoutMutation();

  const logUserOut = async () =>{
    try {
      const resp = await logout().unwrap()
      dispatch(logOut())
  } catch (err) {
      if(err){
          swal("Error", err.message,"error")
          dispatch(logOut())
      }    
    }
  }

  return (
    <div className="flex bg-gray-100 min-h-screen">
      {/* Side Navigation */}

      {/* small screens */}
      <Dialog as="div" open={isOpen} onClose={() => setIsOpen(false)} className="fixed insert-0 z-40">
          <div className="flex flex-col md:hidden relative z-10 h-screen md:block w-72 bg-gray-100 border-r border-gray-200">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-2 right-2 flex items-center w-10 h-10 rounded-sm" 
              type="button" value="Close sidebar" >
              <MenuIcon className="h-6" />
            </button>
            <div className="flex justify-center pt-10 pb-4 px-6">
              <h1 className="text-xl font-bold text-yellow-500">KWAZSU</h1>
            </div>
            <div className="overflow-y-auto flex-1">  
              <div className="mb-10">
                <h3 className="mx-6 mb-2 text-xs text-gray-400 uppercase tracking-widest">
                  Main
                </h3>
                <Link to="/admin/home">
                  <SideNavOptionHelper Icon={HomeIcon} lable="Home" />
                </Link>
              </div>
            </div>
          </div>
        <Dialog.Overlay className="fixed insert-0 bg-gray-700 bg-opacity-50"></Dialog.Overlay>  
      </Dialog>
      {/* large screen */}
        <div className="hidden md:block w-64 bg-teal-800 border-r-4 mr-1 border-teal-800">
          <Logo />
          <div className="mb-10 space-y-10">
            <h3 className="mx-6 mb-4 text-xs text-gray-400 uppercase tracking-widest">
              Main
            </h3>
            <Link to="/user/users">
              <SideNavOptionHelper Icon={HomeIcon} lable="Home" />
            </Link>
            <Link to="/user/profile">
              <SideNavOptionHelper Icon={UserCircleIcon} lable="Profile" />
            </Link>
            <Link to="/user/client-management">
              <SideNavOptionHelper Icon={QrcodeIcon} lable="Manage Clients" />
            </Link>
            <Link to="/user/users">
              <SideNavOptionHelper Icon={UsersIcon} lable="System users" />
            </Link>
            <Link to="/user/agent-users">
              <SideNavOptionHelper Icon={UsersIcon} lable="Agent users" />
            </Link>
            <Link to="/user/commissions">
              <SideNavOptionHelper Icon={TicketIcon} lable="Commissions" />
            </Link>
            <Link to="/user/sytems-roles-management">
              <SideNavOptionHelper Icon={ShieldExclamationIcon} lable="System roles" />
            </Link>
            <Link to="/user/client-roles-management">
              <SideNavOptionHelper Icon={ShieldExclamationIcon} lable="Cleint user roles" />
            </Link>
            <Link to="/user/time-management">
              <SideNavOptionHelper Icon={ClockIcon} lable="Manage Time" />
            </Link>
            <Link to="/user/regions-management">
              <SideNavOptionHelper Icon={ClockIcon} lable="Manage Regions" />
            </Link>
            <Link to="/user/seats-management">
              <SideNavOptionHelper Icon={ClockIcon} lable="Manage Seats" />
            </Link>
            <Link to="/user/stations-management">
              <SideNavOptionHelper Icon={TruckIcon} lable="Manage Station" />
            </Link>
            <Link to="/user/purpose-of-travel-management">
              <SideNavOptionHelper Icon={TruckIcon} lable="Manage Travel" />
            </Link>
            <Link to="/user/routes-management">
              <SideNavOptionHelper Icon={TruckIcon} lable="Manage Routes" />
            </Link>
            <Link to="/user/front-end-images">
              <SideNavOptionHelper Icon={TruckIcon} lable="Manage Images" />
            </Link>
          </div>
        </div>
      {/* Header and Feed */}
      <div className="flex-1 sticky top-0 z-50">
        {/* Feed Header */}
        <div className="flex justify-between items-center py-1 px-6 bg-teal-700 space-x-6">
          <div className="flex items-center md:flex-1">
            <button
              onClick={() => setIsOpen(true)}
              className="mr-2 md:hidden flex flex-shrink-0 items-center w-8 h-8 rounded-sm" 
              type="button" value="Close sidebar" >
              <MenuIcon className="h-6" />
            </button>
          </div>
          <div className="md:hidden">
            <Logo />
          </div>
          <Menu as="div" className='relative'>
            <Menu.Button className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-orange-300 rounded-full">
              <UserCircleIcon className="h-10 w-10 round-full"/>
            </Menu.Button> 
            <Menu.Items className="absolute focus:outline-none right-0 bg-white rounded-md shadow-lg border w-32">
              <Menu.Item>
                <div className='flex justify-center block hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                  <Link to="/user/profile">
                    My profile
                  </Link>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div onClick={logUserOut} className='flex justify-center block hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                  Logout
                </div>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
        {/* Feed */}
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default UserLayout;
