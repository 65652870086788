import { ChevronLeftIcon, ChevronRightIcon, SearchIcon, UserCircleIcon } from '@heroicons/react/outline';
import LoadingSpinner from '../LoadingSpinner';
import { Link, useParams } from 'react-router-dom';
import Pagination from 'rc-pagination';
import { useState } from 'react';
import { useGetClientByIdQuery, useGetClientManagerUserSalesUsersQuery } from '../../Features/clientsSlice';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { Menu} from "@headlessui/react";
import { useSelector } from 'react-redux';
import {  ChartPieIcon, MenuIcon, TableIcon } from '@heroicons/react/solid';
import {  useGetUserProfileByIdQuery } from '../../Features/usersSlice';
import ClientAccountingHeaderView from './ClientAccountingHeaderView';

function ClientAccountingClientManagerUserSalesUsersView() {
    const defaultPagination = 100;
    const [search, setSearch] = useState(0)
    const [pager, setPager] = useState("")

    const { admin_user_id } = useParams();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const {
      data: client,
      isSuccess: isSuccessClient,
  } = useGetClientByIdQuery(client_id);

  const {
    data: clientManagerUser,
    isSuccess: isSuccessClientManagerUser,
} = useGetUserProfileByIdQuery(admin_user_id);

    const {
      data: users,
      isLoading,
      isSuccess,
      isError,
    } = useGetClientManagerUserSalesUsersQuery({client_id: client_id, admin_user_id: admin_user_id, paginate: defaultPagination, search: search, page: pager})

    const [input, setInput] = useState({ 
      search: ''
    })

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value})
    if(input.search != 0){
      setSearch(input.search)
    }else {

      setSearch(0)
    }
  }

    const onChange = (currentPage) => {
      setPager(currentPage)
    }

    return (
      <div className="text-gray-100">
          <ClientAccountingHeaderView />
          <div className="flex justify-between items-center bg-gray-50 p-0">
              {
                (isSuccessClientManagerUser)?
                  <div className='flex items-center'>
                      <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{clientManagerUser.data.team_name} station sales Team</h1>
                  </div>
                : null
              }
          </div>
        {
          (isSuccess)?
              <div>
                <div className="flex justify-end p-1 bg-gray-100 mr-2">
                        <form className="">
                          <div className="relative flex items-center text-gray-400 focus-within:text-gray-600">
                            <SearchIcon className="w-4 h-4 absolute ml-3 pointer-events-none" />
                            <input
                              type="text"
                              name="search"
                              value={input.search}
                              onChange={handleChange}
                              placeholder="Search route"
                              autoComplete="off"
                              aria-label="Search user"
                              className="pr-3 pl-10 px-3 py-1 bg-gray-300 text-sm placeholder-gray-500 text-black rounded-sm border-none outline-none"
                            />
                          </div>
                        </form>
                    </div>
                {
                  (users.success === true && users.data.data.length > 0)?
                    <table className="w-full divide-y divide-gray-200">
                      <thead className="bg-teal-600 text-gray-100 p-1">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                            Details
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                            Contact
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                            Gender
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className=" divide-y divide-gray-300 bg-gray-50">
                        { (isSuccess)? users.data.data.map(data => (
                          <tr key={data.id} className="hover:bg-gray-100">
                            <td className="px-6 py-1 whitespace-nowrap ">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  <UserCircleIcon className="h-8 text-gray-400 w-10 rounded-full"  />
                                </div>
                                <div className="ml-4">
                                    <div className="flex items-center text-xs space-x-2">
                                        <div className="font-bold text-gray-900">{data.lname}</div>
                                        <div className="font-bold text-gray-900">{data.fname}</div>
                                    </div>
                                  <div className="text-xs text-gray-500">{data.user_id}</div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-1  text-xs whitespace-nowrap">
                              <div className=" text-gray-900">{data.email}</div>
                              <div className=" text-gray-500">{data.phone}</div>
                            </td>
                            <td className="px-6 py-1  text-xs whitespace-nowrap">
                              <div className=" text-gray-900">{data.gender}</div>
                            </td>
                            <td className="px-6 py-1  text-xs whitespace-nowrap">
                              <div className=" text-gray-900">
                                {
                                  (data.deleted)?
                                    <div className='text-red-600'>Deleted</div>
                                  :
                                    <div className='text-green-400'>Active</div>
                                }
                              </div>
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap text-sm ">
                              <td className="px-1 py-0 whitespace-nowrap">
                                <Menu as="div" className=''>
                                  <Menu.Button className="focus:outline-none focus:ring-2 focus:ring-offset-2 text-gray-500 focus:ring-green-500 rounded-full">
                                    <MenuIcon className='h-6'/>
                                  </Menu.Button> 
                                  <Menu.Items className="absolute focus:outline-none right-28 bg-white rounded-md shadow-lg w-32">
                                  <Menu.Item>
                                      <Link to={`/accounting/sales-users-diary-sales/${data.id}`} className='flex justify-start border-b border-gray-300 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                      <div className="flex items-center justify-center text-gray-900 text-xs space-x-1">
                                        <TableIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                                        <p className="">Daily Report</p>
                                      </div>
                                      </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <Link to={`/accounting/sales-user-revenue-reports/${data.id}`} className='flex justify-start border-b border-gray-300 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                      <div className="flex items-center justify-center text-gray-900 text-xs space-x-1">
                                        <ChartPieIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                                        <p className="">Revenue</p>
                                      </div>
                                      </Link>
                                    </Menu.Item>
                                  </Menu.Items>
                                </Menu>
                              </td>
                            </td>
                          </tr>
                        ))
                        :
                        ""
                    }
                      </tbody>
                    </table>
                    :
                      <div>Data not available</div>
                    }
              </div>
            : isLoading?
                  <LoadingSpinner />
              : isError?
                  <div className="md:flex p-4">
                    <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                  </div>
              : null
            }
            <div className="flex justify-center p-4 mt-4">
            <div>
            {(isSuccess)?
              (users.success === true && users.data.data.length > defaultPagination)?
              
              <Pagination
                className="flex items-center space-x-3 text-blue-800 cursor-pointer"
                // activePage={this.state.activePage}

                defaultPageSize={defaultPagination}
                total={users.data.total}
                current={users.data.current_page}
                onChange={onChange}
                prevIcon={<ChevronLeftIcon className='h-7 text-white bg-blue-500 cursor-pointer hover:text-gray-300' />}
                nextIcon={<ChevronRightIcon className='h-7 text-white bg-blue-500 cursor-pointer hover:text-gray-300' />}
              />
              : null
              : null
            }
            </div>
            </div>
      </div>
)
}

export default ClientAccountingClientManagerUserSalesUsersView;
