import LoadingSpinner from '../LoadingSpinner';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { useGetCommissionRateQuery, useGetCurrentMonthCommissionQuery, useGetMonthlyCommissionSettlementsQuery, useGetPendingMonthlyCommissionSettlementsQuery, useGetPreviousMonthCommissionQuery } from '../../Features/commissionSlice';

function ManageCommissionsView({props}) {
    const navigate = useNavigate();

    const {
      data: rate,
      isLoading: isLoadingCommissionRate,
      isSuccess: isSuccessCommissionRate,
    } = useGetCommissionRateQuery();

    return (
      <div className="bg-gray-50 overflow-auto">
                  <div className="flex justify-between items-center bg-white">
              <div className='flex items-center'>
                <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Commission Management</h1>
              </div>
                <Menu as="div" className='relative z-10 mr-2 p-1'>
                  <Menu.Button className="flex justify-center bg-blue-400 hover:bg-blue-500 curosor-pointer w-20 rounded-md">
                    <DotsHorizontalIcon className='h-8 w-10 text-white' />
                  </Menu.Button> 
                  <Menu.Items className="absolute focus:outline-none right-0 bg-gray-100 rounded-md shadow-lg border mt-1 w-52">
                    <Menu.Item>
                      <div className='flex justify-center hover:bg-gray-200 cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-gray-200'>
                        <Link to={`/user/set-commission-rate`}>
                          Set rate
                        </Link>
                      </div>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
            </div>
        <div className="bg-gray-600 h-2/6 flex items-center justify-evenly uppercase text-xs">
          <div className="flex flex-col justify-center items-center text-white space-y-2">
          <div className="flex justify-center font-semibold uppercase text-lg mb-4">Total Transactions</div>
              <div className="text-lg text-white ">
                {
                  (isSuccessCommissionRate)? "ZMW "+ rate.data.rate: 'Null' 
                }
              </div>
            </div>
            <div className="flex flex-col justify-center items-center text-white space-y-2">
              <div className="flex justify-center font-semibold uppercase text-lg mb-4">Pending Balance</div>
              <div className="text-lg text-white ">
                {
                  (isSuccessCommissionRate)? "ZMW "+ rate.data.rate: 'Null' 
                }
              </div>
            </div>
            <div className="flex flex-col justify-center items-center text-white space-y-2">
            <div className="flex justify-center font-semibold uppercase text-lg mb-4">Rate</div>
              <div className="text-lg text-white">
                {
                  (isSuccessCommissionRate)? "ZMW "+ rate.data.rate: 'Null' 
                }
              </div>
            </div>
        </div>
        <div className='bg-gray-200 uppercase text-gray-500 flex justify-center font-semibold p-2'>
          Statistics
        </div>

      </div>
)
}

export default ManageCommissionsView;
