import { Route, Routes} from "react-router-dom";
import Layout from "./Components/Layout";
import RequireAuth from "./Components/RequireAuth";
import ClientUserLayout from "./Layouts/ClientUserLayout";
import UserLayout from "./Layouts/UserLayout";
import AddClientView from "./Views/App/AddClientView";
import AddRouteView from "./Views/App/AddRouteView";
import AddStationView from "./Views/App/AddStationView";
import ClientHomeView from "./Views/App/ClientHomeView";
import ClientProfileView from "./Views/App/ClientProfileView";
import CreateTimeView from "./Views/App/CreateTimeView";
import EditClientProfileView from "./Views/App/EditClientProfileView";
import EditClientRouteView from "./Views/App/EditClientRouteView";
import EditRouteView from "./Views/App/EditRouteView";
import EditStationView from "./Views/App/EditStationView";
import EditTimeView from "./Views/App/EditTimeView";
import ManageClientRoutesView from "./Views/App/ManageClientRoutesView";
import ManageClientsView from "./Views/App/ManageClientsView";
import ManageRoutesForClientSubscriptionView from "./Views/App/ManageRoutesForClientSubscriptionView";
import ManageRoutesView from "./Views/App/ManageRoutesView";
import ManageStationsView from "./Views/App/ManageStationsView";
import ManageTimeView from "./Views/App/ManageTimeView";
import ProfileView from "./Views/client/ProfileView";
import RouteEditView from "./Views/client/RouteEditView";
import RoutesManagementView from "./Views/client/RoutesManagementView";
import UserProfileView from "./Views/client/UserProfileView";
import LoginView from "./Views/LoginView";
import AddSystemRoleView from "./Views/user/AddSystemRoleView";
import AllUsersView from "./Views/user/AllUsersView";
import CurrentUserProfileView from "./Views/user/CurrentUserProfileView";
import EditSystemRoleView from "./Views/user/EditSystemRoleView";
import ManageClientRolesView from "./Views/user/ManageClientRolesView";
import ManageSystemRolesView from "./Views/user/ManageSystemRolesView";
import ManageUserClientRolesView from "./Views/user/ManageUserClientRolesView";
import ManageUserProfileView from "./Views/user/ManageUserProfileView";
import ManageUserRolesView from "./Views/user/ManageUserRolesView";
import UserProfileEditView from "./Views/user/UserProfileEditView";
import UserRegisterView from "./Views/UserRegisterView";
import ManageSeatsView from "./Views/App/ManageSeatsView";
import CreateSeatView from "./Views/App/CreateSeatView";
import EditSeatView from "./Views/App/EditSeatView";
import ManageClientSeatsProfileView from "./Views/client/ManageClientSeatsProfileView";
import CreateClientSeatingProfileView from "./Views/client/CreateClientSeatingProfileView";
import HomeView from "./Views/App/HomeView";
import ProvideTicketPurchaseInfoView from "./Views/App/ProvideTicketPurchaseInfoView";
import VerifyTicketView from "./Views/App/VerifyTicketView";
import ManageFrontEndImagesView from "./Views/App/ManageFrontEndImagesView";
import UploadFrontEndImageView from "./Views/App/UploadFrontEndImageView";
import ManageFrontEndImageView from "./Views/App/ManageFrontEndImageView";
import EditFrontEndImageView from "./Views/App/EditFrontEndImageView";
import ClientSeatingProfileManagementView from "./Views/App/ClientSeatingProfileManagementView";
import ManagePerformanceReportingView from "./Views/client/ManagePerformanceReportingView";
import OperatorSeatingStatusView from "./Views/App/OperatorSeatingStatusView";
import CheckAvailableSeatsForRouteView from "./Views/App/CheckAvailableSeatsForRouteView";
import ReserveSeatView from "./Views/App/ReserveSeatView";
import PostponTicketView from "./Views/App/PostponTicketView";
import AllAgentUsersView from "./Views/user/AllAgentUsersView";
import AgentUserRegisterView from "./Views/user/AgentUserRegisterView";
import ManageAgentUserProfileView from "./Views/user/ManageAgentUserProfileView";
import AgentUserProfileEditView from "./Views/user/AgentUserProfileEditView";
import IncreamentRoutesChargeView from "./Views/App/IncreamentRoutesChargeView";
import DecreamentRoutesChargeView from "./Views/App/DecreamentRoutesChargeView";
import ClientRouteLogsView from "./Views/App/ClientRouteLogsView";
import EditCommissionRateView from "./Views/user/EditCommissionRateView";
import ClientSalesTransactionsStatementsView from "./Views/client/ClientSalesTransactionsStatementsView";
import ManageCommissionsView from "./Views/user/ManageCommissionsView";
import ManageSystemRoutesForClientSubscriptionView from "./Views/client/ManageSystemRoutesForClientSubscriptionView";
import PublicAuth from "./Components/PublicAuth";
import UnauthorizedView from "./Views/UnauthorizedView";
import UndefinedRouteView from "./Views/UndefinedRouteView";
import UserManageSystemRoutesForClientSubscriptionView from "./Views/App/UserManageSystemRoutesForClientSubscriptionView";
import ClientDetailsView from "./Views/App/ClientDetailsView";
import UserCreateClientSeatingProfileView from "./Views/App/UserCreateClientSeatingProfileView";
import ManageClientRoutePerformanceReportingView from "./Views/client/ManageClientRoutePerformanceReportingView";
import CreateRegionView from "./Views/App/CreateRegionView";
import EditRegionView from "./Views/App/EditRegionView";
import ManageRegionView from "./Views/App/ManageRegionView";
import ClientSalesStatisticsView from "./Views/client/ClientSalesStatisticsView";
import AgentUserSalesView from "./Views/client/AgentUserSalesView";
import ManagePurposeOfTravelView from "./Views/App/ManagePurposeOfTravelView";
import CreatePurposeOfTravelView from "./Views/App/CreatePurposeOfTravelView";
import EditPurposeOfTravelView from "./Views/App/EditPurposeOfTravelView";
import ClientCustomerMotiveStatisticsView from "./Views/client/ClientCustomerMotiveStatisticsView";
import ClientBulkSMSReportView from "./Views/client/ClientBulkSMSReportView";
import ManageClientBulkSMSView from "./Views/App/ManageClientBulkSMSView";
import CreateClientBulkSMSTransactionView from "./Views/App/CreateClientBulkSMSTransactionView";
import SalesUserProfileEditView from "./Views/client/SalesUserProfileEditView";
import SalesUserRegisterView from "./Views/client/SalesUserRegisterView";
import ClientAdminUsersView from "./Views/client/ClientAdminUsersView";
import AdminUserRegisterView from "./Views/client/AdminUserRegisterView";
import AdminUserProfileEditView from "./Views/client/AdminUserProfileEditView";
import ClientSalesUsersView from "./Views/client/ClientSalesUsersView";
import ClientManagerUserLayout from "./Layouts/ClientManagerUserLayout";
import ClientManagerHomeView from "./Views/client_manager/ClientManagerHomeView";
import ClientManagerUserProfileView from "./Views/client_manager/ClientManagerUserProfileView";
import ManageClientTicketExtentiontionView from "./Views/client/ManageClientTicketExtentiontionView";
import CreateClientTicketExtentionView from "./Views/client/CreateClientTicketExtentionView";
import EditClientTicketExtentionView from "./Views/client/EditClientTicketExtentionView";
import ManageSalesReportingView from "./Views/client/ManageSalesReportingView";
import RelashionshipStatisticsView from "./Views/client/RelashionshipStatisticsView";
import ClientSalesUserRevenueSummaryReportingView from "./Views/client/ClientSalesUserRevenueSummaryReportingView";
import ClientManagerUserSalesUsersView from "./Views/client/ClientManagerUserSalesUsersView";
import ClientManagerTeamRevenueReportView from "./Views/client/ClientManagerTeamRevenueReportView";
import TeamsRevenueReportView from "./Views/client/TeamsRevenueReportView";
import AccountingUserRegisterView from "./Views/client/AccountingUserRegisterView";
import MarketingUserRegisterView from "./Views/client/MarketingUserRegisterView";
import AccountingUserProfileEditView from "./Views/client/AccountingUserProfileEditView";
import MarketingUserProfileEditView from "./Views/client/MarketingUserProfileEditView";
import ClientAccountingUsersView from "./Views/client/ClientAccountingUsersView";
import ClientMarketingUsersView from "./Views/client/ClientMarketingUsersView";
import ClientAccountingHomeView from "./Views/client_accounting/ClientAccountingHomeView";
import ClientAccountingUserLayout from "./Layouts/ClientAccountingUserLayout";
import ClientMarketingUserLayout from "./Layouts/ClientMarketingUserLayout";
import ClientSalesUserSalesReportSummaryView from "./Views/client/ClientSalesUserSalesReportSummaryView";
import ClientManagerUserTeamSalesReportSummaryView from "./Views/client/ClientManagerUserTeamSalesReportSummaryView";
import TeamsSalesReportView from "./Views/client/TeamsSalesReportView";
import IncreamentClientRoutesChargeView from "./Views/client/IncreamentClientRoutesChargeView";
import DecreamentClientRoutesChargeView from "./Views/client/DecreamentClientRoutesChargeView";
import ClientRouteSalesReportView from "./Views/client/ClientRouteSalesReportView";
import AccountingClientRevenueReportingView from "./Views/client_accounting/AccountingClientRevenueReportingView";
import ClientAccountingTeamsRevenueReportView from "./Views/client_accounting/ClientAccountingTeamsRevenueReportView";
import ClientAccountingManagerUsersView from "./Views/client_accounting/ClientAccountingManagerUsersView";
import ClientAccountingClientManagerTeamRevenueReportView from "./Views/client_accounting/ClientAccountingClientManagerTeamRevenueReportView";
import ClientAccountingClientManagerUserSalesUsersView from "./Views/client_accounting/ClientAccountingClientManagerUserSalesUsersView";
import ClientAccoutingSalesUserDiarySalesView from "./Views/client_accounting/ClientAccoutingSalesUserDiarySalesView";
import ClientAccountingClientSalesUserRevenueSummaryReportingView from "./Views/client_accounting/ClientAccountingClientSalesUserRevenueSummaryReportingView";
import ClientSalesHomeView from "./Views/client_marketing/ClientSalesHomeView";
import ClientSalesReportingView from "./Views/client_marketing/ClientSalesReportingView";
import ClientSalesTeamsSalesReportView from "./Views/client_marketing/ClientSalesTeamsSalesReportView";
import ClientSalesManagerUsersView from "./Views/client_marketing/ClientSalesManagerUsersView";
import ClientSalesManagerUserTeamSalesReportView from "./Views/client_marketing/ClientSalesManagerUserTeamSalesReportView";
import ClientSalesClientManagerUserSalesUsersView from "./Views/client_marketing/ClientSalesClientManagerUserSalesUsersView";
import ClientSalesSalesUserDiarySalesView from "./Views/client_marketing/ClientSalesSalesUserDiarySalesView";
import ClientSalesSalesUserSalesReportView from "./Views/client_marketing/ClientSalesSalesUserSalesReportView";
import ClientManagerSalesUsersView from "./Views/client_manager/ClientManagerSalesUsersView";
import ClientManagerSalesUserDailySalesView from "./Views/client_manager/ClientManagerSalesUserDailySalesView";
import ClientMangerSalesUserRevenueReportView from "./Views/client_manager/ClientMangerSalesUserRevenueReportView";
import ClientManagerSalesUserSalesReportView from "./Views/client_manager/ClientManagerSalesUserSalesReportView";
import ClientManagerSalesUsersRevenueReportView from "./Views/client_manager/ClientManagerSalesUsersRevenueReportView";
import EditClientRefundPolicyView from "./Views/client/EditClientRefundPolicyView";
import ClientSalesClientCustomerLocationStatisticsView from "./Views/client_marketing/ClientSalesClientCustomerLocationStatisticsView";
import ClientSalesClientCustomerMotiveStatisticsView from "./Views/client_marketing/ClientSalesClientCustomerMotiveStatisticsView";
import ClientSalesClientCustomerTravelRelashionshipStatisticsView from "./Views/client_marketing/ClientSalesClientCustomerTravelRelashionshipStatisticsView";
import ClientSalesManageClientTicketExtentiontionsView from "./Views/client_marketing/ClientSalesManageClientTicketExtentiontionsView";
import ClientSalesCreateClientTicketExtentionView from "./Views/client_marketing/ClientSalesCreateClientTicketExtentionView";
import ClientSalesEditClientTicketExtentionView from "./Views/client_marketing/ClientSalesEditClientTicketExtentionView";
import ClientCustomerRetentionAnalysisView from "./Views/client/ClientCustomerRetentionAnalysisView";
import CheckoutTicketInfoView from "./Views/App/CheckoutTicketInfoView";
import ClientDataAnalystManagerUsersView from "./Views/client/ClientDataAnalystManagerUsersView";
import ClientDataAnalystManagerUserRegisterView from "./Views/client/ClientDataAnalystManagerUserRegisterView";
import ClientDataAnalystManagerUserProfileEditView from "./Views/client/ClientDataAnalystManagerUserProfileEditView";
import ClientDataAnalystManagerHomeView from "./Views/data_analyst_manager/ClientDataAnalystManagerHomeView";
import ClientDataAnalysisSalesUsersSummaryReportsView from "./Views/data_analyst_manager/ClientDataAnalysisSalesUsersSummaryReportsView";
import ClientDataAnalysisStationsSummaryReportsView from "./Views/data_analyst_manager/ClientDataAnalysisStationsSummaryReportsView";
import ClientDataAnalystTotalSalesSummaryReportingView from "./Views/data_analyst_manager/ClientDataAnalystTotalSalesSummaryReportingView";
import ClientDataAnalysisStationManagerUsersView from "./Views/data_analyst_manager/ClientDataAnalysisStationManagerUsersView";
import ClientDataAnalystStationManagerTeamView from "./Views/data_analyst_manager/ClientDataAnalystStationManagerTeamView";
import ClientDataAnalystManagerSalesUserDailySalesView from "./Views/data_analyst_manager/ClientDataAnalystManagerSalesUserDailySalesView";
import ClientDataAnalystManagerSalesUserSalesReportView from "./Views/data_analyst_manager/ClientDataAnalystManagerSalesUserSalesReportView";
import ClientDataAnalystManagerSalesUsersRevenueReportView from "./Views/data_analyst_manager/ClientDataAnalystManagerSalesUsersRevenueReportView";
import ClientDataAnalystClientManagerTeamRevenueReportView from "./Views/data_analyst_manager/ClientDataAnalystClientManagerTeamRevenueReportView";
import ClientDataAnalystManagerUserTeamSalesReportView from "./Views/data_analyst_manager/ClientDataAnalystManagerUserTeamSalesReportView";
import ClientManagerTotalTeamSalesReportSummaryView from "./Views/client_manager/ClientManagerTotalTeamSalesReportSummaryView";
import ClientAccountingSalesUsersSummaryReportsView from "./Views/client_accounting/ClientAccountingSalesUsersSummaryReportsView";
import ClientMobileSalesUsersView from "./Views/client/ClientMobileSalesUsersView";
import MobileSalesUserRegisterView from "./Views/client/MobileSalesUserRegisterView";
import ClientManagerMobileSalesUsersView from "./Views/client_manager/ClientManagerMobileSalesUsersView";
import ClientManagerMobileSalesUsersSummaryReportView from "./Views/client_manager/ClientManagerMobileSalesUsersSummaryReportView";


function App() {

  return (
    <div>
      <Routes>
              {/* public route */}
          <Route element={ <PublicAuth /> }>
            <Route path="/" element={ <Layout />}>
              <Route path="/" element={ <HomeView /> } />
              <Route path="/operator/:operator_id" element={ <ProvideTicketPurchaseInfoView /> } />
              <Route path="/customer-ticket-information/:name/:phone/:charge/:route/:date/:time/:seat_number/:operator/:ticket_number/:logo_url" element={ <CheckoutTicketInfoView /> } />
              <Route path="/ticket-verication" element={ <VerifyTicketView /> } />
              <Route path="/select-operator" element={ <OperatorSeatingStatusView /> } />
              <Route path="/operator-routes-seating-status/:operator_id" element={ <CheckAvailableSeatsForRouteView /> } />
              <Route path="/available-seats/:client_id/:client_route_id/:time_id/:date/:time_label" element={ <ReserveSeatView /> } />
              <Route path="/user/register" element={ <UserRegisterView /> } />
              <Route path="/postpon-customer-ticket" element={ <PostponTicketView /> } />
              <Route path="/login" element={ <LoginView  /> } />
            </Route>
          </Route>

               {/* User Private route */}
            <Route element={ <RequireAuth allowedRoles={["Admin"]} /> }>
              <Route path="/user" element={ <UserLayout  /> } >
                <Route path="/user/users" element={ <AllUsersView  /> } />
                <Route path="/user/agent-users" element={ <AllAgentUsersView  /> } />
                <Route path="/user/register-agent-user" element={ <AgentUserRegisterView  /> } />
                <Route path="/user/profile" element={ <CurrentUserProfileView  /> } />
                <Route path="/user/manage-user-profile/:user_id" element={ <ManageUserProfileView  /> } />
                <Route path="/user/manage-agent-user-profile/:user_id" element={ <ManageAgentUserProfileView  /> } />
                <Route path="/user/manage-user/roles/:user_id" element={ <ManageUserRolesView  /> } />
                <Route path="/user/user-profile-edit/:user_id" element={ <UserProfileEditView  /> } />
                <Route path="/user/agent-user-profile-edit/:user_id" element={ <AgentUserProfileEditView  /> } />
                <Route path="/user/sytems-roles-management" element={ <ManageSystemRolesView  /> } />
                <Route path="/user/edit-sytems-role/:role_id" element={ <EditSystemRoleView  /> } />
                <Route path="/user/add-sytems-role" element={ <AddSystemRoleView  /> } />
                <Route path="/user/client-roles-management" element={ <ManageClientRolesView  /> } />
                <Route path="/user/manage-user-client-roles/user-client-roles/:user_id" element={ <ManageUserClientRolesView  /> } />
                {/* App Stations */}
                <Route path="/user/stations-management" element={ <ManageStationsView  /> } />
                <Route path="/user/add-new-station" element={ <AddStationView  /> } />
                <Route path="/user/edit-station/:station_id" element={ <EditStationView  /> } />
                {/* App Routes */}
                <Route path="/user/routes-management" element={ <ManageRoutesView  /> } />
                <Route path="/user/add-new-route" element={ <AddRouteView  /> } />
                <Route path="/user/edit-route/:route_id" element={ <EditRouteView  /> } />
                {/* App region */}
                <Route path="/user/regions-management" element={ <ManageRegionView  /> } />
                <Route path="/user/create-region" element={ <CreateRegionView /> } />
                <Route path="/user/edit-region/:region_id" element={ <EditRegionView  /> } />
                {/* App time */}
                <Route path="/user/time-management" element={ <ManageTimeView  /> } />
                <Route path="/user/create-new-time" element={ <CreateTimeView  /> } />
                <Route path="/user/edit-time/:time_id" element={ <EditTimeView  /> } />
                {/* App purpose of travel managent */}
                <Route path="/user/purpose-of-travel-management" element={ <ManagePurposeOfTravelView  /> } />
                <Route path="/user/create-new-purpose" element={ <CreatePurposeOfTravelView  /> } />
                <Route path="/user/edit-purpose/:purpose_id" element={ <EditPurposeOfTravelView  /> } />
                {/* App Seats */}
                <Route path="/user/seats-management" element={ <ManageSeatsView  /> } />
                <Route path="/user/create-new-seat" element={ <CreateSeatView  /> } />
                <Route path="/user/edit-seat/:seat_id" element={ <EditSeatView  /> } />
                {/* App Manage clients */}
                <Route path="/user/client-management" element={ <ManageClientsView  /> } />
                <Route path="/user/add-client" element={ <AddClientView  /> } />
                <Route path="/user/client-profile/:client_id" element={ <ClientProfileView  /> } />
                <Route path="/user/client-details/:client_id" element={ <ClientDetailsView  /> } />
                <Route path="/user/edit-client-profile/:client_id" element={ <EditClientProfileView  /> } />
                <Route path="/user/client-routes-management/:client_id" element={ <ManageClientRoutesView  /> } />
                <Route path="/user/manage-routes-for-client-subscription/:client_id" element={ <UserManageSystemRoutesForClientSubscriptionView  /> } />
                <Route path="/user/manage-routes-for-client-subscription/:client_id" element={ <ManageRoutesForClientSubscriptionView  /> } />
                <Route path="/user/edit-client-route/:client_id/:route_id/:client_route_id" element={ <EditClientRouteView  /> } />
                <Route path="/user/client-route-logs/:client_route_id" element={ <ClientRouteLogsView  /> } />
                <Route path="/user/client-seating-profile-management/:client_id" element={ <ClientSeatingProfileManagementView  /> } />
                <Route path="/user/create-client-seating-profile/:client_id" element={ <UserCreateClientSeatingProfileView  /> } />
                {/* App Manage client routes increment or decrement charge */}
                <Route path="/user/increment-routes-charge/:client_id" element={ <IncreamentRoutesChargeView  /> } />
                <Route path="/user/decrement-routes-charge/:client_id" element={ <DecreamentRoutesChargeView  /> } />
                {/* App Manage commissions */}
                <Route path="/user/commissions" element={ <ManageCommissionsView  /> } />
                <Route path="/user/set-commission-rate" element={ <EditCommissionRateView  /> } />
                {/* App Manage client bulk sms */}
                <Route path="/user/client-bulk-sms/:client_id" element={ <ManageClientBulkSMSView  /> } />
                <Route path="/user/create-bulk-transaction/:client_id" element={ <CreateClientBulkSMSTransactionView  /> } />
                {/* App Manage front end images */}
                <Route path="/user/front-end-images" element={ <ManageFrontEndImagesView  /> } />
                <Route path="/user/upload-front-end-image" element={ <UploadFrontEndImageView  /> } />
                <Route path="/user/manage-front-end-image/:image_id" element={ <ManageFrontEndImageView  /> } />
                <Route path="/user/edit-front-end-image/:image_id" element={ <EditFrontEndImageView  /> } />
              </Route>  
            </Route>
            
               {/* Client User Private route */}
            <Route element={ <RequireAuth allowedRoles={["Client"]} /> }>
              <Route path="/client" element={ <ClientUserLayout  /> } >
                <Route path="/client" element={ <ClientHomeView  /> } />
                <Route path="/client/user-profile" element={ <UserProfileView  /> } />
                <Route path="/client/client-profile" element={ <ProfileView  /> } />
                <Route path="/client/routes-management" element={ <RoutesManagementView  /> } />
                <Route path="/client/increament-routes-charge" element={ <IncreamentClientRoutesChargeView  /> } />
                <Route path="/client/decreament-routes-charge" element={ <DecreamentClientRoutesChargeView  /> } />
                <Route path="/client/manage-routes-for-client-subscription" element={ <ManageSystemRoutesForClientSubscriptionView  /> } />
                <Route path="/client/edit-client-route/:route_id/:client_route_id" element={ <RouteEditView  /> } />
                <Route path="/client/client-seating-profile-management" element={ <ManageClientSeatsProfileView  /> } />
                <Route path="/client/create-client-seating-profile" element={ <CreateClientSeatingProfileView  /> } />
                <Route path="/client/business-performance-reporting" element={ <ManagePerformanceReportingView  /> } />
                <Route path="/client/business-sales-report" element={ <ManageSalesReportingView  /> } />
                <Route path="/client/route-performance-reporting/:client_route_id" element={ <ManageClientRoutePerformanceReportingView  /> } />
                <Route path="/client/route-sales-reporting/:client_route_id" element={ <ClientRouteSalesReportView  /> } />
                <Route path="/client/client-sales-transactions-statement" element={ <ClientSalesTransactionsStatementsView  /> } />
                <Route path="/client/teams-revenue-reports" element={ <TeamsRevenueReportView  /> } />
                <Route path="/client/teams-sales-reports" element={ <TeamsSalesReportView  /> } />
                <Route path="/client/client-sales-statistics" element={ <ClientSalesStatisticsView  /> } />
                <Route path="/client/client-customer-motive-statistics" element={ <ClientCustomerMotiveStatisticsView  /> } />
                <Route path="/client/client-customer-travel-relashionship-statistics" element={ <RelashionshipStatisticsView  /> } />
                <Route path="/client/client-stationed-agent-users" element={ <ClientSalesUsersView  /> } />
                <Route path="/client/client-mobile-agent-users" element={ <ClientMobileSalesUsersView  /> } />
                <Route path="/client/client-agent-users-sales/:agent_user_id" element={ <AgentUserSalesView  /> } />
                <Route path="/client/client-bulk-sms" element={ <ClientBulkSMSReportView  /> } />
                <Route path="/client/sales-user-profile-edit/:user_id" element={ <SalesUserProfileEditView  /> } />
                <Route path="/client/sales-user-revenue-summary-reports/:user_id" element={ <ClientSalesUserRevenueSummaryReportingView  /> } />
                <Route path="/client/sales-user-sales-report-summary/:user_id" element={ <ClientSalesUserSalesReportSummaryView  /> } />
                <Route path="/client/sales-manage-team-sales-report-summary/:user_id" element={ <ClientManagerUserTeamSalesReportSummaryView  /> } />
                <Route path="/client/admin-user-team-revenue-report/:admin_user_id" element={ <ClientManagerTeamRevenueReportView  /> } />
                <Route path="/client/stationed-sales-user-registration" element={ <SalesUserRegisterView  /> } />
                <Route path="/client/mobile-sales-user-registration" element={ <MobileSalesUserRegisterView  /> } />
                <Route path="/client/admin-users" element={ <ClientAdminUsersView  /> } />
                <Route path="/client/admin-user-registration" element={ <AdminUserRegisterView  /> } />
                <Route path="/client/admin-user-team/:admin_user_id" element={ <ClientManagerUserSalesUsersView  /> } />
                <Route path="/client/admin-user-profile-edit/:user_id" element={ <AdminUserProfileEditView  /> } />
                <Route path="/client/client-manage-ticket-extentions" element={ <ManageClientTicketExtentiontionView  /> } />
                <Route path="/client/client-create-ticket-extention" element={ <CreateClientTicketExtentionView  /> } />
                <Route path="/client/client-edit-ticket-extention/:extention_id" element={ <EditClientTicketExtentionView  /> } />
                <Route path="/client/customer-retention-analysis" element={ <ClientCustomerRetentionAnalysisView  /> } />
                <Route path="/client/edit-refund-policy" element={ <EditClientRefundPolicyView  /> } />
                {/* Client User management routes */}
                <Route path="/client/accounting-user-registration" element={ <AccountingUserRegisterView  /> } />
                <Route path="/client/marketing-user-registration" element={ <MarketingUserRegisterView  /> } />
                <Route path="/client/accounting-user-profile-edit/:user_id" element={ <AccountingUserProfileEditView  /> } />
                <Route path="/client/marketing-user-profile-edit/:user_id" element={ <MarketingUserProfileEditView  /> } />
                <Route path="/client/accounting-users" element={ <ClientAccountingUsersView  /> } />
                <Route path="/client/marketing-users" element={ <ClientMarketingUsersView  /> } />
                <Route path="/client/data-analyst-manager-users" element={ <ClientDataAnalystManagerUsersView  /> } />
                <Route path="/client/data-analyst-user-registration" element={ <ClientDataAnalystManagerUserRegisterView  /> } />
                <Route path="/client/data-analyst-manager-user-profile-edit/:user_id" element={ <ClientDataAnalystManagerUserProfileEditView  /> } />
              </Route>  
            </Route>
            
            {/* Client data analyst manager User Private route */}
            <Route element={ <RequireAuth allowedRoles={["Client_Data_Analyst_Manager"]} /> }>
              <Route path="/data-analysis" element={ <ClientManagerUserLayout  /> } >
                <Route path="/data-analysis" element={ <ClientDataAnalystManagerHomeView  /> } />
                <Route path="/data-analysis/sales-agents-summary-reports" element={ <ClientDataAnalysisSalesUsersSummaryReportsView  /> } />
                <Route path="/data-analysis/stations-summary-reports" element={ <ClientDataAnalysisStationsSummaryReportsView  /> } />
                <Route path="/data-analysis/total-sales-summary-reports" element={ <ClientDataAnalystTotalSalesSummaryReportingView  /> } />
                <Route path="/data-analysis/station-mananger-users" element={ <ClientDataAnalysisStationManagerUsersView  /> } />
                <Route path="/data-analysis/station-sales-team/:admin_user_id" element={ <ClientDataAnalystStationManagerTeamView  /> } />
                <Route path="/data-analysis/sales-user-dialy-sales/:agent_user_id" element={ <ClientDataAnalystManagerSalesUserDailySalesView  /> } />
                <Route path="/data-analysis/sales-user-sales-reports/:user_id" element={ <ClientDataAnalystManagerSalesUserSalesReportView  /> } />
                <Route path="/data-analysis/sales-user-revenue-reports/:user_id" element={ <ClientDataAnalystManagerSalesUsersRevenueReportView  /> } />
                <Route path="/data-analysis/station-team-revenue-reports/:admin_user_id" element={ <ClientDataAnalystClientManagerTeamRevenueReportView  /> } />
                <Route path="/data-analysis/station-team-sales-reports/:admin_user_id" element={ <ClientDataAnalystManagerUserTeamSalesReportView  /> } />
              </Route>  
            </Route>

            {/* Client manager User Private route */}
            <Route element={ <RequireAuth allowedRoles={["Client_Manager"]} /> }>
              <Route path="/manager" element={ <ClientManagerUserLayout  /> } >
                <Route path="/manager" element={ <ClientManagerHomeView  /> } />
                <Route path="/manager/user-profile" element={ <ClientManagerUserProfileView  /> } />
                <Route path="/manager/team-sales-users" element={ <ClientManagerSalesUsersView  /> } />
                <Route path="/manager/mobile-sales-users" element={ <ClientManagerMobileSalesUsersView  /> } />
                <Route path="/manager/sales-user-dialy-sales/:agent_user_id" element={ <ClientManagerSalesUserDailySalesView  /> } />
                <Route path="/manager/sales-user-revenue-reports/:user_id" element={ <ClientMangerSalesUserRevenueReportView  /> } />
                <Route path="/manager/sales-user-sales-reports/:user_id" element={ <ClientManagerSalesUserSalesReportView  /> } />
                <Route path="/manager/station-total-sales-reports-summary" element={ <ClientManagerTotalTeamSalesReportSummaryView  /> } />
                <Route path="/manager/team-sales-team-reports-summary" element={ <ClientManagerSalesUsersRevenueReportView  /> } />
                <Route path="/manager/mobile-sales-team-reports-summary" element={ <ClientManagerMobileSalesUsersSummaryReportView  /> } />
              </Route>  
            </Route>
            
            {/* Client accounting User Private route */}
            <Route element={ <RequireAuth allowedRoles={["Client_Accounting"]} /> }>
              <Route path="/accounting" element={ <ClientAccountingUserLayout  /> } >
                <Route path="/accounting" element={ <ClientAccountingHomeView  /> } />
                <Route path="/accounting/client-revenue-reporting" element={ <AccountingClientRevenueReportingView  /> } />
                <Route path="/accounting/teams-revenue-reporting" element={ <ClientAccountingTeamsRevenueReportView  /> } />
                <Route path="/accounting/manager-users" element={ <ClientAccountingManagerUsersView  /> } />
                <Route path="/accounting/manager-user-team-revenue-reports/:admin_user_id" element={ <ClientAccountingClientManagerTeamRevenueReportView  /> } />
                <Route path="/accounting/manager-user-team/:admin_user_id" element={ <ClientAccountingClientManagerUserSalesUsersView  /> } />   
                <Route path="/accounting/sales-users-diary-sales/:agent_user_id" element={ <ClientAccoutingSalesUserDiarySalesView  /> } />   
                <Route path="/accounting/sales-user-revenue-reports/:user_id" element={ <ClientAccountingClientSalesUserRevenueSummaryReportingView  /> } /> 
                <Route path="/accounting/sales-team-reports-summary" element={ <ClientAccountingSalesUsersSummaryReportsView  /> } /> 
              </Route>  
            </Route>
            
            {/* Client marketing User Private route */}
            <Route element={ <RequireAuth allowedRoles={["Client_Promotor"]} /> }>
              <Route path="/sales" element={ <ClientMarketingUserLayout  /> } >
                <Route path="/sales" element={ <ClientSalesHomeView  /> } />
                <Route path="/sales/client-sales-reporting" element={ <ClientSalesReportingView  /> } />
                <Route path="/sales/teams-sales-reporting" element={ <ClientSalesTeamsSalesReportView  /> } />
                <Route path="/sales/manager-users" element={ <ClientSalesManagerUsersView  /> } />
                <Route path="/sales/manager-user-team-sales-reports/:user_id" element={ <ClientSalesManagerUserTeamSalesReportView /> } />
                <Route path="/sales/manager-user-team/:admin_user_id" element={ <ClientSalesClientManagerUserSalesUsersView  /> } />
                <Route path="/sales/sales-users-diary-sales/:agent_user_id" element={ <ClientSalesSalesUserDiarySalesView  /> } /> 
                <Route path="/sales/sales-user-revenue-reports/:user_id" element={ <ClientSalesSalesUserSalesReportView  /> } />
                <Route path="/sales/customer-location-statistics" element={ <ClientSalesClientCustomerLocationStatisticsView  /> } /> 
                <Route path="/sales/customer-motive-statistics" element={ <ClientSalesClientCustomerMotiveStatisticsView  /> } /> 
                <Route path="/sales/customer-travel-motive-relationship-statistics" element={ <ClientSalesClientCustomerTravelRelashionshipStatisticsView  /> } /> 
                <Route path="/sales/manage-ticket-extentions" element={ <ClientSalesManageClientTicketExtentiontionsView  /> } /> 
                <Route path="/sales/create-ticket-extentions" element={ <ClientSalesCreateClientTicketExtentionView  /> } /> 
                <Route path="/sales/edit-ticket-extentions/:extention_id" element={ <ClientSalesEditClientTicketExtentionView  /> } /> 
              </Route>  
            </Route>

            {/* unauthorizede route */}
            <Route path="/authentication/unauthorized" element={ <UnauthorizedView  /> } />

            {/* undefined routes */}
            <Route path="*" element={ <UndefinedRouteView  /> } />
      </Routes>
    </div>
  );
}

export default App;
