import { apiSlice } from "../app/api/apiSlice"


export const smsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendSMSMessage: builder.mutation({
            query: (input) => ({
                mode: 'no-cors',
                url: `http://www.probasesms.com/api/json/multi/res/bulk/sms`,
                method: 'POST',
                body: input 
            }),
        }),
    })
})

export const {
    useSendSMSMessageMutation,
} = apiSlice

