import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import {useGetSeatingProfileSeatsByClientAndSeatingProfileIdsQuery } from '../../Features/seatsSlice';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';

function ClientSeatingProfileManagementView() {
    const navigate = useNavigate();
    const { client_id } = useParams();  

    const {
        data: client,
        isSuccess: isSuccessClient,
    } = useGetClientByIdQuery(client_id);

    const {
        data: clientSeatingProfileSeats,
        isSuccess,
        isLoading,
        isError,
        error
      } = useGetSeatingProfileSeatsByClientAndSeatingProfileIdsQuery({client_id: client_id})

    const back = ()=> navigate(-1);

    return   (
                <div className="bg-gray-100">
                    {
                        (isSuccessClient)?
                            <div className="flex justify-center items-center bg-white">
                                <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">{client.data.name} {client.data.business_type} Seats management</h1>
                            </div>
                         : null
                     }
                    {
                        (isSuccess)?
                            <div class="mt-10 sm:mt-0  md:pt-4">
                                <div class="md:grid md:grid-cols-2 md:pr-60 md:pl-60 lg:pr-60 lg:pl-60 mt-10">
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <div class=" shadow overflow-hidden rounded-t-md">
                                                <div class="col-span-6 p-4  bg-gray-700 uppercase">
                                                    <div className='flex justify-center'>
                                                        <h1 className='uppercase font-semibold text-sm text-white'>Profile</h1>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="md:col-span-2 bg-gray-100">
                                        <div className="flex p-2 mt-2 justify-around flex-wrap">
                                            { (clientSeatingProfileSeats)?clientSeatingProfileSeats.data.map(data => (
                                                    <div className='flex justify-center bg-gray-200 w-14 mt-2 rounded-md border border-gray-500'>
                                                        <p className='text-gray-900'>{data.number}</p>
                                                    </div>
                                                ))
                                                    : ''
                                            }
                                        </div>
                                        <div className="flex justify-center mt-1 space-x-4 bg-gray-200 border-t border-gray-400 p-4">
                                            <div onClick={back} className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label  className="flex justify-center space-x-2 items-center text-sm bg-gray-500 hover:bg-gray-600 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Back
                                                    </div>
                                                </label>
                                            </div>  
                                            <div className="flex flex-col items-center  w-28 rounded-md">
                                                <label className="flex justify-center space-x-2 items-center text-sm bg-blue-400 hover:bg-blue-500 text-white cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <Link to={`/user/create-client-seating-profile/${client_id}`} className=''>
                                                        <div className=''>
                                                            Edit
                                                        </div>
                                                    </Link>
                                                </label>
                                            </div>                          
                                    </div>
                                    </div>
                                </div>
                            </div>
                        : isLoading?
                            <LoadingSpinner />
                        : isError?
                            <div className="md:flex flex-col p-4">
                                <p className="text-blue-500 italic border-b border-black flex flex-1">Empty</p>
                                <Link to={`/user/create-client-seating-profile/${client_id}`} className='flex justify-center p-8'>
                                    <h1 className='bg-blue-400 hover:bg-blue-500 text-white p-4 text-sm font-semibold rounded-md cursor-pointer uppercase'>Create new</h1>
                                </Link>
                            </div>
                        : null
                        
                    }
                </div>
    )
}

export default ClientSeatingProfileManagementView;
