import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ScriptableContext,
    Filler,
  } from 'chart.js';
  import { Bar, Line, Pie } from 'react-chartjs-2';
import { useGetClientRouteSalesRevenueReportReviewQuery } from '../../Features/dataAnalysisReportingSlice';
import ClientHeaderView from '../App/ClientHeaderView';
import jsPDF from "jspdf";
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

function ClientRouteSalesReportView() {
    const { client_route_id } = useParams();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const {
        data: client,
        isSuccess: isSuccessClient,
        isLoading: isLoadingClient,
        isError: isErrorClient,
    } = useGetClientByIdQuery(client_id);

const {
    data: clientSalesReport,
    isSuccess: isSuccessClientSalesReport,
    isLoading: isLoadingClientSalesReport,
    isError: isErrorClientSalesReport,
} = useGetClientRouteSalesRevenueReportReviewQuery({client_id: client_id, client_route_id: client_route_id});

const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getFullYear();
  }

  const formatDisplayDateForDayMonth = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

  const numberWithCommas = (x)=>{
    return (x !==null)? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
  }

  const chartBgColor = {
    id: 'chartBgColor',
    beforeDraw: (chart, options)=>{
      const{ ctx, width, height }= chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    }
  }


    return   (
                <div className="bg-gray-100">
                    <ClientHeaderView />
                      {
                        (isSuccessClientSalesReport)?
                          (clientSalesReport.data.length)?
                            <div>
                              {
                                clientSalesReport.data.map(data=>(
                                  (data.route_information)?
                                  <div className="flex justify-center items-center bg-gray-50 p-1">
                                  <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{data.route_information.start_off_station_name} to {data.route_information.destination_station_name} sales reports</h1>
                              </div>
                                  :null
                                ))
                              }
                              {
                                (isSuccessClient)?
                                    <div class="mt-4 sm:mt-0  md:pt-4 space-y-4 md:mr-40 md:ml-40  lg:mr-60 lg:ml-60 mb-12">
                                        <div class="grid grid-cols-1">         
                                            {
                                                  clientSalesReport.data.map(data => (
                                                      (data.last28Days)?
                                                      <div>
                                                        <div>
                                                          <Line
                                                              height={260}
                                                              data={{
                                                                labels: (data.last28Days)? data.last28Days.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                                datasets: [
                                                                  {
                                                                    label: 'Total',
                                                                    data: (data.last28Days)? data.last28Days.map(data=>data.total): '',
                                                                    fill: "start",
                                                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                                                      const ctx = context.chart.ctx;
                                                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                      gradient.addColorStop(1, 'rgba(54, 162, 235, 0.05)');
                                                                      gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                                                      return gradient;
                                                                    },
                                                                    borderColor: 'rgba(75, 192, 192, 1)',
                                                                    borderWidth: 1
                                                                  },
                                                                ]
                                                              }}
                                                              options={{
                                                                bezierCurve: true,
                                                                plugins: {
                                                                  color: "red",
                                                                  legend: {
                                                                    position: 'top',
                                                                  },
                                                                  title: {
                                                                    display: true,
                                                                    text: 'Last 28 days daily sales report',
                                                                  },
                                                                  tooltip: {
                                                                    enabled: false
                                                                  },
                                                                  datalabels: {
                                                                    align: 'top',
                                                                    labels: {
                                                                      value: {
                                                                        color: 'black',
                                                                        font: {
                                                                          weight: ''
                                                                        }
                                                                      }
                                                                    },
                                                                    formatter: (value)=>{
                                                                      return numberWithCommas(value);
                                                                    }
                                                                  }
                                                                },
                                                                elements: {
                                                                  line: {
                                                                    tension: 0.16
                                                                  }
                                                                },
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                  yAxes: [
                                                                    {
                                                                      ticks: {
                                                                        beginAtZero: true,
                                                                        stepSize: 10
                                                                      }
                                                                    }
                                                                  ]
                                                                }
                                                              }
                                                            }
                                                            plugins={[chartBgColor]}
                                                            />
                                                        </div>
                                                      </div>
                                                      :null
                                                  ))
                                            }
                                        </div>
                                        <div class="grid grid-cols-1">         
                                            {
                                                  clientSalesReport.data.map(data => (
                                                      (data.last4weeks)?
                                                      <div>
                                                        <div>
                                                          <Bar
                                                              height={260}
                                                              data={{
                                                                labels: (data.last4weeks)? data.last4weeks.map(data=>formatDisplayDateForDayMonth(data.start_date)+" - "+formatDisplayDateForDayMonth(data.end_date)): '',
                                                                datasets: [
                                                                  {
                                                                    label: 'Total',
                                                                    data: (data.last4weeks)? data.last4weeks.map(data=>data.total): '',
                                                                    fill: "start",
                                                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                                                      const ctx = context.chart.ctx;
                                                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                      gradient.addColorStop(1, 'rgba(54, 220,215, 0.18)');
                                                                      gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                                                      return gradient;
                                                                    },
                                                                    borderRadius: 3,
                                                                  },
                                                                ]
                                                              }}
                                                              options={{
                                                                bezierCurve: true,
                                                                plugins: {
                                                                  color: "red",
                                                                  legend: {
                                                                    position: 'top',
                                                                  },
                                                                  title: {
                                                                    display: true,
                                                                    text: 'Last 4 weeks weekly sales report',
                                                                  },
                                                                  tooltip: {
                                                                    enabled: false
                                                                  },
                                                                  datalabels: {
                                                                    align: 'top',
                                                                    labels: {
                                                                      value: {
                                                                        color: 'black',
                                                                        font: {
                                                                          weight: ''
                                                                        }
                                                                      }
                                                                    },
                                                                    formatter: (value)=>{
                                                                      return numberWithCommas(value);
                                                                    }
                                                                  }
                                                                },
                                                                elements: {
                                                                  line: {
                                                                    tension: 0.16
                                                                  }
                                                                },
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                  yAxes: [
                                                                    {
                                                                      ticks: {
                                                                        beginAtZero: true,
                                                                        stepSize: 10
                                                                      }
                                                                    }
                                                                  ]
                                                                }
                                                              }
                                                            }
                                                            plugins={[chartBgColor]}
                                                            />
                                                        </div>
                                                      </div>
                                                      :null
                                                  ))
                                            }
                                        </div>
                                        <div class="ggrid grid-cols-2">         
                                            {
                                              clientSalesReport.data.map(data => (
                                                  (data.last12Months)?
                                                    <div>
                                                      <div>                                                          
                                                        <Bar
                                                            height={260}
                                                            data={{
                                                            labels: (data.last12Months)? data.last12Months.map(data=>formatDisplayDate(data.monthname)): '',
                                                            datasets: [
                                                                    {
                                                                    label: 'Total',
                                                                    data: (data.last12Months)? data.last12Months.map(data=>data.total): '',
                                                                    fill: "start",
                                                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                                                      const ctx = context.chart.ctx;
                                                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                      gradient.addColorStop(1, 'rgba(54, 162, 235, 0.13)');
                                                                      gradient.addColorStop(0, 'rgba(250,174,50,0.2)');
                                                                      return gradient;
                                                                    },
                                                                    borderRadius: 3,
                                                                    },
                                                            ]
                                                        }}
                                                        options={{
                                                            bezierCurve : true,
                                                            plugins: {
                                                              legend: {
                                                                position: 'top',
                                                              },
                                                              title: {
                                                                display: true,
                                                                text: 'Last 12 months sales report',
                                                              },
                                                              tooltip: {
                                                                enabled: false
                                                              },
                                                              datalabels: {
                                                                align: 'top',
                                                                color: 'black',
                                                                labels: {
                                                                  value: {
                                                                    font: {
                                                                      weight: ''
                                                                    }
                                                                  }
                                                                },
                                                                formatter: (value)=>{
                                                                  return numberWithCommas(value);
                                                                }
                                                              }
                                                            },
                                                            elements: {
                                                                line: {
                                                                  tension: 0.55
                                                                }
                                                              },
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                              yAxes: [
                                                                {
                                                                  ticks: {
                                                                    beginAtZero: true,
                                                                  }
                                                                }
                                                              ]
                                                            }
                                                        }}
                                                        plugins={[chartBgColor]}
                                                        />
                                                      </div>
                                                    </div>
                                                  :null
                                              ))
                                            }
                                        </div>
                                        <div class="ggrid grid-cols-2">         
                                            {
                                              clientSalesReport.data.map(data => (
                                                (data.annualSales)?
                                                    <div>
                                                      <div>                                                          
                                                        <Pie
                                                            height={260}
                                                            data={{
                                                              labels: (data.annualSales)? data.annualSales.map(data=>data.year): '',
                                                              datasets: [
                                                                      {
                                                                      label: 'Total',
                                                                      data: (data.annualSales)? data.annualSales.map(data=>data.total): '',
                                                                      backgroundColor: [
                                                                          'rgba(53, 162, 235, 0.4)',
                                                                          'rgba(255, 132, 235, 0.4)',
                                                                          'rgba(54, 162, 235, 0.4)',
                                                                          'rgba(255, 206, 86, 0.4)',
                                                                          'rgba(75, 192, 192, 0.4)',
                                                                      ]
                                                                      },
                                                              ]
                                                              }}
                                                        options={{
                                                            bezierCurve : true,
                                                            plugins: {
                                                              legend: {
                                                                position: 'top',
                                                              },
                                                              title: {
                                                                display: true,
                                                                text: 'Annual sales report',
                                                              },
                                                              tooltip: {
                                                                enabled: false
                                                              },
                                                              datalabels: {
                                                                align: 'center',
                                                                color: 'black',
                                                                labels: {
                                                                  value: {
                                                                    font: {
                                                                      weight: ''
                                                                    }
                                                                  }
                                                                },
                                                                formatter: (value)=>{
                                                                  return numberWithCommas(value);
                                                                }
                                                              }
                                                            },
                                                            elements: {
                                                                line: {
                                                                  tension: 0.55
                                                                }
                                                              },
                                                            maintainAspectRatio: false,
                                                        }}
                                                        plugins={[chartBgColor]}
                                                        />
                                                      </div>
                                                    </div>
                                                  :null
                                              ))
                                            }
                                        </div>
                                    </div>
                                : isLoadingClient?
                                    <LoadingSpinner />
                                : isErrorClient?
                                    <div className="md:flex flex-col p-4 mb-20">
                                        <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                                    </div>
                                : null
                                
                            }
                            </div>
                          :
                            <div className="md:flex flex-col p-4 mb-20">
                              <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                            </div>
                        : isLoadingClientSalesReport?
                          <LoadingSpinner />
                        : isErrorClientSalesReport?
                          <div className="md:flex flex-col p-4 mb-20">
                              <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                          </div>
                        :null
                      }
                </div>
    )
}

export default ClientRouteSalesReportView;
