import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react'
import swal from 'sweetalert'
import LoadingSpinner from '../LoadingSpinner';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import { useEditClientTicketExtentionMutation, useGetClientTicketExtentionByIdQuery } from '../../Features/clientTicketExtentionSlice';
import { useEffect } from 'react';
import ClientSalesHeaderView from './ClientSalesHeaderView';

function ClientSalesEditClientTicketExtentionView() {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
    const { extention_id } = useParams();
  
    const client_id = user.client_id;
        
    const [input, setInput] = useState({
        body: '',
        purpose: '',
        id: '',
    });

    const [purpose, setPurpose] = useState({});

    const [errors, setErrors] = useState({
        body: '',
    })

    const {
        data: client,
        isSuccess: isClientSuccessful,
      } = useGetClientByIdQuery(client_id)

      const {
        data: extention,
        isSuccess: isSuccessExtention,
        isLoading: isLoadingExtention,
      } = useGetClientTicketExtentionByIdQuery(extention_id)

      useEffect(()=>{
        if(isSuccessExtention && extention.data){
            setInput(extention.data)
        }
        return () =>{
        }
    },[extention]);

      const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const [editClientTicketExtention, {isLoading: isLoadingCreateTicketExtention}] = useEditClientTicketExtentionMutation()

    const handleSubmit = async(e)=>{
        e.preventDefault();
        const data = {
            body: input.body,
            id: input.id,
        }

        try {
            const resp = await editClientTicketExtention(data).unwrap()
            swal("Success",resp.message,"success");
            navigate(-1);
        } catch (err) {
            if(err.data.errors == true){
                setErrors({
                    ...errors, 
                    body: err.data.errors.body, 
                })
            }else if (err.data.error == true){
                swal("Error",err.data.error,"error");
                navigate(-1);
            }
        } 
    }

    const back = ()=> navigate(-1);
    
   let constent = isLoadingExtention?
        <LoadingSpinner />
        : isLoadingCreateTicketExtention?
                <LoadingSpinner />
        :
        (
            <div className="bg-gray-100">
                <ClientSalesHeaderView />
                <div className="mb-10" >
                  {
                    (isClientSuccessful)?
                    <div className="flex items-center justify-center bg-gray-50 p-0">
                      <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} Ticket extention management</h1>
                    </div>
                    : null
                  }
                </div>
                <div class="md:flex justify-center mt-5 sm:mt-0 mb-10 md:pt-4">
                    <div class="bg-gray-100 md:w-1/2">
                        <div class="w-full sm:rounded-md">
                            <form onSubmit={handleSubmit} autoComplete="off" >
                                <div class=" shadow sm:rounded-md">
                                        <div class="col-span-6 p-4 shadow-md bg-teal-500 uppercase rounded-t">
                                            <div className='flex justify-center'>
                                                <h1 className='uppercase font-semibold text-xs text-white'>edit {input.purpose} ticket extention</h1>
                                            </div>
                                        </div>
                                <div class="px-4 py-5 bg-gray-100 sm:p-6">
                                    <div class="grid grid-cols-2 gap-4">
                                        <div class="col-span-6 sm:col-span-3 mb-2">
                                            <label for="body" class="block text-xs font-medium text-gray-700">Message</label>
                                            <textarea 
                                                class="mt-1 p-2 h-20 outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="text"
                                                placeholder='Message in less than 50 charactors...'
                                                name="body" 
                                                id="body" 
                                                value={input.body}
                                                maxLength="50"
                                                onChange={handleChange}
                                            />
                                            <p className="block text-xs font-medium text-red-600 p-0 m-0">{errors.body}</p>
                                        </div>      
                                    </div>
                                </div>
                                <div class="flex justify-center space-x-6 items-center px-4 py-4 bg-gray-200 text-right sm:px-6">
                                    <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md bg-gray-300 hover:bg-gray-400 cursor-pointer">Cancel</div>
                                    <button type="submit" class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500">Save</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    return constent;
}

export default ClientSalesEditClientTicketExtentionView;
