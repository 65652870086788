import { Link, useNavigate, useParams } from "react-router-dom";
import Home from "../../HeaderLayouts/Home";
import Select from 'react-select';
import { useState,react } from "react";
import swal from "sweetalert";
import { useGetAvailableSeatsForPostponTicketMutation, usePostponTicketMutation, useTicketVerificationMutation } from "../../Features/ticketsSlice";


function PostponTicketView(props) {  
    const navigate = useNavigate();

    const [seatingSort, setSeatingSort] = useState([]);
    const [search, setSearch] = useState(false);
    const [selectedSeat, setSeat] = useState(false);
    const [time, setTime] = useState(false);

    const [input, setInput] = useState({
        token: '',
        time: '',
        time_label: '',
        date: '',
        client_id: '',
        client_route_id: '',
        ticket_id: '',
    });

    const [errors, setErrors] = useState({
        token: '',
        time: '',
        time_label: '',
        date: '',
        client_id: '',
        client_route_id: '',
    })

    const [ticketVerification, {isLoading}] = useTicketVerificationMutation();

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleSearch = async(e)=>{
        e.preventDefault();
        let data = {
            token: input.token,
        }
        try {
            const resp = await ticketVerification(data).unwrap()
            if(resp.success == true){
                if(resp.data.postponed != 0){
                    swal("Action Failed","This ticket has already been postponed once","error");
                    return navigate("/");
                }
                setSearch(resp.data);
                setInput({
                    ...input, 
                    client_id: resp.data.client_id, 
                    client_route_id: resp.data.client_route_id, 
                    ticket_id: resp.data.ticket_id, 
                })
                // const routeTimeResp = await getOperatorRouteTimeByclientRouteId(resp.data.client_route_id).unwrap()
                // if(routeTimeResp.success == true){
                //     setTime(routeTimeResp.data);

                //     let searchField = document.getElementById("search-field");
                //     let FormField = document.getElementById("form-field");
    
                //     searchField.classList.add("hidden");
                //     FormField.classList.remove("hidden");
                // }
            }
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                swal("Search field is empty",err.data.errors.token[0],"error");
            }else if (err.data.hasOwnProperty('error') == true){
                setSearch(false);
                let FormField = document.getElementById("form-field");
                FormField.classList.add("hidden");
                swal("Search not found",err.data.message,"error");
            }
        }          
    }

    const [getAvailableSeatsForPostponTicket] = useGetAvailableSeatsForPostponTicketMutation();

    const nextPage = async(e)=>{
        e.preventDefault();
        let data = {
            client_id: input.client_id,
            client_route_id: input.client_route_id,
            time_id: input.time,
            date: input.date,
        }
        try {
            const resp = await getAvailableSeatsForPostponTicket(data).unwrap()
            if(resp.success == true){
                setSeatingSort(resp.data);
                let seatsField = document.getElementById("seats-form-field");
                let FormField = document.getElementById("form-field");

                FormField.classList.add("hidden");
                seatsField.classList.remove("hidden");
            }
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({
                    ...errors, 
                    client_id: err.data.errors.client_id, 
                    client_route_id: err.data.errors.client_route_id, 
                    time_id: err.data.errors.time_id,
                    date: err.data.errors.date,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                setSearch(false);
                let seatsField = document.getElementById("seats-form-field");
                seatsField.classList.add("hidden");
                swal("Search not found",err.data.message,"error");
            }
        }          
    }

    const [postponTicket] = usePostponTicketMutation();

    const handleSubmit = async(e)=>{
        e.preventDefault();
        if(selectedSeat == false){
            return swal("Error","Select seat number","error");
        }
        let data = {
            client_id: input.client_id,
            client_route_id: input.client_route_id,
            time_id: input.time,
            time: input.time_label,
            ticket_id: input.ticket_id,
            date: input.date,
            seat_id: selectedSeat,
        }
        try {
            const resp = await postponTicket(data).unwrap();
            navigate("/");
            swal("Success","Ticket updated successfully","success");
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                swal("Error",err.data.errors,"error");
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }          
    }

    const handleSeatChange = (e)=>{
        const { value, checked } = e.target;
        setSeat(value)
    }

    const handleSetTime = (e)=>{
        setInput({
            ...input, time: e.value, time_label: e.label
        })
    }

    const back = (e)=>{
        let seatsField = document.getElementById("seats-form-field");
        let FormField = document.getElementById("form-field");

        FormField.classList.remove("hidden");
        seatsField.classList.add("hidden");
    }

    const previous = ()=> navigate(-1);
    
    const formatDisplayDate = (formatDate)=>{
        let tempDate = new Date(formatDate);
        let month = tempDate.toLocaleString('default',{month: 'short'});
        return month + "-" + tempDate.getDate() + "-" + tempDate.getFullYear();
    }

    return (
                    <div className='bg-white'>
                        <div className="sticky top-0 z-50">
                            <Home />
                        </div>
                        {
                                        <div className="bg-white">
                                        <div class="mt-10 mb-10 sm:mt-0 md:pt-4">
                                            <div class="md:grid md:grid-cols-2 md:pr-20 md:pl-20 lg:pr-72 lg:pl-72">
                                                <div class="mt-5 md:mt-0 md:col-span-2 bg-gray-200">
                                                    <div id="search-field" className="bg-gray-200"> 
                                                        <div className="flex flex-col bg-yellow-500 p-4 rounded text-sm text-yellow-800">
                                                            <div className="flex justify-center">
                                                                <h1 className="text-sm font-semibold uppercase text-gray-800">
                                                                    Postpon Ticket
                                                                </h1>
                                                            </div>
                                                        </div>
                                                        <form onSubmit={handleSearch} autoComplete="off">
                                                            <div className='flex flex-col space-y-4'>
                                                                <div className="mt-4 pr-4 pl-4">
                                                                    <label for="name" class="flex justify-center block text-sm font-medium text-gray-700">Ticket Token</label>
                                                                    <input 
                                                                        class="mt-1 p-2 text-sm outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                        type="text"
                                                                        placeholder='Enter ticket token...'
                                                                        name="token" 
                                                                        id="token" 
                                                                        value={input.token}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <button type="submit" class="w-20 flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600">Search</button>
                                                                </div>
                                                            </div>
                                                        </form>                                  
                                                    </div>
                                                    <div id="form-field" className="hidden">
                                                    <form onSubmit={nextPage} autoComplete="off">
                                                                <div className="flex flex-col bg-yellow-500 p-4 rounded text-sm text-yellow-800">
                                                                    <div className="flex justify-center">
                                                                        <h1 className="text-sm font-semibold uppercase text-gray-800">
                                                                            Your current ticket information
                                                                        </h1>
                                                                    </div>
                                                                </div>                                        
                                                            <div class="form-group mb-1">
                                                                <div className="flex flex-col ">
                                                                    <div className="flex flex-col">
                                                                        <div className="pr-5 pl-5 md:pr-24 md:pl-24 bg-gray-100 pt-2 pb-2 space-y-2">
                                                                        <h1 className="flex space-x-6 bg-gray-100 p-2 justify-center text-sm uppercase text-gray-500">
                                                                                <div className="flex flex-col text-gray-500">
                                                                                    <div className="flex justify-center font-semibold">Token</div>
                                                                                    <div className="text-sm">
                                                                                        {search.ticket_token}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col text-gray-500">
                                                                                    <div className="flex justify-center font-semibold">Status</div>
                                                                                    <div className="text-sm">
                                                                                        {search.date}
                                                                                    </div>
                                                                                </div>
                                                                            </h1> 
                                                                            <div className="flex justify-between border-b border-gray-300">
                                                                                <p className="text-sm font-semibold uppercase">Operator</p>
                                                                                <p className="text-md">{search.name} {search.business_type}</p>
                                                                            </div>
                                                                            <div className="flex justify-between border-b border-gray-300">
                                                                                <p className="text-sm font-semibold uppercase">Name</p>
                                                                                <p className="text-md">{search.fname} {search.lname}</p>
                                                                            </div>
                                                                            <div className="flex justify-between border-b border-gray-300">
                                                                                <p className="text-sm font-semibold uppercase">Phone No.</p>
                                                                                <p className="text-md">{search.phone}</p>
                                                                            </div>
                                                                            <div className="flex justify-between border-b border-gray-300">
                                                                                <p className="text-sm font-semibold uppercase">Seat No.</p>
                                                                                <p className="text-md">{search.seat_number}</p>
                                                                            </div>
                                                                            <div className="flex justify-between border-b border-gray-300">
                                                                                <p className="text-sm font-semibold uppercase">Charge</p>
                                                                                <p className="text-md font-semibold">K {search.charge}</p>
                                                                            </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-4">
                                                                <div className="flex flex-col ">
                                                                    <div className="flex flex-col ">
                                                                        </div>
                                                                            <h1 className="flex space-x-4 bg-orange-400 p-2 justify-center text-sm uppercase text-gray-500">
                                                                                <div className="flex">
                                                                                    <div className="flex-row justify-center">
                                                                                        <div className="text-gray-800 font-semibold text-md flex justify-center">Route</div>
                                                                                        <div className="text-gray-700 text-sm flex justify-center">
                                                                                        {search.start_off_station_name} - {search.destination_station_name}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex">
                                                                                    <div className="flex-row justify-center">
                                                                                        <div className="text-gray-800 font-semibold text-md flex justify-center">Departure</div>
                                                                                        <div className="text-gray-700 text-sm flex justify-center">
                                                                                        {formatDisplayDate(search.date)} @ { search.departure_time} Hrs
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </h1> 
                                                                        </div>
                                                                    </div>
                                                                    <div id="customer-seat-info" className="">
                                                                        <div class="grid grid-cols-2 gap-2">
                                                                            <div class="form-group mb-6">
                                                                            <label for="date" class="flex justify-center block text-sm font-medium text-gray-700">Select Date</label>
                                                                                <input type="date" class="form-control block
                                                                                    w-full
                                                                                    px-3
                                                                                    py-1.5
                                                                                    text-base
                                                                                    font-normal
                                                                                    text-gray-700
                                                                                    bg-white bg-clip-padding
                                                                                    border border-solid border-gray-300
                                                                                    rounded
                                                                                    transition
                                                                                    ease-in-out
                                                                                    m-0
                                                                                    focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                                                                                    placeholder="Select travel date"
                                                                                    name="date" 
                                                                                    id="date" 
                                                                                    value={input.date}
                                                                                    onChange={handleChange}
                                                                                    />
                                                                                    <p className="flex justify-center block text-sm font-semibold text-red-600 p-0 m-0">{errors.date}</p>
                                                                            </div>
                                                                            <div class="form-group m-1">
                                                                                <label for="time" class="flex justify-center block text-sm font-medium text-gray-700">Select time</label>
                                                                                <Select 
                                                                                        class="block
                                                                                        w-full
                                                                                        px-3
                                                                                        py-1.5
                                                                                        text-base
                                                                                        font-normal
                                                                                        text-gray-700
                                                                                        bg-white bg-clip-padding
                                                                                        border border-solid border-gray-300
                                                                                        rounded
                                                                                        transition
                                                                                        ease-in-out
                                                                                        m-0"
                                                                                        placeholder="Time"
                                                                                        options={time}
                                                                                        isSearchable
                                                                                        onChange={handleSetTime}
                                                                                />
                                                                                <p className="flex justify-center block text-sm font-semibold text-red-600 p-0 m-0">{errors.time_id}</p>
                                                                            </div>                                                                
                                                                            </div>                                      
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-center space-x-6 pb-2">
                                                                <div className="cursor-pointer">
                                                                    <div 
                                                                        className="
                                                                            px-6
                                                                            w-20
                                                                            py-2
                                                                            bg-gray-500
                                                                            text-white
                                                                            font-medium
                                                                            text-xs
                                                                            rounded-md
                                                                            shadow-md
                                                                            "
                                                                        onClick={(e)=>previous(e)}
                                                                    >
                                                                        Cancel
                                                                    </div>
                                                                </div>
                                                                <button type="submit" class="
                                                                px-1
                                                                w-20
                                                                py-2
                                                                bg-green-500
                                                                text-white
                                                                font-medium
                                                                text-xs
                                                                rounded-md
                                                                "
                                                                >
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div id="seats-form-field" className="hidden bg-white">
                                                        <form autoComplete="off" onSubmit={handleSubmit}>
                                                                <div className="flex flex-col bg-green-400 p-4 rounded text-sm text-yellow-800">
                                                                    <div className="flex justify-center">
                                                                        <h1 className="text-sm font-semibold uppercase text-gray-800">
                                                                            Comfirm Your ticket information
                                                                        </h1>
                                                                    </div>
                                                                </div>                                        
                                                            <div class="form-group mb-1 bg-white">
                                                                <div className="flex flex-col ">
                                                                    <div className="flex flex-col">
                                                                        <div className="pr-5 pl-5 md:pr-24 md:pl-24 bg-gray-100 pt-2 pb-2 space-y-2">
                                                                        <h1 className="flex space-x-6 bg-gray-100 p-2 justify-center text-sm uppercase text-gray-500">
                                                                                <div className="flex flex-col text-gray-500">
                                                                                    <div className="flex justify-center font-semibold">Token</div>
                                                                                    <div className="text-sm">
                                                                                        {search.ticket_token}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col text-gray-500">
                                                                                    <div className="flex justify-center font-semibold">Status</div>
                                                                                    <div className="text-sm">
                                                                                        {search.date}
                                                                                    </div>
                                                                                </div>
                                                                            </h1> 
                                                                            <div className="flex justify-between border-b border-gray-300">
                                                                                <p className="text-sm font-semibold uppercase">Operator</p>
                                                                                <p className="text-md">{search.name} {search.business_type}</p>
                                                                            </div>
                                                                            <div className="flex justify-between border-b border-gray-300">
                                                                                <p className="text-sm font-semibold uppercase">Name</p>
                                                                                <p className="text-md">{search.fname} {search.lname}</p>
                                                                            </div>
                                                                            <div className="flex justify-between border-b border-gray-300">
                                                                                <p className="text-sm font-semibold uppercase">Phone No.</p>
                                                                                <p className="text-md">{search.phone}</p>
                                                                            </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-4 bg-white">
                                                                <div className="flex flex-col ">
                                                                    <div className="flex flex-col ">
                                                                        </div>
                                                                            <h1 className="flex space-x-4 bg-orange-400 p-2 justify-center text-sm uppercase text-gray-500">
                                                                                <div className="flex">
                                                                                    <div className="flex-row justify-center">
                                                                                        <div className="text-gray-800 font-semibold text-md flex justify-center">Route</div>
                                                                                        <div className="text-gray-700 text-sm flex justify-center">
                                                                                        {search.start_off_station_name} - {search.destination_station_name}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex">
                                                                                    <div className="flex-row justify-center">
                                                                                        <div className="text-gray-800 font-semibold text-md flex justify-center">Departure</div>
                                                                                        <div className="text-gray-700 text-sm flex justify-center">
                                                                                        {formatDisplayDate(input.date)} @ { input.time_label} Hrs
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </h1> 
                                                                        </div>
                                                                        {
                                                                            (seatingSort != null)?
                                                                                <div className="mb-3 flex flex-wrap justify-between p-2 border-2 bg-white  border-gray-200 mt-0 rounded-b-md">
                                                                                    { (seatingSort.length > 0)?seatingSort.map(data => (
                                                                                            <div htmlFor="seat_number" key={data.id} className={`${(selectedSeat == data.id)? "bg-orange-500 border-orange-500" : "bg-blue-700 border-blue-800"}  border pt-1 pb-1 pl-2 pr-2 rounded-lg mt-2 mr-1`}>
                                                                                            <p className='text-white'>{data.number}</p>
                                                                                            <input 
                                                                                                className="bg-green-500"
                                                                                                type="radio" 
                                                                                                name="seat_number"
                                                                                                id="seat_number"
                                                                                                value={data.id}
                                                                                                onChange={handleSeatChange}
                                                                                            />
                                                                                        </div>
                                                                                            
                                                                                        ))
                                                                                            : ''
                                                                                    }
                                                                                </div>
                                                                            :
                                                                                <div className="flex justify-center pl-2 pr-2">
                                                                                    { 
                                                                                        (seatingSort.success == false)?
                                                                                            <h1 className="text-sm text-red-500">{seatingSort.error}</h1>
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-center space-x-6 pb-2 bg-white">
                                                                <div className="cursor-pointer">
                                                                    <div 
                                                                        className="
                                                                            px-6
                                                                            w-20
                                                                            py-2
                                                                            bg-gray-500
                                                                            text-white
                                                                            font-medium
                                                                            text-xs
                                                                            rounded-md
                                                                            shadow-md
                                                                            "
                                                                        onClick={back}
                                                                    >
                                                                        Back
                                                                    </div>
                                                                </div>
                                                                <button type="submit" class="
                                                                px-1
                                                                w-20
                                                                py-2
                                                                bg-green-500
                                                                text-white
                                                                font-medium
                                                                text-xs
                                                                rounded-md
                                                                "
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>


                        }
                        </div>
    )
}

export default PostponTicketView;
