import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetClientRouteEditLogsQuery } from '../../Features/clientsRoutesSlice';
import LoadingSpinner from '../LoadingSpinner';

function ClientRouteLogsView() {
    const navigate = useNavigate();
    const { client_route_id } = useParams();

    const {
        data: logs,
        isLoading: isLoadingLogs,
        isSuccess: isSuccessLogs,
        isError: isErrorLogs
      } = useGetClientRouteEditLogsQuery(client_route_id)

    const back = ()=> navigate(-1);
    
    return (
        <div className="bg-gray-100">
            <div className="flex items-center justify-center bg-white p-1 mb-5">
                <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Route management</h1>
            </div>
            {
                (isSuccessLogs)?
                    <div class="md:flex justify-center mt-5 sm:mt-0 mb-10 md:pt-4">
                        <div class=" bg-gray-700 md:w-1/2">
                            <div class="w-full shadow sm:rounded-md">
                                <div className="bg-gray-50">
                                            <div class="col-span-6 p-4 shadow-md bg-gray-700 uppercase">
                                                {
                                                    <div className='flex justify-center'>
                                                        <h1 className='uppercase font-semibold text-sm text-gray-100'>Route logs</h1>
                                                    </div>
                                                }
                                            </div>
                                            <div className="bg-gray-100 p-2 space-y-2">
                                                {
                                                    (logs.hasOwnProperty('success') === true && logs.data.length > 0)?
                                                    logs.data.map(data => (
                                                        <div class="">
                                                            {
                                                                <div className="flex flex-col ">
                                                                    <h1 className="flex space-x-4 rounded-md bg-gray-200 hover:bg-gray-300 p-2 justify-center text-sm text-gray-500">
                                                                        <div className="flex flex-col text-gray-600">
                                                                            <Link to={`/user/manage-user-profile/${data.user_id}`} className="text-sm text-gray-500 cursor-pointer hover:text-blue-500">
                                                                            <div className="flex justify-center text-sm font-semibold uppercase">User</div>
                                                                                {data.fname} - {data.lname}
                                                                            </Link>
                                                                        </div>
                                                                        <div className="flex flex-col text-gray-600">
                                                                            <div className="flex justify-center text-sm font-semibold uppercase">Updated_at</div>
                                                                            <div className="text-sm text-gray-500">
                                                                                {data.created_at}
                                                                            </div>
                                                                        </div>
                                                                    </h1>
                                                                </div>
                                                            }
                                                        </div>
                                                    ))
                                                    :''
                                                }
                                            </div>
                                    <div class="flex justify-center space-x-6 items-center px-4 py-2 bg-gray-300 text-right sm:px-6">
                                        <div onClick={back} class="w-20 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-400 hover:bg-gray-500 cursor-pointer">Back</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : isLoadingLogs?
                <LoadingSpinner />
                :isErrorLogs?
                        <div className="md:flex flex-col p-4">
                            <p className="text-blue-500 italic border-b border-black flex flex-1">Empty</p>
                        </div>
                :null

            }
        </div>
    )
}

export default ClientRouteLogsView;
