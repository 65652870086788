import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ScriptableContext,
    Filler,
  } from 'chart.js';
  import { Bar, Line, Pie } from 'react-chartjs-2';
import { useGetClientStationManagerTotalTeamSalesReportSummaryQuery } from '../../Features/dataAnalysisReportingSlice';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetUserProfileByIdQuery } from '../../Features/usersSlice';
import ClientManagerHeaderView from './ClientManagerHeaderView';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

function ClientManagerTotalTeamSalesReportSummaryView() {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;
    const admin_user_id = user.id;

    const {
        data: client,
        isSuccess: isSuccessClient,
        isLoading: isLoadingClient,
        isError: isErrorClient,
        error: errorClient
    } = useGetClientByIdQuery(client_id);

    const {
      data: managerUser,
      isLoading,
      isSuccess,
      isError,
      error
  } = useGetUserProfileByIdQuery(admin_user_id)

    const {
      data: teamRevenueReport,
      isSuccess: isSuccessTeamRevenueReport,
      isLoading: isLoadingTeamRevenueReport,
      isError: isErrorTeamRevenueReport,
      error: errorTeamRevenueReport
  } = useGetClientStationManagerTotalTeamSalesReportSummaryQuery(admin_user_id);

const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getFullYear();
  }

  const formatDisplayDateForDayMonth = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

  const numberWithCommas = (x)=>{
    if(x !==null){
      const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
      return formatedNumber;
    }
    return null;
  }

  const chartBgColor = {
    id: 'chartBgColor',
    beforeDraw: (chart, options)=>{
      const{ ctx, width, height }= chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    }
  }


    return   (
      <div className="bg-gray-100">
      <ClientManagerHeaderView />
       {
          (isSuccess)?
              <div className="flex justify-center items-center bg-gray-50 p-1">
                  <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{`${managerUser.data.team_name}`} station sales reports summary{`(${managerUser.data.lname} ${managerUser.data.fname})`}</h1>
              </div>
           : null
       }
        {
          (isSuccessTeamRevenueReport)?
            (teamRevenueReport.data.length)?
              <div>
                {
                  (isSuccessClient)?
                      <div class="mt-4 sm:mt-0  md:pt-4 space-y-4 md:mr-28 md:ml-28  lg:mr-28 lg:ml-28 mb-12">
                        <div class="grid grid-cols-1">         
                            {
                                  teamRevenueReport.data.map(data => (
                                      (data.last28days_revenue)?
                                      <div>
                                        <div>
                                          <Line
                                              height={300}
                                              data={{
                                                labels: (data.last28days_revenue)? data.last28days_revenue.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                datasets: [
                                                  {
                                                    label: 'Total',
                                                    data: (data.last28days_revenue)? data.last28days_revenue.map(data=>data.cashTotal): '',
                                                    fill: "start",
                                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                                      const ctx = context.chart.ctx;
                                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                      gradient.addColorStop(1, 'rgba(54, 162, 235, 0.05)');
                                                      gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                                      return gradient;
                                                    },
                                                    borderColor: 'rgba(75, 192, 192, 1)',
                                                    borderWidth: 1
                                                  },
                                                ]
                                              }}
                                              options={{
                                                bezierCurve: true,
                                                plugins: {
                                                  color: "red",
                                                  legend: {
                                                    position: 'top',
                                                  },
                                                  title: {
                                                    display: true,
                                                    text: 'Last 28 days daily revenue report',
                                                  },
                                                  tooltip: {
                                                    enabled: false
                                                  },
                                                  datalabels: {
                                                    align: 'top',
                                                    labels: {
                                                      value: {
                                                        color: 'black',
                                                        font: {
                                                          weight: ''
                                                        }
                                                      }
                                                    },
                                                    formatter: (value)=>{
                                                      return numberWithCommas(value);
                                                    }
                                                  }
                                                },
                                                elements: {
                                                  line: {
                                                    tension: 0.16
                                                  }
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                  yAxes: [
                                                    {
                                                      ticks: {
                                                        beginAtZero: true,
                                                        stepSize: 10
                                                      }
                                                    }
                                                  ]
                                                },
                                                layout: {
                                                  padding: {
                                                      right: 50,
                                                  }
                                                }
                                              }
                                            }
                                            plugins={[chartBgColor]}
                                            />
                                        </div>
                                      </div>
                                      :null
                                  ))
                            }
                        </div>
                        <div class="grid grid-cols-1">         
                            {
                                  teamRevenueReport.data.map(data => (
                                      (data.last28days_sales)?
                                      <div>
                                        <div>
                                          <Line
                                              height={300}
                                              data={{
                                                labels: (data.last28days_sales)? data.last28days_sales.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                datasets: [
                                                  {
                                                    label: 'Total',
                                                    data: (data.last28days_sales)? data.last28days_sales.map(data=>data.total): '',
                                                    fill: "start",
                                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                                      const ctx = context.chart.ctx;
                                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                      gradient.addColorStop(1, 'rgba(153, 102, 255, 0.02)');
                                                      gradient.addColorStop(0, 'rgba(153, 102, 255,0.1)');
                                                      return gradient;
                                                    },
                                                    borderColor: 'rgba(153, 102, 255, 0.7)',
                                                    borderWidth: 1
                                                  },
                                                ]
                                              }}
                                              options={{
                                                bezierCurve: true,
                                                plugins: {
                                                  color: "red",
                                                  legend: {
                                                    position: 'top',
                                                  },
                                                  title: {
                                                    display: true,
                                                    text: 'Last 28 days daily sales report',
                                                  },
                                                  tooltip: {
                                                    enabled: false
                                                  },
                                                  datalabels: {
                                                    align: 'top',
                                                    labels: {
                                                      value: {
                                                        color: 'black',
                                                        font: {
                                                          weight: ''
                                                        }
                                                      }
                                                    },
                                                    formatter: (value)=>{
                                                      return value;
                                                    }
                                                  }
                                                },
                                                elements: {
                                                  line: {
                                                    tension: 0.16
                                                  }
                                                },
                                                maintainAspectRatio: false,
                                                scales: {
                                                  yAxes: [
                                                    {
                                                      ticks: {
                                                        beginAtZero: true,
                                                        stepSize: 10
                                                      }
                                                    }
                                                  ]
                                                },
                                                layout: {
                                                  padding: {
                                                      right: 50,
                                                  }
                                                }
                                              }
                                            }
                                            plugins={[chartBgColor]}
                                            />
                                        </div>
                                      </div>
                                      :null
                                  ))
                            }
                        </div>
                      </div>
                  : isLoadingClient?
                      <LoadingSpinner />
                  : isErrorClient?
                      <div className="md:flex flex-col p-4 mb-20">
                          <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                      </div>
                  : null
                  
              }
              </div>
            :
              <div className="md:flex flex-col p-4 mb-20">
                <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
              </div>
          : isLoadingTeamRevenueReport?
            <LoadingSpinner />
          : isErrorTeamRevenueReport?
            <div className="md:flex flex-col p-4 mb-20">
                <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
            </div>
          :null
        }
  </div>
    )
}

export default ClientManagerTotalTeamSalesReportSummaryView;
