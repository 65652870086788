import LoadingSpinner from '../LoadingSpinner';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ScriptableContext,
    Filler,
  } from 'chart.js';
  import { Bar, Line, Pie } from 'react-chartjs-2';
import { useGetClientSalesUserSummaryReportMutation } from '../../Features/dataAnalysisReportingSlice';
import { ArrowCircleDownIcon } from '@heroicons/react/solid';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import swal from 'sweetalert'
import { useState } from 'react';
import { useGetClientUnpaginatedManagerUserSalesUsersQuery } from '../../Features/usersSlice';
import ClientManagerHeaderView from './ClientManagerHeaderView';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

function ClientManagerSalesUsersRevenueReportView() {
    const  user  = useSelector(selectCurrentUser);

    const [teamData, setTeamData] = useState(false)
    const [teamProfile, setTeamProfile] = useState(false)
  
    const client_id = user.client_id;
    const admin_user_id = user.id;
    const team_name = user.team_name;

    const {
        data: client,
        isSuccess: isSuccessClient,
        isLoading: isLoadingClient,
        isError: isErrorClient,
        error: errorClient
    } = useGetClientByIdQuery(client_id);

    const {
      data: users,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetClientUnpaginatedManagerUserSalesUsersQuery(admin_user_id)

  const [getClientSalesUserSummaryReport, {isLoading: isLoadingTeamRevenue, isSuccess: isSuccessTeamRevenue, isErro: isErrorTeamReavenue}] = useGetClientSalesUserSummaryReportMutation()
  
  const handleFetchTeamReport = async(e,agent_user_id)=>{
    try {
        const resp = await getClientSalesUserSummaryReport({client_id: client_id, agent_user_id: agent_user_id}).unwrap();
        if(resp.success){
          resp.data.map(data=>(
            (data.profile)?
                setTeamProfile(data.profile)
              :null
            ))
          setTeamData(resp)
        }
    } catch (err) {
      return swal("",err.message,"error");
    }
}

const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getFullYear();
  }

  const formatDisplayDateForDayMonth = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

  const formatDisplayDateForYarMonth = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

  const numberWithCommas = (x)=>{
    if(x !==null){
      const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
      return formatedNumber;
    }
    return null;
  }

  const chartBgColor = {
    id: 'chartBgColor',
    beforeDraw: (chart, options)=>{
      const{ ctx, width, height }= chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    }
  }


    return   (
      <div className="bg-gray-100">
      <ClientManagerHeaderView />
       {
          (isSuccess)?
              <div className="flex justify-center items-center bg-gray-50 p-1 mb-10">
                  <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{team_name} agents sales summary reports</h1>
              </div>
           : null
       }
        {
          (isSuccess)?
            (users.data.length)?
              <div>
                {
                  (isSuccessClient)?
                      <div class="md:mr-2 md:ml-2  lg:mr-2 lg:ml-2 bg-white rounded-b mb-10">
                        <div className='flex justify-center uppercase text-gray-700 items-center font-bold p-3 bg-gray-100 border border-gray-400 rounded-t'>
                          {`${(teamProfile)? teamProfile.fname+' '+teamProfile.lname+"s' sales summary reports" : 'Sales agents summary reports'}`}
                        </div>
                        <div className='flex bg-gray-50'>
                          <div className="w-1/6 flex flex-col justify-start p-2 h-screen border-r border-gray-300">
                            <div className='flex uppercase justify-center items-center w-full font-bold border-b border-gray-200 text-xs p-2 mb-2'>
                            team sales agents
                            </div>
                            <div className='flex flex-col w-full text-xs'>
                              {
                                users.data.map(data => (
                                  <div onClick={(e)=>handleFetchTeamReport(e, data.id)} className='text-xs p-2 hover:bg-gray-200 active:bg-gray-200 cursor-pointer rounded-sm'>
                                    <div className=''>{data.fname} {data.lname}</div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                          <div className="w-full pb-5 bg-white p-2">
                            {
                              (isSuccessTeamRevenue)?
                                (teamData && teamData.data['0'].last28days_revenue !== null)?
                                  <div>
                                    <div class="grid grid-cols-1">         
                                        {
                                              teamData.data.map(data => (
                                                  (data.last28days_revenue)?
                                                  <div>
                                                  <div> 
                                                      <Line
                                                          height={270}
                                                          data={{
                                                            labels: (data.last28days_revenue)? data.last28days_revenue.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                            datasets: [
                                                              {
                                                                label: 'Total',
                                                                data: (data.last28days_revenue)? data.last28days_revenue.map(data=>data.total): '',
                                                                fill: "start",
                                                                backgroundColor: (context: ScriptableContext<"line">) => {
                                                                  const ctx = context.chart.ctx;
                                                                  const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                  gradient.addColorStop(1, 'rgba(80, 120, 255, 0.13)');
                                                                  gradient.addColorStop(0, 'rgba(250,174,50,0.2)');
                                                                  return gradient;
                                                                },
                                                                borderColor: 'rgba(80, 120, 255, 0.13)',
                                                                borderWidth: 0.8
                                                              },
                                                            ]
                                                          }}
                                                          options={{
                                                            bezierCurve: true,
                                                            plugins: {
                                                              color: "red",
                                                              legend: {
                                                                position: 'top',
                                                              },
                                                              title: {
                                                                display: true,
                                                                text: 'Last 28 days daily revenue report',
                                                              },
                                                              tooltip: {
                                                                enabled: false
                                                              },
                                                              datalabels: {
                                                                align: 'top',
                                                                labels: {
                                                                  value: {
                                                                    color: 'black',
                                                                    font: {
                                                                      weight: ''
                                                                    }
                                                                  }
                                                                },
                                                                formatter: (value)=>{
                                                                  return numberWithCommas(value);
                                                                }
                                                              }
                                                            },
                                                            elements: {
                                                              line: {
                                                                tension: 0.1
                                                              }
                                                            },
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                              yAxes: [
                                                                {
                                                                  ticks: {
                                                                    beginAtZero: true,
                                                                    stepSize: 10
                                                                  }
                                                                }
                                                              ]
                                                            },
                                                            layout: {
                                                              padding: {
                                                                  right: 50,
                                                              }
                                                            }
                                                          }
                                                        }
                                                        plugins={[chartBgColor]}
                                                        />
                                                    </div>
                                                  </div>
                                                  :null
                                              ))
                                        }
                                    </div>
                                    <div class="grid grid-cols-1">         
                                        {
                                              teamData.data.map(data => (
                                                  (data.last28days_sales)?
                                                  <div>
                                                  <div> 
                                                      <Line
                                                          height={270}
                                                          data={{
                                                            labels: (data.last28days_sales)? data.last28days_sales.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                            datasets: [
                                                              {
                                                                label: 'Total',
                                                                data: (data.last28days_sales)? data.last28days_sales.map(data=>data.total): '',
                                                                fill: "start",
                                                                backgroundColor: (context: ScriptableContext<"line">) => {
                                                                  const ctx = context.chart.ctx;
                                                                  const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                  gradient.addColorStop(1, 'rgba(80, 120, 255, 0.13)');
                                                                  gradient.addColorStop(0, 'rgba(250,174,50,0.7)');
                                                                  return gradient;
                                                                },
                                                                borderColor: 'rgba(80, 120, 255, 0.13)',
                                                                borderWidth: 0.6,
                                                                borderRadius: 3
                                                              },
                                                            ]
                                                          }}
                                                          options={{
                                                            bezierCurve: true,
                                                            plugins: {
                                                              color: "red",
                                                              legend: {
                                                                position: 'top',
                                                              },
                                                              title: {
                                                                display: true,
                                                                text: 'Last 28 days daily sales report',
                                                              },
                                                              tooltip: {
                                                                enabled: false
                                                              },
                                                              datalabels: {
                                                                align: 'top',
                                                                labels: {
                                                                  value: {
                                                                    color: 'black',
                                                                    font: {
                                                                      weight: ''
                                                                    }
                                                                  }
                                                                },
                                                                formatter: (value)=>{
                                                                  return value;
                                                                }
                                                              }
                                                            },
                                                            elements: {
                                                              line: {
                                                                tension: 0.1
                                                              }
                                                            },
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                              yAxes: [
                                                                {
                                                                  ticks: {
                                                                    beginAtZero: true,
                                                                    stepSize: 10
                                                                  }
                                                                }
                                                              ]
                                                            },
                                                            layout: {
                                                              padding: {
                                                                  right: 50,
                                                              }
                                                            }
                                                          }
                                                        }
                                                        plugins={[chartBgColor]}
                                                        />
                                                    </div>
                                                  </div>
                                                  :null
                                              ))
                                        }
                                    </div>
                                  </div>
                                :
                                  <div class="flex justify-center items-center h-96">
                                    <div className='flex justify-center'>
                                      <div className="flex flex-col space-y-3 text-gray-400">
                                        <div className='flex justify-center text-xs uppercase font-bold'>Reports not available</div>
                                        <ArrowCircleDownIcon className='h-10 flex justify-center'/>
                                      </div>
                                    </div>
                                  </div> 
                              :(isLoadingTeamRevenue)?
                                <div class="flex justify-center items-center h-96">
                                  <div className='flex justify-center'>
                                    <div className="flex flex-col space-y-3 text-gray-400">
                                      <div className='flex justify-center text-xs uppercase font-bold'>Loading...</div>
                                    </div>
                                  </div>
                                </div>
                              :isErrorTeamReavenue?
                                  <div class="flex justify-center items-center h-96">
                                    <div className='flex justify-center'>
                                      <div className="flex flex-col space-y-3 text-gray-400">
                                        <div className='flex justify-center text-xs uppercase font-bold'>Reports not available</div>
                                        <ArrowCircleDownIcon className='h-10 flex justify-center'/>
                                      </div>
                                    </div>
                                  </div>
                              :
                              <div class="flex justify-center items-center h-96">
                                <div className='flex justify-center'>
                                  <div className="flex flex-col space-y-3 text-gray-400">
                                    <div className='flex justify-center text-xs uppercase font-bold'>Choose agent</div>
                                    <ArrowCircleDownIcon className='h-10 flex justify-center'/>
                                  </div>
                                </div>
                              </div> 
                            }
                          </div>
                        </div>
                      </div>
                  : isLoadingClient?
                      <LoadingSpinner />
                  : isErrorClient?
                      <div className="md:flex flex-col p-4 mb-20">
                          <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                      </div>
                  : null
                  
              }
              </div>
            :
              <div className="md:flex flex-col p-4 mb-20">
                <p className="text-blue-500 italic border-b border-black flex flex-1">Revenue report not available</p>
              </div>
          : isLoading?
            <LoadingSpinner />
          : isError?
            <div className="md:flex flex-col p-4 mb-20">
                <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
            </div>
          :null
        }
  </div>
    )
}

export default ClientManagerSalesUsersRevenueReportView;
