import { SearchIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import { useGetAllRoutesOrSearchQuery } from '../../Features/RoutesSlice';
import { useGetClientsQuery } from '../../Features/clientsSlice';

function ManageClientsView({}) {
    const navigate = useNavigate();

    const {
      data: clients,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetClientsQuery()

    return (
      <div className="bg-gray-100">
          <div className="flex items-center justify-between bg-white p-1">
              <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">Clients Management</h1>
              <Link 
                to="/user/add-client" 
                className="w-24 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800">
                  Add new
              </Link>
            </div>
            <div className="flex p-4 mt-2 flex justify-around flex-wrap">
            {(isSuccess)?
                (clients.hasOwnProperty('success') === true && clients.data.length > 0)?
                  clients.data.map(data => (
                      <div key={data.id} className="flex m-2 bg-gray-100 rounded-md flex-col justify-around">
                          <div className="flex">
                            <img 
                                className="bg-gray-100 rounded-t-lg object-fill h-48 w-72"
                                src={`http://localhost:8000/client_logo_image/`+data.logo_image_url} 
                                alt="Image" 
                            />  
                          </div>
                          <Link to={`/user/client-details/${data.id}`} className="flex flex-col shadow-lg">
                              <div className="bg-blue-300 hover:bg-blue-400 flex justify-center cursor-pointer p-2 rounded-b-md text-sm text-gray-100">
                                {data.name}
                              </div>
                          </Link>
                      </div>
                  ))
              :
                <div>No users record found</div>
              : isLoading?
                    <LoadingSpinner />
                : isError?
                  <div className="md:flex flex-col p-4">
                    <p className="text-blue-500 italic border-b border-black flex flex-1">Empty</p>
                    <Link to={`/user/add-client`} className='flex justify-center p-8'>
                      <h1 className='bg-blue-600 hover:bg-yellow-500 p-4 text-sm font-semibold rounded-md text-white cursor-pointer'>Add Client</h1>
                    </Link>
                  </div>
                : null   
            }
                </div>
      </div>
    )
}

export default ManageClientsView;
