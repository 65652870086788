import LoadingSpinner from '../LoadingSpinner';
import { useClientCustomerRetentionAnalysisReportQuery, useGetClientByIdQuery } from '../../Features/clientsSlice';
import ClientHeaderView from '../App/ClientHeaderView';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ScriptableContext,
  Filler,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function ClientCustomerRetentionAnalysisView() {

    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const {
      data: client,
      isLoading: isLoadingClient,
      isSuccess: isSuccessClient,
      isError: isErrorClient
  } = useGetClientByIdQuery(client_id);

    const {
      data: retentionAnalysis,
      isLoading: isLoadingRetentionAnalysis,
      isSuccess: isSuccessRetentionAnalysis,
      isError: isErrorRetentionAnalysis,
      error
    } = useClientCustomerRetentionAnalysisReportQuery(client_id)

    const formatDisplayDateForDayMonth = (date)=>{
      let tempDate = new Date(date);
      let month = tempDate.toLocaleString('default',{month: 'short'});
      return month + "-" + tempDate.getDate();
    }

    const numberWithCommas = (x)=>{
      if(x !==null){
        const formatedNumber = x.toLocaleString('en-US', {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                });
        return formatedNumber;
      }
      return null;
    }

    const chartBgColor = {
      id: 'chartBgColor',
      beforeDraw: (chart, options)=>{
        const{ ctx, width, height }= chart;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    }

  return   (
        <div className="bg-gray-100">
            <ClientHeaderView />
             {
                (isSuccessClient)?
                    <div className="flex justify-center items-center bg-gray-50 p-1">
                        <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} customer retention analysis</h1>
                    </div>
                 : null
             }
              {
                (isSuccessRetentionAnalysis)?
                  (retentionAnalysis.data.length)?
                    <div>
                      {
                        (isSuccessClient)?
                            <div class="mt-4 sm:mt-0  md:pt-4 space-y-4 md:mr-28 md:ml-28  lg:mr-28 lg:ml-28 mb-12">
                                <div class="grid grid-cols-1">         
                                    {
                                              <div>
                                                <div>
                                                  <Line
                                                      height={260}
                                                      data={{
                                                        labels: (retentionAnalysis.data)? retentionAnalysis.data.map(data=>formatDisplayDateForDayMonth(data.startOfMonth)+" - "+formatDisplayDateForDayMonth(data.endOfMonth)): '',
                                                        datasets: [
                                                          {
                                                            label: 'Total',
                                                            data: (retentionAnalysis.data)? retentionAnalysis.data.map(data=>numberWithCommas(data.percentage)): '',
                                                            fill: "start",
                                                            backgroundColor: (context: ScriptableContext<"line">) => {
                                                              const ctx = context.chart.ctx;
                                                              const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                              gradient.addColorStop(1, 'rgba(54, 162, 235, 0.05)');
                                                              gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                                              return gradient;
                                                            },
                                                            borderColor: 'rgba(75, 192, 192, 1)',
                                                            borderWidth: 1
                                                          },
                                                        ]
                                                      }}
                                                      options={{
                                                        bezierCurve: true,
                                                        plugins: {
                                                          color: "red",
                                                          legend: {
                                                            position: 'top',
                                                          },
                                                          title: {
                                                            display: true,
                                                            text: 'Last 28 days daily sales report',
                                                          },
                                                          tooltip: {
                                                            enabled: false
                                                          },
                                                          datalabels: {
                                                            align: 'top',
                                                            labels: {
                                                              value: {
                                                                color: 'black',
                                                                font: {
                                                                  weight: ''
                                                                }
                                                              }
                                                            },
                                                            formatter: (value)=>{
                                                              return value;
                                                            }
                                                          }
                                                        },
                                                        elements: {
                                                          line: {
                                                            tension: 0.16
                                                          }
                                                        },
                                                        maintainAspectRatio: false,
                                                        scales: {
                                                          yAxes: [
                                                            {
                                                              ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 10
                                                              }
                                                            }
                                                          ]
                                                        },
                                                        layout: {
                                                          padding: {
                                                              right: 50,
                                                          }
                                                        }
                                                      }
                                                    }
                                                    plugins={[chartBgColor]}
                                                    />
                                                </div>
                                              </div>
                                    }
                                </div>
                            </div>
                        : isLoadingClient?
                            <LoadingSpinner />
                        : isErrorClient?
                            <div className="md:flex flex-col p-4 mb-20">
                                <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                            </div>
                        : null
                        
                    }
                    </div>
                  :
                    <div className="md:flex flex-col p-4 mb-20">
                      <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                    </div>
                : isLoadingRetentionAnalysis?
                  <LoadingSpinner />
                : isErrorRetentionAnalysis?
                  <div className="md:flex flex-col p-4 mb-20">
                      <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                  </div>
                :null
              }
        </div>
)
}

export default ClientCustomerRetentionAnalysisView;
