import { apiSlice } from "../app/api/apiSlice";

export const commissionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCommissionRate: builder.query({
            query: () => '/user/get-commission-rate',
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['CommissionRate']
        }),
        setCommissionRate: builder.mutation({
            query: (reqBoby) => ({
                url: `user/set-commission-rate`,
                method: 'POST',
                body: reqBoby
            }),
            invalidatesTags: ['CommissionRate']
        }),
        getUserCommissionBalance: builder.query({
            query: (user_id) => `/user/user-commission-balance/${user_id}`,
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: ['commissionSettlementAccounts']
        }),
    })
})

export const {
    useSetCommissionRateMutation,
    useGetCommissionRateQuery,
    useGetUserCommissionBalanceQuery,
} = commissionApiSlice

