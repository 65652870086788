import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectCurrentClientRole, selectCurrentUser } from '../../Features/auth/authSlice';
import { useGetClientLast28daysTicketSalesReportQuery } from '../../Features/dataAnalysisReportingSlice';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartBarIcon, ChartPieIcon, ChartSquareBarIcon, ChatAlt2Icon, ChatIcon, ColorSwatchIcon, IdentificationIcon, TableIcon, TruckIcon, UserCircleIcon, UserGroupIcon } from '@heroicons/react/solid';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import ClientDataAnalysisHeaderView from './ClientDataAnalysisHeaderView';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);




function ClientDataAnalystManagerHomeView(props) {
  const navigate = useNavigate();
  const  role  = useSelector(selectCurrentClientRole);
  const  user  = useSelector(selectCurrentUser);
  
  const client_id = user.client_id;

    const {
      data: client,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetClientByIdQuery(client_id)

    const {
      data: last28Days,
      isSuccess: isSuccessLast28Days,
      isLoading: isLoadingLast28Days,
      isError: isErrorLast28Days,
      error: errorLast28Days
  } = useGetClientLast28daysTicketSalesReportQuery(client_id);
  
  const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

    return (
      <div className="bg-gray-100 mb-10">
          <ClientDataAnalysisHeaderView />
          {
            (isSuccessLast28Days) ?
              <div>
                <div className="mb-10" >
                  {
                    (isSuccess)?
                    <div className="flex items-center justify-center bg-gray-50 p-0">
                      <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} analysis Dashboard</h1>
                    </div>
                    : null
                  }
                </div>
                <div class="mt-4 sm:mt-0  md:pt-4 md:mr-32 md:ml-32  lg:mr-32 lg:ml-32 bg-white">
                <div class="grid grid-cols-2">
                  {
                      (last28Days.data.length > 0) ?
                            <Line
                              height={260}
                              data={{
                                labels: last28Days.data.map(data=>formatDisplayDate(data.date)),
                                datasets: [
                                  {
                                    label: 'Ticket Sales',
                                    data: last28Days.data.map(data=>data.total),
                                    fill: "start",
                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                      const ctx = context.chart.ctx;
                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                      gradient.addColorStop(1, 'rgba(54, 162, 235, 0.05)');
                                      gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                      return gradient;
                                    },
                                    borderColor: 'rgba(75, 192, 192, 1)',
                                    borderWidth: 1
                                  },
                                ]
                              }}
                              options={{
                                bezierCurve: true,
                                plugins: {
                                  legend: {
                                    position: 'top',
                                  },
                                  title: {
                                    display: true,
                                    text: 'Last 28 days daily sales',
                                  },
                                  tooltip: {
                                    enabled: false
                                  },
                                  datalabels: {
                                    align: 'top',
                                  }
                                },
                                elements: {
                                  line: {
                                    tension: 0.14
                                  }
                                },
                                maintainAspectRatio: false,
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: true,
                                        stepSize: 50
                                      }
                                    }
                                  ]
                                },
                                layout: {
                                  padding: {
                                      right: 50,
                                  }
                                }
                              }}
                            />
                        : null
                  }
                </div>
              </div>
              <div className='flex justify-center p-6 font-semibold text-gray-500'>
                <h1 className='text-xs'>Manage your account</h1>
              </div>
                <div className='flex items-center justify-evenly flex-wrap  md:mr-32 md:ml-32  lg:mr-32 lg:ml-32 space-x-4 space-y-4'>
                  <Link to={`/data-analysis/total-sales-summary-reports`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-yellow-400 hover:text-yellow-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                  <div className='flex flex-col'>
                    <ChartPieIcon className='h-20'/>
                    <p className='flex justify-center text-gray-800'>Sales</p>
                  </div>
                  </Link>
                  <Link to={`/data-analysis/station-mananger-users`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-blue-400 hover:text-blue-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                  <div className='flex flex-col'>
                    <UserGroupIcon className='h-20'/>
                    <p className='flex justify-center text-gray-900'>Teams</p>
                  </div>
                  </Link>
                </div>
              </div>
              : isLoadingLast28Days ?
              <LoadingSpinner />
              : isErrorLast28Days ?
                  <div>
                    <div className="mb-10" >
                      {
                        (isSuccess)?
                        <div className="flex items-center justify-center bg-gray-50 p-0">
                          <h1 className="text-sm text-gray-700 uppercase font-semibold p-2">{client.data.name} Dashboard</h1>
                        </div>
                        : null
                      }
                    </div>
                    <div class="mt-4 sm:mt-0  md:pt-4 md:mr-32 md:ml-32  lg:mr-32 lg:ml-32 bg-white">
                        <div class="grid grid-cols-2">
                        <Line
                              height={260}
                              data={{
                                labels: [],
                                datasets: [
                                  {
                                    label: 'Ticket Sales',
                                    data: [],
                                    fill: "start",
                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                      const ctx = context.chart.ctx;
                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                      gradient.addColorStop(1, 'rgba(54, 162, 235, 0.05)');
                                      gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                      return gradient;
                                    },
                                    borderColor: 'rgba(75, 192, 192, 1)',
                                    borderWidth: 1
                                  },
                                ]
                              }}
                              options={{
                                bezierCurve: true,
                                plugins: {
                                  legend: {
                                    position: 'top',
                                  },
                                  title: {
                                    display: true,
                                    text: 'Last 28 days daily sales',
                                  },
                                  tooltip: {
                                    enabled: false
                                  },
                                  datalabels: {
                                    align: 'top',
                                  }
                                },
                                elements: {
                                  line: {
                                    tension: 0.14
                                  }
                                },
                                maintainAspectRatio: false,
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: true,
                                        stepSize: 50
                                      }
                                    }
                                  ]
                                },
                              }}
                            />
                      </div>
                    </div>
                    <div className='flex justify-center p-6 font-semibold text-gray-500'>
                      <h1 className='text-xs'>Manage your account</h1>
                    </div>
                    <div className='flex items-center justify-evenly flex-wrap  md:mr-32 md:ml-32  lg:mr-32 lg:ml-32 space-x-4 space-y-4'>
                      <Link to={`/data-analysis/total-sales-summary-reports`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-yellow-400 hover:text-yellow-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                      <div className='flex flex-col'>
                        <ChartPieIcon className='h-20'/>
                        <p className='flex justify-center text-gray-800'>Sales</p>
                      </div>
                      </Link>
                      <Link to={`/data-analysis/station-mananger-users`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-blue-400 hover:text-blue-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                      <div className='flex flex-col'>
                        <UserGroupIcon className='h-20'/>
                        <p className='flex justify-center text-gray-900'>Teams</p>
                      </div>
                      </Link>
                    </div>
                  </div>
                : null
          }
      </div>
    )
}

export default ClientDataAnalystManagerHomeView;
