import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectCurrentClientRole, selectCurrentUser } from '../../Features/auth/authSlice';
import { useGetClientLast28daysRevenueReportQuery } from '../../Features/dataAnalysisReportingSlice';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartBarIcon, ChartPieIcon, ChartSquareBarIcon, ChatAlt2Icon, ChatIcon, ColorSwatchIcon, IdentificationIcon, TableIcon, TruckIcon, UserCircleIcon, UserGroupIcon } from '@heroicons/react/solid';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import ClientAccountingHeaderView from './ClientAccountingHeaderView';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);




function ClientAccountingHomeView(props) {
  const navigate = useNavigate();
  const  role  = useSelector(selectCurrentClientRole);
  const  user  = useSelector(selectCurrentUser);
  
  const client_id = user.client_id;

    const {
      data: client,
      isSuccess,
    } = useGetClientByIdQuery(client_id)

    const {
      data: last28Days,
      isSuccess: isSuccessLast28Days,
      isLoading: isLoadingLast28Days,
      isError: isErrorLast28Days,
  } = useGetClientLast28daysRevenueReportQuery(client_id);

  const numberWithCommas = (x)=>{
    if(x !==null){
      const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
      return formatedNumber;
    }
    return null;
  }
  
  const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

    return (
      <div className="bg-gray-100 mb-10">
          <ClientAccountingHeaderView />
          {
            (isSuccessLast28Days) ?
              <div>
                <div className="mb-10" >
                  {
                    (isSuccess)?
                    <div className="flex items-center justify-center bg-gray-50 p-0">
                      <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} Accounting Portal Dashboard</h1>
                    </div>
                    : null
                  }
                </div>
                <div class="mt-4 sm:mt-0  md:pt-4 md:mr-28 md:ml-28  lg:mr-28 lg:ml-28 bg-white">
                <div class="grid grid-cols-2">
                  {
                      (last28Days.data.length > 0) ?
                            <Line
                              height={260}
                              data={{
                                labels: last28Days.data.map(data=>formatDisplayDate(data.date)),
                                datasets: [
                                  {
                                    label: 'Revenue',
                                    data: last28Days.data.map(data=>data.total),
                                    fill: "start",
                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                      const ctx = context.chart.ctx;
                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                      gradient.addColorStop(1, 'rgba(54, 162, 235, 0.05)');
                                      gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                      return gradient;
                                    },
                                    borderColor: 'rgba(75, 192, 192, 1)',
                                    borderWidth: 1
                                  },
                                ]
                              }}
                              options={{
                                bezierCurve: true,
                                plugins: {
                                  legend: {
                                    position: 'top',
                                  },
                                  title: {
                                    display: true,
                                    text: 'Last 28 days daily revenue',
                                  },
                                  tooltip: {
                                    enabled: false
                                  },
                                  datalabels: {
                                    align: 'top',
                                  },
                                  datalabels: {
                                    align: 'top',
                                    labels: {
                                      value: {
                                        color: 'black',
                                        font: {
                                          weight: ''
                                        }
                                      }
                                    },
                                    formatter: (value)=>{
                                      return numberWithCommas(value);
                                    }
                                  },
                                },
                                elements: {
                                  line: {
                                    tension: 0.14
                                  }
                                },
                                maintainAspectRatio: false,
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: true,
                                        stepSize: 50
                                      }
                                    }
                                  ]
                                },
                                layout: {
                                  padding: {
                                      right: 50,
                                  }
                                }
                              }}
                            />
                        : null
                  }
                </div>
              </div>
              <div className='flex justify-center p-6 font-semibold text-gray-500'>
                <h1 className='text-xs'>Manage your account</h1>
              </div>
              <div className='flex items-center justify-evenly flex-wrap  md:mr-32 md:ml-32  lg:mr-32 lg:ml-32 space-x-4 space-y-4'>
                <Link to={`/accounting/client-revenue-reporting`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-yellow-400 hover:text-yellow-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                <div className='flex flex-col'>
                  <ChartPieIcon className='h-20'/>
                  <p className='flex justify-center text-gray-800'>Revenue</p>
                </div>
                </Link>
                <Link to={`/accounting/manager-users`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-blue-400 hover:text-blue-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                <div className='flex flex-col'>
                  <UserGroupIcon className='h-20'/>
                  <p className='flex justify-center text-gray-900'>Teams</p>
                </div>
                </Link>
                </div>
              </div>
              : isLoadingLast28Days ?
              <LoadingSpinner />
              : isErrorLast28Days ?
                  <div>
                    <div className="mb-10" >
                      {
                        (isSuccess)?
                        <div className="flex items-center justify-center bg-gray-50 p-0">
                          <h1 className="text-sm text-gray-700 uppercase font-semibold p-2">{client.data.name} Dashboard</h1>
                        </div>
                        : null
                      }
                    </div>
                    <div class="mt-4 sm:mt-0  md:pt-4 md:mr-32 md:ml-32  lg:mr-32 lg:ml-32 bg-white">
                        <div class="grid grid-cols-2">
                        <Line
                              height={260}
                              data={{
                                labels: [],
                                datasets: [
                                  {
                                    label: 'Ticket Sales',
                                    data: [],
                                    fill: "start",
                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                      const ctx = context.chart.ctx;
                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                      gradient.addColorStop(1, 'rgba(54, 162, 235, 0.05)');
                                      gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                      return gradient;
                                    },
                                    borderColor: 'rgba(75, 192, 192, 1)',
                                    borderWidth: 1
                                  },
                                ]
                              }}
                              options={{
                                bezierCurve: true,
                                plugins: {
                                  legend: {
                                    position: 'top',
                                  },
                                  title: {
                                    display: true,
                                    text: 'Last 28 days daily sales',
                                  },
                                  tooltip: {
                                    enabled: false
                                  },
                                  datalabels: {
                                    align: 'top',
                                  }
                                },
                                elements: {
                                  line: {
                                    tension: 0.14
                                  }
                                },
                                maintainAspectRatio: false,
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: true,
                                        stepSize: 50
                                      }
                                    }
                                  ]
                                },
                                layout: {
                                  padding: {
                                      right: 50,
                                  }
                                }
                              }}
                            />
                      </div>
                    </div>
                    <div className='flex justify-center p-6 font-semibold text-gray-500'>
                      <h1 className='text-xs'>Manage your account</h1>
                    </div>
                    <div className='flex items-center justify-evenly flex-wrap  md:mr-32 md:ml-32  lg:mr-32 lg:ml-32 space-x-4 space-y-4'>
                      <Link to={`/client/business-performance-reporting`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-yellow-400 hover:text-yellow-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                      <div className='flex flex-col'>
                        <ChartPieIcon className='h-20'/>
                        <p className='flex justify-center text-gray-800'>Revenue</p>
                      </div>
                      </Link>
                      <Link to={`/client/routes-management`} className="shadow-md hover:shadow-lg p-4 w-1/4 border bg-white border-gray-200 hover:border-gray-300 text-gray-400 hover:text-gray-500 rounded-md flex justify-center cursor-pointer">
                      <div className='flex flex-col'>
                        <TruckIcon className='h-20'/>
                        <p className='flex justify-center text-gray-800'>Routes</p>
                      </div>
                      </Link>
                      <Link to={`/client/client-seating-profile-management`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-indigo-600 hover:text-indigo-700 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                      <div className='flex flex-col'>
                        <ColorSwatchIcon className='h-20'/>
                        <p className='flex justify-center text-gray-900'>Seats</p>
                      </div>
                      </Link>
                      <Link to={`/client/business-sales-report`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-rose-400 hover:text-rose-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                      <div className='flex flex-col'>
                        <ChartBarIcon className='h-20'/>
                        <p className='flex justify-center text-gray-900'>Sales</p>
                      </div>
                      </Link>
                      <Link to={`/client/client-bulk-sms`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-green-300 hover:text-green-400 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                      <div className='flex flex-col'>
                        <ChatAlt2Icon className='h-20'/>
                        <p className='flex justify-center text-gray-900'>Bulk SMS</p>
                      </div>
                      </Link>
                      <Link to={`/client/client-profile`} className="shadow-md hover:shadow-lg p-4 w-1/4 bg-white text-blue-400 hover:text-blue-500 border border-gray-200 hover:border-gray-300 rounded-md flex justify-center cursor-pointer">
                      <div className='flex flex-col'>
                        <IdentificationIcon className='h-20'/>
                        <p className='flex justify-center text-gray-900'>Profile</p>
                      </div>
                      </Link>
                    </div>
                  </div>
                : null
          }
      </div>
    )
}

export default ClientAccountingHomeView;
