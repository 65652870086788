import { ArrowCircleDownIcon, ChartBarIcon, ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon, SearchIcon } from '@heroicons/react/outline';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from 'rc-pagination';
import { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import swal from 'sweetalert';
import { Menu} from "@headlessui/react";
import { useGetClientRoutesOrSearchQuery, useToggleClientRouteStatusMutation } from '../../Features/clientsRoutesSlice';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import ClientHeaderView from '../App/ClientHeaderView';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { ChartPieIcon, ChevronDownIcon, PencilAltIcon } from '@heroicons/react/solid';

function RoutesManagementView({}) {
    const navigate = useNavigate();
    const defaultPagination = 100;
    const [search, setSearch] = useState(0)
    const [pager, setPager] = useState("")
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;    

    const {
      data: routes,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetClientRoutesOrSearchQuery({paginate: defaultPagination, search: search, page: pager, id: client_id})

    const {
      data: client,
      isSuccess: isSuccessClient,
  } = useGetClientByIdQuery(client_id);

    const [input, setInput] = useState({
      search: ''
    })

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value})
    if(input.search != 0){
      setSearch(input.search)
    }else {

      setSearch(0)
    }
  }

  const onChange = (currentPage) => {
    setPager(currentPage)
  }

  const [toggleClientRouteStatus] = useToggleClientRouteStatusMutation();

  const toggleClientRouteStatusHandler = async (e, routeId, clientId) =>{
    if(window.confirm("Are you sure you want to toggle route status?")){
      try {
        const resp = await toggleClientRouteStatus({route_id: routeId, client_id: clientId}).unwrap()
        swal("Success",resp.message,"success");
      } catch (err) {
        if(err.data.error == true){
            swal("Error",err.data.error,"error");
          }
      }
    }
  }
    
    return (
      <div className=" bg-gray-100">
        <ClientHeaderView />
          <div className="flex justify-between items-center bg-gray-50 p-0">
              {
                (isSuccessClient)?
                  <div className='flex items-center'>
                      <h1 className="text-sm text-gray-700 uppercase font-semibold p-2">{client.data.name} {client.data.business_type} Routes Management</h1>
                  </div>
                : null
              }
                <Menu as="div" className='relative z-10 mr-2 p-1'>
                  <Menu.Button className="flex justify-center items-center bg-gray-300 hover:bg-gray-400 border border-gray-600 curosor-pointer w-20 rounded-md">
                    <div className="text-sm">Menu</div>
                    <ChevronDownIcon className='h-6 w-6 text-gray-600' />
                  </Menu.Button> 
                  <Menu.Items className="absolute focus:outline-none right-0 bg-gray-50 rounded-md shadow-lg border mt-1 w-52">
                    <Menu.Item>
                      <div className='flex justify-start text-xs hover:bg-gray-200 cursor-pointer px-4 py-2 text-gray-700 border-b border-gray-200'>
                        {
                          (isSuccessClient)?
                            <Link to={`/client/manage-routes-for-client-subscription`}>
                              Add route
                            </Link>
                          :null
                        }
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className='flex justify-start text-xs hover:bg-gray-200 cursor-pointer px-4 py-2 text-gray-700 border-b border-gray-200'>
                        {
                          (isSuccessClient)?
                            <Link to={`/client/increament-routes-charge`}>
                              Increament charge
                            </Link>
                          :null
                        }
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className='flex justify-start text-xs hover:bg-gray-200 cursor-pointer px-4 py-2 text-gray-700 border-b border-gray-200'>
                        {
                          (isSuccessClient)?
                            <Link to={`/client/decreament-routes-charge`}>
                              Decreament charge
                            </Link>
                          :null
                        }
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className='flex justify-start text-xs hover:bg-gray-200 cursor-pointer px-4 py-2 text-gray-700 border-b border-gray-200'>
                        {
                          (isSuccessClient)?
                            <Link to={`/client/edit-refund-policy`}>
                              Refund policy
                            </Link>
                          :null
                        }
                      </div>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
            </div>
            {(isSuccess)?
                <div>
                    <div className="flex justify-end p-1 bg-gray-100 mr-2">
                        <form className="">
                          <div className="relative flex items-center text-gray-400 focus-within:text-gray-600">
                            <SearchIcon className="w-4 h-4 absolute ml-3 pointer-events-none" />
                            <input
                              type="text"
                              name="search"
                              value={input.search}
                              onChange={handleChange}
                              placeholder="Search route"
                              autoComplete="off"
                              aria-label="Search user"
                              className="pr-3 pl-10 px-3 py-1 bg-gray-300 text-sm placeholder-gray-500 text-black rounded-sm border-none outline-none"
                            />
                          </div>
                        </form>
                    </div>
                  {
                  (routes.success === true && routes.data.data.length > 0)?
                  <div className='pr-1 pl-1'>
                    <table className="w-full divide-y divide-gray-200">
                      <thead className="bg-teal-500 shadow-lg">
                          <tr>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Departure
                          </th>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Destination
                          </th>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Duration
                          </th>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Commission_Charge
                          </th>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Cash_Charge
                          </th>
                          <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                              Status
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>  
                      <tbody className="bg-white divide-y divide-gray-100">
                              { (routes.success === true && routes.data.data.length > 0)?
                                  routes.data.data.map(data => (
                                      <tr key={data.id} className={`${(data.route_duration !== '' && data.client_charge == null)? 'bg-yellow-100 hover:bg-yellow-200': (data.status == 1)? 'bg-green-100 hover:bg-green-200': (data.client_charge != null && data.cash_charge != null && data.status == 0)? 'bg-blue-100 hover:bg-blue-200 text-gray-900': 'hover:bg-gray-200 text-gray-900'}`}>
                                          <td className="px-6 py-1 whitespace-nowrap">
                                          <div className="flex items-center">
                                              <div className="flex items-center space-x-4 text-xs font-medium">
                                                  <input 
                                                      type="checkbox" 
                                                      name="roles[]" 
                                                      value={data.id}
                                                      onChange={handleChange}
                                                  />
                                                  <label className=''>{data.start_off_station_name}</label>
                                              </div>
                                          </div>
                                          </td>
                                          <td className="px-2 py-1 whitespace-nowrap text-xs font-medium">
                                              <div className=''>{data.destination_station_name}</div>
                                          </td>
                                          <td className="px-2 py-1 whitespace-nowrap text-xs">
                                            {
                                                (data.route_duration !== "")?
                                                  <div className=' text-xs'>
                                                      <div className=''>
                                                          {data.route_duration} Hrs
                                                      </div>
                                                  </div>
                                                :
                                                  <div className='text-sm'>
                                                      <div className='text-sm'>
                                                          --
                                                      </div>
                                                  </div>
                                              }
                                          </td>
                                          <td className="px-2 py-1 whitespace-nowrap text-sm">
                                            {
                                                (data.client_charge !== null)?
                                                  <div className=' text-xs'>
                                                      <div className=''>
                                                          K {data.client_charge}
                                                      </div>
                                                  </div>
                                                :
                                                  <div className='text-sm'>
                                                      <div className='text-sm'>
                                                          --
                                                      </div>
                                                  </div>
                                              }
                                          </td>
                                          <td className="px-2 py-1 whitespace-nowrap text-sm">
                                            {
                                                (data.cash_charge !== null)?
                                                  <div className=' text-xs'>
                                                      <div className=''>
                                                          K {data.cash_charge}
                                                      </div>
                                                  </div>
                                                :
                                                  <div className='text-sm'>
                                                      <div className='text-sm'>
                                                          --
                                                      </div>
                                                  </div>
                                              }
                                          </td>
                                          <td className="px-1 py-0 whitespace-nowrap">
                                            {
                                              (data.status === 1)?
                                                <div onClick={(e)=>toggleClientRouteStatusHandler(e,data.client_route_id,data.client_id)} className='flex justify-center md:w-20 w-14 sm:14 mr-4 mt-1 bg-gray-100 text-sm rounded-md hover:bg-gray-300  cursor-pointer text-green-500 hover:text-green-600'>
                                                    <div className='text-xs'>
                                                        Active
                                                    </div>
                                                </div>
                                              :
                                                <div onClick={(e)=>toggleClientRouteStatusHandler(e,data.client_route_id,data.client_id)} className='flex justify-center md:w-20 w-14 sm:14 mr-4 mt-1 bg-gray-100 text-sm rounded-md hover:bg-gray-300 cursor-pointer text-red-600 hover:text-red-700'>
                                                    <div className='text-xs'>
                                                        Disabled
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                        <td className="px-1 py-0 whitespace-nowrap">
                                          <Menu as="div" className=''>
                                            <Menu.Button className="focus:outline-none focus:ring-2 focus:ring-offset-2 text-gray-500 focus:ring-green-500 rounded-full">
                                              <ArrowCircleDownIcon className='h-6'/>
                                            </Menu.Button> 
                                            <Menu.Items className="absolute focus:outline-none right-8 bg-white rounded-md shadow-lg w-32">
                                              <Menu.Item>
                                                <Link to={`/client/route-performance-reporting/${data.client_route_id}`} className='flex justify-start border-b border-gray-300 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                  <ChartPieIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                                                  <p className="">Revenue</p>
                                                </div>
                                                </Link>
                                              </Menu.Item>
                                              <Menu.Item>
                                                <Link to={`/client/route-sales-reporting/${data.client_route_id}`} className='flex justify-start border-b border-gray-300 hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                  <ChartBarIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                                                  <p className="">Sales</p>
                                                </div>
                                                </Link>
                                              </Menu.Item>
                                              <Menu.Item>
                                                <Link to={`/client/edit-client-route/${data.route_id}/${data.client_route_id}`} className='flex justify-start hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                                                <div className="flex items-center justify-start text-gray-900 text-xs space-x-1">
                                                  <PencilAltIcon className="h-5 text-gray-500 hover:text-gray-600"/>
                                                  <p className="">Edit</p>
                                                </div>
                                                </Link>
                                              </Menu.Item>
                                            </Menu.Items>
                                          </Menu>
                                        </td> 
                                      </tr>
                                      ))
                                      : ''
                              }
                        </tbody>
                      </table>
                    </div>
                      :
                        <div>No users record found</div>
                    }
                </div>
              : isLoading?
                    <LoadingSpinner />
                : isError?
                  <div className="md:flex flex-col p-4">
                    <p className="text-blue-500 italic border-b border-black flex flex-1">You are not subscribed to any route</p>
                    <div className='flex justify-center p-8'>
                      <h1 className='flex p-1 text-sm rounded-md text-gray-600 space-x-2'>
                        <p className=''>Note: Make sure to add a seating profile before subscribing to routes</p>
                        <Link to={`/client/client-seating-profile-management`} className='text-blue-400'>
                          click here to add a seating profile
                        </Link>
                      </h1>
                    </div>
                    <Link to={`/client/manage-routes-for-client-subscription`} className='flex justify-center p-8'>
                      <h1 className='bg-teal-600 hover:bg-teal-500 p-3 text-white text-xs font-semibold rounded-md cursor-pointer uppercase'>Subscribe to routes</h1>
                    </Link>
                  </div>
                : null
              }
          <div className="flex justify-center p-1 mt-2 mb-2">
            <div>
              {(isSuccess)?
                (routes.success === true && routes.data.data.length > 0)?
                (routes.data.total > 100)?
                  <Pagination
                    className="flex items-center space-x-3 text-blue-800 cursor-pointer"
                    defaultPageSize={100}
                    total={routes.data.total}
                    current={routes.data.current_page}
                    onChange={onChange}
                    prevIcon={<ChevronLeftIcon className='h-6 w-10 text-blue-700 cursor-pointer hover:text-blue-500' />}
                    nextIcon={<ChevronRightIcon className='h-6 w-10 text-blue-700 cursor-pointer hover:text-blue-500' />}
                  />
                : null
                : null
                : null
              }
            </div>
          </div>
      </div>
    )
}

export default RoutesManagementView;
