import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useGetClientByIdQuery } from '../../Features/clientsSlice';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ScriptableContext,
    Filler,
  } from 'chart.js';
  import { Bar, Line, Pie } from 'react-chartjs-2';
import { useGetClientTotalSalesAndTransactionsReportSumarryQuery } from '../../Features/dataAnalysisReportingSlice';
import jsPDF from "jspdf";
import { DocumentIcon } from '@heroicons/react/solid';
import { selectCurrentUser } from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import ClientDataAnalysisHeaderView from './ClientDataAnalysisHeaderView';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

function ClientDataAnalystTotalSalesSummaryReportingView() {
    const navigate = useNavigate();
    const  user  = useSelector(selectCurrentUser);
  
    const client_id = user.client_id;

    const {
        data: client,
        isSuccess: isSuccessClient,
        isLoading: isLoadingClient,
        isError: isErrorClient,
        error: errorClient
    } = useGetClientByIdQuery(client_id);

const {
    data: clientSalesReport,
    isSuccess: isSuccessClientSalesReport,
    isLoading: isLoadingClientSalesReport,
    isError: isErrorClientSalesReport,
    error: errorClientSalesReport
} = useGetClientTotalSalesAndTransactionsReportSumarryQuery(client_id);

const formatDisplayDate = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getFullYear();
  }

  const formatDisplayDateForDayMonth = (date)=>{
    let tempDate = new Date(date);
    let month = tempDate.toLocaleString('default',{month: 'short'});
    return month + "-" + tempDate.getDate();
  }

  const generateCurrentYear = ()=>{
    return new Date().getFullYear();
  }

  const generatePDF = ()=>{
    last3MonthsGeneratePDF()
    last4WeeksGeneratePDF()
    currentYearGeneratePDF()
    annualReportGeneratePDF()
  }

  const numberWithCommas = (x)=>{
    if(x !==null){
      const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
      return formatedNumber;
    }
    return null;
  }

  const last4WeeksGeneratePDF = ()=>{
    const chart1Image = document.getElementsByTagName('canvas')[1].toDataURL("Image/jpeg",1.0)
    const clientName = isSuccessClient && client.data.name+"_"+client.data.business_type;
    const label = isSuccessClient && client.data.name+" "+client.data.business_type;
    let pdf = new jsPDF("l", "pt", "a2");
        pdf.setFontSize(22);
        pdf.addImage(chart1Image, 'JPEG',140 ,300, 1440, 600);
        pdf.setTextColor('red');
        pdf.text(`SWIFT TICKET ${label.toUpperCase()} REVENUE REPORT`,620 ,100, 1440, 600);
        pdf.setFontSize(16);
        pdf.setTextColor('black')
        pdf.text(`Copyright 2023 Switft Ticket`,760 ,980, 1440, 1440);
        pdf.save('Swift_Ticket_'+clientName+"_last_4_weeks_weekly_revenue_repord.pdf");
  }

  const last3MonthsGeneratePDF = ()=>{
    const chart1Image = document.getElementsByTagName('canvas')[2].toDataURL("Image/jpeg",1.0)
    const clientName = isSuccessClient && client.data.name+"_"+client.data.business_type;
    const label = isSuccessClient && client.data.name+" "+client.data.business_type;
    let pdf = new jsPDF("l", "pt", "a2");
        pdf.setFontSize(22);
        pdf.addImage(chart1Image, 'JPEG',140 ,300, 1440, 600);
        pdf.setTextColor('red');
        pdf.text(`SWIFT TICKET ${label.toUpperCase()} REVENUE REPORT`,620 ,100, 1440, 600);
        pdf.setFontSize(16);
        pdf.setTextColor('black')
        pdf.text(`Copyright 2023 Switft Ticket`,760 ,980, 1440, 1440);
        pdf.save('Swift_Ticket_'+clientName+"_last_3_months_monthly_revenue_repord.pdf");
  }

  const currentYearGeneratePDF = ()=>{
    const chart1Image = document.getElementsByTagName('canvas')[3].toDataURL("Image/jpeg",1.0)
    const clientName = isSuccessClient && client.data.name+"_"+client.data.business_type;
    const label = isSuccessClient && client.data.name+" "+client.data.business_type;
    let pdf = new jsPDF("l", "pt", "a2");
        pdf.setFontSize(22);
        pdf.addImage(chart1Image, 'JPEG',140 ,300, 1440, 600);
        pdf.setTextColor('red');
        pdf.text(`SWIFT TICKET ${label.toUpperCase()} REVENUE REPORT`,620 ,100, 1440, 600);
        pdf.setFontSize(16);
        pdf.setTextColor('black')
        pdf.text(`Copyright 2023 Switft Ticket`,760 ,980, 1440, 1440);
        pdf.save('Swift_Ticket_'+clientName+'_'+generateCurrentYear()+"_monthly_revenue_repord.pdf");
  }

  const annualReportGeneratePDF = ()=>{
    const chart1Image = document.getElementsByTagName('canvas')[4].toDataURL("Image/jpeg",1.0)
    const clientName = isSuccessClient && client.data.name+"_"+client.data.business_type;
    const label = isSuccessClient && client.data.name+" "+client.data.business_type;
    let pdf = new jsPDF("l", "pt", "a2");
        pdf.setFontSize(22);
        pdf.addImage(chart1Image, 'JPEG',140 ,300, 1440, 400);
        pdf.setTextColor('red');
        pdf.text(`SWIFT TICKET ${label.toUpperCase()} REVENUE REPORT`,620 ,100, 1440, 600);
        pdf.setFontSize(16);
        pdf.setTextColor('black')
        pdf.text(`Copyright 2023 Switft Ticket`,760 ,980, 1440, 1440);
        pdf.save('Swift_Ticket_'+clientName+"_Annual_revenue_repord.pdf");
  }

  const chartBgColor = {
    id: 'chartBgColor',
    beforeDraw: (chart, options)=>{
      const{ ctx, width, height }= chart;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    }
  }


    return   (
                <div className="bg-gray-100">
                    <ClientDataAnalysisHeaderView />
                     {
                        (isSuccessClient)?
                            <div className="flex justify-center items-center bg-gray-50 p-1">
                                <h1 className="text-xs text-gray-700 uppercase font-semibold p-2">{client.data.name} total sales reports summary</h1>
                            </div>
                         : null
                     }
                      {
                        (isSuccessClientSalesReport)?
                          (clientSalesReport.data.length)?
                            <div>
                              {
                                clientSalesReport.data.map(data=>(
                                  (data.annualSales)?
                                    <div className="flex items-center text-md justify-end md:mt-1 md:p-4 mt-1 p-2">
                                        <div onClick={generatePDF} className='bg-gray-100 p-1 hover:bg-gray-200 border border-gray-600 cursor-pointer text-xs p-0 md:mr-8 mr-1 rounded flex items-center space-x-1 justify-center'>
                                          <p className="">Generate all to PDF</p>
                                          <DocumentIcon className="h-5 round-full text-gray-500" />
                                        </div>
                                    </div>
                                  :null
                                ))
                              }
                              {
                                (isSuccessClient)?
                                    <div class="mt-4 sm:mt-0  md:pt-4 space-y-4 md:mr-28 md:ml-28  lg:mr-28 lg:ml-28 mb-12">
                                        <div class="grid grid-cols-1">         
                                            {
                                                  clientSalesReport.data.map(data => (
                                                      (data.last28days_revenue)?
                                                      <div>
                                                        <div>
                                                          <Line
                                                              height={300}
                                                              data={{
                                                                labels: (data.last28days_revenue)? data.last28days_revenue.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                                datasets: [
                                                                  {
                                                                    label: 'Total',
                                                                    data: (data.last28days_revenue)? data.last28days_revenue.map(data=>data.cashTotal): '',
                                                                    fill: "start",
                                                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                                                      const ctx = context.chart.ctx;
                                                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                      gradient.addColorStop(1, 'rgba(54, 162, 235, 0.05)');
                                                                      gradient.addColorStop(0, 'rgba(250,174,50,0.1)');
                                                                      return gradient;
                                                                    },
                                                                    borderColor: 'rgba(75, 192, 192, 1)',
                                                                    borderWidth: 1
                                                                  },
                                                                ]
                                                              }}
                                                              options={{
                                                                bezierCurve: true,
                                                                plugins: {
                                                                  color: "red",
                                                                  legend: {
                                                                    position: 'top',
                                                                  },
                                                                  title: {
                                                                    display: true,
                                                                    text: 'Last 28 days daily revenue report',
                                                                  },
                                                                  tooltip: {
                                                                    enabled: false
                                                                  },
                                                                  datalabels: {
                                                                    align: 'top',
                                                                    labels: {
                                                                      value: {
                                                                        color: 'black',
                                                                        font: {
                                                                          weight: ''
                                                                        }
                                                                      }
                                                                    },
                                                                    formatter: (value)=>{
                                                                      return numberWithCommas(value);
                                                                    }
                                                                  }
                                                                },
                                                                elements: {
                                                                  line: {
                                                                    tension: 0.16
                                                                  }
                                                                },
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                  yAxes: [
                                                                    {
                                                                      ticks: {
                                                                        beginAtZero: true,
                                                                        stepSize: 10
                                                                      }
                                                                    }
                                                                  ]
                                                                },
                                                                layout: {
                                                                  padding: {
                                                                      right: 50,
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            plugins={[chartBgColor]}
                                                            />
                                                        </div>
                                                      </div>
                                                      :null
                                                  ))
                                            }
                                        </div>
                                        <div class="grid grid-cols-1">         
                                            {
                                                  clientSalesReport.data.map(data => (
                                                      (data.last28days_sales)?
                                                      <div>
                                                        <div>
                                                          <Line
                                                              height={300}
                                                              data={{
                                                                labels: (data.last28days_sales)? data.last28days_sales.map(data=>formatDisplayDateForDayMonth(data.date)): '',
                                                                datasets: [
                                                                  {
                                                                    label: 'Total',
                                                                    data: (data.last28days_sales)? data.last28days_sales.map(data=>data.total): '',
                                                                    fill: "start",
                                                                    backgroundColor: (context: ScriptableContext<"line">) => {
                                                                      const ctx = context.chart.ctx;
                                                                      const gradient = ctx.createLinearGradient(0, 0, 0, 260);
                                                                      gradient.addColorStop(1, 'rgba(153, 102, 255, 0.02)');
                                                                      gradient.addColorStop(0, 'rgba(153, 102, 255,0.1)');
                                                                      return gradient;
                                                                    },
                                                                    borderColor: 'rgba(153, 102, 255, 0.7)',
                                                                    borderWidth: 1
                                                                  },
                                                                ]
                                                              }}
                                                              options={{
                                                                bezierCurve: true,
                                                                plugins: {
                                                                  color: "red",
                                                                  legend: {
                                                                    position: 'top',
                                                                  },
                                                                  title: {
                                                                    display: true,
                                                                    text: 'Last 28 days daily sales report',
                                                                  },
                                                                  tooltip: {
                                                                    enabled: false
                                                                  },
                                                                  datalabels: {
                                                                    align: 'top',
                                                                    labels: {
                                                                      value: {
                                                                        color: 'black',
                                                                        font: {
                                                                          weight: ''
                                                                        }
                                                                      }
                                                                    },
                                                                    formatter: (value)=>{
                                                                      return value;
                                                                    }
                                                                  }
                                                                },
                                                                elements: {
                                                                  line: {
                                                                    tension: 0.16
                                                                  }
                                                                },
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                  yAxes: [
                                                                    {
                                                                      ticks: {
                                                                        beginAtZero: true,
                                                                        stepSize: 10
                                                                      }
                                                                    }
                                                                  ]
                                                                },
                                                                layout: {
                                                                  padding: {
                                                                      right: 50,
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            plugins={[chartBgColor]}
                                                            />
                                                        </div>
                                                      </div>
                                                      :null
                                                  ))
                                            }
                                        </div>
                                    </div>
                                : isLoadingClient?
                                    <LoadingSpinner />
                                : isErrorClient?
                                    <div className="md:flex flex-col p-4 mb-20">
                                        <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                                    </div>
                                : null
                                
                            }
                            </div>
                          :
                            <div className="md:flex flex-col p-4 mb-20">
                              <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                            </div>
                        : isLoadingClientSalesReport?
                          <LoadingSpinner />
                        : isErrorClientSalesReport?
                          <div className="md:flex flex-col p-4 mb-20">
                              <p className="text-blue-500 italic border-b border-black flex flex-1">Data not available</p>
                          </div>
                        :null
                      }
                </div>
    )
}

export default ClientDataAnalystTotalSalesSummaryReportingView;
